import * as React from 'react';
import { NewDialog } from 'web-components';
import { Grid, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function DeleteSensorCard({ open, handleCloseDialog, handleApply }) {
  const { t } = useTranslation();

  const handleOk = () => {
    handleApply();
    handleCloseDialog();
  };

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {t('dialog.delete_sensor.message')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const actionBar = (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => handleCloseDialog()}
        sx={{ height: '56px', padding: '16px' }}
      >
        {t('default_actions.keep_it')}
      </Button>
      <Button variant="contained" color="error" onClick={handleOk} sx={{ height: '56px', padding: '16px' }}>
        {t('default_actions.yes_remove')}
      </Button>
    </>
  );

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.delete_sensor.title')}
      subTitle={t('dialog.delete_sensor.subtitle')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minWidth="450px"
      maxWidth="98%"
      actionBar={actionBar}
    />
  );
}

DeleteSensorCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired
};

export default DeleteSensorCard;

import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import { ButtonContainer } from './elements';

interface AccountFormActionButtonsProps {
  dirty: boolean;
  isSubmitting: boolean;
  handleReset: () => void;
  handleSubmit: () => void;
  errors: {
    first_name: string;
    last_name: string;
    phone: {
      number: string;
    };
  };
  isMobile: boolean;
}

const AccountFormActionButtons = ({
  dirty,
  isSubmitting,
  handleReset,
  handleSubmit,
  errors,
  isMobile
}: AccountFormActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer container>
      <Button
        variant="text"
        color="primary"
        disabled={!dirty || isSubmitting}
        onClick={handleReset}
        fullWidth={isMobile}
      >
        {t('form.cancel')}
      </Button>
      <Button
        disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
        endIcon={isSubmitting && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        type="submit"
        fullWidth={isMobile}
      >
        {t('form.apply')}
      </Button>
    </ButtonContainer>
  );
};

export default AccountFormActionButtons;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

const MachineErrorTooltip = ({ error }) => {
  const { t } = useTranslation();

  return (error || {}).code || (error || {}).is_custom ? (
    <div>{error.is_custom ? error.message : `${error.code}: ${t(`dashboard.machines.error_codes.${error.code}`)}`}</div>
  ) : (
    ''
  );
};

MachineErrorTooltip.propTypes = {
  error: T.shape({
    code: T.string,
    is_custom: T.bool,
    message: T.string
  })
};

MachineErrorTooltip.defaultProps = {
  error: {
    code: '',
    is_custom: '',
    message: ''
  }
};

export default MachineErrorTooltip;

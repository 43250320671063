export const PERIOD_TYPE_LAST_8_HOURS = 'PERIOD_TYPE_LAST_8_HOURS';
export const PERIOD_TYPE_LAST_24_HOURS = 'PERIOD_TYPE_LAST_24_HOURS';
export const PERIOD_TYPE_LAST_WEEK_DAYS = 'PERIOD_TYPE_LAST_WEEK_DAYS';
export const PERIOD_TYPE_CUSTOM_RANG = 'PERIOD_TYPE_CUSTOM_RANG';

export const periodTypeList = [PERIOD_TYPE_LAST_8_HOURS, PERIOD_TYPE_LAST_24_HOURS, PERIOD_TYPE_LAST_WEEK_DAYS];

/**
 * Get date range for the given period type
 * @param {string} periodType
 * @returns {Array<Date>}
 */
export function getDateRange(periodType) {
  const pastDate = new Date();
  const currentDate = new Date();

  switch (periodType) {
    case PERIOD_TYPE_LAST_8_HOURS:
      pastDate.setHours(currentDate.getHours() - 8);
      break;
    case PERIOD_TYPE_LAST_24_HOURS:
      pastDate.setHours(currentDate.getHours() - 24);
      break;
    case PERIOD_TYPE_LAST_WEEK_DAYS:
      pastDate.setDate(currentDate.getDate() - 7);
      break;
    default:
      pastDate.setHours(currentDate.getHours() - 8);
      break;
  }

  return [pastDate.toISOString(), currentDate.toISOString()];
}

export const ACCESS_TYPE_USER_ONLY = 'ACCESS_TYPE_USER_ONLY';
export const ACCESS_TYPE_ALL_ORG = 'ACCESS_TYPE_ALL_ORG';

export const LINE_STYLE_DASH = 'LINE_STYLE_DASH';
export const LINE_STYLE_SOLID = 'LINE_STYLE_SOLID';

/**
 * Returns the line style based on the given value.
 *
 * @param {string} value - The value to determine the line style.
 * @returns {string} The line style ('dashed' or 'solid').
 */
export const getLineStyle = value => {
  if (value === LINE_STYLE_DASH) {
    return 'dashed';
  }
  return 'solid';
};

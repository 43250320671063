import { createSelector } from 'reselect';

const getActiveOrganization = state => state.organization;
const getUserInfo = state => state.user;
const getUserId = state => state.id;

const getOrganization = createSelector([getUserInfo], userInfo => userInfo.organization);

const getUserData = createSelector([getUserInfo], userInfo => userInfo.user.data);

const getActiveOrganizationId = createSelector([getUserData], userData => userData.organization.id);

const getUserRole = createSelector([getUserData], user => user.organization.userRole);

const getUserFeatures = createSelector([getUserData], user => user.features);

export {
  getActiveOrganization,
  getUserId,
  getUserInfo,
  getUserRole,
  getUserData,
  getOrganization,
  getActiveOrganizationId,
  getUserFeatures
};

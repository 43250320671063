import React, { useCallback, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { NotifyIcon } from 'web-components';

import { ERROR, INFO, SUCCESS, WARNING } from '../../attrs/status';
import { NOTIFICATION_COLORS } from '../../attrs/colors';
import {
  NOTIFICATION_TYPE_MASCHINE,
  NOTIFICATION_TYPE_OPTIONS,
  NOTIFICATION_TYPE_SENSOR
} from '../../attrs/notifications';
import MachineAlert from '../elements/MachineAlert';
import { getNotifications } from '../../redux/ui/selectors';
import { toggleAlertsMenu } from '../../redux/ui/alertsMenu/actions';
import { resolveNotification } from '../../redux/ui/notifications/actions';
import { markAlertAsRead } from '../../redux/alerts/actions';
import { getAlertUrl } from '../../helpers/alerts';
import { isNullOrUndefined } from '../../helpers/utils';

const variantIcon = {
  [SUCCESS]: <NotifyIcon iconName="checkmark" style={{ color: NOTIFICATION_COLORS[SUCCESS], marginRight: '.5rem' }} />,
  [WARNING]: <NotifyIcon iconName="warning" style={{ color: NOTIFICATION_COLORS[WARNING], marginRight: '.5rem' }} />,
  [ERROR]: <NotifyIcon iconName="error" style={{ color: NOTIFICATION_COLORS[ERROR], marginRight: '.5rem' }} />,
  [INFO]: <NotifyIcon iconName="info" style={{ color: NOTIFICATION_COLORS[INFO], marginRight: '.5rem' }} />
};

let displayed = [];

/**
 * Create the content message for the snackbar with translation
 * @param message Translation key for the message
 * @param type Variant icon type
 * @param t Translation service
 * @param {[{name: string, data: string}]} variables Array of variables to supply the message
 * @param variables.name The variable declared inside the translation key
 * @param variables.data The content to replace the variable declared in the translation
 * @returns {JSX.Element}
 */
const createMessage = (message, type, t, variables) => {
  let text = t(message);
  if (!isNullOrUndefined(variables) && typeof variables === 'object') {
    const obj = {};
    variables.forEach(variable => {
      obj[variable.name] = variable.data;
    });
    text = t(message, obj);
  }
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {variantIcon[type]}
      {text}
    </span>
  );
};

const Notifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  const openAlertsMenu = useCallback(() => {
    dispatch(toggleAlertsMenu(true));
    closeSnackbar();
  }, [dispatch, closeSnackbar]);

  const handleViewAlertClick = useCallback(
    alert => {
      const url = getAlertUrl(alert);

      dispatch(markAlertAsRead(alert));
      dispatch(push(url));
      closeSnackbar();
    },
    [dispatch, closeSnackbar]
  );

  useEffect(() => {
    notifications.forEach(
      ({ key, message, variables, type, alert, options = {}, notificationType, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        const typeSpecificOptions = NOTIFICATION_TYPE_OPTIONS[notificationType];

        if (notificationType === NOTIFICATION_TYPE_MASCHINE || notificationType === NOTIFICATION_TYPE_SENSOR) {
          enqueueSnackbar(null, {
            ...typeSpecificOptions,
            content: id => (
              <MachineAlert
                id={id}
                closeSnackbar={closeSnackbar}
                openAlertsMenu={openAlertsMenu}
                alert={alert}
                onViewAlertClick={handleViewAlertClick}
              />
            ),
            onExited: (event, myKey) => {
              dispatch(resolveNotification(key));
              removeDisplayed(myKey);
            },
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            }
          });
        } else {
          const action = () => (
            <IconButton key="close" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
              <NotifyIcon fontSize="small" iconName="close" />
            </IconButton>
          );

          // display snackbar using notistack
          enqueueSnackbar(createMessage(message, type, t, variables), {
            key,
            action,
            ...options,
            ...typeSpecificOptions,
            onClose: (event, reason, myKey) => {
              if (options.onClose) {
                options.onClose(event, reason, myKey);
              }
            },
            onExited: (event, myKey) => {
              dispatch(resolveNotification(myKey));
              removeDisplayed(myKey);
            }
          });
        }

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t, handleViewAlertClick, openAlertsMenu]);

  return null;
};

export default Notifications;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';

import { useTranslation } from 'react-i18next';
import { FormElements, NotifyIcon } from 'web-components';

import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import { getMillSensor } from 'attrs/sensorType';
import { GraphTemplateCard } from './GraphTemplateCard';
import StyledButton from '../../../../components/elements/StyledButton';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import { isNullUndefinedOrEmpty } from '../../../../helpers/utils';
import { favoriteGraphTemplate, loadGraphsTemplates } from '../../../../redux/graphTemplates/actions';
import { getAllGraphTemplates, getFavoriteTemplate } from '../../../../redux/graphTemplates/selectors';

import { AddTemplate } from './AddTemplate';
import { AddNewTemplate } from './AddNewTemplate';
import { CloneNewTemplate } from './CloneNewTemplate';

import EmptyState from '../../../../components/EmptyState';

const GraphsTemplates = ({ machine, sensors }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const graphTemplates = useSelector(getAllGraphTemplates);

  const sortedGraphTemplates = useMemo(
    () => orderBy(graphTemplates, ['favorite', 'name'], ['desc', 'asc']),
    [graphTemplates]
  );

  const favoriteTemplate = useSelector(getFavoriteTemplate);

  const sensorsLines =
    sensors.length > 0 && sensors
      ? sensors.map(sensor => {
          const millSensor = getMillSensor(sensor?.type);
          const sensorName = t(millSensor?.name || sensor?.name);
          return {
            title: sensorName,
            value: sensor.type
          };
        })
      : [];

  const [templateName, setTemplateName] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [sensorsList, setSensorList] = useState([]);
  const [openNewTemplateDialog, setOpenNewtemplateDialog] = useState(false);
  const [openAddNewTemplateIsOpen, setAddNewTemplateIsOpen] = useState(false);
  const [openCloneTemplateIsOpen, setCloneTemplateIsOpen] = useState(false);
  const [clean, setClean] = useState(false);

  const loadTemplates = useCallback(() => {
    const accessType = isChecked ? 'ACCESS_TYPE_ALL_ORG' : 'ACCESS_TYPE_USER_ONLY';
    dispatch(
      loadGraphsTemplates({
        machineId: machine.id,
        accessType,
        sensorsList,
        templateName
      })
    );
  }, [dispatch, machine.id, isChecked, sensorsList, templateName]);

  useEffect(() => {
    if (machine.id) {
      loadTemplates();
    }
  }, [machine.id, isChecked, templateName, sensorsList, favoriteTemplate, loadTemplates]);

  const handleOpenAddTemplateDialog = () => {
    setOpenNewtemplateDialog(true);
  };

  const handleCloseAddTemplateDialog = (_event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpenNewtemplateDialog(false);
    }
  };

  const handleCloseAddNewTemplateDialog = (_event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setAddNewTemplateIsOpen(false);
    }
  };

  const handleCloseAddNewCloneTemplateDialog = (_event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setCloneTemplateIsOpen(false);
    }
  };

  const handleChangeOnlyMe = event => {
    setIsChecked(event.target.checked);
  };

  const handleFavoriteChange = (newFavoriteValue, graphId) => {
    dispatch(favoriteGraphTemplate(graphId));
  };

  const handleSensorChange = (event, newValue) => {
    setSelectedSensors(newValue);
    setSensorList(newValue.map(item => item.value));
  };

  return (
    <>
      <AddTemplate
        open={openNewTemplateDialog}
        setNewTemplateIsOpen={setAddNewTemplateIsOpen}
        setCloneTemplateIsOpen={setCloneTemplateIsOpen}
        handleCloseDialog={handleCloseAddTemplateDialog}
      />

      <Box
        sx={{
          mt: { xs: '32px', sm: '-40px' },
          fontSize: { xs: '12px', sm: '14px' },
          textAlign: { xs: 'left', sm: 'right' }
        }}
      >
        <StyledButton
          iconName="plus"
          buttonText={t('machines.detail.tabs.templates.template')}
          variant="outlined"
          color="primary"
          size="medium"
          data-selector="machine-details-export-data-button"
          onClick={handleOpenAddTemplateDialog}
          style={{ padding: '1rem' }}
          sx={{
            width: { xs: '100%', sm: '135px' }
          }}
        />
      </Box>

      <AddNewTemplate
        machine={machine}
        open={openAddNewTemplateIsOpen}
        handleCloseDialog={handleCloseAddNewTemplateDialog}
      />

      <CloneNewTemplate
        machineId={machine.id}
        open={openCloneTemplateIsOpen}
        handleCloseDialog={handleCloseAddNewCloneTemplateDialog}
      />

      <Grid
        container
        spacing={2}
        sx={{
          mt: { xs: '12px', sm: '32px', md: '0px' }
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <FormElements.TextField
            label={t('machines.detail.tabs.templates.search_name_button')}
            bgcolor="#FFFFFF"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="search" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={e => setTemplateName(e.target.value)}
            value={templateName}
            data-selector="batch-note-modal-title-field"
            sx={{
              width: { xs: '100%', md: '100%' }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormElements.MultiSelect
            label={t('machines.detail.tabs.templates.filter_by_sensor')}
            bgcolor="#FFFFFF"
            name="filter_sensor"
            id="filter_sensor"
            activeColor={COLOR_PRIMARY}
            fullWidth
            value={selectedSensors}
            setFieldValue={handleSensorChange}
            options={sensorsLines.length > 0 && sensorsLines?.filter(s => !isNullUndefinedOrEmpty(s.title))}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="machines-detail-tabs-templates-filter-by-sensor-field"
            clear={clean}
            setClear={setClean}
            sx={{
              width: { xs: '100%', md: '100%' },
              input: { bgcolor: 'white !important' },
              '.MuiTextField-root > .MuiInputBase-root': { bgcolor: 'white !important' }
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: '24px' }}>
        <Grid item xs={12} md={6}>
          <FormElements.Checkbox
            activeColor={COLOR_PRIMARY}
            label={t('machines.detail.tabs.templates.see_all_graph_templates')}
            checked={isChecked}
            onChange={handleChangeOnlyMe}
          />
        </Grid>
      </Grid>

      <Stack spacing={{ sm: 2, md: 2 }} sx={{ mt: '18px' }}>
        {sortedGraphTemplates && sortedGraphTemplates.length > 0 ? (
          sortedGraphTemplates.map(graph => (
            <GraphTemplateCard key={graph?.id} graph={graph} onFavoriteChange={handleFavoriteChange} />
          ))
        ) : (
          <EmptyState type="graph_templates" />
        )}
      </Stack>
    </>
  );
};

GraphsTemplates.propTypes = {
  machine: T.shape({
    id: T.oneOfType([T.string, T.number]).isRequired
  }).isRequired,
  sensors: T.arrayOf(T.shape({})).isRequired
};

export default GraphsTemplates;

import styled from 'styled-components';

import { Dialog } from 'web-components';

export const DialogHeaderStyled = styled(Dialog.Header)`
  & .MuiTypography-h1 {
    font-size: 1.75rem;
  }
`;

export const DialogSubHeaderStyled = styled(Dialog.SubHeader)`
  & .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`;

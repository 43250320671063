import { put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as constants from './constants';
import { login, logout, requestResetPassword } from '../../api/user';
import { checkToken, registerUserWithDetails, resetPasswordNonAuth } from '../../api/auth';
import { actionStatus, statusAction, getError, getUserInformationFromToken, getMessage } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { checkAuthentication, updateAuthTokens, setAuthentication } from './actions';
import { getIsAuthenticated } from './selectors';
import { setUserInformation } from '../user/actions';

function* handleUpdateTokens({ data }) {
  if (data) {
    yield localStorage.setItem('accessToken', data.access);
    yield localStorage.setItem('refreshToken', data.refresh);
    yield put(checkAuthentication());
  } else {
    yield localStorage.removeItem('refreshToken');
    yield localStorage.removeItem('accessToken');

    if (window.location.pathname !== '/login') {
      window.location = '/login';
    }

    window.location.reload();
  }
}

function* handleLogin({ userLogin, password, recaptchaValue }) {
  yield put(statusAction(constants.LOGIN, actionStatus.START));
  try {
    const { data } = yield login(userLogin, password, recaptchaValue);

    yield put(updateAuthTokens(data));
    yield put(statusAction(constants.LOGIN, actionStatus.SUCCESS));
    yield put(setAuthentication(true));
  } catch (err) {
    window.grecaptcha.reset();
    const message = getMessage(err);
    yield put(
      statusAction(constants.LOGIN, actionStatus.ERROR, {
        message
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.login.${message}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );

    yield put(setAuthentication(false));
  }
}

function* handleExternalLogin({ authorization }) {
  yield put(statusAction(constants.EXTERNAL_LOGIN, actionStatus.START));
  try {
    yield put(updateAuthTokens(authorization));
    yield put(statusAction(constants.EXTERNAL_LOGIN, actionStatus.SUCCESS));
    yield put(setAuthentication(true));
    yield put(push('/dashboard'));
  } catch (err) {
    window.grecaptcha.reset();
    const message = getMessage(err);
    yield put(
      statusAction(constants.EXTERNAL_LOGIN, actionStatus.ERROR, {
        message
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.login.${message}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );

    yield put(setAuthentication(false));
    yield put(push('/login'));
  }
}

function* handleLogout({ url }) {
  yield put(statusAction(constants.LOGOUT, actionStatus.START));
  try {
    yield logout();
    yield put(setAuthentication(false));

    // removes the access & refreshtoken from localstore
    yield localStorage.removeItem('accessToken');
    yield localStorage.removeItem('refreshToken');

    yield put(statusAction(constants.LOGOUT, actionStatus.SUCCESS));
    if (url) {
      yield put(push(url));
    }
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOGOUT, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.logout.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleCheckAuthentication() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const isAuthenticated = yield select(getIsAuthenticated);

  if (refreshToken && accessToken) {
    // always load user information
    const user = getUserInformationFromToken(accessToken);
    yield put(setUserInformation(user));

    if (!isAuthenticated) {
      yield put(setAuthentication(true));
    }
  } else if (isAuthenticated) {
    yield put(setAuthentication(false));
  }
}

function* handleRequestPasswordReset({ resetLogin, recaptchaValue }) {
  yield put(statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.START));
  try {
    yield requestResetPassword(resetLogin, recaptchaValue);

    yield put(statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.SUCCESS));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.request_success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    window.grecaptcha.reset();
    const error = getError(err);
    yield put(
      statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.auth.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleCheckTokenCode({ value }) {
  yield put(statusAction(constants.CHECK_TOKEN_CODE, actionStatus.START));
  try {
    const { data } = yield checkToken(value);
    yield put(statusAction(constants.CHECK_TOKEN_CODE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CHECK_TOKEN_CODE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.auth.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleResetPasswordNonAuth({ values, auth }) {
  yield put(statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.START));
  try {
    yield resetPasswordNonAuth(values, auth);
    yield put(statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.SUCCESS));

    yield put(push('/login'));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.user.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleRegisterUser({ values, auth, recaptchaValue }) {
  yield put(statusAction(constants.REGISTER, actionStatus.START));
  try {
    const { data } = yield resetPasswordNonAuth(values, auth, recaptchaValue);
    yield put(statusAction(constants.REGISTER, actionStatus.SUCCESS));

    // get user information from token
    const user = getUserInformationFromToken(data.access);

    const filteredUser = { ...user };
    delete filteredUser.id;
    const userData = {
      ...filteredUser,
      ...values
    };
    yield registerUserWithDetails(userData, user.id, data.access);

    yield put(statusAction(constants.REGISTER, actionStatus.SUCCESS));

    yield handleLogin({ userLogin: user['e-mail'], password: values.password, recaptchaValue });
    yield put(push('/dashboard'));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.register_success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    window.grecaptcha.reset();
    const error = getError(err);
    yield put(
      statusAction(constants.REGISTER, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.register.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOGIN, handleLogin);
  yield takeLatest(constants.EXTERNAL_LOGIN, handleExternalLogin);
  yield takeLatest(constants.LOGOUT, handleLogout);
  yield takeLatest(constants.CHECK_AUTHENTICATION, handleCheckAuthentication);
  yield takeLatest(constants.REQUEST_PASSWORD_RESET, handleRequestPasswordReset);
  yield takeLatest(constants.CHECK_TOKEN_CODE, handleCheckTokenCode);
  yield takeLatest(constants.RESET_PASSWORD_NON_AUTH, handleResetPasswordNonAuth);
  yield takeLatest(constants.REGISTER, handleRegisterUser);
  yield takeLatest(constants.UPDATE_AUTH_TOKENS, handleUpdateTokens);
}

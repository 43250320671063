import * as constants from './constants';

const updateAuthTokens = data => ({
  type: constants.UPDATE_AUTH_TOKENS,
  data
});

const checkAuthentication = () => ({
  type: constants.CHECK_AUTHENTICATION
});

const login = (userLogin, password, recaptchaValue) => ({
  type: constants.LOGIN,
  userLogin,
  password,
  recaptchaValue
});

const externalLogin = authorization => ({
  type: constants.EXTERNAL_LOGIN,
  authorization
});

const logout = url => ({
  type: constants.LOGOUT,
  url
});

const setAuthentication = value => ({
  type: constants.SET_AUTHENTICATION,
  value
});

const requestPasswordReset = (resetLogin, recaptchaValue) => ({
  type: constants.REQUEST_PASSWORD_RESET,
  resetLogin,
  recaptchaValue
});

const checkTokenCode = value => ({
  type: constants.CHECK_TOKEN_CODE,
  value
});

const resetPasswordNonAuth = (values, auth) => ({
  type: constants.RESET_PASSWORD_NON_AUTH,
  values,
  auth
});

const registerUser = (values, auth, recaptchaValue) => ({
  type: constants.REGISTER,
  values,
  auth,
  recaptchaValue
});

export {
  checkAuthentication,
  login,
  externalLogin,
  logout,
  setAuthentication,
  requestPasswordReset,
  checkTokenCode,
  resetPasswordNonAuth,
  registerUser,
  updateAuthTokens
};

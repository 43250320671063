import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import T from 'prop-types';
import { DivStyled } from './Loading.styled';

function CircularIndeterminate({ visible }) {
  return visible ? (
    <DivStyled>
      <CircularProgress />
    </DivStyled>
  ) : null;
}

CircularIndeterminate.propTypes = {
  visible: T.bool.isRequired
};

export default CircularIndeterminate;

import React from 'react';
import T from 'prop-types';

import { ReferenceLine } from 'recharts';

import { AuxiliaryAreaChart } from 'components/Graphs/multipleSensors/MultipleSensorsGraph';
import { DARKER_GREY } from 'attrs/colors';

const renderArrowUp = ({ viewBox: { x, y } }) => {
  const width = 10;
  const height = 8;
  return (
    <svg x={x - width / 2} y={y - height / 2} width={width} height={height} fill="none">
      <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill={DARKER_GREY} />
    </svg>
  );
};

const CustomChartLabels = ({ sensors, xAxisDomain, xAxisTicks, chartData, config }) => (
  <AuxiliaryAreaChart sensors={sensors} xAxisDomain={xAxisDomain} xAxisTicks={xAxisTicks} chartData={chartData}>
    {config.map(({ time, label, position, shouldRenderArrow }) => (
      <ReferenceLine
        key={label}
        x={time}
        stroke="transparent"
        yAxisId="machine_state"
        label={
          shouldRenderArrow
            ? renderArrowUp
            : {
                value: label,
                position,
                offset: 0,
                fill: DARKER_GREY,
                fontSize: '0.5rem'
              }
        }
      />
    ))}
  </AuxiliaryAreaChart>
);

CustomChartLabels.propTypes = {
  sensors: T.arrayOf(T.shape({})).isRequired,
  xAxisDomain: T.arrayOf(T.number).isRequired,
  xAxisTicks: T.arrayOf(T.number).isRequired,
  chartData: T.arrayOf(T.shape({})).isRequired,
  config: T.arrayOf(
    T.shape({
      time: T.number,
      label: T.string,
      position: T.string,
      shouldRenderArrow: T.bool
    })
  ).isRequired
};

export default CustomChartLabels;

import axios from './axios';

const CUSTOM_SENSOR_URL = '/custom_sensors';

const getCustomSensor = customSensorId =>
  axios({
    method: 'get',
    url: `/v1${CUSTOM_SENSOR_URL}/${customSensorId}`
  });

export { getCustomSensor };

import axios from './axios';

const getAlerts = userId =>
  axios({
    method: 'get',
    url: '/v1/alerts',
    params: {
      user_id: userId
    }
  });

const getNotifications = userId =>
  axios({
    method: 'get',
    url: '/v1/notifications',
    params: {
      user_id: userId
    }
  });

const deleteNotifications = notificationsIds =>
  axios({
    method: 'delete',
    url: '/v1/notifications',
    params: {
      notification_ids: notificationsIds.toString()
    }
  });

export { getAlerts, getNotifications, deleteNotifications };

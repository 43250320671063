import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NotifyTypo } from 'web-components';

import withLoadingState from '../../../helpers/withLoadingState';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import { getCheckAuthCode } from '../../../redux/auth/selectors';
import RegisterAccountForm from '../../../components/forms/Auth/RegisterAccountForm';

const { Heading2, Body1, InfoText } = NotifyTypo;

const RegisterAccount = ({ token, initLanguage }) => {
  const { t } = useTranslation();
  const { data } = useSelector(getCheckAuthCode);

  return (
    initLanguage && (
      <>
        <Heading2 align="center" gutterBottom style={{ color: COLOR_PRIMARY }}>
          {t('profile.thanks_for_joining')}
        </Heading2>
        <Body1 align="center">{t('profile.register_description')}</Body1>
        <InfoText align="center" gutterBottom style={{ marginBottom: '2rem' }}>
          {t('profile.account_register_info_text')}
          <b>{data['e-mail']}</b>
        </InfoText>
        <RegisterAccountForm token={token} initLanguage={initLanguage} />
      </>
    )
  );
};

RegisterAccount.propTypes = {
  token: T.string.isRequired,
  initLanguage: T.string
};

RegisterAccount.defaultProps = {
  initLanguage: null
};

export default withLoadingState(RegisterAccount);

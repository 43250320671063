import styled from 'styled-components';
import { Dialog, FormElements } from 'web-components';
import { DISABLED_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from 'attrs/colors';

const DIALOG_BACKGROUND_COLOR = '#FFFFFF';
const FOOTER_BACKGROUND_COLOR = '#F8F8F9';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background-color: ${FOOTER_BACKGROUND_COLOR} !important;
    overflow: hidden;
  }
`;

// Define the header title
export const StyledDialogHeader = styled(Dialog.Header)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  padding: 1rem 0 0.5rem !important;

  & h2 {
    margin-left: 3rem;
  }
  
  & .MuiTypography-h1 {
    font-size: 1.75rem;
    align-self: center;

    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
    }
  }

  & .MuiIconButton-root {
    position: absolute;
    right: .5rem;
  }
`
);

// Define the subheader title
export const StyledDialogSubHeader = styled(Dialog.SubHeader)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  &.MuiDialogTitle-root {
    padding: 0 4rem;
  }

  & .MuiTypography-body1 {
    font-size: 0.875rem;

    ${theme.breakpoints.down('xs')} {
      margin-top: -0.5rem;
    }
  }
`
);

// Body
export const StyledDialogBody = styled(Dialog.Body)`
  background-color: ${DIALOG_BACKGROUND_COLOR};
  min-height: 60vh;
`;

// Buttons
export const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    margin: 1.25rem;

    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 0.375rem 2.25rem;
    }
  }
`;

export const StyledSambaSystemRadioGroup = styled.div(
  ({ theme, disabled }) => `
    margin: 1rem 0 0 0.75rem;

    & > .MuiTypography-subtitle2 {
      font-size: 0.75rem;
      margin-bottom: -0.375rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};
    }

    & > .MuiTypography-caption {
      font-size: 1rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        display: block;
        height: 2rem;
        margin-top: 0.25rem;
      }
    }

    & .MuiRadio-root {
      margin-top: -0.125rem;
      font-size: 0.625rem;
    }

    & > label {
      font-size: 0.875rem;
      margin-left: 1rem;
      color: ${disabled ? DISABLED_COLOR : PRIMARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        &:first-of-type {
          margin-left: -0.625rem;
        }        
      }
    }
  `
);

export const StyledCheckbox = styled(FormElements.Checkbox)`
  &.MuiFormControlLabel-root {
    margin: 0 2rem 0 -11px;

    & .MuiTypography-root {
      font-size: 0.875rem;
    }
  }

  margin-bottom: 8px;
`;

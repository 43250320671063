import styled, { css } from 'styled-components';
import { Card } from '@mui/material';
import { COLOR_MAIN_HIGHLIGHT_TEXT, COLOR_PRIMARY } from '../../attrs/colors';

const StyledCard = styled(Card)`
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
  &.MuiPaper-root {
    padding: 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 70px;
    box-shadow: 0 4px 4px rgba(54, 125, 120, 0.25);
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      ${props => props.borderColor || COLOR_PRIMARY} 5px,
      ${COLOR_MAIN_HIGHLIGHT_TEXT} 5px
    );
  }

  &:hover {
    box-shadow: rgba(86, 93, 100, 0.2) 0 0.5rem 2.5rem 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;

const ContentCard = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
`;

export { StyledCard, StyledContent, ContentCard };

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';

import { Brush, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useResolutionCheck } from 'web-components';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import { BRUSH_HEIGHT } from '../../../attrs/layout';
import { StyledBrushArea } from '../elements';
import { getData } from '../../../redux/rootSelectors';
import { getSelectedMachineSensorComparisonSensorData } from '../../../redux/machines/selectors';
import { getScopedTimeWindow, getTimeWindow } from '../../../redux/ui/settings/selectors';
import { setIsBatchRecordsDetails, setScopedTimeWindow } from '../../../redux/ui/settings/actions';
import { COLORS, removeLabelsReturnedMetricsChartData } from './utils';
import {
  getChartGraphIndex,
  getTimeWindowAfterBrushChange,
  getYAxisDomainBySensor,
  isValidTimeRange,
  CustomTickChart
} from '../graphUtils';
import { getSensorName, getSensorUnit } from '../../../helpers/utils';

const MultipleSensorsBrush = ({ machineId, isBatchRecords, batchId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timer = useRef(null);
  const { isMobile, isSmallDevice } = useResolutionCheck();

  const metricsHistoryChartData = useSelector(compose(getData, getSelectedMachineSensorComparisonSensorData));

  const sensors = metricsHistoryChartData.sensors.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const timeWindow = useSelector(getTimeWindow);
  const scopedTimeWindow = useSelector(getScopedTimeWindow);

  const [initialIndices, setInitialIndices] = useState({ startIndex: null, endIndex: null });
  const [indices, setIndices] = useState({ startIndex: 0, endIndex: 0 });
  const [redraw, setRedraw] = useState(0);

  useEffect(() => {
    const scopedTo = moment(timeWindow.to).valueOf();
    const scopedFrom = moment(timeWindow.from).valueOf();

    setInitialIndices({
      startIndex: getChartGraphIndex(
        metricsHistoryChartData.chartData.map(item => item.time),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        metricsHistoryChartData.chartData.map(item => item.time),
        scopedTo,
        'end'
      )
    });
  }, [timeWindow, setInitialIndices, metricsHistoryChartData]);

  useEffect(() => {
    const scopedTo = moment(scopedTimeWindow.to).valueOf();
    const scopedFrom = moment(scopedTimeWindow.from).valueOf();

    setIndices({
      startIndex: getChartGraphIndex(
        metricsHistoryChartData.chartData.map(item => item.time),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        metricsHistoryChartData.chartData.map(item => item.time),
        scopedTo,
        'end'
      )
    });
    // We need to artificially cause renders of the rechart
    // Related issue: https://github.com/recharts/recharts/issues/2404
    setRedraw(value => value + 1);
  }, [scopedTimeWindow, setIndices, metricsHistoryChartData]);

  const handleOnChange = (_isBatchRecords, _batchId) => event => {
    const { startIndex, endIndex } = event;

    // check whether time is above 30 min
    if (
      isValidTimeRange(
        metricsHistoryChartData.chartData[startIndex].time,
        metricsHistoryChartData.chartData[endIndex].time
      )
    ) {
      // We help Recharts to properly react on the brush sliding
      // Related issue: https://github.com/recharts/recharts/issues/963
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dispatch(
          setIsBatchRecordsDetails(
            isBatchRecords,
            batchId,
            moment(metricsHistoryChartData.chartData[startIndex].time).toISOString(),
            moment(metricsHistoryChartData.chartData[endIndex].time).toISOString()
          )
        );
        dispatch(
          setScopedTimeWindow(
            moment(metricsHistoryChartData.chartData[startIndex].time).toISOString(),
            moment(metricsHistoryChartData.chartData[endIndex].time).toISOString(),
            machineId,
            sensors.map(sensor => sensor.type)
          )
        );
      }, 300);
    } else {
      // we need to abort timer and reset to corrected time window based on index that got changed
      if (timer.current) {
        clearTimeout(timer.current);
      }

      const { from, to } = getTimeWindowAfterBrushChange(indices, { startIndex, endIndex }, scopedTimeWindow);
      dispatch(setIsBatchRecordsDetails(_isBatchRecords, _batchId, from, to));
      dispatch(
        setScopedTimeWindow(
          from,
          to,
          machineId,
          sensors.map(sensor => sensor.type)
        )
      );
    }
  };

  const shouldShowLabel = d =>
    !(
      d < initialIndices.startIndex + initialIndices.endIndex * 0.05 ||
      d + initialIndices.endIndex * 0.05 > initialIndices.endIndex
    );

  const normalizedChartData = removeLabelsReturnedMetricsChartData(metricsHistoryChartData.chartData);
  const sortedXAxisDomain = orderBy(metricsHistoryChartData.xAxisDomain, item => item, 'asc');

  return (
    <StyledBrushArea>
      <ResponsiveContainer>
        <LineChart key={`chart-${redraw}`} data={normalizedChartData}>
          <Brush
            height={BRUSH_HEIGHT}
            startIndex={indices.startIndex}
            endIndex={indices.endIndex}
            travellerWidth={10 + (isMobile ? 10 : 0) + (isSmallDevice ? 10 : 0)}
            stroke={COLOR_PRIMARY}
            onChange={handleOnChange(isBatchRecords, batchId)}
            tickFormatter={d =>
              shouldShowLabel(d) ? moment(metricsHistoryChartData.chartData[d].time).format('LT') : null
            }
          >
            <LineChart data={metricsHistoryChartData.chartData}>
              {sensors.map((sensor, key) => (
                <YAxis key={`${sensor.type}-yaxis`} yAxisId={key} hide domain={getYAxisDomainBySensor(sensor)} />
              ))}
              {sensors.map((sensor, key) => (
                <Line
                  type="monotone"
                  dot={false}
                  key={`${sensor.type}-line`}
                  isAnimationActive={false}
                  stroke={COLORS[key]}
                  strokeWidth={2}
                  name={getSensorName(sensor, t(sensor.name))}
                  dataKey={sensor.type}
                  yAxisId={key}
                  unit={getSensorUnit(sensor)}
                />
              ))}
              <XAxis
                dataKey="time"
                type="number"
                interval="preserveStartEnd"
                hide
                domain={metricsHistoryChartData.xAxisDomain}
              />
            </LineChart>
          </Brush>
          <XAxis
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            allowDataOverflow
            domain={sortedXAxisDomain}
            stroke="false"
            ticks={metricsHistoryChartData.xAxisTicks}
            tickFormatter={tick => {
              moment(tick).format('DD/MM/YYYY HH:mm:ss');
            }}
            tick={props => <CustomTickChart {...props} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledBrushArea>
  );
};

MultipleSensorsBrush.propTypes = {
  machineId: T.string.isRequired,
  isBatchRecords: T.bool,
  batchId: T.string
};

MultipleSensorsBrush.defaultProps = {
  isBatchRecords: false,
  batchId: ''
};

export default MultipleSensorsBrush;

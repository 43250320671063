const createSagaInjector = (runSaga, rootSaga) => {
  const injectedSagas = new Map();

  const isInjected = key => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    if (isInjected(key)) {
      injectedSagas.get(key).cancel();
    }

    const task = runSaga(saga);

    injectedSagas.set(key, task);
  };

  const unplugSaga = key => {
    const saga = injectedSagas.get(key);
    saga.cancel();
    delete injectedSagas[key];
  };

  injectSaga('root', rootSaga);

  return {
    injectSaga,
    unplugSaga
  };
};

export default createSagaInjector;

import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';

import { ChartWrapper } from '../elements';
import SensorStatusBrush from './SensorStatusBrush';
import SensorStatusChart from './SensorStatusChart';

const SensorGraph = ({ machineId }) => {
  const { isMobile } = useResolutionCheck();

  return (
    <ChartWrapper data-selector="sensor-status-chart" style={{ paddingRight: '1rem' }} mobile={isMobile}>
      <SensorStatusChart machineId={machineId} />
      <SensorStatusBrush machineId={machineId} />
    </ChartWrapper>
  );
};

SensorGraph.propTypes = {
  machineId: T.string.isRequired
};

export default SensorGraph;

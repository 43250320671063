import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';
import { useTranslation } from 'react-i18next';
import { BoxWrapper, BoxCardInner, BoxCardValue, BoxCardName } from './elements';

const BoxCard = ({ mvalue, title }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();

  const FormatValues = (fvalue, ftitle) => {
    switch (ftitle) {
      case 'grinding_energy': {
        return (
          <>
            <BoxCardValue variant="h4">{parseFloat(fvalue).toFixed(1)}kWh</BoxCardValue>
            <BoxCardName>{t(`machines.detail.tabs.metrics.grinding_energy`)}</BoxCardName>
          </>
        );
      }
      case 'production_size': {
        return (
          <>
            <BoxCardValue variant="h4">{parseFloat(fvalue).toFixed(1)}kg</BoxCardValue>
            <BoxCardName>{t(`machines.detail.tabs.metrics.production_size`)}</BoxCardName>
          </>
        );
      }
      default: {
        return (
          <>
            <BoxCardValue variant="h4">{fvalue}</BoxCardValue>
            <BoxCardName>{t(`machines.detail.tabs.metrics.${String(title)}`)}</BoxCardName>
          </>
        );
      }
    }
  };

  const cardDetails = () => (
    <BoxWrapper withWrap={isTablet}>
      <BoxCardInner> {FormatValues(mvalue, title)}</BoxCardInner>
    </BoxWrapper>
  );

  return <>{cardDetails()}</>;
};

BoxCard.propTypes = {
  mvalue: T.string.isRequired,
  title: T.string.isRequired
};

export default BoxCard;

import styled from 'styled-components';
import { Divider, Paper } from '@mui/material';

export const StyledMarginDiv = styled.div(
  ({ marginTop }) => `
  margin-top:${marginTop};
`
);

export const StyledPaper = styled(Paper)`
  padding: 1.25rem;
  margin-bottom: 2.5rem;
  margin-top: ${props => props.marginTop};
  &.MuiPaper-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(17, 17, 26, 0.1);
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

export const HeadingWrapper = styled.div(
  ({ expanded }) => `
  display: flex;
  justify-content: flex-start;
  align-items:center;
  padding-bottom: ${expanded ? '0.75' : '0'}rem;

  & > .MuiTypography-root {
    font-weight: 500;
  }

  & > .MuiButton-root {
    padding 0 0.25rem;
    font-weight: 0.875rem;
    margin-right: 1.5rem;
  }
`
);

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 1.25rem 0;
  }
`;

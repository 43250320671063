import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerMetricsTitle, MetricsSubtitle, MetricsTitle } from '../elements';
import FormatNumber from '../../../../helpers/formatNumber';
import ProductionMetricDetails from './ProductionMetricDetails';

const ThisMonthUpToday = ({ metrics }) => {
  const { t } = useTranslation();

  return (
    <>
      <ContainerMetricsTitle>
        <MetricsTitle>{t('machines.detail.tabs.metrics.this_month_up_to_today')}</MetricsTitle>
        <MetricsSubtitle>
          ({t('machines.detail.tabs.metrics.from')} {FormatNumber.FormatDate(metrics.from)}{' '}
          {t('machines.detail.tabs.metrics.to')} {FormatNumber.FormatDate(metrics.to)})
        </MetricsSubtitle>
      </ContainerMetricsTitle>

      <ProductionMetricDetails metrics={metrics} />
    </>
  );
};

ThisMonthUpToday.propTypes = {
  metrics: T.shape({
    alarms: T.number,
    alerts: T.number,
    from: T.string,
    hour_meter: T.string,
    to: T.string,
    batches: T.shape({
      completed: T.number,
      cancelled: T.number
    })
  }).isRequired
};

export default ThisMonthUpToday;

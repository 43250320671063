import styled from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { Card, AppBar, Container } from '@mui/material';
import { Styles } from 'web-components';

import { SNACKBAR_BACKGROUND, COLOR_MAIN_HIGHLIGHT, COLOR_MAIN_HIGHLIGHT_TEXT } from './attrs/colors';
import { FOOTER_NAVIGATION_BAR_HEIGHT } from './attrs/layout';

const { GlobalStyle, AppBarContentWrapper, MainContainer, ContentWrapper } = Styles;

// Demo page
const ElementsContainer = styled.div`
  & button {
    margin: 0 0.5rem 0.5rem 0;
  }
  & > div {
    margin: 0 0.5rem 0.5rem 0;
  }
  & svg {
    margin: 0.5rem;
  }
  margin: 1rem 0;
`;

// Demo page
const StyledCard = styled(Card)`
  margin: 0.5rem 0;
`;

const StyledSnackbarProvider = styled(SnackbarProvider)`
  & .MuiSnackbarContent-root {
    background-color: ${SNACKBAR_BACKGROUND};
  }
  & .MuiSnackbarContent-message {
    font-weight: 400;
    display: flex;
    flex: 1;
  }
`;

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-colorPrimary {
    background-color: ${COLOR_MAIN_HIGHLIGHT};
    color: ${COLOR_MAIN_HIGHLIGHT_TEXT};
  }
`;

const StyledFooterBar = styled(AppBar)(
  props => `
  &.MuiAppBar-positionFixed {
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.97)), ${COLOR_MAIN_HIGHLIGHT};
  }
  &.MuiAppBar-positionSticky {
    margin-top: 3rem;
    background: none;
    box-shadow: none;
    & > .MuiToolbar-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
  & > .MuiToolbar-root {
    min-height: ${props.height ? props.height : FOOTER_NAVIGATION_BAR_HEIGHT}px;
  }
`
);

const StyledFooterBarContainer = styled(Container)(
  props => `
  &.MuiContainer-root {
    display: flex;
    justify-content: space-between;
    ${props.theme.breakpoints.down('xs')} {
      flex-direction: column;
      padding: 1rem 0;
      & button {
        width: 100%;
        margin-bottom: .5rem;
      }
    }
  }
`
);

export {
  GlobalStyle,
  ElementsContainer,
  StyledCard,
  StyledSnackbarProvider,
  StyledFooterBar,
  StyledFooterBarContainer,
  StyledAppBar,
  AppBarContentWrapper,
  MainContainer,
  ContentWrapper
};

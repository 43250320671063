import { combineReducers } from 'redux';

import alertsMenuReducer from './alertsMenu/reducer';
import notificationsReducer from './notifications/reducer';
import confirmationsReducer from './confirmations/reducer';
import settingsReducer from './settings/reducer';
import { createReducer } from '../utils';
import { SHOW_SUPPORT, SHOW_SALESFORCE_MODAL } from './constants';

export default combineReducers({
  alertsMenu: alertsMenuReducer,
  notifications: notificationsReducer,
  confirmations: confirmationsReducer,
  settings: settingsReducer,
  support: createReducer(
    {
      [SHOW_SUPPORT]: (state, { value }) => value
    },
    false
  ),
  salesforceModal: createReducer(
    {
      [SHOW_SALESFORCE_MODAL]: (state, { value }) => value
    },
    false
  )
});

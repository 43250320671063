import { put, takeLatest, select } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from '../utils';
import { DATA_EXPORT_SEND_REQUEST, DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES } from './constants';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { getMachineDataExports } from '../../api/data_exports';
import { showDataExportModal } from './actions';
import { getUserInfo } from '../user/selectors';

/**
 * Start an export data request
 * @param {Object<machine_id<{type: string}>|batch_id<{type: string}>,date_from, date_from, date_to, time_from,
 *         time_to>} req
 * @param {string} action DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES constant, add more if needed
 * @returns {Object}
 */
function* requestDataExport({ req, action }) {
  yield put(statusAction(DATA_EXPORT_SEND_REQUEST, actionStatus.START));
  try {
    let ok = false;
    let successMessage = '';
    let res = null;
    switch (action) {
      case DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES:
        res = yield getMachineDataExports(req.machineId, req);
        successMessage = 'machine_success_message';
        if (res.status !== 200) {
          yield put(
            addNotification({
              key: generateId(),
              type: actionStatus.ERROR,
              message: `errors.data_exports.machine`,
              notificationType: NOTIFICATION_TYPE_INTERACTION
            })
          );
          return;
        }
        ok = true;
        break;
      default:
        yield put(
          addNotification({
            key: generateId(),
            type: actionStatus.ERROR,
            message: `errors.alerts.denied`,
            notificationType: NOTIFICATION_TYPE_INTERACTION
          })
        );
        break;
    }

    if (ok) {
      const { user } = yield select(getUserInfo);
      yield put(statusAction(DATA_EXPORT_SEND_REQUEST, actionStatus.SUCCESS, { payload: req }));
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: `dialog.data_exports.${successMessage}`,
          variables: [{ name: 'email', data: user.data['e-mail'] }],
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
      yield put(showDataExportModal(false)); // Hidden data export modal after
    }
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(DATA_EXPORT_SEND_REQUEST, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.generic`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(DATA_EXPORT_SEND_REQUEST, requestDataExport);
}

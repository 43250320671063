import React, { useRef, useState } from 'react';
import {
  Grid,
  Box,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  TextField
} from '@mui/material';
import { NewDialog, MachineIcon, FormElements } from 'web-components';
import { getSensorProps, isFieldStringValidNumber, isStringValidNumber, useClickOutside } from 'helpers/utils';
import { getMillSensor } from 'attrs/sensorType';
import { SENSOR_STATUS_OK } from 'attrs/notifications';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { generateColor } from 'helpers/colorUtils';
import { ColorButton } from './EditSensorCard.Styles';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';

function EditSensorCard({ open, sensorIdx, sensor, handleCloseDialog, handleApply }) {
  const { t } = useTranslation();
  const millSensor = getMillSensor(sensor?.type);
  const name = millSensor?.name || sensor?.name;
  const mapValue = millSensor?.mapValue || sensor?.custom_icon_type;

  const [lineThickness, setLineThickness] = useState(sensor?.line_thickness || 1);
  const [line, setLine] = useState(sensor?.line_style || 'LINE_STYLE_SOLID');
  const [autoScale, setAutoScale] = useState(sensor?.auto_scale !== undefined ? sensor?.auto_scale : true);
  const [scale, setScale] = useState({ min: sensor?.min_scale || 0, max: sensor?.max_scale || 2000 });

  const pickerRef = useRef();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  useClickOutside(pickerRef, () => setDisplayColorPicker(false));
  const [color, setColor] = useState(sensor?.line_color || generateColor(sensorIdx));
  const theme = useTheme();
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorChange = newColor => {
    setColor(newColor.hex);
  };

  const handleLineClick = event => {
    setLineThickness(event.target.value);
  };

  const handleLineStyleChange = event => {
    setLine(event.target.value);
  };

  const machineIconProps = {
    iconName: getSensorProps(mapValue).icon,
    status: SENSOR_STATUS_OK,
    style: {
      fontSize: '2rem'
    },
    viewBox: '0 0 32 32'
  };

  const handleAutoScaleChange = event => {
    setAutoScale(event.target.checked);
  };

  const handleScaleChange = scaleName => event => {
    const newValue = event.target.value;
    if (isFieldStringValidNumber(newValue)) {
      setScale({ ...scale, [scaleName]: newValue });
    }
  };

  const content = (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 5 }}>
        <Grid item md={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack direction="column" justifyContent="space-around" alignItems="flex-start" spacing={0.5}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <MachineIcon {...machineIconProps} />
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{t(`${name}`)}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ paddingLeft: '40px' }}
              >
                <Typography sx={{ fontSize: 10 }}>{sensor?.production_line}</Typography>
                <Typography sx={{ fontSize: 10 }}>{sensor?.machine_name}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <ColorButton endIcon={<ArrowDropDownIcon />} onClick={handleColorClick}>
            <div
              style={{
                width: '24px',
                height: '14px',
                borderRadius: '2px',
                background: color
              }}
            />
          </ColorButton>
          {displayColorPicker ? (
            <Box ref={pickerRef} sx={{ zIndex: 9999, position: 'absolute' }}>
              <SketchPicker color={color} onChangeComplete={handleColorChange} />
            </Box>
          ) : null}
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="line-thickness-select-label">Line Thickness</InputLabel>
            <Select
              labelId="line-thickness-select-label"
              id="line-thickness-select"
              value={lineThickness}
              onChange={handleLineClick}
              label={t('dialog.edit_sensor.line_style')}
              data-selector="dialog-edit-sensor-line-thickness-field"
            >
              {[1, 2, 3, 4].map(thickness => (
                <MenuItem key={thickness} value={thickness}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        height: `${thickness}px`,
                        width: '100%',
                        backgroundColor: thickness <= lineThickness ? 'primary.main' : '#e0e0e0',
                        transition: 'background-color 300ms',
                        marginRight: 2
                      }}
                    />
                    <Typography variant="body2">{`${thickness}px`}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="line-style-picker-label">Line Style</InputLabel>
            <Select
              labelId="line-style-picker-label"
              id="line-style-picker"
              value={line}
              onChange={handleLineStyleChange}
              IconComponent={ArrowDropDownIcon}
              label={t('dialog.edit_sensor.line_style')}
              data-selector="dialog-edit-sensor-line-style-field"
            >
              <MenuItem value="LINE_STYLE_SOLID">
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ border: '2px solid', width: '40px', marginRight: '8px' }} />
                  Solid
                </Box>
              </MenuItem>
              <MenuItem value="LINE_STYLE_DASH">
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ border: '2px dashed', width: '40px', marginRight: '8px' }} />
                  Dash
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} md={6}>
          <TextField
            id="dialog-edit-sensor-minimum-scale-field"
            data-selector="dialog-edit-sensor-minimum-scale-field"
            label={t('dialog.edit_sensor.minimum_scale')}
            variant="filled"
            fullWidth
            disabled={autoScale}
            type="text" // type 'text' to allow typing "-"
            inputProps={{ inputMode: 'numeric', pattern: '^-?\\d*$' }} // only numbers
            value={scale.min}
            onChange={handleScaleChange('min')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="dialog-edit-sensor-maximum-scale-field"
            data-selector="dialog-edit-sensor-maximum-scale-field"
            label={t('dialog.edit_sensor.maximum_scale')}
            variant="filled"
            fullWidth
            disabled={autoScale}
            type="text"
            inputProps={{ inputMode: 'numeric', pattern: '^-?\\d*$' }}
            value={scale.max}
            onChange={handleScaleChange('max')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormElements.Checkbox
            activeColor={COLOR_PRIMARY}
            label={t('dialog.edit_sensor.auto_scale')}
            checked={autoScale}
            onChange={handleAutoScaleChange}
            data-selector="dialog-edit-sensor-auto-scale-field"
          />
        </Grid>
      </Grid>
    </>
  );

  const handleOk = () => {
    const values = {
      ...sensor,
      line_color: color,
      line_style: line,
      line_thickness: lineThickness,
      min_scale: autoScale ? null : Number(scale.min),
      max_scale: autoScale ? null : Number(scale.max),
      auto_scale: autoScale
    };
    handleApply(values);
    handleCloseDialog();
  };

  return (
    <NewDialog
      open={open}
      canSave={autoScale || (isStringValidNumber(scale.min) && isStringValidNumber(scale.max))}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.edit_sensor.title')}
      subTitle={t('dialog.edit_sensor.subtitle')}
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.apply')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minHeight={isUpXl ? '600px' : undefined}
      maxHeight="98%"
      maxWidth="98%"
    />
  );
}

EditSensorCard.propTypes = {
  open: PropTypes.bool.isRequired,
  sensorIdx: PropTypes.number.isRequired,
  sensor: PropTypes.shape().isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired
};

export default EditSensorCard;

import styled from 'styled-components';
import { TableCell, Container, TableHead, TableContainer as ContainerTable } from '@mui/material';
import { NotifyTypo } from 'web-components';

const { Body1 } = NotifyTypo;

const StyledMobileTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 0;
    padding: 0.5rem;
  }

  &.paddingTop {
    padding-top: 1rem;
  }
  &.paddingLeft {
    padding-left: 1rem;
  }
  &.paddingRight {
    padding-right: 1rem;
  }
  &.paddingBottom {
    padding-bottom: 1rem;
  }
`;

const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    padding: 0 3.75rem 0;
    
    ${theme.breakpoints.down('sm')} {
      padding: 0 1.25rem 0;
    }
    ${theme.breakpoints.down('xs')} {
      padding: 1rem 0 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
   
`
);

const TableContainer = styled(ContainerTable)(
  props => `

  &.MuiTableContainer-root{
    .MuiTable-root{
      @media(max-width:1024px){
        min-width: ${props.isActive ? '1024px' : '600px'};
      }

      @media(max-width:425px){
        width: 900px !important;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      padding-left: 10px;
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #9597A0;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #757473;
    }
  } 

`
);

const NotifyTypoBody1 = styled(Body1)`
  &.MuiTypography-body1 {
    font-size: 0.875rem;
  }
`;

const HeadTable = styled(TableHead)`
  &.MuiTableCell-root,
  & .MuiTableCell-head {
    color: #7a7d85;
    font-weight: 500;
    font-size: 0.875rem;
  }
  ,
  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    background-color: #efefef;
    color: #7a7d85;
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

export { StyledMobileTableCell, StyledContainer, NotifyTypoBody1, HeadTable, TableContainer };

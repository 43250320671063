import { createSelector } from 'reselect';
import { getUi } from '../rootSelectors';

const getAlertsMenu = createSelector([getUi], ui => ui.alertsMenu);

const getNotifications = createSelector([getUi], ui => ui.notifications);

const getConfirmations = createSelector([getUi], ui => ui.confirmations);

const getShowSupport = createSelector([getUi], ui => ui.support);

const getShowSalesforceModal = createSelector([getUi], ui => ui.salesforceModal);

const getSettings = createSelector([getUi], ui => ui.settings);

export { getAlertsMenu, getNotifications, getConfirmations, getShowSupport, getShowSalesforceModal, getSettings };

import moment from 'moment';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';
import { groupBy } from 'lodash';

import { Brush, AreaChart, Area, ResponsiveContainer, XAxis } from 'recharts';
import { useResolutionCheck } from 'web-components';
import { GRAPH_BRUSH_OVERLAY_COLOR } from '../../../attrs/colors';
import { BRUSH_HEIGHT } from '../../../attrs/layout';
import { StyledBrushArea } from '../elements';
import { getData } from '../../../redux/rootSelectors';
import { getSelectedMetrics } from '../../../redux/machines/selectors';
import { getScopedTimeWindow, getTimeWindow } from '../../../redux/ui/settings/selectors';
import { setScopedTimeWindow } from '../../../redux/ui/settings/actions';
import { generateMachineData } from './utils';
import { isValidTimeRange, getTimeWindowAfterBrushChange, getChartGraphIndex, CustomTick } from '../graphUtils';
import { getMachineStatusProps } from '../../../helpers/utils';

const MachineStatusBrush = ({ machineId }) => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const { isMobile, isSmallDevice } = useResolutionCheck();

  const data = useSelector(compose(getData, getSelectedMetrics));
  const timeWindow = useSelector(getTimeWindow);
  const scopedTimeWindow = useSelector(getScopedTimeWindow);

  const [initialIndices, setInitialIndices] = useState({ startIndex: null, endIndex: null });
  const [indices, setIndices] = useState({ startIndex: 0, endIndex: 0 });
  const [redraw, setRedraw] = useState(0);

  const { chartData, xAxisDomain, xAxisTicks } = useMemo(
    () => generateMachineData(data, timeWindow),
    [data, timeWindow]
  );
  const groupedChartData = groupBy(chartData, 'value');

  useEffect(() => {
    const scopedTo = moment(timeWindow.to).valueOf();
    const scopedFrom = moment(timeWindow.from).valueOf();

    setInitialIndices({
      startIndex: getChartGraphIndex(
        chartData.map(item => item.updated_at),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        chartData.map(item => item.updated_at),
        scopedTo,
        'end'
      )
    });
  }, [timeWindow, setInitialIndices, chartData]);

  useEffect(() => {
    const scopedTo = moment(scopedTimeWindow.to).valueOf();
    const scopedFrom = moment(scopedTimeWindow.from).valueOf();

    setIndices({
      startIndex: getChartGraphIndex(
        chartData.map(item => item.updated_at),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        chartData.map(item => item.updated_at),
        scopedTo,
        'end'
      )
    });
    // We need to artifically cause rerenders of the rechart
    // Related issue: https://github.com/recharts/recharts/issues/2404
    setRedraw(value => value + 1);
  }, [scopedTimeWindow, setIndices, chartData]);

  const handleOnChange = event => {
    const { startIndex, endIndex } = event;

    // check whether time is above 30 min
    if (isValidTimeRange(chartData[startIndex].updated_at, chartData[endIndex].updated_at)) {
      // We help Recharts to properly react on the brush sliding
      // Related issue: https://github.com/recharts/recharts/issues/963
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dispatch(
          setScopedTimeWindow(
            moment(chartData[startIndex].updated_at).toISOString(),
            moment(chartData[endIndex].updated_at).toISOString(),
            machineId
          )
        );
      }, 300);
    } else {
      // we need to abort timer and reset to corrected time window based on index that got changed
      if (timer.current) {
        clearTimeout(timer.current);
      }

      const { from, to } = getTimeWindowAfterBrushChange(indices, { startIndex, endIndex }, scopedTimeWindow);
      dispatch(setScopedTimeWindow(from, to, machineId));
    }
  };

  const shouldShowLabel = d =>
    !(
      d < initialIndices.startIndex + initialIndices.endIndex * 0.05 ||
      d + initialIndices.endIndex * 0.05 > initialIndices.endIndex
    );

  return (
    <StyledBrushArea>
      <ResponsiveContainer>
        <AreaChart key={`chart-${redraw}`} data={chartData}>
          <Brush
            height={BRUSH_HEIGHT}
            startIndex={indices.startIndex}
            endIndex={indices.endIndex}
            travellerWidth={10 + (isMobile ? 10 : 0) + (isSmallDevice ? 10 : 0)}
            stroke={GRAPH_BRUSH_OVERLAY_COLOR}
            onChange={handleOnChange}
            tickFormatter={d => (shouldShowLabel(d) ? moment(chartData[d].updated_at).format('LT') : null)}
          >
            <AreaChart key={`chart-${redraw}`}>
              {Object.keys(groupedChartData).map(key => {
                const { color } = getMachineStatusProps(key);
                return (
                  <Area
                    key={key}
                    fillOpacity={0.4}
                    dataKey={key}
                    isAnimationActive={false}
                    stroke={color}
                    fill={color}
                  />
                );
              })}
              <XAxis dataKey="updated_at" type="number" hide domain={xAxisDomain} />
            </AreaChart>
          </Brush>
          <XAxis
            dataKey="updated_at"
            type="number"
            scale="time"
            interval="preserveStartEnd"
            allowDataOverflow
            domain={xAxisDomain}
            stroke="false"
            ticks={xAxisTicks}
            tickFormatter={tick => {
              moment(tick).format('DD/MM/YYYY HH:mm:ss');
            }}
            tick={props => (
              <CustomTick
                {...props}
                lastIndex={xAxisTicks.length - 1}
                spanValue={{
                  fisrtIndex: 100,
                  lastIndex: -20,
                  othersIndex: 25
                }}
              />
            )}
          />
        </AreaChart>
      </ResponsiveContainer>
    </StyledBrushArea>
  );
};

MachineStatusBrush.propTypes = {
  machineId: T.string.isRequired
};

export default MachineStatusBrush;

import styled from 'styled-components';

import { SECONDARY_TEXT_COLOR } from 'attrs/colors';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  color: ${SECONDARY_TEXT_COLOR};
  margin: 1rem;
`;

export const TitleWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;

  & > .MuiTypography-root {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

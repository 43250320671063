import styled from 'styled-components';
import { Badge, Popper, Drawer, Paper, MenuItem } from '@mui/material';
import { NotifyTypo } from 'web-components';
import { SUB_NAVIGATION_WIDTH } from '../../../attrs/layout';
import { LIGHT_GREY, PRIMARY_TEXT_COLOR, COLOR_PRIMARY } from '../../../attrs/colors';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    top: 9px;
    right: 8px;
  }
`;

const StyledPopper = styled(Popper)(
  () => `
  z-index: 1100;
`
);

const StyledDrawer = styled(Drawer)(
  () => `
  & .MuiPaper-root {
    min-height: 100%;
    background: #fff;
    color: ${PRIMARY_TEXT_COLOR};
  }
  & .MuiBackdrop-root {
    background: none;
  }
`
);

const StyledNotificationWrapper = styled(Paper)`
  &.MuiPaper-root {
    width: ${SUB_NAVIGATION_WIDTH}px;
    max-width: ${SUB_NAVIGATION_WIDTH}px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    border: 1px solid ${LIGHT_GREY};
    white-space: pre-line;
    padding: 1rem 1rem;
    & > div {
      display: flex;
      overflow-x: hidden;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        overflow-x: hidden;
      }
    }
    &.unread {
      border-left: 3px solid ${COLOR_PRIMARY};
      background-color: ${COLOR_PRIMARY}0D;
    }
  }
`;

const NotificationHeading = styled.div`
  padding: calc(1rem - 8px) 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationFooter = styled.div`
  padding: 1rem 1rem calc(1rem - 8px) 1rem;
  display: flex;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  padding: 1rem;
`;

const StyledEmptyState = styled(NotifyTypo.Body2)`
  &.MuiTypography-root {
    margin: 5rem 4rem;
  }
`;

export {
  StyledBadge,
  StyledEmptyState,
  StyledPopper,
  StyledDrawer,
  StyledNotificationWrapper,
  StyledMenuItem,
  NotificationHeading,
  NotificationFooter,
  ErrorWrapper
};

import React from 'react';
import T from 'prop-types';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BatchStatusChip } from 'web-components';
import { BlockTitle, Content, Wrapper } from './InfoBlockRow.styled';
import InfoBlockCell from './InfoBlockCell';

const InfoBlockRow = ({ localeKey, dataProvider }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Wrapper theme={theme}>
      {localeKey && <BlockTitle>{t(`batch_records.details.${localeKey}`)}</BlockTitle>}
      <Content theme={theme}>
        {dataProvider.map(({ localeKey: key, value }) => (
          <InfoBlockCell
            key={key}
            localeKey={key}
            value={
              key.includes('status') ? (
                <BatchStatusChip status={value} label={t(`batch_records.overview.${value.toLowerCase()}`)} />
              ) : (
                value
              )
            }
          />
        ))}
      </Content>
    </Wrapper>
  );
};

InfoBlockRow.propTypes = {
  localeKey: T.string,
  dataProvider: T.arrayOf(
    T.shape({
      localeKey: T.string.isRequired,
      value: T.oneOfType([T.string, T.node]).isRequired
    })
  ).isRequired
};

InfoBlockRow.defaultProps = {
  localeKey: null
};

export default InfoBlockRow;

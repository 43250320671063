import { StepLabel } from '@mui/material';
import styled from 'styled-components';

export const CustomStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: ${({ active, completed }) => (active || completed ? '#2BACA4' : '#7A7D85')};

    &.Mui-active {
      color: #2baca4;
    }

    &.Mui-completed {
      color: #2baca4;
    }
  }
`;

export const SectionTitle = styled('h3')`
  font-size: 16px;
  color: #333;
  font-weight: 500;
  text-align: left;
  max-width: 700px;
`;

import {
  Alert,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import {
  ACCESS_TYPE_ALL_ORG,
  ACCESS_TYPE_USER_ONLY,
  PERIOD_TYPE_LAST_24_HOURS,
  PERIOD_TYPE_LAST_8_HOURS,
  PERIOD_TYPE_LAST_WEEK_DAYS
} from 'attrs/chartsTemplate';
import PropTypes from 'prop-types';

export default function EditInfoChartTemplateDetails({ template, setEditingTemplate }) {
  const { t } = useTranslation();
  const boldWarningRef = useRef(null);
  const [templateName, setTemplateName] = useState('');
  const [isTemplateNameTouched, setTemplateNameTouched] = useState(false);
  const [interval, setInterval] = useState(PERIOD_TYPE_LAST_8_HOURS);
  const [description, setDescription] = useState('');
  const [target, setTarget] = useState(ACCESS_TYPE_USER_ONLY);

  useEffect(() => {
    if (template?.id) {
      setTemplateName(template?.name);
      setInterval(template?.period_type);
      setDescription(template?.description);
      setTarget(template?.tmpl_access_type);
    }
  }, [template]);

  const onChangeTemplateName = event => {
    const manufacturerNameParam = event.target.value;
    setTemplateName(manufacturerNameParam);
    setTemplateNameTouched(true);
    setEditingTemplate({ ...template, name: manufacturerNameParam });
  };

  const onChangeInterval = event => {
    const intervalParam = event.target.value;
    setInterval(intervalParam);
    setEditingTemplate({ ...template, period_type: intervalParam });
  };

  const onChangeDescription = event => {
    const descriptionParam = event.target.value;
    setDescription(descriptionParam);
    setEditingTemplate({ ...template, description: descriptionParam });
  };

  const handleChangeTarget = event => {
    setTarget(event.target.value);
    setEditingTemplate({ ...template, tmpl_access_type: event.target.value });
  };

  useEffect(() => {
    if (boldWarningRef.current) {
      boldWarningRef.current.innerHTML = t('edit_chart_template_details.default_warning', {
        interpolation: { escapeValue: false }
      });
    }
  }, [t]);

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        width: '100%',
        p: 3
      }}
      data-selector="edit-info-chart-template-details"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            <Typography sx={{ color: 'rgb(57,124,202)' }} ref={boldWarningRef} />
          </Alert>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            id="template-name"
            label={t('edit_chart_template_details.template_identification')}
            variant="filled"
            value={templateName}
            onChange={onChangeTemplateName}
            error={!templateName && isTemplateNameTouched}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="template-label">{t('edit_chart_template_details.filter_time')}</InputLabel>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              labelId="period-type-label"
              id="period-type-select"
              onChange={onChangeInterval}
              value={interval}
            >
              <MenuItem key={`${PERIOD_TYPE_LAST_8_HOURS}`} value={`${PERIOD_TYPE_LAST_8_HOURS}`}>
                {t('enum.chart_templates.period.PERIOD_TYPE_LAST_8_HOURS')}
              </MenuItem>
              <MenuItem key={`${PERIOD_TYPE_LAST_24_HOURS}`} value={`${PERIOD_TYPE_LAST_24_HOURS}`}>
                {t('enum.chart_templates.period.PERIOD_TYPE_LAST_24_HOURS')}
              </MenuItem>
              <MenuItem key={`${PERIOD_TYPE_LAST_WEEK_DAYS}`} value={`${PERIOD_TYPE_LAST_WEEK_DAYS}`}>
                {t('enum.chart_templates.period.PERIOD_TYPE_LAST_WEEK_DAYS')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            id="description"
            label={t('edit_chart_template_details.description')}
            variant="filled"
            value={description}
            onChange={onChangeDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              {t('edit_chart_template_details.access_type')}
            </Typography>
            <RadioGroup
              aria-labelledby="lbl-target-radio-group"
              name="target-radio-group"
              value={target}
              onChange={handleChangeTarget}
            >
              <FormControlLabel
                value={`${ACCESS_TYPE_USER_ONLY}`}
                control={<Radio />}
                label={t('enum.chart_templates.target.ACCESS_TYPE_USER_ONLY')}
              />
              <FormControlLabel
                value={`${ACCESS_TYPE_ALL_ORG}`}
                control={<Radio />}
                label={t('enum.chart_templates.target.ACCESS_TYPE_ALL_ORG')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}

EditInfoChartTemplateDetails.propTypes = {
  template: PropTypes.shape(),
  setEditingTemplate: PropTypes.func.isRequired
};

EditInfoChartTemplateDetails.defaultProps = {
  template: {}
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { DataTable, useResolutionCheck } from 'web-components';

import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_BATCH_RECORDS } from '../../attrs/routePaths';
import MobileView from './MobileView';
import Footer from '../DataTable/Footer';
import { generateColumns } from './BatchRecordsOverview.config';
import { ContainerTitle, PageHeader, StyledContainer } from './BatchRecordsOverview.styled';
import { PaginationResponse } from '../../helpers/propTypes';
import { getBatchesFiltered } from '../../redux/batch/actions';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { getBatchSortOrder } from '../../redux/batch/selectors';
import DesktopView from './DesktopView';
import { BATCH_DEFAULT_LIST_ORDER, BATCH_DEFAULT_LIST_SORT } from '../../attrs/batch';
import TooltipInfo from '../TooltipInfo';

const BatchRecordsOverview = ({ rows }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const batchSortOrder = useSelector(getBatchSortOrder);

  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState([]);
  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentSort, setCurrentSort] = useState({
    colId: batchSortOrder.sort || BATCH_DEFAULT_LIST_SORT,
    order: batchSortOrder.order || BATCH_DEFAULT_LIST_ORDER
  });
  const { isMobile } = useResolutionCheck();
  const { t } = useTranslation();
  const history = useHistory();
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const [batchId, setBatchId] = useState('');
  const [machine, setMachine] = useState('');
  const [recipe, setRecipe] = useState('');

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(DataTable.sortFn(rows.data, currentSort.colId, currentSort.order));
    } else {
      setCurrentView([]);
    }
  }, [currentSort, rows]);

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    dispatch(getBatchesFiltered(page, rowsPerPage, currentSort.colId, currentSort.order, batchId, machine, recipe));
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(getBatchesFiltered(currentPage, rowsPerPage, colId, order, batchId, machine, recipe));
    setCurrentSort({ colId, order });
  };

  const handleSearchBatchID = value => {
    setBatchId(value);
  };

  const handleSearchMachine = value => {
    setMachine(value);
  };

  const handleSearchRecipe = value => {
    setRecipe(value);
  };

  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    dispatch(
      getBatchesFiltered(
        PAGINATION_DEFAULT_START_PAGE,
        rowsPerPage,
        currentSort.colId,
        currentSort.order,
        batchId,
        machine,
        recipe
      )
    );
  }, [batchId, machine, recipe]);

  const handleSelectRow = row => history.push(`/${ROUTE_BATCH_RECORDS}/${row.id}`);

  const props = {
    dataSelectorTable: 'batch-records-table',
    dataSelectorRows: 'batch-records-table-rows',
    isLoading,
    columns: generateColumns(t),
    rows: currentView,
    footer: (
      <Footer
        currentPage={rows.page}
        totalPages={rows.total_pages || 1}
        totalItems={rowsPerPage || 0}
        onChange={handleChangePage}
      />
    ),
    defaultSort: currentSort,
    sortFn: handleSort,
    onClick: handleSelectRow
  };

  const search = {
    handleSearchBatchID,
    handleSearchMachine,
    handleSearchRecipe,
    batchId,
    machine,
    recipe
  };

  const clearAllFields = () => {
    setBatchId('');
    setMachine('');
    setRecipe('');
  };

  return (
    <>
      <PageHeader className="withoutShadow" alwaysVisible>
        <PageHeader.Title>
          <ContainerTitle>
            <PageHeader.Title.Main>{t('batch_records.title')}</PageHeader.Title.Main>
            <TooltipInfo titleTooltip="batch_records.subtitle" />
          </ContainerTitle>
        </PageHeader.Title>
      </PageHeader>
      <StyledContainer maxWidth={false} theme={theme}>
        {isMobile ? (
          <MobileView {...props} search={search} clearAllFields={clearAllFields} />
        ) : (
          <DesktopView props={props} search={search} clearAllFields={clearAllFields} />
        )}
      </StyledContainer>
    </>
  );
};

BatchRecordsOverview.propTypes = {
  rows: T.shape(PaginationResponse).isRequired
};

export default BatchRecordsOverview;

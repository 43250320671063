import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Toolbar, Link, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotifyIcon, NotifyTypo } from 'web-components';

import { FOOTER_NAVIGATION_BAR_HEIGHT, NAVIGATION_BAR_HEIGHT, NAVIGATION_BAR_LOGO_HEIGHT } from '../../../attrs/layout';
import { isSuccess } from '../../../helpers/utils';
import { AppBarContentWrapper, StyledAppBar } from '../../../elements';
import Logo from '../../../attrs/logo';
import { getRequestPasswordReset } from '../../../redux/auth/selectors';
import RequestPasswordResetForm from '../../../components/forms/Auth/RequestPasswordResetForm';

const { Body1, InfoText } = NotifyTypo;

const RequestPasswordReset = () => {
  const { status } = useSelector(getRequestPasswordReset);
  const { t } = useTranslation();

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar variant="dense">
          <IconButton as={Link} edge="start" color="inherit" aria-label="back" href="/login">
            <NotifyIcon iconName="chevronLeft" />
          </IconButton>
          <AppBarContentWrapper center>
            <Logo height={`${NAVIGATION_BAR_LOGO_HEIGHT}px`} style={{ minWidth: '70px' }} />
          </AppBarContentWrapper>
          <div style={{ width: '48px' }} />
        </Toolbar>
      </StyledAppBar>
      {isSuccess(status) ? (
        <Container
          maxWidth="sm"
          style={{
            marginTop: NAVIGATION_BAR_HEIGHT,
            paddingTop: '2rem'
          }}
        >
          <Body1>{t('auth.request_password_sent_email')}</Body1>
        </Container>
      ) : (
        <Container
          maxWidth="sm"
          style={{
            marginTop: NAVIGATION_BAR_HEIGHT,
            paddingTop: '3rem',
            paddingBottom: `${FOOTER_NAVIGATION_BAR_HEIGHT + 20}px`
          }}
        >
          <InfoText style={{ marginBottom: '1.5rem' }}>{t('auth.request_password_sub_title')}</InfoText>
          <RequestPasswordResetForm />
        </Container>
      )}
    </>
  );
};

export default RequestPasswordReset;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { createReducer, loadingStateReducer } from '../utils';

import {
  BATCH_MACHINE_RANGE,
  CHANGE_BATCHES_FILTER,
  CHANGE_BATCHES_ORDER,
  LOAD_BATCH,
  LOAD_BATCHES,
  LOAD_BATCHES_PAGE,
  LOAD_BATCHES_WITH_FILTER
} from './constants';
import { BATCH_DEFAULT_LIST_ORDER, BATCH_DEFAULT_LIST_SORT } from '../../attrs/batch';

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_BATCHES, LOAD_BATCH]),
  data: typeToReducer(
    {
      [LOAD_BATCHES]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_BATCHES_PAGE]: {
        SUCCESS: (data, { payload }) => ({ payload })
      },
      [LOAD_BATCH]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_BATCHES_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  ),
  batchMachineRange: createReducer(
    {
      [BATCH_MACHINE_RANGE]: (batchMachineRange, { machineId, start, end }) => ({
        machineId,
        start,
        end
      })
    },
    { machineId: '', start: '', end: '' }
  ),
  batchSortOrder: createReducer(
    {
      [CHANGE_BATCHES_ORDER]: (batchSortOrder, { sort, order }) => ({
        sort,
        order
      })
    },
    { sort: BATCH_DEFAULT_LIST_SORT, order: BATCH_DEFAULT_LIST_ORDER }
  ),
  batchFilter: createReducer(
    {
      [CHANGE_BATCHES_FILTER]: (batchFilter, { filter }) => ({ filter })
    },
    { filter: '' }
  )
});

import { put, takeLatest } from 'redux-saga/effects';
import { CUSTOM_SENSOR_GET } from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { getCustomSensor } from '../../api/custom_sensor';

function* requestCustomSensor({ customSensorId }) {
  yield put(statusAction(CUSTOM_SENSOR_GET, actionStatus.START));

  try {
    const res = yield getCustomSensor(customSensorId);
    if (res.status !== 200) {
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.generic`, // TODO: Create a custom sensor get error message
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
      return;
    }
    yield put(statusAction(CUSTOM_SENSOR_GET, actionStatus.SUCCESS, { customSensor: res.data }));
  } catch (err) {
    const error = getError(err);
    yield put(statusAction(CUSTOM_SENSOR_GET, actionStatus.ERROR, { message: error }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.generic`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(CUSTOM_SENSOR_GET, requestCustomSensor);
}

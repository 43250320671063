import styled from 'styled-components';
import { Chip } from '@mui/material';
import {
  MACHINE_MAINTENANCE_COLOR,
  MACHINE_RUNNING_COLOR,
  MACHINE_CRITICAL_COLOR,
  MACHINE_WARNING_COLOR,
  MACHINE_IDLE_COLOR,
  MACHINE_NO_DATA_COLOR,
  COLOR_MAIN_HIGHLIGHT_TEXT
} from '../../attrs/colors';

const StyledChip = styled(Chip)`
  min-width: 70px;
  max-width: 100%;

  &.maintenance {
    background-color: ${MACHINE_MAINTENANCE_COLOR}26;
    color: ${MACHINE_MAINTENANCE_COLOR};
  }

  &.running {
    background-color: ${MACHINE_RUNNING_COLOR}26;
    color: ${MACHINE_RUNNING_COLOR};
  }

  &.error {
    background-color: ${MACHINE_CRITICAL_COLOR};
    color: ${COLOR_MAIN_HIGHLIGHT_TEXT};
  }

  &.warning {
    background-color: ${MACHINE_WARNING_COLOR}26;
    color: ${MACHINE_WARNING_COLOR};
  }

  &.idle {
    background-color: ${MACHINE_IDLE_COLOR}26;
    color: ${MACHINE_IDLE_COLOR};
  }

  &.missingLink {
    background-color: ${MACHINE_NO_DATA_COLOR}26;
    color: ${MACHINE_NO_DATA_COLOR};
  }
`;

export default StyledChip;

import React from 'react';
import T from 'prop-types';
import { MachineIcon } from 'web-components';

import FormElements from 'web-components/build/FormElements';
import NotifyTypo from 'web-components/build/NotifyTypo';
import { COLOR_PRIMARY } from '../../attrs/colors';
import { getSensorProps } from '../../helpers/utils';
import { StyledSelectableComparisonCard } from './elements';

const SelectableComparisonSensorCard = ({ sensor, onClick, selected, disabled }) => {
  const machineIconProps = {
    /* eslint-disable camelcase */
    iconName: getSensorProps(!sensor.is_custom ? sensor.mapValue : sensor.custom_icon_type).icon,
    status: sensor.status,
    fontSize: 'large',
    style: {
      marginRight: '1rem'
    },
    viewBox: '0 0 32 32'
  };

  const handleClick = e => {
    if (!disabled) {
      onClick(e, sensor.type);
    }
  };

  return (
    <StyledSelectableComparisonCard
      data-selector="selectable-sensor-card"
      data-test-selectable={selected ? 'not-selectable' : 'selectable'}
      elevation={1}
      selected={selected}
      disabled={disabled}
      onClick={handleClick}
    >
      <FormElements.Checkbox
        activeColor={COLOR_PRIMARY}
        disabled={disabled}
        name="terms_accept"
        checked={selected}
        onChange={handleClick}
      />
      <MachineIcon {...machineIconProps} />
      <NotifyTypo.Caption
        display="block"
        style={{
          lineHeight: '1.2rem',
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
          wordBreak: 'break-word'
        }}
      >
        {sensor.name}
      </NotifyTypo.Caption>
    </StyledSelectableComparisonCard>
  );
};

SelectableComparisonSensorCard.propTypes = {
  sensor: T.shape({
    mapValue: T.string,
    name: T.string,
    type: T.string,
    status: T.string,
    is_custom: T.bool,
    custom_icon_type: T.string
  }).isRequired,
  disabled: T.bool.isRequired,
  selected: T.bool.isRequired,
  onClick: T.func.isRequired
};

export default SelectableComparisonSensorCard;

import * as constants from './constants';

const loadAlerts = () => ({
  type: constants.LOAD_ALERTS
});

const markAlertAsRead = alert => ({
  type: constants.MARK_ALERT_AS_READ,
  payload: alert
});

const markAllAlertsAsRead = alerts => ({
  type: constants.MARK_ALL_ALERTS_AS_READ,
  payload: alerts
});

const subscribeToAlertsStream = () => ({
  type: constants.SUBSCRIBE_TO_ALERTS_STREAM
});

export { loadAlerts, markAlertAsRead, markAllAlertsAsRead, subscribeToAlertsStream };

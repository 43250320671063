import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';

import cellphoneRotate from '../../attrs/images/rotate_cellphone.svg';
import blockRotate from '../../attrs/images/block_rotate.svg';

export const ContainerSensorPortrait = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;
export const BlockRotateWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;

function MobileSensorPortrait({ ...props }) {
  const { t } = useTranslation();
  return (
    <ContainerSensorPortrait>
      <img src={cellphoneRotate} alt={t('dashboard.sensors.mobile_sensor.alt_rotate_img')} />
      <NotifyTypo.Heading2 align="center" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
        {t('dashboard.sensors.mobile_sensor.title')}
      </NotifyTypo.Heading2>
      <NotifyTypo.InfoText align="center">{t('dashboard.sensors.mobile_sensor.first_description')}</NotifyTypo.InfoText>
      <BlockRotateWrapper>
        <img src={blockRotate} alt={t('dashboard.sensors.mobile_sensor.alt_block_rotate')} />
        <NotifyTypo.InfoText style={{ marginLeft: 15 }}>
          {t('dashboard.sensors.mobile_sensor.last_description')}
        </NotifyTypo.InfoText>
      </BlockRotateWrapper>
      {props.children}
    </ContainerSensorPortrait>
  );
}
MobileSensorPortrait.propTypes = {
  children: T.node
};
MobileSensorPortrait.defaultProps = {
  children: <></>
};
export default MobileSensorPortrait;

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';

import { Brush, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useResolutionCheck } from 'web-components';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import { BRUSH_HEIGHT } from '../../../attrs/layout';
import { StyledBrushArea } from '../elements';
import { getData } from '../../../redux/rootSelectors';
import { getSelectedSensorHistory } from '../../../redux/machines/selectors';
import { getScopedTimeWindow, getTimeWindow } from '../../../redux/ui/settings/selectors';
import { setScopedTimeWindow } from '../../../redux/ui/settings/actions';
import {
  getChartGraphIndex,
  getPredefinedYAxis,
  getTimeWindowAfterBrushChange,
  isValidTimeRange,
  CustomTickChart
} from '../graphUtils';
import { getSensorPropsFromMetric } from '../../../helpers/utils';

const SensorStatusBrush = ({ machineId }) => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const { isMobile, isSmallDevice } = useResolutionCheck();

  const metricHistoryData = useSelector(compose(getData, getSelectedSensorHistory));
  const timeWindow = useSelector(getTimeWindow);
  const scopedTimeWindow = useSelector(getScopedTimeWindow);

  const [initialIndices, setInitialIndices] = useState({ startIndex: null, endIndex: null });
  const [indices, setIndices] = useState({ startIndex: 0, endIndex: 0 });
  const [redraw, setRedraw] = useState(0);

  useEffect(() => {
    const scopedTo = moment(timeWindow.to).valueOf();
    const scopedFrom = moment(timeWindow.from).valueOf();

    setInitialIndices({
      startIndex: getChartGraphIndex(
        metricHistoryData.chartData.map(item => item.time),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        metricHistoryData.chartData.map(item => item.time),
        scopedTo,
        'end'
      )
    });
  }, [timeWindow, setInitialIndices, metricHistoryData.chartData]);

  useEffect(() => {
    const scopedTo = moment(scopedTimeWindow.to).valueOf();
    const scopedFrom = moment(scopedTimeWindow.from).valueOf();

    setIndices({
      startIndex: getChartGraphIndex(
        metricHistoryData.chartData.map(item => item.time),
        scopedFrom,
        'start'
      ),
      endIndex: getChartGraphIndex(
        metricHistoryData.chartData.map(item => item.time),
        scopedTo,
        'end'
      )
    });
    // We need to artifically cause rerenders of the rechart
    // Related issue: https://github.com/recharts/recharts/issues/2404
    setRedraw(value => value + 1);
  }, [scopedTimeWindow, setIndices, metricHistoryData.chartData]);

  const handleOnChange = event => {
    const { startIndex, endIndex } = event;
    // check whether time is above 30 min
    if (isValidTimeRange(metricHistoryData.chartData[startIndex].time, metricHistoryData.chartData[endIndex].time)) {
      // We help Recharts to properly react on the brush sliding
      // Related issue: https://github.com/recharts/recharts/issues/963
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dispatch(
          setScopedTimeWindow(
            moment(metricHistoryData.chartData[startIndex].time).toISOString(),
            moment(metricHistoryData.chartData[endIndex].time).toISOString(),
            machineId,
            metricHistoryData.sensor.type
          )
        );
      }, 300);
    } else {
      // we need to abort timer and reset to corrected time window based on index that got changed
      if (timer.current) {
        clearTimeout(timer.current);
      }

      const { from, to } = getTimeWindowAfterBrushChange(indices, { startIndex, endIndex }, scopedTimeWindow);
      dispatch(setScopedTimeWindow(from, to, machineId, metricHistoryData.sensor.type));
    }
  };

  const shouldShowLabel = d =>
    !(
      d < initialIndices.startIndex + initialIndices.endIndex * 0.05 ||
      d + initialIndices.endIndex * 0.05 > initialIndices.endIndex
    );

  const sensorData = getSensorPropsFromMetric(
    {
      ...metricHistoryData.sensor,
      yAxisDomain: metricHistoryData.yAxisDomain
    } || {}
  );

  const visualizationYAxis = getPredefinedYAxis(sensorData) || metricHistoryData.yAxisDomain;

  return (
    <StyledBrushArea>
      <ResponsiveContainer>
        <LineChart key={`chart-${redraw}`} data={metricHistoryData.chartData}>
          <Brush
            height={BRUSH_HEIGHT}
            startIndex={indices.startIndex}
            endIndex={indices.endIndex}
            travellerWidth={10 + (isMobile ? 10 : 0) + (isSmallDevice ? 10 : 0)}
            stroke={COLOR_PRIMARY}
            onChange={handleOnChange}
            tickFormatter={d => (shouldShowLabel(d) ? moment(metricHistoryData.chartData[d].time).format('LT') : null)}
          >
            <LineChart data={metricHistoryData.chartData}>
              <YAxis hide domain={visualizationYAxis} />
              <Line type="monotone" isAnimationActive={false} stroke={COLOR_PRIMARY} dataKey="value" dot={false} />
              <Line type="monotone" dataKey="brush" />
              <XAxis dataKey="time" type="number" hide domain={metricHistoryData.xAxisDomain} />
            </LineChart>
          </Brush>
          <XAxis
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            allowDataOverflow
            domain={['dataMin, dataMax']}
            stroke="false"
            ticks={metricHistoryData.xAxisTicks}
            tickFormatter={tick => {
              moment(tick).format('DD/MM/YYYY HH:mm:ss');
            }}
            tick={props => <CustomTickChart {...props} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledBrushArea>
  );
};

SensorStatusBrush.propTypes = {
  machineId: T.string.isRequired
};

export default SensorStatusBrush;

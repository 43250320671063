import React, { useState } from 'react';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material';
import { deleteGraphsTemplateById } from '../../../../../../redux/graphTemplates/actions';

export function DeleteTemplate({ machineID, templateID, open, handleCloseDialog }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState('');

  async function handleDeleteTempalte() {
    try {
      setError('');
      await dispatch(deleteGraphsTemplateById(machineID, templateID));
    } catch (err) {
      setError('Failed to remove template');
    } finally {
      handleCloseDialog();
    }
  }

  const handleOk = () => {
    handleDeleteTempalte();
  };

  if (error) return <p>{error}</p>;

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            {t('dialog.delete_template.message')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const actionBar = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 2,
          width: { xs: '100%', md: '310px' }
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleCloseDialog()}
          sx={{
            height: '56px',
            padding: '16px',
            width: '100%',
            marginBottom: 1
          }}
        >
          {t('dialog.delete_template.cancel_button')}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleOk}
          sx={{
            height: '56px',
            padding: '16px',
            width: '100%'
          }}
        >
          {t('default_actions.yes_delete')}
        </Button>
      </Box>
    </>
  );

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.delete_template.title')}
      subTitle={t('dialog.delete_template.subtitle')}
      cancelCaption={t('dialog.delete_template.cancel_button')}
      okCaption={t('default_actions.yes_delete')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="flex-end"
      maxWidth="688px"
      actionBar={actionBar}
    />
  );
}

DeleteTemplate.propTypes = {
  machineID: PropTypes.string,
  templateID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired
};

DeleteTemplate.defaultProps = {
  machineID: '',
  templateID: undefined
};

import React, { useEffect, useCallback } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'web-components';
import { MenuItem } from '@mui/material';

import { loadUsers } from '../../../../redux/users/actions';
import { getUsers } from '../../../../redux/rootSelectors';
import { getActiveUsersAsOptions, getCommonLoadingState } from '../../../../helpers/utils';
import SensorNotificationSettings from './SensorNotificationSettings';
import { getSelectedMachine, getMachineNotificationRules } from '../../../../redux/machines/selectors';
import { loadMachine, loadMachineNotificationRules } from '../../../../redux/machines/actions';

const ConnectedSensorNotificationSettings = ({ machineId, sensor, menu, onMenuClick, isMobile }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getUsers);
  const { loadingState: machineLoadingState, data: machineData } = useSelector(getSelectedMachine);
  const {
    loadingState: notificationRuleLoadingState,
    data: notificationRules,
    updateLoadingState: notificationUpdateState,
    deleteLoadingState: notificationDeleteState
  } = useSelector(getMachineNotificationRules);

  const loadNeededData = useCallback(() => {
    dispatch(loadUsers());
    dispatch(loadMachine(machineId));
    dispatch(loadMachineNotificationRules(machineId, sensor.type));
  }, [dispatch, machineId, sensor.type]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  const notifyRules = {
    min: Array.isArray(notificationRules)
      ? notificationRules.find(
          (rule = { warning: {} }) =>
            typeof rule !== 'undefined' &&
            typeof rule.warning !== 'undefined' &&
            typeof rule.warning.min !== 'undefined'
        )
      : notificationRules,
    max: Array.isArray(notificationRules)
      ? (notificationRules || []).find(
          (rule = { warning: {} }) =>
            typeof rule !== 'undefined' &&
            typeof rule.warning !== 'undefined' &&
            typeof rule.warning.max !== 'undefined'
        )
      : notificationRules
  };

  return (
    <SensorNotificationSettings
      loadingState={getCommonLoadingState([loadingState, machineLoadingState, notificationRuleLoadingState])}
      loadingComponent={() =>
        menu ? (
          <MenuItem>
            <Loader size={15} color="primary" />
          </MenuItem>
        ) : (
          <Loader size={20} />
        )
      }
      reloadAction={loadNeededData}
      users={getActiveUsersAsOptions(data)}
      machine={machineData}
      menu={menu}
      onMenuClick={onMenuClick}
      sensor={sensor}
      notificationRules={notifyRules}
      notificationUpdateState={notificationUpdateState}
      notificationDeleteState={notificationDeleteState}
      isMobile={isMobile}
    />
  );
};

ConnectedSensorNotificationSettings.propTypes = {
  machineId: T.string.isRequired,
  sensor: T.shape({ type: T.string }).isRequired,
  menu: T.bool,
  onMenuClick: T.func,
  isMobile: T.bool
};

ConnectedSensorNotificationSettings.defaultProps = {
  menu: false,
  onMenuClick: null,
  isMobile: false
};

export default ConnectedSensorNotificationSettings;

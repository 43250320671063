import React from 'react';
import T from 'prop-types';
import AlarmHistoryOverview from '../../components/AlarmHistoryOverview';
import { PaginationResponse } from '../../helpers/propTypes';

const AlarmHistory = ({ alarms }) => <AlarmHistoryOverview rows={alarms} />;

AlarmHistory.propTypes = {
  alarms: T.shape(PaginationResponse)
};

AlarmHistory.defaultProps = {
  alarms: null
};

export default AlarmHistory;

export const LOAD_GRAPHS_TEMPLATES = 'LOAD_GRAPHS_TEMPLATES/LOAD';
export const FAVORITE_GRAPH_TEMPLATE = 'FAVORITE_GRAPH_TEMPLATE';
export const CREATE_GRAPH_TEMPLATE = 'CREATE_GRAPH_TEMPLATE';
export const GET_GRAPH_TEMPLATE_BY_ID = 'GET_GRAPH_TEMPLATE_BY_ID';
export const DELETE_GRAPH_TEMPLATE_BY_ID = 'DELETE_GRAPH_TEMPLATE_BY_ID';
export const LOAD_SENSOR_HISTORY = 'CHARTS_TEMPLATE/LOAD_SENSOR_HISTORY';
export const LOAD_STATUS_HISTORY = 'CHARTS_TEMPLATE/LOAD_STATUS_HISTORY';
export const LOAD_SENSOR_AVERAGE = 'CHARTS_TEMPLATE/LOAD_SENSOR_AVERAGE';
export const DELETE_SENSOR_GRAPH_TEMPLATE = 'DELETE_SENSOR_GRAPH_TEMPLATE';
export const UPDATE_GRAPH_TEMPLATE = 'UPDATE_GRAPH_TEMPLATE';
export const EXPORT_GRAPH_TEMPLATE = 'EXPORT_GRAPH_TEMPLATE';

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@mui/material';

import { NotifyIcon } from 'web-components';

import { DeviceDetailsWrapper, ContentMarginWrapper } from './DeviceContent.styled';

const DeviceContent = ({ data, createTicket }) => {
  const { t } = useTranslation();

  return (
    <DeviceDetailsWrapper>
      <ContentMarginWrapper>
        <Button
          fullWidth
          variant="contained"
          startIcon={<NotifyIcon iconName="plus" style={{ fontSize: '1.875rem' }} />}
          color="primary"
          size="large"
          onClick={createTicket}
          disableElevation
        >
          {t('support.create_ticket')}
        </Button>
      </ContentMarginWrapper>
      <Divider orientation="horizontal" />
      <ContentMarginWrapper>
        {data['e-mail'] && (
          <Button fullWidth variant="outlined" color="primary" size="large" href={`mailto:${data['e-mail']}`}>
            {t('support.email_us_short')}
          </Button>
        )}
        {data.phone && (
          <Button
            fullWidth
            variant="outlined"
            href={`tel: ${data.phone}`}
            color="primary"
            size="large"
            style={{ marginTop: '1.5rem' }}
          >
            {t('support.call_us_short')}
          </Button>
        )}
      </ContentMarginWrapper>
    </DeviceDetailsWrapper>
  );
};

DeviceContent.propTypes = {
  data: T.shape({ 'e-mail': T.string, phone: T.string }).isRequired,
  createTicket: T.func.isRequired
};

export default DeviceContent;

import { createSelector } from 'reselect';
import { getMachines } from '../rootSelectors';

const getActiveMachines = createSelector([getMachines], machines =>
  machines.data.filter(machine => machine.production_line !== '' && machine.name !== '')
);

const getSelectedMachine = createSelector([getMachines], machines => machines.selected);

const getSelectedMetrics = createSelector([getSelectedMachine], selected => selected.metrics);

const getSelectedScopedMachineHistory = createSelector([getSelectedMetrics], metrics => metrics.scopedHistory);

const getSelectedMachineName = createSelector([getSelectedMachine], selected => selected.data.name);

const getSelectedMachineId = createSelector([getSelectedMachine], selected => ((selected || {}).data || {}).id || null);

const getSelectedMachineConfiguration = createSelector(
  [getSelectedMachine],
  selected => ((selected || {}).data || {}).configuration
);

const getSelectedMachineError = createSelector([getSelectedMachine], selected => selected.error);

const getSelectedSensor = createSelector([getSelectedMachine], selected => selected.sensor);

const getSelectedSensorHistory = createSelector([getSelectedSensor], sensor => sensor.history);

const getSelectedSensorLastMetric = createSelector([getSelectedSensor], selected => selected.lastMetric);

const getSelectedScopeSensorHistory = createSelector([getSelectedSensor], selected => selected.scopedHistory);

const getSelectedMachineSensors = createSelector([getSelectedMachine], selected => selected.sensors);

const getSelectedMachineSensorComparison = createSelector([getSelectedMachineSensors], sensors => sensors.comparison);

const getSelectedMachineSensorComparisonSensorTypes = createSelector(
  [getSelectedMachineSensorComparison],
  comparison => comparison.selected
);

const getSelectedMachineSensorComparisonSensorData = createSelector(
  [getSelectedMachineSensorComparison],
  comparison => comparison.historical
);

const getSelectedMachineScopeSensorComparisonSensorData = createSelector(
  [getSelectedMachineSensorComparison],
  comparison => comparison.scopedHistory
);

const getSelectedSensorName = createSelector(
  [getSelectedSensor],
  sensor =>
    (((sensor || {}).data || {}).is_custom
      ? ((sensor || {}).data || {}).custom_name
      : ((sensor || {}).data || {}).name) || null
);

const getMachineNotificationRules = createSelector([getSelectedMachine], selected => selected.notificationRules);

const getSelectedMachineNotificationRule = createSelector([getMachineNotificationRules], rules =>
  rules.data.filter(rule => !rule.metric_type)
);

const getSensorType = (_, sensorType) => sensorType;

const getSensorNotificationRule = createSelector([getMachineNotificationRules, getSensorType], (rules, sensorType) =>
  rules.data.filter(rule => rule.metric_type === sensorType)
);

const getLastMachineTimestamp = createSelector([getActiveMachines], machines => {
  let lastDate = null;

  machines.forEach(machine => {
    if (((machine || {}).status || {}).updated_at) {
      if (!lastDate || machine.status.updated_at > lastDate) {
        lastDate = machine.status.updated_at;
      }
    }
  });

  return lastDate;
});

const getLastSelectedMachineSensorTimestamp = createSelector([getSelectedMachine], machine => {
  let lastDate = null;

  (((machine || {}).sensors || {}).data || []).forEach(sensor => {
    if (((sensor || {}).last || {}).time) {
      if (!lastDate || sensor.last.time > lastDate) {
        lastDate = sensor.last.time;
      }
    }
  });

  return lastDate;
});

const getShowMachineEditModal = createSelector([getSelectedMachine], machine => machine.machineEditModal);

export {
  getActiveMachines,
  getSelectedMachine,
  getSelectedMetrics,
  getSelectedMachineError,
  getSelectedSensor,
  getSelectedSensorName,
  getSelectedMachineNotificationRule,
  getSensorNotificationRule,
  getMachineNotificationRules,
  getLastMachineTimestamp,
  getLastSelectedMachineSensorTimestamp,
  getSelectedMachineSensors,
  getSelectedMachineSensorComparison,
  getSelectedMachineSensorComparisonSensorTypes,
  getSelectedMachineSensorComparisonSensorData,
  getSelectedMachineConfiguration,
  getSelectedSensorLastMetric,
  getSelectedMachineId,
  getSelectedScopeSensorHistory,
  getSelectedScopedMachineHistory,
  getSelectedMachineScopeSensorComparisonSensorData,
  getSelectedMachineName,
  getSelectedSensorHistory,
  getShowMachineEditModal
};

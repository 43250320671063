import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Hidden, ClickAwayListener } from '@mui/material';
import { NotifyIcon } from 'web-components';

import { StyledBadge, StyledPopper, StyledDrawer, StyledNotificationWrapper } from './elements';
import ConnectedAlerts from './ConnectedAlerts';
import { loadAlerts } from '../../../redux/alerts/actions';
import { toggleAlertsMenu } from '../../../redux/ui/alertsMenu/actions';
import { getAlerts } from '../../../redux/rootSelectors';
import { getAlertsMenu } from '../../../redux/ui/selectors';

const AlertsWrapper = () => {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const { data: alerts } = useSelector(getAlerts);
  const { isMenuOpen: menuOpen } = useSelector(getAlertsMenu);
  const setMenuOpen = shouldOpen => dispatch(toggleAlertsMenu(shouldOpen));

  useEffect(() => {
    dispatch(loadAlerts());
  }, [dispatch]);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const prevOpen = useRef(menuOpen);

  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  const anyNewAlert = !!Object.values(alerts).length && alerts.some(alert => alert.unread);

  return (
    <>
      <IconButton
        edge="start"
        color="primary"
        ref={anchorRef}
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="notifications"
        data-selector="alerts-toggle-button"
        onClick={handleToggle}
        style={{ width: 60 }}
      >
        {anyNewAlert ? (
          <StyledBadge color="secondary" badgeContent="" variant="dot">
            <NotifyIcon iconName="notification" />
          </StyledBadge>
        ) : (
          <NotifyIcon iconName="notification" />
        )}
      </IconButton>
      <Hidden xsDown>
        <StyledPopper open={menuOpen} anchorEl={anchorRef.current} role={undefined} disablePortal placement="bottom">
          <StyledNotificationWrapper elevation={2} square>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <ConnectedAlerts setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              </div>
            </ClickAwayListener>
          </StyledNotificationWrapper>
        </StyledPopper>
      </Hidden>
      <Hidden smUp>
        <StyledDrawer anchor="top" open={menuOpen} onClose={() => setMenuOpen(false)}>
          <ConnectedAlerts setMenuOpen={setMenuOpen} menuOpen={menuOpen} mobile />
        </StyledDrawer>
      </Hidden>
    </>
  );
};

export default AlertsWrapper;

const LOGIN = 'AUTH/LOGIN';
const EXTERNAL_LOGIN = 'AUTH/EXTERNAL_LOGIN';
const LOGOUT = 'AUTH/LOGOUT';
const CHECK_AUTHENTICATION = 'AUTH/CHECK_AUTHENTICATION';
const SET_AUTHENTICATION = 'AUTH/SET_AUTHENTICATION';
const REQUEST_PASSWORD_RESET = 'AUTH/REQUEST_PASSWORD_RESET';
const CHECK_TOKEN_CODE = 'AUTH/CHECK_TOKEN_CODE';
const RESET_PASSWORD_NON_AUTH = 'AUTH/RESET_PASSWORD_NON_AUTH';
const REGISTER = 'AUTH/REGISTER';
const UPDATE_AUTH_TOKENS = 'AUTH/UPDATE_AUTH_TOKENS';

export {
  LOGIN,
  EXTERNAL_LOGIN,
  LOGOUT,
  SET_AUTHENTICATION,
  CHECK_AUTHENTICATION,
  REQUEST_PASSWORD_RESET,
  CHECK_TOKEN_CODE,
  RESET_PASSWORD_NON_AUTH,
  REGISTER,
  UPDATE_AUTH_TOKENS
};

import styled from 'styled-components';

import { SECONDARY_TEXT_COLOR } from 'attrs/colors';
import { Grid, Paper } from '@mui/material';

const COLOR_BORDER = '#b1b7c560';

export const Content = styled.div``;

export const MobileCellWrapper = styled.div``;

export const CellLabel = styled.div`
  font-size: 0.625rem;
  color: ${SECONDARY_TEXT_COLOR};
`;

export const CellValue = styled.div`
  font-size: ${({ isSmallDevice }) => (isSmallDevice ? '0.75rem' : '0.875rem')};
  margin-top: ${({ isSmallDevice }) => (isSmallDevice ? '0.25rem' : '0.125rem')};
`;

export const PaperStyled = styled(Paper)`
  position: relative;
`;

export const Wrapper = styled.div``;

export const GridStyled = styled(Grid)`
  padding: 1rem;
  border-bottom: 1px solid ${COLOR_BORDER};
`;

export const GridEdit = styled.div`
  height: 100%;
  margin: auto;
`;

export const ContentRow = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.25rem 0.25rem;

  ${theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.breakpoints.down('xs')} {
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`
);

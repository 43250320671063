import { ROLE_MANAGER, ROLE_OPERATOR } from './roles';

const PERMISSIONS = {
  dashboard: {
    [ROLE_MANAGER]: {
      canEditMachines: true,
      canEditMachineNotifications: true,
      canEditSensorNotifications: true
    },
    [ROLE_OPERATOR]: {
      canEditMachines: false,
      canEditMachineNotifications: false,
      canEditSensorNotifications: false
    }
  }
};

export default PERMISSIONS;

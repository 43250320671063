import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

import UserInviteForm from '../../components/forms/UserManagement/UserInviteForm';
import ConnectedUserManagementContent from './ConnectedUserManagementContent';
import { ContentWrapper } from '../../elements';
import TooltipInfo from '../../components/TooltipInfo';
import { ContainerTitle, PageHeader, PageHeaderAction, Tab, PageHeaderTitle, PageHeaderTabs } from './elements';

const labelAmmend = 'user_management.list';

const UserManagement = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const [widthTabs, setWidthTabs] = useState(0);

  const isMobile = useMediaQuery('(max-width:425px)');
  const isTablet = useMediaQuery('(max-width:768px)');

  const USER_TABS = [
    {
      value: 0,
      title: 'active',
      subtitle: 'active_subtitle',
      filter: entry => entry.is_active,
      columns: [
        { id: 'first_name', label: t(`${labelAmmend}.first_name`), sortable: true },
        { id: 'last_name', label: t(`${labelAmmend}.last_name`), sortable: true },
        { id: 'role', label: t(`${labelAmmend}.role`), sortable: false },
        { id: 'e-mail', label: t(`${labelAmmend}.email`), sortable: false },
        { id: 'phone', label: t(`${labelAmmend}.phone`), sortable: false }
      ]
    },
    {
      value: 1,
      title: 'pending',
      subtitle: 'pending_subtitle',
      filter: entry => !entry.is_active,
      columns: [
        { id: 'role', label: t(`${labelAmmend}.role`), sortable: true },
        { id: 'e-mail', label: t(`${labelAmmend}.email`), sortable: true }
      ]
    }
  ];

  const childRefs = React.useMemo(() => USER_TABS.map(() => React.createRef()), [USER_TABS]);

  const getWidthOfTabs = tabsRef => {
    tabsRef.map(value => setWidthTabs(prevState => prevState + value.current.offsetWidth));
  };

  useEffect(() => {
    getWidthOfTabs(childRefs);
    // eslint-disable-next-line
  }, []);

  const selectedTab = USER_TABS.find(tab => tab.value === selected);
  const variant = isMobile ? 'fullWidth' : 'standard';

  const marginTabValue = isTablet ? '3rem' : '5rem';

  return (
    <>
      <PageHeader className="withoutShadow" alwaysVisible>
        <PageHeader.Title>
          <ContainerTitle>
            <PageHeaderTitle>{t('user_management.title')}</PageHeaderTitle>
            <TooltipInfo titleTooltip="user_management.title_tooltip" />
          </ContainerTitle>
        </PageHeader.Title>
        <PageHeaderAction>
          <UserInviteForm />
        </PageHeaderAction>
      </PageHeader>
      <PageHeaderTabs
        ariaLabel="userTabs"
        onChange={setSelected}
        selected={selected}
        variant={variant}
        style={{ background: '#F7F7FA' }}
        widthLineTabEnd={isMobile ? '100%' : `${widthTabs}px`}
        bottomLineTabEnd="-2px"
        leftLineTabEnd={isMobile ? '0' : marginTabValue}
        color="transparent"
      >
        {USER_TABS.map((tab, i) => (
          <Tab key={tab.value} label={t(`user_management.${tab.title}`)} ref={childRefs[i]} />
        ))}
      </PageHeaderTabs>
      <ContentWrapper maxWidth={false} className="withPageHeaderAndTabs withTabs">
        <ConnectedUserManagementContent selectedTab={selectedTab} />
      </ContentWrapper>
    </>
  );
};
export default UserManagement;

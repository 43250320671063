import React from 'react';
import T from 'prop-types';

import { useResolutionCheck } from 'web-components';
import { CellLabel, CellValue, MobileCellWrapper } from './MobileView.styled';
import {
  COLUMN_BRAND,
  COLUMN_EDIT,
  COLUMN_MACHINE_NAME,
  COLUMN_MILL_SYSTEM,
  COLUMN_PRODUCTION_LINE,
  COLUMN_STATUS
} from '../MachinesOverview.config';
import MachineTrackStatusIcon, {
  MACHINE_STATUS_TRACKED,
  MACHINE_STATUS_UNTRACKED
} from '../../MachineTrackStatusIcon/MachineTrackStatusIcon';
import { getMachineType } from '../../../helpers/utils';

const MobileCell = ({ row, label, id, value }) => {
  const { isSmallDevice } = useResolutionCheck();
  const machineType = getMachineType(row.type);

  const cellValue = {
    [true]: () => value,
    [id === COLUMN_STATUS]: () => {
      const status = row.name !== '' && row.production_line !== '' ? MACHINE_STATUS_TRACKED : MACHINE_STATUS_UNTRACKED;
      return (
        <div className="statusWrapper">
          <MachineTrackStatusIcon status={status} />
        </div>
      );
    },
    [[COLUMN_MACHINE_NAME, COLUMN_PRODUCTION_LINE].includes(id)]: () => <div>{value || '--'}</div>,
    [id === COLUMN_BRAND]: () => <div>{machineType.brand || '--'}</div>,
    [id === COLUMN_MILL_SYSTEM]: () => <div>{machineType.type || '--'}</div>,
    [id === COLUMN_EDIT]: () => <></>
  }.true;

  return (
    <MobileCellWrapper isSmallDevice={isSmallDevice}>
      <CellLabel>{label}</CellLabel>
      <CellValue isSmallDevice={isSmallDevice}>{cellValue()}</CellValue>
    </MobileCellWrapper>
  );
};

MobileCell.propTypes = {
  row: T.shape({
    name: T.string,
    production_line: T.string,
    type: T.string
  }).isRequired,
  label: T.string.isRequired,
  id: T.string.isRequired,
  value: T.oneOfType([T.string, T.array, T.shape({})]).isRequired
};

export default MobileCell;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { getOrganization } from 'redux/user/selectors';
import { loadOrganization } from 'redux/user/actions';
import { loadMachines } from 'redux/machines/actions';
import { getLoadingState, getMachines } from 'redux/rootSelectors';
import { getCommonLoadingState } from 'helpers/utils';

import SupportContent from './SupportContent';

const ConnectedSupportContent = () => {
  const dispatch = useDispatch();
  const { loadingState: organizationLoadingState, data } = useSelector(getOrganization);
  const machinesLoadingState = useSelector(compose(getLoadingState, getMachines));
  const loadingState = getCommonLoadingState([organizationLoadingState, machinesLoadingState]);

  useEffect(() => {
    dispatch(loadOrganization());
    dispatch(loadMachines());
  }, [dispatch]);

  return <SupportContent data={data} loadingState={loadingState} reloadAction={() => dispatch(loadOrganization())} />;
};

export default ConnectedSupportContent;

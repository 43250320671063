import { createSelector } from 'reselect';
import { getFilters } from '../rootSelectors';

const getMachineSensorsInformationById = createSelector([getFilters], filter => filter.machinesensors);
const getMachineSensorsTreeView = createSelector([getFilters], filter => filter.treeview);
const getProductionLines = createSelector([getFilters], filter => filter.productionline);
const getMachinesList = createSelector([getFilters], filter => filter.machines);
const getSensors = createSelector([getFilters], filter => filter.sensors);

export { getMachineSensorsInformationById, getMachineSensorsTreeView, getProductionLines, getMachinesList, getSensors };

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MachineHistoricalGraph from './MachineHistoricalGraph';
import { loadMachineStatusMetrics } from '../../../../redux/machines/actions';
import { getSelectedMetrics } from '../../../../redux/machines/selectors';
import { METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from '../../../../attrs/layout';
import { getMetricsTimeFrame } from '../../../../helpers/utils';

const ConnectedMachineHistoricalGraph = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingState, data } = useSelector(getSelectedMetrics);

  const loadNeededData = useCallback(() => {
    dispatch(
      loadMachineStatusMetrics(
        id,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
  }, [dispatch, id]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  return (
    <MachineHistoricalGraph loadingState={loadingState} reloadAction={loadNeededData} metrics={data} machineId={id} />
  );
};

export default ConnectedMachineHistoricalGraph;

import React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResolutionCheck, ContentPageHeader } from 'web-components';

import { ContentWrapper } from '../../../elements';
import { FOOTER_NAVIGATION_BAR_HEIGHT } from '../../../attrs/layout';
import ResetPasswordForm from '../../../components/forms/Account/ResetPasswordForm';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();

  return (
    <ContentWrapper>
      <ContentPageHeader
        header={t('profile.account_settings')}
        subHeader={t('profile.reset_password_sub_title')}
        backUrl="/account"
      />
      <Container
        disableGutters={isTablet}
        maxWidth="sm"
        style={{ paddingBottom: `${FOOTER_NAVIGATION_BAR_HEIGHT + 20}px` }}
      >
        <ResetPasswordForm />
      </Container>
    </ContentWrapper>
  );
};

export default ResetPassword;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import {
  BATCH_NOTE_CREATE,
  BATCH_NOTE_DELETE,
  BATCH_NOTE_UPDATE,
  BATCH_NOTE_ALL_BY_BATCH_ID,
  BATCH_NOTE_SHOW_MODAL,
  BATCH_NOTE_RESET
} from './constants';
import { createReducer, enhanceWithResetActions, loadingStateReducer } from '../utils';

export default combineReducers({
  batchLoadingState: enhanceWithResetActions([BATCH_NOTE_ALL_BY_BATCH_ID, BATCH_NOTE_RESET])(
    loadingStateReducer([BATCH_NOTE_ALL_BY_BATCH_ID, BATCH_NOTE_DELETE, BATCH_NOTE_CREATE, BATCH_NOTE_UPDATE])
  ),
  batchNotes: enhanceWithResetActions([BATCH_NOTE_RESET])(
    typeToReducer(
      {
        [BATCH_NOTE_ALL_BY_BATCH_ID]: {
          SUCCESS: (data, { payload }) => payload
        },
        [BATCH_NOTE_CREATE]: {
          SUCCESS: (state, { payload }) => [payload, ...state]
        },
        [BATCH_NOTE_UPDATE]: {
          SUCCESS: (data, { payload }) => [payload, ...data.filter(batchNote => batchNote.id !== payload.id)]
        },
        [BATCH_NOTE_DELETE]: {
          SUCCESS: (data, { payload }) => payload
        }
      },
      [{ id: '', title: '', note: '' }]
    )
  ),
  batchNotesModal: createReducer(
    // Show/hide the batch note modal
    {
      [BATCH_NOTE_SHOW_MODAL]: (state, { value }) => value
    },
    false
  )
});

import styled from 'styled-components';

export const DeviceDetailsWrapper = styled.div`
  & > .MuiDivider-root {
    margin: 2.5rem 0;
  }

  & > .MuiButton-root {
    margin: 0 20px;
  }
`;

export const ContentMarginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  & > .MuiButton-root {
    max-width: 300px;
    height: 48px;
  }
`;

import styled from 'styled-components';

import { SECONDARY_TEXT_COLOR } from 'attrs/colors';
import { Grid, Paper } from '@mui/material';

const COLOR_BORDER = '#b1b7c560';

export const Content = styled.div``;

export const MobileRowWrapper = styled(Grid)`
  padding: 1.25rem 1.25rem 0;
  border-bottom: 1px solid ${COLOR_BORDER};
`;

export const CellLabel = styled.div`
  font-size: 0.625rem;
  color: ${SECONDARY_TEXT_COLOR};
`;

export const CellValue = styled(Grid)`
  font-size: ${({ isSmallDevice }) => (isSmallDevice ? '0.75rem' : '0.875rem')};
  margin-top: ${({ isSmallDevice }) => (isSmallDevice ? '0.25rem' : '0.125rem')};
`;

export const Inline = styled.div`
  display: ${({ isSmallDevice }) => (isSmallDevice ? 'block' : 'flex')};
  justify-content: flex-end;
`;

export const PaperStyled = styled(Paper)`
  position: relative;
`;

export const CellSubValue = styled.div``;

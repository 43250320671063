import React from 'react';
import MachinesOverviewDetails from 'components/MachinesOverviewDetails';
import T from 'prop-types';
import withLoadingState from '../../../helpers/withLoadingState';

const MachinesDetail = ({ data }) => {
  if (!data) {
    return null;
  }

  return <MachinesOverviewDetails machine={data} />;
};

MachinesDetail.propTypes = {
  data: T.shape({})
};

MachinesDetail.defaultProps = {
  data: null
};

export default withLoadingState(MachinesDetail);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';

import Dashboard from './Dashboard';
import { loadMachines, resetMachinesState, subscribeToMachinesUpdateStream } from '../../redux/machines/actions';
import { getActiveMachines } from '../../redux/machines/selectors';
import { isSuccess } from '../../helpers/utils';
import { loadAlertHistory } from '../../redux/alert_history/actions';
import { loadAlarms } from '../../redux/alarms_history/actions';
import { PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';

const ConnectedDashboard = () => {
  const dispatch = useDispatch();
  const { loadingState } = useSelector(state => state.machines);
  const { loadingState: wsLoadingState } = useSelector(state => state.stream);
  const machines = useSelector(getActiveMachines);
  const alarms = useSelector(state => state.alarmsHistory);
  const alerts = useSelector(state => state.alertsHistory);

  useEffect(() => {
    dispatch(loadAlarms(PAGINATION_DEFAULT_START_PAGE));
    dispatch(loadAlertHistory(PAGINATION_DEFAULT_START_PAGE));
    dispatch(loadMachines());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(wsLoadingState.status)) {
      dispatch(subscribeToMachinesUpdateStream());
    }
  }, [dispatch, wsLoadingState]);

  useEffect(() => () => dispatch(resetMachinesState()), [dispatch]);

  return (
    <Dashboard
      loadingState={loadingState}
      reloadAction={() => dispatch(loadMachines())}
      machines={groupBy(machines, 'production_line')}
      alarms={alarms.data}
      alerts={alerts.data}
    />
  );
};

export default ConnectedDashboard;

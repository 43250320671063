import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { compose } from 'redux';

import { METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from 'attrs/layout';
import { setIsBatchRecordsDetails, setTimeWindow, setTimeWindowRange } from 'redux/ui/settings/actions';
import {
  resetMachineState,
  subscribeToSensorsUpdateStream,
  loadMachine,
  addSensorsForComparison
} from '../../../../redux/machines/actions';
import { getMetricsTimeFrame, isSuccess } from '../../../../helpers/utils';
import { getSelectedMachine, getSelectedMachineConfiguration } from '../../../../redux/machines/selectors';
import SensorComparison from './SensorComparison';
import { getLoadingState } from '../../../../redux/rootSelectors';

const useQuery = () => new URLSearchParams(useLocation().search);

const ConnectedSensorComparison = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingState: wsLoadingState } = useSelector(state => state.stream);
  const loadingState = useSelector(compose(getLoadingState, getSelectedMachine));
  const configuration = useSelector(getSelectedMachineConfiguration);

  const query = useQuery();
  const querySensorTypes = (query.get('types') && query.get('types').split(',')) || [];

  useEffect(() => {
    if (isSuccess(loadingState.status) && querySensorTypes.length > 0) {
      const validSensorTypes2 = [];

      // For each to ensure the order of given sensors
      querySensorTypes.forEach(sensor => {
        const isValidSensor = ((configuration || {}).metrics || []).some(
          metric => (metric.is_custom ? metric.custom_type : metric.type) === sensor
        );
        if (isValidSensor) {
          validSensorTypes2.push(sensor);
        }
      });
      if (validSensorTypes2.length > 0) {
        // only take the first 6 valid sensors
        dispatch(addSensorsForComparison(validSensorTypes2.slice(0, 6), id));
      }
    }
  }, [loadingState, dispatch, querySensorTypes, configuration, id]);

  const loadNeededData = useCallback(() => {
    dispatch(loadMachine(id));
  }, [dispatch, id]);

  useEffect(() => {
    const { from, to } = getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS);
    dispatch(setIsBatchRecordsDetails(false, ''));
    dispatch(setTimeWindow(from, to));
    dispatch(setTimeWindowRange(false));

    loadNeededData();
  }, [loadNeededData, dispatch]);

  useEffect(() => {
    if (isSuccess(wsLoadingState.status)) {
      dispatch(subscribeToSensorsUpdateStream(id));
    }
  }, [dispatch, id, wsLoadingState]);

  useEffect(() => () => dispatch(resetMachineState()), [dispatch]);

  return <SensorComparison loadingState={loadingState} reloadAction={loadNeededData} />;
};

export default ConnectedSensorComparison;

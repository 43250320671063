// Using this to returns the language of the browser UI
const userLocale = navigator.language || 'pt-BR';

const FormatDate = date => {
  const DateString = new Date(date);
  const Dt = DateString.toLocaleDateString(userLocale, { timeZone: 'UTC' });
  return Dt;
};
const FormatNumber = {
  FormatDate
};

export default FormatNumber;

import styled from 'styled-components';
import { Dialog } from 'web-components';
import { Grid } from '@mui/material';

const DIALOG_BACKGROUND_COLOR = '#FFFFFF';
const FOOTER_BACKGROUND_COLOR = '#F8F8F9';
const INPUT_COLOR_ERROR = '#F13309';
const DIALOG_CLOSE_SVG_COLOR = '#2B2F42';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background-color: ${FOOTER_BACKGROUND_COLOR} !important;
  }

  & .MuiFormHelperText-root {
    color: ${INPUT_COLOR_ERROR} !important;
    position: absolute;
    top: 55px;
  }
`;

export const StyledDialogHeader = styled(Dialog.Header)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  padding: 2rem 4rem 0.5rem!important;

  .MuiSvgIcon-root {
    color: ${DIALOG_CLOSE_SVG_COLOR};
  }

  & .MuiTypography-h1 {
    margin: 0 !important;
    font-size: 1.75rem;
    align-self: center;

    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
    }
  }
`
);

export const StyledBetweendGrid = styled(Grid)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-content: space-around !important;
  align-items: flex-start !important;
  gap: 1rem;

  @media (max-width: 960px) {
    gap: 0;
    flex-direction: column !important;
    align-items: normal !important;
  }
`;

export const StyledLeftdGrid = styled(Grid)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-content: space-between !important;

  align-items: center !important;
  margin-top: 1rem;
`;

export const StyledDialogBody = styled(Dialog.Body)`
  display: flex;
  background-color: ${DIALOG_BACKGROUND_COLOR};
  min-height: 60vh;

  &.MuiDialogContent-root {
    padding: 2rem 4rem;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 55px;
  }
`;

export const StyledDescription = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7a7d85;
  margin-top: -0.5rem;
`;

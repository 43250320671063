import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Container, useMediaQuery, useTheme } from '@mui/material';
import { NotifyIcon, FormElements } from 'web-components';

import { setConfirmationRequest } from '../../../redux/ui/confirmations/actions';
import { ROLE_OPERATOR, ROLES } from '../../../attrs/roles';
import { getUserRole } from '../../../helpers/utils';
import { UserInviteSchema } from '../../../attrs/formValidation';
import { inviteUser } from '../../../redux/users/actions';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import * as S from './styles';

const UserInvite = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('max-width:425px');

  const handleFormSubmit = values => {
    dispatch(inviteUser(values));
    setOpen(false);
  };

  const handleCloseDialog = dirty => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => setOpen(false)
        })
      );
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        fullWidth={isMobile}
        variant="contained"
        color="primary"
        iconName="addUser"
        onClick={() => setOpen(true)}
        style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        size="medium"
        startIcon={<NotifyIcon iconName="addUser" />}
      >
        {t('user_management.invite_user')}
      </Button>
      <S.StyledDialog open={open} disableScrollLock>
        <Formik
          initialValues={{
            email: '',
            role: ROLE_OPERATOR
          }}
          onSubmit={values => handleFormSubmit(values)}
          validationSchema={UserInviteSchema}
          enableReinitialize
        >
          {({ values, dirty, handleSubmit, handleBlur, handleChange, touched, isSubmitting, errors }) => (
            <>
              <S.StyledDialogHeader
                highlightColor={COLOR_PRIMARY}
                onClose={() => handleCloseDialog(dirty)}
                closeButtonProps={{ color: 'primary' }}
                theme={theme}
              >
                {t('user_management.invite_user_title')}
              </S.StyledDialogHeader>
              <S.StyledDialogSubHeader theme={theme} withDivider={false}>
                {t('user_management.invite_user_subtitle')}
              </S.StyledDialogSubHeader>
              <S.StyledDialogBody>
                <Container style={{ marginTop: '2rem', paddingRight: '1rem' }}>
                  <FormElements.TextField
                    label={t('user_management.list.email')}
                    fullWidth
                    id="email"
                    margin="normal"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && t(`form.validate.${errors.email}`)}
                  />
                  <FormElements.Select
                    value={values.role}
                    fullWidth
                    handleChange={handleChange}
                    label={t('user_management.list.role')}
                    name="role"
                    id="role"
                    size="small"
                  >
                    {ROLES.map(role => (
                      <option key={role.value} value={role.value}>
                        {t(getUserRole(role.value).label)}
                      </option>
                    ))}
                  </FormElements.Select>
                </Container>
              </S.StyledDialogBody>
              <S.StyledDialogActions>
                <Button variant="text" color="secondary" onClick={() => handleCloseDialog(dirty)}>
                  {t('dashboard.custom.cancel')}
                </Button>
                <Button
                  disabled={!dirty || isSubmitting || Object.keys(errors).length !== 0}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  {t('form.invite')}
                </Button>
              </S.StyledDialogActions>
            </>
          )}
        </Formik>
      </S.StyledDialog>
    </>
  );
};

export default UserInvite;

import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import T from 'prop-types';
import moment from 'moment';
import { FormElements, NotifyIcon } from 'web-components';
import Autocomplete from '@mui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { getEvery15Min } from '../../helpers/utils';

const TimeInputList = ({
  errors,
  handleBlur,
  notTime,
  value,
  handleTimeChange,
  fieldId,
  showIcon,
  labelText,
  autoComplete,
  options,
  iconName,
  forceValue,
  getError,
  getHelperText,
  disabled
}) => {
  const { t } = useTranslation();
  const [localTime, setLocalTime] = useState(moment(value).format('HH:mm'));
  const timeSlotOptions = options?.length ? options : getEvery15Min();

  /**
   * Return today date with the selected date
   * @param {string} time HH:mm format
   */
  const getDateTime = time => {
    const {
      groups: { hour, minutes }
    } = /(?<hour>[0-9]{2}):(?<minutes>[0-9]{2})/gm.exec(time);
    return moment(new Date())
      .set('hour', hour)
      .set('minutes', minutes)
      .set('millisecond', 0)
      .set('seconds', 0)
      .toDate();
  };

  const CurrentInput = () => {
    if (autoComplete) {
      return (
        <Autocomplete
          disabled={disabled}
          freeSolo
          fullWidth
          size="small"
          disableClearable
          value={localTime}
          id={`autocomplete-time-${fieldId}`}
          options={timeSlotOptions}
          onChange={(event, newValue) => handleTimeChange(getDateTime(newValue))}
          renderInput={params => (
            <FormElements.TextField
              {...params}
              label={t(labelText)}
              fullWidth
              id={fieldId}
              margin="none"
              onChange={event => {
                setLocalTime(event.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.target.value) {
                  handleTimeChange(e.target.value);
                }
              }}
              name={fieldId}
              onBlur={handleBlur}
              error={errors && errors[fieldId]}
              helperText={errors && errors[fieldId] && errors[fieldId]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <NotifyIcon iconName="clock" />
                  </InputAdornment>
                )
              }}
            />
          )}
          style={{ width: '100%' }}
        />
      );
    }

    return (
      <FormElements.Select
        value={forceValue ? value : localTime}
        fullWidth
        handleChange={event => {
          handleTimeChange(notTime ? event?.target?.value : getDateTime(event?.target?.value));
          setLocalTime(event?.target?.value);
        }}
        label={t(labelText)}
        name={fieldId}
        id={fieldId}
        onBlur={handleBlur}
        iconName={iconName}
        error={getError(`${fieldId}`)}
        helperText={getHelperText(`${fieldId}`)}
        disabled={disabled}
      >
        {notTime
          ? timeSlotOptions.map(({ key, value: timeValue }) => (
              <option key={key} value={key}>
                {timeValue}
              </option>
            ))
          : timeSlotOptions.map(item => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
      </FormElements.Select>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center'
      }}
    >
      {showIcon ? (
        <div>
          <IconButton
            aria-label="confirm-time"
            size="small"
            color="primary"
            onClick={() => handleTimeChange(getDateTime(localTime))}
          >
            <NotifyIcon iconName="clock" color="inherit" style={{ marginRight: '.5rem' }} />
          </IconButton>
        </div>
      ) : (
        <></>
      )}
      <CurrentInput />
    </div>
  );
};

TimeInputList.propTypes = {
  handleTimeChange: T.func.isRequired,
  fieldId: T.string,
  showIcon: T.bool,
  labelText: T.string,
  errors: T.objectOf(T.shape({})).isRequired,
  handleBlur: T.func.isRequired,
  notTime: T.bool.isRequired,
  value: T.oneOfType([T.instanceOf(Date), T.string]).isRequired,
  autoComplete: T.bool,
  options: T.arrayOf(T.shape({})),
  iconName: T.string,
  forceValue: T.bool,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  disabled: T.bool
};

TimeInputList.defaultProps = {
  fieldId: 'timeT',
  showIcon: false,
  labelText: 'dashboard.custom.time_selection.start_time',
  autoComplete: true,
  options: [],
  iconName: '',
  forceValue: false,
  disabled: false
};

export default TimeInputList;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import {
  LOAD_MACHINE_SENSORS_BY_ID,
  LOAD_MACHINE_SENSORS_TREEVIEW,
  LOAD_PRODUCTION_LINES,
  LOAD_MACHINES,
  LOAD_SENSORS
} from './constants';

const machinesensorsReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_SENSORS_BY_ID),
  data: typeToReducer(
    {
      [LOAD_MACHINE_SENSORS_BY_ID]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const treeviewReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_SENSORS_TREEVIEW),
  data: typeToReducer(
    {
      [LOAD_MACHINE_SENSORS_TREEVIEW]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const productionLineReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_PRODUCTION_LINES),
  data: typeToReducer(
    {
      [LOAD_PRODUCTION_LINES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const machinesReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINES),
  data: typeToReducer(
    {
      [LOAD_MACHINES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const sensorsReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_SENSORS),
  data: typeToReducer(
    {
      [LOAD_SENSORS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

export default combineReducers({
  machinesensors: machinesensorsReducer,
  treeview: treeviewReducer,
  productionline: productionLineReducer,
  machines: machinesReducer,
  sensors: sensorsReducer
});

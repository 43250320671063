import React from 'react';
import T from 'prop-types';
import { NotifyIcon } from 'web-components';

import FormAlertWrapper from './elements';

const FormAlert = ({ message }) => (
  <FormAlertWrapper>
    <NotifyIcon iconName="error" style={{ marginRight: '.5rem' }} />
    {message}
  </FormAlertWrapper>
);

FormAlert.propTypes = {
  message: T.string.isRequired
};

export default FormAlert;

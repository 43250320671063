import { createSelector } from 'reselect';

export const getGraphsTemplatesState = state => state.graphTemplates.data;

export const getNewTemplate = createSelector([getGraphsTemplatesState], data => data.newTemplate);

export const getAllGraphTemplates = createSelector([getGraphsTemplatesState], data => data.templates);

export const getCurrentTemplate = createSelector([getGraphsTemplatesState], data => data.currentTemplate);

export const getTemplateCreationError = createSelector([getGraphsTemplatesState], data => data.error);

export const getDeleteSuccess = createSelector([getGraphsTemplatesState], data => data.deleteSuccess);

export const getFavoriteTemplate = createSelector([getGraphsTemplatesState], data => data.favorite);

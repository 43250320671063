import styled from 'styled-components';
import { Container } from '@mui/material';
import { Dialog } from 'web-components';

export const Modal = styled(Dialog)`
  &.MuiDialog-paperFullWidth {
    border-radius: 10px;
    box-shadow: 0px 8px 32px rgba(17, 17, 26, 0.03);
  }
`;

export const DialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    padding: 2rem;
  }
`;
export const DialogHeader = styled(Dialog.Header)`
  &.MuiDialogTitle-root {
    padding-top: 1rem;
    padding-left: 3rem;
    @media (max-width: 425px) {
      padding-left: 2rem;
      h2 {
        font-size: 1rem;
      }
    }
  }
`;
export const DialogSubHeader = styled(Dialog.SubHeader)`
  &.MuiDialogTitle-root {
    padding-left: 3rem;
    @media (max-width: 425px) {
      padding-left: 2rem;
      p {
        font-size: 0.857rem;
      }
    }
  }
`;
export const Content = styled(Container)`
  &.MuiContainer-root {
    margin-top: 1rem;
    margin-left: 0;
    padding-left: 2rem;
    padding-bottom: 10rem;
    @media (max-width: 768px) {
      padding-bottom: 5rem;
      padding-left: 1.75rem;
    }
    @media (max-width: 425px) {
      padding-bottom: 2.5rem;
      padding-left: 0.5rem;
    }
  }
`;

export const ContainerMultiSelect = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import SensorSelector from './SensorSelector';
import SelectedSensorsToolbar from './SelectedSensorsToolbar';

const placeHolderKeys = [
  'placeholder-0',
  'placeholder-1',
  'placeholder-2',
  'placeholder-3',
  'placeholder-4',
  'placeholder-5'
];

const SensorComparisonFooter = ({ className, sensors, selectedSensors, onChange, mobile }) => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedSensorType, setSelectedSensorType] = useState(null);
  const [selectedSensorTypes, setSelectedSensorTypes] = useState(selectedSensors);

  const sensorsWithTranslatedNames = sensors
    .filter(item => !item.is_static)
    .map(item => ({
      ...item,
      name: t(item.name)
    }));

  const handleItemSelect = type => {
    const newSelectedSensorTypes = !placeHolderKeys.includes(selectedSensorType)
      ? selectedSensorTypes.map(sensorType => (sensorType === selectedSensorType ? type : sensorType))
      : [...selectedSensorTypes, type];

    setSelectedSensorTypes(newSelectedSensorTypes);
    onChange(newSelectedSensorTypes);
  };

  const handleRemoveClick = (event, type) => {
    event.stopPropagation();
    const newSelectedSensorTypes = selectedSensorTypes.filter(sensor => sensor !== type);
    setSelectedSensorTypes(newSelectedSensorTypes);
    onChange(newSelectedSensorTypes);
  };

  const handleSelectedItemClick = (event, type = null) => {
    event.stopPropagation();
    setSelectedSensorType(type);
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
    setSelectedSensorType(null);
  };

  return (
    <div className={className}>
      <SelectedSensorsToolbar
        selectedSensorType={selectedSensorType}
        selectedSensorTypes={selectedSensorTypes}
        placeHolderKeys={placeHolderKeys}
        sensors={sensorsWithTranslatedNames}
        onSelectedItemClick={handleSelectedItemClick}
        onRemove={handleRemoveClick}
        mobile={mobile}
      />
      <SensorSelector
        anchorElement={anchorElement}
        selectedSensorType={selectedSensorType}
        selectedSensorTypes={selectedSensorTypes}
        sensors={sensorsWithTranslatedNames}
        onItemSelect={handleItemSelect}
        onClose={handleMenuClose}
      />
    </div>
  );
};

SensorComparisonFooter.propTypes = {
  className: T.string,
  sensors: T.arrayOf(T.shape({})).isRequired,
  selectedSensors: T.arrayOf(T.string).isRequired,
  onChange: T.func.isRequired,
  mobile: T.bool
};

SensorComparisonFooter.defaultProps = {
  className: '',
  mobile: false
};

export default SensorComparisonFooter;

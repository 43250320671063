const SET_SHOW_MACHINE_STATE = 'SETTINGS/SET_SHOW_MACHINE_STATE';
const SET_TIME_WINDOW = 'SETTINGS/SET_TIME_WINDOW';
const SET_SCOPED_TIME_WINDOW = 'SETTINGS/SET_SCOPED_TIME_WINDOW';
const SET_TIME_WINDOW_RANGE = 'SETTINGS/SET_TIME_WINDOW_RANGE';
const SET_IS_BATCH_RECORD_DETAILS = 'SETTINGS/SET_IS_BATCH_RECORD_DETAILS';

export {
  SET_SHOW_MACHINE_STATE,
  SET_TIME_WINDOW,
  SET_SCOPED_TIME_WINDOW,
  SET_TIME_WINDOW_RANGE,
  SET_IS_BATCH_RECORD_DETAILS
};

const SET_USER_INFORMATION = 'USER/SET_USER_INFORMATION';
const LOAD_USER = 'USER/LOAD_USER';
const UPDATE_USER = 'USER/UPDATE_USER';
const RESET_PASSWORD = 'USER/RESET_PASSWORD';
const GET_ORGANIZATION = 'USER/GET_ORGANIZATION';
const RESET_USER_UPDATE_LOADING_STATE = 'USER/RESET_USER_UPDATE_LOADING_STATE';

export {
  LOAD_USER,
  SET_USER_INFORMATION,
  GET_ORGANIZATION,
  UPDATE_USER,
  RESET_PASSWORD,
  RESET_USER_UPDATE_LOADING_STATE
};

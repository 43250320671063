/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { NotifyIcon } from 'web-components';

import CustomTooltip from './Tooltip';

const TooltipWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 1rem;
`;

const TooltipIcon = ({ label, title, placement, color }) => {
  const [open, setOpen] = useState(false);

  const handleClose = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const toggleOpen = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <CustomTooltip
        title={<ContentWrapper>{title}</ContentWrapper>}
        open={open}
        onClose={handleClose}
        placement={placement}
      >
        <TooltipWrapper onClick={toggleOpen}>
          <NotifyIcon iconName={open ? 'infoFilled' : 'info'} style={{ marginRight: '.5rem' }} color={color} />
          {label && label}
        </TooltipWrapper>
      </CustomTooltip>
    </ClickAwayListener>
  );
};

TooltipIcon.propTypes = {
  title: T.node.isRequired,
  label: T.string,
  placement: T.string,
  color: T.string
};

TooltipIcon.defaultProps = {
  placement: 'bottom',
  label: null,
  color: ''
};

export default TooltipIcon;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import T from 'prop-types';

import Alerts from './Alerts';
import { getAlerts } from '../../../redux/rootSelectors';
import AlertError from './AlertError';

const ConnectedAlerts = ({ setMenuOpen, ...props }) => {
  const { data, loadingState } = useSelector(getAlerts);

  return (
    <Alerts
      loadingState={loadingState}
      alerts={data}
      errorComponent={() => <AlertError setMenuOpen={setMenuOpen} />}
      setMenuOpen={setMenuOpen}
      {...props}
    />
  );
};

ConnectedAlerts.propTypes = {
  setMenuOpen: T.func.isRequired
};

export default ConnectedAlerts;

import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import { CUSTOM_SENSOR_GET, CUSTOM_SENSOR_RESET } from './constants';
import { enhanceWithResetActions, loadingStateReducer } from '../utils';

export default combineReducers({
  customSensorLoadingState: enhanceWithResetActions([CUSTOM_SENSOR_GET, CUSTOM_SENSOR_RESET])(
    loadingStateReducer([CUSTOM_SENSOR_GET])
  ),
  customSensor: enhanceWithResetActions([CUSTOM_SENSOR_RESET])(
    typeToReducer(
      {
        [CUSTOM_SENSOR_GET]: {
          SUCCESS: (data, { customSensor }) => customSensor
        }
      },
      { name: '' }
    )
  )
});

import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';
import { ContentWrapper } from '../../elements';
import withLoadingState from '../../helpers/withLoadingState';
import LastUpdated from '../../components/elements/LastUpdated';
import { ContainerTitle, PageHeader, Tab, PageHeaderTitle, PageHeaderTabs } from './elements';
import TooltipInfo from '../../components/TooltipInfo';

import { PaginationResponse } from '../../helpers/propTypes';

import StatusOverview from './StatusOverview';
import AlarmHistory from './AlarmHistory';
import AlertHistory from './AlertHistory';

const Dashboard = ({ machines, alarms, alerts }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const [widthTabs, setWidthTabs] = useState(0);

  const isMobile = useMediaQuery('(max-width:425px)');
  const isTablet = useMediaQuery('(max-width:768px)');

  const CONTENT_TABS = [
    {
      value: 0,
      title: 'dashboard.content_tabs.title_tab_1',
      subtitle: 'active_subtitle',
      filter: entry => entry.is_active
    },
    {
      value: 1,
      title: 'dashboard.content_tabs.title_tab_2',
      subtitle: 'pending_subtitle',
      filter: entry => !entry.is_active
    },
    {
      value: 2,
      title: 'dashboard.content_tabs.title_tab_3',
      subtitle: 'pending_subtitle',
      filter: entry => !entry.is_active
    }
  ];

  const childRefs = React.useMemo(() => CONTENT_TABS.map(() => React.createRef()), [CONTENT_TABS]);

  const getWidthOfTabs = tabsRef => {
    tabsRef.map(value => setWidthTabs(prevState => prevState + value.current.offsetWidth));
  };

  useEffect(() => {
    getWidthOfTabs(childRefs);
    // eslint-disable-next-line
  }, []);

  const variant = isMobile ? 'fullWidth' : 'standard';
  const marginTabValue = isTablet ? '3rem' : '5rem';

  return (
    <>
      <PageHeader alwaysVisible>
        <PageHeader.Title>
          <ContainerTitle>
            <PageHeaderTitle>{t('dashboard.title')}</PageHeaderTitle>
            <TooltipInfo titleTooltip="dashboard.title_tooltip" />
          </ContainerTitle>
          {Object.keys(machines).length > 0 && <LastUpdated type="dashboard" align="left" />}
        </PageHeader.Title>
      </PageHeader>

      <PageHeaderTabs
        ariaLabel="dashboardTabs"
        onChange={setSelected}
        selected={selected}
        variant={variant}
        style={{ background: '#F7F7FA' }}
        widthLineTabEnd={isMobile ? '100%' : `${widthTabs}px`}
        bottomLineTabEnd="-2px"
        leftLineTabEnd={isMobile ? '0' : marginTabValue}
        color="transparent"
      >
        {CONTENT_TABS.map((tab, i) => (
          <Tab key={tab.value} label={t(tab.title)} ref={childRefs[i]} />
        ))}
      </PageHeaderTabs>
      <ContentWrapper className="withPageHeaderAndLastUpdated" maxWidth={false}>
        {selected === 0 && <StatusOverview machines={machines} alerts={alerts} />}
        {selected === 1 && <AlertHistory alerts={alerts || {}} />}
        {selected === 2 && <AlarmHistory alarms={alarms || {}} />}
      </ContentWrapper>
    </>
  );
};

Dashboard.propTypes = {
  machines: T.shape({}),
  alarms: T.shape(PaginationResponse),
  alerts: T.shape(PaginationResponse)
};

Dashboard.defaultProps = {
  machines: null,
  alarms: null,
  alerts: null
};

export default withLoadingState(Dashboard);

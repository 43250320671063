import styled from 'styled-components';

export const DivStyled = styled.div`
  display: flex;
  '& > * + *': {
    margin-left: 2rem;
  }
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
`;

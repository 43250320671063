export const prepareSupportTicketPayload = ({ formValues, machines, organizationId }) => {
  const machine = machines.data.find(({ id, production_line: prodLine }) =>
    formValues.machine ? formValues.machine === id : formValues.productionLine === prodLine
  );

  return {
    organization_id: organizationId,
    subject: formValues.subject,
    description: formValues.description,
    production_line_related: formValues.productionRelated,
    ...(!formValues.productionRelated
      ? {
          production_line: machine.production_line,
          commission_number: machine.commission_number,
          machine_id: formValues.machine ? machine.id : '',
          machine_name: formValues.machine ? machine.name : '',
          machine_stop: formValues.machineStopped
        }
      : {})
  };
};

import styled from 'styled-components';

import { SECONDARY_TEXT_COLOR } from 'attrs/colors';

export const MobileCellWrapper = styled.div``;

export const CellLabel = styled.div(
  ({ isSmallDevice }) => `
  font-size: ${isSmallDevice ? '0.625' : '0.75'}rem;
  color: ${SECONDARY_TEXT_COLOR};
`
);

export const CellValue = styled.div(
  ({ isSmallDevice }) => `
  margin-top: 0.125rem;
  font-size: ${isSmallDevice ? '0.75rem' : '0.875rem'};
`
);

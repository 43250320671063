const LOAD_METRIC_HISTORY = 'METRICS/LOADING_METRICS_HISTORY';
const LOAD_METRICS_HISTORY_CHART_DATA = 'METRICS/LOAD_HISTORY_CHART_DATA';
const LOAD_METRIC_HISTORY_CHART_DATA = 'METRIC/LOAD_HISTORY_CHART_DATA';
const LOAD_METRICS_HISTORY_RESET_CHART_DATA = 'METRIC/RESET_CHART_DATA';

export {
  LOAD_METRIC_HISTORY,
  LOAD_METRICS_HISTORY_CHART_DATA,
  LOAD_METRIC_HISTORY_CHART_DATA,
  LOAD_METRICS_HISTORY_RESET_CHART_DATA
};

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { createReducer, loadingStateReducer } from '../utils';
import { CHANGE_ALERTS_HISTORY_ORDER, LOAD_ALERTS_HISTORY, LOAD_ALERTS_HISTORY_WITH_FILTER } from './constants';
import { ALERT_HISTORY_DEFAULT_LIST_SORT, LIST_ORDER } from '../../attrs/sorter_default_values';

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_ALERTS_HISTORY]),
  data: typeToReducer(
    {
      [LOAD_ALERTS_HISTORY]: {
        SUCCESS: (state, { payload }) => payload
      },
      [LOAD_ALERTS_HISTORY_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  alertSortOrder: createReducer(
    {
      [CHANGE_ALERTS_HISTORY_ORDER]: (alarmSortOrder, { sort, order }) => ({
        sort,
        order
      })
    },
    {
      sort: ALERT_HISTORY_DEFAULT_LIST_SORT,
      order: LIST_ORDER
    }
  )
});

import { getCurrentGMT, getDecimalSeparator, getUserLocale } from '../../helpers/utils';
import { DEFAULT_FILE_FORMAT_EXPORT } from '../../helpers/consts';

const BATCH_REPORT_EXPORT_SHOW_MODAL = 'BATCH_REPORT_EXPORT/SHOW_MODAL';
const BATCH_REPORT_EXPORT_SEND_REQUEST = 'BATCH_REPORT_EXPORT/SEND_EXPORT_SEND_REQUEST';
const BATCH_REPORT_EXPORT_RESET = 'BATCH_REPORT_EXPORT/RESET';

const BATCH_REPORT_EXPORT_SEND_EXPORT_REQUEST_MACHINES = 'BATCH_REPORT_EXPORT/SEND_EXPORT_REQUEST_MACHINE';

const initialReportExportValues = {
  window: '24h0m0s',
  action: DEFAULT_FILE_FORMAT_EXPORT,
  timeZone: getCurrentGMT(),
  decimal_separator: getDecimalSeparator(),
  locale: getUserLocale()
};

const BatchExportActionsType = {
  PDF: 'pdf',
  CSV: 'csv',
  timeZone: getCurrentGMT(),
  decimal_separator: getDecimalSeparator(),
  locale: getUserLocale()
};

export {
  BATCH_REPORT_EXPORT_SHOW_MODAL,
  BATCH_REPORT_EXPORT_SEND_REQUEST,
  BATCH_REPORT_EXPORT_SEND_EXPORT_REQUEST_MACHINES,
  BATCH_REPORT_EXPORT_RESET,
  initialReportExportValues,
  BatchExportActionsType
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateExportDialog from 'components/DateExportDialog';
import { getDateRange, PERIOD_TYPE_CUSTOM_RANG } from 'attrs/chartsTemplate';
import { convertChartTemplate2Bas64, generateImage } from 'helpers/chartUtils';
import ChartTemplateDetails from './ChartTemplateDetails';
import { exportGraphsTemplate, getGraphsTemplateById } from '../../../../../redux/graphTemplates/actions';
import { getCurrentTemplate } from '../../../../../redux/graphTemplates/selectors';
import { DeleteTemplate } from './DeleteTemplate';
import { getUserLocale } from '../../../../../helpers/utils';
import ChartTemplateDetailsHeader from './ChartTemplateDetailsHeader';

export function ContainerChartTemplateDetails() {
  const dispatch = useDispatch();

  const { id, templateId } = useParams();
  const { t } = useTranslation();
  const graphTemplate = useSelector(getCurrentTemplate);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [interval, setInterval] = useState('');
  const [customPeriodStart, setCustomPeriodStart] = useState();
  const [customPeriodEnd, setCustomPeriodEnd] = useState();
  const chartRef = useRef(null);

  const isMobile = useMediaQuery('(max-width:425px)');
  const valueOffset = isMobile ? '-10px, 0' : '0, 0';

  const isDataMachineExport = false;

  const footerRef = useRef(null);

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteTemplateDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleExportdata = () => {
    setOpenExportDialog(true);
  };

  const handleCloseExportDataDialog = () => {
    setOpenExportDialog(false);
  };

  useEffect(() => {
    dispatch(getGraphsTemplateById(templateId));
  }, [dispatch, getGraphsTemplateById, templateId]);

  const normalizeValuesForActions = async values => {
    const { action } = values;
    const updateValue = values;

    if (action === 'csv' || action === 'excel') {
      updateValue.graphs = null;
      return updateValue;
    }

    const chartInstance = chartRef?.current?.getEchartsInstance();
    let chartPng = '';
    let footerPng = '';
    if (chartInstance && footerRef) {
      chartPng = convertChartTemplate2Bas64(chartInstance, 1024, 580);
      footerPng = await generateImage(footerRef);
    }

    updateValue.graphs = [
      {
        name: graphTemplate?.name,
        image: chartPng
      },
      {
        name: `${graphTemplate?.name} footer`,
        image: footerPng
      }
    ];

    return updateValue;
  };

  const handleSetValuesDateExportPeriod = async (
    _newStartDateTimeText,
    _newFinalDateTimeText,
    fileFormatParam,
    samplePeriodParam,
    timezoneParam
  ) => {
    const [pastDate, currentDate] =
      interval !== PERIOD_TYPE_CUSTOM_RANG ? getDateRange(interval) : [customPeriodStart, customPeriodEnd];
    const values = {
      date_from: pastDate,
      date_to: currentDate,
      window: samplePeriodParam,
      timezone: timezoneParam,
      decimal_separator: ',',
      locale: getUserLocale(),
      action: fileFormatParam
    };

    const normalizedValue = await normalizeValuesForActions(values);

    dispatch(exportGraphsTemplate(templateId, normalizedValue));
  };

  return (
    <>
      <DeleteTemplate
        machineID={id}
        templateID={graphTemplate?.id}
        open={openDeleteDialog}
        handleCloseDialog={handleCloseDeleteTemplateDialog}
      />
      <Container maxWidth={false} data-selector="container-chart-template-details">
        <Box
          sx={{
            mx: {
              sm: '16px',
              md: '24px'
            },
            pt: '48px',
            '& nav': {
              overflow: {
                xs: 'hidden',
                sm: 'visible'
              }
            }
          }}
        >
          <Stack spacing={3}>
            <ChartTemplateDetailsHeader
              templateId={templateId}
              valueOffset={valueOffset}
              handleExportdata={handleExportdata}
              handleDelete={handleDelete}
            />
            <ChartTemplateDetails
              graphTemplate={graphTemplate}
              chartRef={chartRef}
              footerRef={footerRef}
              interval={interval}
              setInterval={setInterval}
              customPeriodStart={customPeriodStart}
              setCustomPeriodStart={setCustomPeriodStart}
              customPeriodEnd={customPeriodEnd}
              setCustomPeriodEnd={setCustomPeriodEnd}
            />
          </Stack>
        </Box>
      </Container>
      <DateExportDialog
        open={openExportDialog}
        handleClose={handleCloseExportDataDialog}
        title={t('dialog.export_template.title')}
        subTitle={t('dialog.export_template.subtitle')}
        startTimeCaption={t('dialog.data_exports.time_from')}
        endTimeCaption={t('dialog.data_exports.time_to')}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('dialog.data_exports.export')}
        setValues={handleSetValuesDateExportPeriod}
        showDatePicker={false}
        showTimePicker={false}
        isDataMachineExport={isDataMachineExport}
        maxWidth="464px"
      />
    </>
  );
}

import styled, { css } from 'styled-components';

import { GRAPH_HEIGHT, GRAPH_MOBILE_HEIGHT } from 'attrs/layout';
import EmptyState from 'components/EmptyState';

export const GraphWrapper = styled.div`
  height: ${GRAPH_HEIGHT}px;
  margin-top: 2rem;
  ${props =>
    props.mobile &&
    css`
      height: ${GRAPH_MOBILE_HEIGHT}px;
    `}

  & .recharts-tooltip-wrapper {
    z-index: 1;
  }
`;

export const BrushWrapper = styled.div`
  padding: 3.5rem 0 5rem;
  padding-bottom: ${({ mobile }) => mobile && '3rem'};
`;

export const StyledEmptyState = styled(EmptyState)`
  margin-bottom: 8rem;
`;

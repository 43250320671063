import { all } from 'redux-saga/effects';

import * as userSagas from './user/sagas';
import * as authSagas from './auth/sagas';
import * as machinesSagas from './machines/sagas';
import * as alertsSagas from './alerts/sagas';
import * as usersSagas from './users/sagas';
import * as streamSagas from './stream/sagas';
import * as supportSagas from './support/sagas';
import * as batchSagas from './batch/sagas';
import * as batchNotesSagas from './batch_notes/sagas';
import * as dataExportsSagas from './data_exports/sagas';
import * as batchExportsSagas from './batch_export/sagas';
import * as alarmsHistorySagas from './alarms_history/sagas';
import * as customSensorSagas from './custom_sensor/sagas';
import * as alertHistorySagas from './alert_history/sagas';
import * as metricsHistorySagas from './metrics/sagas';
import * as metricsMachinesSagas from './metrics_machines/sagas';
import * as graphTemplatesSagas from './graphTemplates/sagas';
import { uiSagas } from './ui/sagas';
import * as filtersSagas from './filters/sagas';

const allSagas = [
  ...Object.values(userSagas),
  ...Object.values(authSagas),
  ...Object.values(machinesSagas),
  ...Object.values(alertsSagas),
  ...Object.values(usersSagas),
  ...Object.values(streamSagas),
  ...Object.values(supportSagas),
  ...Object.values(batchSagas),
  ...Object.values(batchNotesSagas),
  ...Object.values(dataExportsSagas),
  ...Object.values(batchExportsSagas),
  ...Object.values(alarmsHistorySagas),
  ...Object.values(customSensorSagas),
  ...Object.values(alertHistorySagas),
  ...Object.values(metricsHistorySagas),
  ...Object.values(metricsMachinesSagas),
  ...Object.values(graphTemplatesSagas),
  ...Object.values(filtersSagas),
  ...uiSagas
];

export default function* rootSaga() {
  yield all(allSagas.map(saga => saga()));
}

const LOAD_BATCHES = 'BATCH/LOAD_ALL';
const LOAD_BATCH = 'BATCH/LOAD';
const LOAD_BATCHES_PAGE = 'BATCH/LOAD_ALL_PAGE';
const CHANGE_BATCHES_ORDER = 'BATCH/CHANGE_ORDER';
const CHANGE_BATCHES_FILTER = 'BATCH/CHANGE_FILTER';
const BATCH_MACHINE_RANGE = 'BATCH/MACHINE_RANGE';
const LOAD_BATCHES_WITH_FILTER = 'BATCH/LOAD_ALL_FILTERED';

export {
  LOAD_BATCHES,
  LOAD_BATCH,
  LOAD_BATCHES_PAGE,
  CHANGE_BATCHES_ORDER,
  CHANGE_BATCHES_FILTER,
  BATCH_MACHINE_RANGE,
  LOAD_BATCHES_WITH_FILTER
};

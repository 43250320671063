import moment from 'moment';
import { orderBy } from 'lodash';

import parseduration from 'parse-duration';
import {
  GRAPH_COMPARE_LINE_1,
  GRAPH_COMPARE_LINE_2,
  GRAPH_COMPARE_LINE_3,
  GRAPH_COMPARE_LINE_4,
  GRAPH_COMPARE_LINE_5,
  GRAPH_COMPARE_LINE_6
} from '../../../attrs/colors';
import { getEquallyDistributedTicks, getMachineStatusByTime, getMachineWarningStatusByTime } from '../graphUtils';
import { getRoundedValue } from '../../../helpers/utils';

const COLORS = [
  GRAPH_COMPARE_LINE_1,
  GRAPH_COMPARE_LINE_2,
  GRAPH_COMPARE_LINE_3,
  GRAPH_COMPARE_LINE_4,
  GRAPH_COMPARE_LINE_5,
  GRAPH_COMPARE_LINE_6
];

const getAllTimeValues = sensors =>
  orderBy(
    sensors.map(sensor => sensor.values.map(v => v.time)),
    'asc'
  )
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);

const getReducedSensorData = (sensors, time) => {
  const sensorValues = sensors.map(sensor => ({
    [sensor.type]: sensor.values.find(v => v.time === time)
      ? getRoundedValue(sensor.values.find(v => v.time === time)).value
      : null
  }));

  return sensorValues.reduce((a, b) => ({ ...a, ...b }), {});
};

/**
 * Verify if one value of a data sensor are not greater than the period*2, if the values in the middle of the gap are
 * null, insert the before value inside the value. This will force the graph to show the line when sensors have
 * different periods
 * @param orderedChartData
 * @param sensors
 * @returns {Array<Object<time: number, activeStatus: number | null, warning: null | number>>}
 */
const removePeriodGaps = (orderedChartData, sensors) => {
  const ordered = orderedChartData;
  ordered.forEach((v, i) => {
    Object.keys(v).forEach(p => {
      if (p !== 'activeStatus' && p !== 'warning') {
        const sensor = sensors.filter(s => s.type === p)[0] || null;
        if (sensor) {
          // from here to before, verify the latest time this sensor registered a value
          // if the value is greater than the period*2 of the sensor, get the value and add to the null
          // point of the array
          if (ordered[i][sensor.type] || typeof ordered[i][sensor.type] === 'number') {
            for (let beforeIdx = i - 1; beforeIdx > 0; beforeIdx -= 1) {
              if (ordered[beforeIdx]) {
                const ms = parseduration(sensor.period, 'ms');
                // Stop searching values if the period is more the two times
                if (ordered[i].time - ordered[beforeIdx].time > ms * 2) {
                  break;
                }
                if (ordered[beforeIdx][sensor.type] === null && ordered[i].time - ordered[beforeIdx].time <= ms * 2) {
                  let latestValue = null;
                  // Get the last registered value from here
                  for (let beforeValueIdx = beforeIdx + 1; beforeValueIdx > 0; beforeValueIdx -= 1) {
                    if (
                      ordered[beforeValueIdx][sensor.type] ||
                      typeof ordered[beforeValueIdx][sensor.type] === 'number'
                    ) {
                      latestValue = ordered[beforeValueIdx][sensor.type];
                      break;
                    }
                  }

                  // Put the latest value or the current value when do not exist any value registered before
                  ordered[beforeIdx][sensor.type] = latestValue ?? ordered[i][sensor.type];
                  break;
                }
              }
            }
          }
        }
      }
    });
  });
  return ordered;
};

/**
 * @param {Array<Object<period: string, is_custom: bool, custom_sensor_id: number>>} sensors
 *        array or one single sensor, expect: 20s, 30s....
 * @param machineStatusData
 * @returns {unknown[]}
 */
const createChartData = (sensors, machineStatusData) => {
  // console.log("sensors: %o", sensors);
  const chartData = getAllTimeValues(sensors).map(time => ({
    time: moment(time).valueOf(),
    activeStatus: getMachineStatusByTime(moment(time).valueOf(), machineStatusData),
    warning: Number(getMachineWarningStatusByTime(moment(time).valueOf(), machineStatusData)),
    ...getReducedSensorData(sensors, time)
  }));

  /**
   * @type {Array<Object<time: number, activeStatus: number | null, warning: null | number>>}
   */
  let ordered = orderBy(chartData, item => item.time, 'asc');
  ordered = removePeriodGaps(ordered, sensors);

  return ordered;
};

/**
 * Return a normalized graph chart data, this will not connect empty data
 * into charts, for connect put connectNull flag into "Line" component for recharts component
 * This will filter all data, if any of data are 2 periods ahead of the window, will add a one dataframe
 * with a "null" value, who will force the recharts to draw a "null" space between two "nodata" window
 * @param {Object<period: string>} sensors array or one single sensor, expect: 20s, 30s....
 * @param {Object<{from: string, to: string}>} timeWindow
 * @param {Object<{time}>[]} initChartData
 * @returns {Object<value, name, time>[]}
 */
const normalizeGraphChartData = (sensors, timeWindow, initChartData) => {
  let averagePeriod = -1;
  for (let i = 0; i < initChartData.length; i += 1) {
    if (initChartData[i + 1]) {
      averagePeriod += initChartData[i + 1].time - initChartData[i].time;
    }
  }

  if (averagePeriod !== -1) {
    averagePeriod = Math.floor(averagePeriod / initChartData.length);

    const size = Math.floor(Math.log(Math.abs(averagePeriod)) / Math.LN10);
    const magnitude = 10 ** size;
    averagePeriod = Math.ceil(averagePeriod / magnitude) * magnitude;
  } else {
    averagePeriod = 10000;
  }

  const orderedData = orderBy(initChartData, item => item.time, 'asc');

  const finalDate = [];
  for (let i = 0; i < orderedData.length; i += 1) {
    finalDate.push(orderedData[i]);
    if (orderedData[i + 1]) {
      const next = orderedData[i + 1];
      const actual = orderedData[i];
      if (next.time - actual.time > averagePeriod * 2) {
        finalDate.push({
          value: null,
          name: 'brush'
        });
      }
    }
  }

  return finalDate;
};

const generateBrushChartData = (sensors, timeWindow) => {
  const xAxisTicks = getEquallyDistributedTicks(timeWindow).sort();
  const xAxisDomain = [xAxisTicks[0], xAxisTicks[xAxisTicks.length - 1]];

  const initChartData = getAllTimeValues(sensors).map(time => ({
    time: moment(time).valueOf(),
    ...getReducedSensorData(sensors, time)
  }));

  let chartData = normalizeGraphChartData(sensors, timeWindow, initChartData);
  chartData = removePeriodGaps(chartData, sensors);

  return {
    chartData,
    xAxisTicks,
    xAxisDomain
  };
};

/**
 * Get the labels names and values and return a normalized graph chart data
 * Golang can't create dynamic returned types, so we need to "normalize" the data
 * @param chartData
 * @returns {Array.<{
 *  time: number,
 *  activeStatus: string,
 * }>}
 */
const removeLabelsReturnedMetricsChartData = chartData => {
  let normalizedChartData = [];
  if (Array.isArray(chartData)) {
    normalizedChartData = (chartData || []).map(v => {
      const l = (v.labels || []).map(label => ({ [label.type]: label.value }));
      const ret = {
        time: v.time,
        activeStatus: v.activeStatus
      };
      if (l.length > 0) {
        l.forEach(obj => {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(obj)) {
            ret[key] = value;
          }
        });
      }
      return {
        ...ret,
        ...v.labels
      };
    });
  }
  return normalizedChartData;
};

export {
  COLORS,
  createChartData,
  generateBrushChartData,
  normalizeGraphChartData,
  removeLabelsReturnedMetricsChartData
};

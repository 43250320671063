import styled from 'styled-components';
import { PageHeader as Header } from 'web-components';

export const DetailsWrapper = styled.div(
  ({ theme }) => `
  padding: 0 2.25rem;

  ${theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }

  ${theme.breakpoints.down('xs')} {
    padding: 0;
  }
`
);
export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const ContainerPageTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  @media (max-width: 425px) {
    padding-right: 1rem;
    flex-wrap: wrap;
  }
`;
export const PageHeader = styled(Header)`
  &.MuiPaper-root {
    background-color: #f7f7fa;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 425px) {
    height: 150px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  @media (max-width: 425px) {
    flex-direction: column;
    width: 100%;
  }
`;

import React from 'react';
import T from 'prop-types';
import { Grid, useMediaQuery, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import SensorCard from '../../../components/SensorCard/SensorCard';
import EmptyState from '../../../components/EmptyState';
import ComparisonDialog from './ComparisonDialog';
import { ContainerSensorTitle, SensorsTitle } from './elements';

const Sensor = ({ machine, sensors, hasSensors }) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width:425px)');

  const sensorsWithTranslatedNames = (sensors || []).map(item => ({
    ...item
  }));
  const sensorsSortedByStaticAndName = orderBy(sensorsWithTranslatedNames, item => [item.is_static, item.name], [
    'desc',
    'asc'
  ]);

  return (
    <>
      <ContainerSensorTitle>
        <SensorsTitle>{t('dashboard.sensors.current_status')}</SensorsTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={6} />
          <Grid item md={12} style={{ textAlign: 'right' }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: { xs: '12px', md: '14px' },
                textAlign: { xs: 'left', md: 'right' },
                marginTop: { md: '-15px' },
                marginBottom: { xs: '20px' }
              }}
            >
              {t('machines.detail.tabs.sensors.subtitle')}
            </Typography>
            {hasSensors && <ComparisonDialog isMobile={isMobile} />}
          </Grid>
        </Grid>
      </ContainerSensorTitle>

      <Grid container spacing={3}>
        {hasSensors ? (
          sensorsSortedByStaticAndName.map(sensor => (
            <Grid
              key={sensor.is_custom || sensor.is_static ? sensor.custom_type : sensor.type}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <SensorCard machineId={machine.id} sensor={sensor} />
            </Grid>
          ))
        ) : (
          <EmptyState type="sensors" />
        )}
      </Grid>
    </>
  );
};

Sensor.propTypes = {
  machine: T.shape({
    id: T.string.isRequired
  }).isRequired,
  sensors: T.arrayOf(T.shape({})).isRequired,
  hasSensors: T.bool.isRequired
};

export default Sensor;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { isValidTimeRange } from 'components/Graphs/graphUtils';
import { getSensorSettingByIds } from 'redux/ui/settings/selectors';
import { setScopedTimeWindow } from 'redux/ui/settings/actions';
import { getSelectedMachineScopeSensorComparisonSensorData } from 'redux/machines/selectors';
import { removeLabelsReturnedMetricsChartData } from '../utils';

const useMultipleSensorsGraphData = (machineId, sensorType) => {
  const dispatch = useDispatch();
  const { loadingState: scopedLoadingState, data: metricsHistoryChartData } = useSelector(
    compose(getSelectedMachineScopeSensorComparisonSensorData)
  );

  const sensorSettings = useSelector(state => getSensorSettingByIds(state, machineId, sensorType));
  const [zoomState, setZoomState] = useState({ refAreaLeft: null, refAreaRight: null });

  // User setted visualisation settings
  const shouldShowMachineState = (sensorSettings || {}).showMachineState || false;

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight } = zoomState;

    if (refAreaLeft === refAreaRight || refAreaRight === null) {
      setZoomState({
        refAreaLeft: null,
        refAreaRight: null
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    if (isValidTimeRange(moment(refAreaLeft).valueOf(), moment(refAreaRight).valueOf())) {
      dispatch(
        setScopedTimeWindow(
          moment(refAreaLeft).toISOString(),
          moment(refAreaRight).toISOString(),
          machineId,
          metricsHistoryChartData.sensors.map(sensor => sensor.type)
        )
      );
    }
    setZoomState({ refAreaLeft: null, refAreaRight: null });
  };

  const handleMouseDown = e => {
    window.event.preventDefault();
    if (e && e.activeLabel) {
      setZoomState({ ...zoomState, refAreaLeft: e.activeLabel });
    }
  };

  const chartData = removeLabelsReturnedMetricsChartData(metricsHistoryChartData.chartData);

  return {
    scopedLoadingState,
    sensors: metricsHistoryChartData.sensors,
    zoomState,
    setZoomState,
    xAxisTicks: metricsHistoryChartData.xAxisTicks,
    xAxisDomain: metricsHistoryChartData.xAxisDomain,
    machineChartData: metricsHistoryChartData.machineChartData,
    chartData,
    shouldShowMachineState,
    handleZoom,
    handleMouseDown
  };
};

export default useMultipleSensorsGraphData;

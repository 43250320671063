import styled from 'styled-components';
import { COLOR_ERROR } from '../../attrs/colors';

const FormAlertWrapper = styled.div`
  margin: 2rem 0;
  color: ${COLOR_ERROR};
  display: flex;
  align-items: center;
`;

export default FormAlertWrapper;

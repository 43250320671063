import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

import { ConcludedWrapper, StyledNotifyIcon } from './BatchStatusIcon.styled';

const STATUS_UNKNOWN = 'UNKNOWN';
const STATUS_COMPLETED = 'COMPLETED';
const STATUS_CONCLUDED = 'CONCLUDED';
const STATUS_CANCELLED = 'CANCELLED';
const STATUS_IN_OPERATION = 'IN_OPERATION';

const BatchStatusIcon = ({ status }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (status === STATUS_CONCLUDED) {
    return <ConcludedWrapper theme={theme}>{t('batch_records.concluded')}</ConcludedWrapper>;
  }

  let icon = null;
  switch (status) {
    case STATUS_COMPLETED: {
      icon = `successSemiFilled`;
      break;
    }
    case STATUS_CONCLUDED: {
      icon = `concludedSemiFilled`;
      break;
    }
    case STATUS_CANCELLED: {
      icon = `errorSemiFilled`;
      break;
    }
    case STATUS_IN_OPERATION: {
      icon = `progressSemiFilled`;
      break;
    }
    default: {
      icon = `unknownSemiFilled`;
      break;
    }
  }

  return <StyledNotifyIcon fontSize="large" iconName={icon} viewBox="0 0 32 32" theme={theme} />;
};

BatchStatusIcon.propTypes = {
  status: T.oneOf([STATUS_UNKNOWN, STATUS_COMPLETED, STATUS_CONCLUDED, STATUS_CANCELLED, STATUS_IN_OPERATION])
    .isRequired
};

export default BatchStatusIcon;

import { put, takeLatest, select, call } from 'redux-saga/effects';

import { createSupportTicket } from 'api/support';
import { actionStatus, getError, statusAction } from 'redux/utils';
import { getActiveOrganizationId } from 'redux/user/selectors';
import { getMachines } from 'redux/rootSelectors';

import { showSalesforceModal } from 'redux/ui/actions';
import { addNotification } from 'redux/ui/notifications/actions';
import { generateId } from 'helpers/utils';
import { NOTIFICATION_TYPE_INTERACTION } from 'attrs/notifications';

import { SUBMIT_SALESFORCE_TICKET } from './constants';
import { prepareSupportTicketPayload } from './helpers';

function* handleSubmitSalesforceTicket({ value }) {
  yield put(statusAction(SUBMIT_SALESFORCE_TICKET, actionStatus.START));

  try {
    const organizationId = yield select(getActiveOrganizationId);
    const machines = yield select(getMachines);
    const payload = prepareSupportTicketPayload({
      formValues: value,
      machines,
      organizationId
    });

    yield call(createSupportTicket, payload);

    yield put(statusAction(SUBMIT_SALESFORCE_TICKET, actionStatus.SUCCESS));
    yield put(showSalesforceModal(false));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'support.salesforce.submit_success',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (error) {
    yield put(
      statusAction(SUBMIT_SALESFORCE_TICKET, actionStatus.ERROR, {
        message: getError(error)
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: 'support.salesforce.submit_failure',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchSupportActions() {
  yield takeLatest(SUBMIT_SALESFORCE_TICKET, handleSubmitSalesforceTicket);
}

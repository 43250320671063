import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import ExternalAuth from './containers/Auth/ExternalLogin';
import SignIn from './containers/Auth/SignIn';
import RequestPasswordReset from './containers/Auth/ForgotPassword/RequestPasswordReset';
import SetPassword from './containers/Auth/ForgotPassword/SetPassword';
import RegisterAccount from './containers/Auth/RegisterAccount';

const externalAuthPath = '/login/:tokenHeader/:tokenPayload/:tokenSignature/'.concat(
  ':refreshTokenHeader/:refreshTokenPayload/:refreshTokenSignature'
);
const LoggedOut = () => {
  const { location: prevPath } = useHistory();

  return (
    <Switch>
      <Route component={ExternalAuth} path={externalAuthPath} />
      <Route component={SignIn} path="/login" />
      <Route component={RequestPasswordReset} path="/forgot-password" />
      <Route component={SetPassword} path="/set-password/:token" />
      <Route component={RegisterAccount} path="/register/:token" />
      <Redirect to={{ pathname: '/login', state: { prevPath } }} />
    </Switch>
  );
};

export default LoggedOut;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormElements } from 'web-components';

import { Wrapper, StyledInfoText, ACTIVE_COLOR } from './MachineStoppedRadioGroup.styled';

const MachineStoppedRadioGroup = ({ machineStopped, setFieldValue }) => {
  const { t } = useTranslation();

  const handleNewChange = e => setFieldValue('machineStopped', e.target.value === 'true');

  return (
    <Wrapper>
      <StyledInfoText>{t('support.salesforce.machine_stopped')}</StyledInfoText>

      <label htmlFor="support.salesforce.machineStopped.yes">
        <FormElements.RadioButton
          margin="normal"
          name="machineStopped"
          activeColor={ACTIVE_COLOR}
          onChange={handleNewChange}
          value="true"
          checked={machineStopped}
        />
        {t('support.salesforce.machine_stopped_yes')}
      </label>
      <label htmlFor="support.salesforce.machineStopped.no">
        <FormElements.RadioButton
          margin="normal"
          name="machineStopped"
          activeColor={ACTIVE_COLOR}
          onChange={handleNewChange}
          value="false"
          checked={!machineStopped}
        />
        {t('support.salesforce.machine_stopped_no')}
      </label>
    </Wrapper>
  );
};

MachineStoppedRadioGroup.propTypes = {
  machineStopped: T.bool.isRequired,
  setFieldValue: T.func.isRequired
};

export default MachineStoppedRadioGroup;

import axios from './axios';

const loadMachines = organizationId =>
  axios({
    method: 'get',
    url: '/v1/machines',
    params: {
      organization_id: organizationId
    }
  });

const loadMachinesResumed = organizationId =>
  axios({
    method: 'get',
    url: '/v1/machines/resume',
    params: {
      organization_id: organizationId
    }
  });

const updateMachine = (id, values) =>
  axios({
    method: 'put',
    url: `/v1/machines/${id}`,
    data: values
  });

const loadMachine = id =>
  axios({
    method: 'get',
    url: `/v1/machines/${id}`
  });

const loadMachineErrors = (id, from, to) =>
  axios({
    method: 'get',
    url: '/v1/errors',
    params: {
      machine_id: id,
      from,
      to
    }
  });

const loadUnresolvedMachineErrors = id =>
  axios({
    method: 'get',
    url: '/v1/errors/unresolved',
    params: {
      machine_id: id
    }
  });

export {
  loadMachines,
  loadMachinesResumed,
  updateMachine,
  loadMachine,
  loadMachineErrors,
  loadUnresolvedMachineErrors
};

import styled from 'styled-components';
import { Dialog } from 'web-components';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    box-shadow: 0 8px 32px rgba(17, 17, 26, 0.03);
    border-radius: 10px;

    &.MuiDialog-paperFullWidth {
      @media (min-width: 1201px) {
        width: calc(60% - 64px);
      }
      @media (max-width: 1200px) {
        width: calc(80% - 64px);
      }
      @media (max-width: 800px) {
        width: calc(100% - 64px);
      }
    }
  }
`;

export const StyledDialogHeader = styled(Dialog.Header)`
  &.MuiDialogTitle-root {
    padding: 2rem 1rem 0.5rem 2rem;

    @media (max-width: 425px) {
      padding: 1rem 1rem 0.5rem 2rem;
    }
    .MuiTypography-h1 {
      @media (max-width: 425px) {
        font-size: 1.25rem;
      }
    }
  }
`;

export const StyledDialogBody = styled(Dialog.Body)`
  height: 14vh;
`;

export const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    padding: 2rem;
    justify-content: space-between;

    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 1rem 2.25rem;
    }
    & button:first-child {
      margin-right: 1rem;
      @media (max-width: 425px) {
        margin-right: 0;
      }
    }
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

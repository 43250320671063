import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE } from './attrs/languages';

import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/pt-br';
import 'moment/locale/es';

// const debug = window.configuration.nodeEnv === 'development';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  debug: false
});

i18n.on('languageChanged', lng => {
  const momentLoc = lng.split('-')[0] || lng.split('_')[0];
  moment.locale(momentLoc);
});

i18n.setDefaultNamespace('generics');

export default i18n;

import { createSelector } from 'reselect';
import { getAuth } from '../rootSelectors';

const getIsAuthenticated = createSelector([getAuth], auth => auth.isAuthenticated);

const getCheckAuthCode = createSelector([getAuth], auth => auth.checkAuthCode);

const getRequestPasswordReset = createSelector([getAuth], auth => auth.requestPasswordReset);

const getResetPassword = createSelector([getAuth], auth => auth.resetPassword);

const getRegister = createSelector([getAuth], auth => auth.register);

const getLogin = createSelector([getAuth], auth => auth.login);

export { getIsAuthenticated, getLogin, getCheckAuthCode, getResetPassword, getRequestPasswordReset, getRegister };

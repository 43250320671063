import axios from './axios';

const DATA_EXPORTS_URL = '/data-exports';
const DATA_EXPORTS_MACHINE_URL = '/machines';

const getMachineDataExports = (machineId, req) =>
  axios({
    method: 'put',
    url: `/v1${DATA_EXPORTS_URL}${DATA_EXPORTS_MACHINE_URL}/${machineId}`,
    data: { ...req }
  });

export { getMachineDataExports };

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { MachineIcon } from 'web-components';
import { getSensorProps } from 'helpers/utils';
import { SENSOR_STATUS_OK } from 'attrs/notifications';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { getLineStyle } from 'attrs/chartsTemplate';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function ChartTemplateFooterContent({ resumedSensor, isLineVisible, toggleLineVisibility }) {
  const { t } = useTranslation();
  const millSensor = getMillSensor(resumedSensor?.type);
  const name = millSensor?.name || resumedSensor?.name;
  const mapValue = millSensor?.mapValue || resumedSensor?.customIconType || 'n/a';

  const machineIconProps = {
    iconName: getSensorProps(mapValue)?.icon,
    // accepted status type: SENSOR_STATUS_NODATA, SENSOR_STATUS_OK, SENSOR_STATUS_WARNING, SENSOR_STATUS_CRITICAL
    status: SENSOR_STATUS_OK,
    style: {
      fontSize: '2rem'
    },
    viewBox: '0 0 32 32'
  };
  return (
    <Paper
      sx={{
        minWidth: { xs: '100%', sm: '100%', md: 300 },
        minHeight: 120,
        maxHeight: 120,
        border: `${resumedSensor?.lineThickness || 1}px ${getLineStyle(resumedSensor?.lineStyle)}`,
        borderColor: resumedSensor?.lineColor,
        borderRadius: 2,
        mr: 2,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        gap: 0.5
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ width: '100%' }}>
        <MachineIcon {...machineIconProps} />
        <Typography noWrap sx={{ fontSize: 14, fontWeight: 'bold', flexGrow: 1 }}>
          {t(`${name}`)}
        </Typography>
        <IconButton aria-label="delete" onClick={toggleLineVisibility}>
          {isLineVisible ? (
            <VisibilityOffOutlinedIcon color="primary" fontSize="inherit" />
          ) : (
            <VisibilityOutlinedIcon color="primary" fontSize="inherit" />
          )}
        </IconButton>
      </Stack>
      <Typography noWrap sx={{ fontSize: 10, width: '100%' }}>
        {resumedSensor?.productionLine}
      </Typography>
      <Typography noWrap sx={{ fontSize: 10, width: '100%' }}>
        {resumedSensor?.machineName}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ pt: 0.5, width: '100%' }}
      >
        <Typography noWrap sx={{ fontSize: 10, color: 'text.secondary', width: '100%' }}>{`${t(
          'chart_template_details.avg'
        )} ${resumedSensor?.avg} ${resumedSensor?.unit}`}</Typography>
        <Typography noWrap sx={{ fontSize: 10, color: 'text.secondary', width: '100%' }}>{`${t(
          'chart_template_details.min'
        )} ${resumedSensor?.min} ${resumedSensor?.unit}`}</Typography>
        <Typography noWrap sx={{ fontSize: 10, color: 'text.secondary', width: '100%' }}>{`${t(
          'chart_template_details.max'
        )} ${resumedSensor?.max} ${resumedSensor?.unit}`}</Typography>
      </Stack>
    </Paper>
  );
}

ChartTemplateFooterContent.propTypes = {
  resumedSensor: PropTypes.shape().isRequired,
  isLineVisible: PropTypes.bool.isRequired,
  toggleLineVisibility: PropTypes.func.isRequired
};

export default ChartTemplateFooterContent;

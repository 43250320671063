/* eslint-disable no-bitwise,no-mixed-operators,function-paren-newline,no-param-reassign */

import { takeEvery } from 'redux-saga/effects';
import typeToReducer from 'type-to-reducer';
import jwtDecode from 'jwt-decode';

import * as loadingStatus from '../attrs/status';

const getStatusActionType = (baseAction, actionStatus) => [baseAction, actionStatus].join('_');

const actionStatus = {
  START: loadingStatus.START,
  SUCCESS: loadingStatus.SUCCESS,
  ERROR: loadingStatus.ERROR
};

const mapErrorStatusToErrorCode = errorStatus => {
  if (errorStatus === 404) {
    return 'not_found';
  }

  return errorStatus;
};

const getError = err => {
  let error;

  if (err && err.response) {
    const errorCode = err.response.data ? err.response.data.code : null;
    error = errorCode || mapErrorStatusToErrorCode(err.response.status);
  }

  return error || 'generic';
};

/**
 * Extracts a message from the given context object and converts it to lowercase.
 * If the message is not available, returns a default value ('generic').
 *
 * @param {Object} context - The context object containing response data.
 * @param {Object} [context.response] - The response object from the context.
 * @param {Object} [context.response.data] - The data object within the response.
 * @param {string} [context.response.data.message] - The message to retrieve and format.
 * @returns {string} The message in lowercase if available, or the default string 'generic'.
 */
const getMessage = context => context?.response?.data?.message?.toLowerCase() || 'generic';

const statusAction = (baseAction, aStatus, params) => ({
  type: getStatusActionType(baseAction, aStatus),
  ...params
});

const defaultLoadingState = {
  status: loadingStatus.INITIAL,
  message: null
};

const loadingStateReducer = baseTypeParam => {
  const baseTypes = baseTypeParam instanceof Array ? baseTypeParam : [baseTypeParam];

  const constructLoadingState =
    status =>
    (state, { message }) => ({
      status,
      message: message || null
    });

  const extendWithLoadingState = (acc, baseType) => ({
    ...acc,
    [baseType]: {
      START: constructLoadingState(loadingStatus.IN_PROGRESS),
      SUCCESS: constructLoadingState(loadingStatus.SUCCESS),
      ERROR: constructLoadingState(loadingStatus.ERROR)
    }
  });

  const object = baseTypes.reduce(extendWithLoadingState, {});

  return typeToReducer(object, defaultLoadingState);
};

const enhanceWithResetActions = (resetActions, propagateResetActionToReducer) => {
  const isResetAction = action => resetActions.includes(action.type);

  return reducer => (state, action) => {
    let currentState = state;

    if (isResetAction(action)) {
      currentState = reducer(undefined, action);

      if (!propagateResetActionToReducer) {
        return currentState;
      }
    }

    return reducer(currentState, action);
  };
};

const createSaga = (actionType, handler) =>
  function* actionWatcher() {
    yield takeEvery(actionType, handler);
  };

const sagasFromHandlerMap = handlerMap =>
  Object.entries(handlerMap).map(([actionType, handler]) => createSaga(actionType, handler));

const createReducer =
  (fnMap, initialState, resetActions = []) =>
  (state = initialState, payload) => {
    if (resetActions.includes(payload.type)) {
      return initialState;
    }

    const handler = fnMap[payload.type];

    return handler ? handler(state, payload) : state;
  };

const getUserInformationFromToken = token => {
  const decoded = jwtDecode(token);

  const activeOrganizationId = Object.keys(decoded.organizations)[0];
  const activeUserRole = decoded.organizations[activeOrganizationId];
  const features = decoded.features || [];

  return {
    'e-mail': decoded['e-mail'],
    first_name: decoded.first_name,
    last_name: decoded.last_name,
    id: decoded.id,
    is_active: decoded.is_active,
    language: decoded.language,
    phone: decoded.phone,
    organizations: decoded.organizations,
    organization: {
      id: activeOrganizationId,
      userRole: activeUserRole
    },
    features: features.map(organizationFeature => ({
      feature_id: organizationFeature.feature_id,
      enabled: organizationFeature.enabled,
      constant: organizationFeature.feature.constant
    }))
  };
};

/**
 Convert empty string to null
 @param {Object} object - object to convert
 @param {Array} ignoreList - array of paths to ignore, for object {a: {b: {c: ''}}}, path is 'a.b.c' for array
  {a: [{b: '1'}, {b: ''}]}, path is 'a.n.b' (the 'n' is for array index)
 */
const removeEmptyValuesRecursively = (object, ignoreList = []) => {
  function convertToNull(obj, ignore = [], currentPath = '') {
    if (obj instanceof Array) {
      currentPath = [currentPath, 'n'].filter(Boolean).join('.');
      for (let i = 0; i < obj.length; i += 1) {
        obj[i] = convertToNull(obj[i], ignore, currentPath);
        if (
          obj[i] === null ||
          obj[i].length === 0 ||
          Object.keys(obj[i]).length === 0 ||
          JSON.stringify(obj[i]) === JSON.stringify({})
        ) {
          delete obj[i];
        }
      }
      obj = obj.filter(Boolean);
      if (obj.length === 0 || Object.keys(obj).length === 0 || JSON.stringify(obj) === JSON.stringify({})) {
        obj = null;
      }
    } else if (obj instanceof Object) {
      Object.keys(obj).forEach(key => {
        if (ignore.indexOf([currentPath, key].filter(Boolean).join('.')) === -1) {
          obj[key] = convertToNull(obj[key], ignore, key);
          if (obj[key] === null) {
            delete obj[key];
          }
        }
      });
      if (Object.keys(obj).length === 0) {
        obj = null;
      }
    } else if (obj === '') {
      obj = null;
    }
    return obj;
  }

  return convertToNull(object, ignoreList);
};

const removeEmptyValuesRecursivelyPaginated = obj =>
  removeEmptyValuesRecursively(obj, [`limit`, `page`, `sort`, `order`]);

export {
  actionStatus,
  getError,
  getMessage,
  statusAction,
  enhanceWithResetActions,
  createSaga,
  sagasFromHandlerMap,
  createReducer,
  loadingStateReducer,
  getUserInformationFromToken,
  removeEmptyValuesRecursively,
  removeEmptyValuesRecursivelyPaginated
};

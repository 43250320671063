/* eslint-disable react/jsx-props-no-spreading */

import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import { PRIMARY_TEXT_COLOR } from '../../../attrs/colors';

const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: PRIMARY_TEXT_COLOR
  },
  tooltip: {
    backgroundColor: PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontSize: '12px'
  }
}));

const CustomTooltip = forwardRef((props, ref) => {
  const classes = useTooltipStyles();
  // eslint-disable-next-line react/prop-types
  const { disableHoverListener } = props;
  return (
    <Tooltip
      arrow
      disableHoverListener={disableHoverListener}
      leaveTouchDelay={3000}
      classes={classes}
      ref={ref}
      {...props}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </Tooltip>
  );
});

export default CustomTooltip;

import * as constants from './constants';

const loadOrganization = id => ({
  type: constants.GET_ORGANIZATION,
  id
});

const setUserInformation = payload => ({
  type: constants.SET_USER_INFORMATION,
  payload
});

const loadUser = (id = null) => ({
  type: constants.LOAD_USER,
  id
});

const updateUser = (userId, values) => ({
  type: constants.UPDATE_USER,
  userId,
  values
});

const resetPassword = values => ({
  type: constants.RESET_PASSWORD,
  values
});

const resetUserUpdateLoadingState = () => ({
  type: constants.RESET_USER_UPDATE_LOADING_STATE
});

export { setUserInformation, loadOrganization, loadUser, updateUser, resetPassword, resetUserUpdateLoadingState };

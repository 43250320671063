import React, { useMemo, useState } from 'react';
import T from 'prop-types';
import moment from 'moment';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer, ReferenceArea } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';

import { ArtificalXAxis } from '../elements';
import { MACHINE_WARNING_COLOR } from '../../../attrs/colors';
import { getMachineStatusProps, isLoading } from '../../../helpers/utils';
import { CustomMachineTooltip, formatXAxis, isValidTimeRange, ScopedTimeRangeLoader, CustomTick } from '../graphUtils';
import { getScopedTimeWindow } from '../../../redux/ui/settings/selectors';
import { generateMachineData } from './utils';
import { getSelectedScopedMachineHistory } from '../../../redux/machines/selectors';
import { setScopedTimeWindow } from '../../../redux/ui/settings/actions';

const MachineStatusGraph = ({ machineId }) => {
  const dispatch = useDispatch();

  const scopedTimeWindow = useSelector(getScopedTimeWindow);
  const { loadingState: scopedLoadingState, data } = useSelector(getSelectedScopedMachineHistory);

  const [zoomState, setZoomState] = useState({ refAreaLeft: null, refAreaRight: null });

  const { chartData, xAxisTicks, xAxisDomain } = useMemo(
    () => generateMachineData(data, scopedTimeWindow),
    [data, scopedTimeWindow]
  );
  const groupedChartData = groupBy(chartData, 'value');

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight } = zoomState;

    if (refAreaLeft === refAreaRight || refAreaRight === null) {
      setZoomState({
        refAreaLeft: null,
        refAreaRight: null
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    if (isValidTimeRange(moment(refAreaLeft).valueOf(), moment(refAreaRight).valueOf())) {
      dispatch(setScopedTimeWindow(moment(refAreaLeft).toISOString(), moment(refAreaRight).toISOString(), machineId));
    }
    setZoomState({ refAreaLeft: null, refAreaRight: null });
  };

  const handleMouseDown = e => {
    window.event.preventDefault();
    if (e && e.activeLabel) {
      setZoomState({ ...zoomState, refAreaLeft: e.activeLabel });
    }
  };

  return (
    <>
      {isLoading(scopedLoadingState.status) && <ScopedTimeRangeLoader />}
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          onMouseDown={e => handleMouseDown(e)}
          onMouseMove={e => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
          onMouseUp={handleZoom}
        >
          <XAxis
            dataKey="updated_at"
            type="number"
            interval="preserveStartEnd"
            domain={xAxisDomain}
            ticks={xAxisTicks}
            tick={false}
            tickFormatter={formatXAxis}
            stroke="false"
          />
          <Tooltip content={<CustomMachineTooltip />} />
          {Object.keys(groupedChartData).map(key => {
            const { color } = getMachineStatusProps(key);

            return (
              <Area key={key} fillOpacity={0.4} dataKey={key} isAnimationActive={false} stroke={color} fill={color} />
            );
          })}
          {zoomState.refAreaLeft && zoomState.refAreaRight ? (
            <ReferenceArea x1={zoomState.refAreaLeft} x2={zoomState.refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
      <ArtificalXAxis>
        <ResponsiveContainer height={45}>
          <AreaChart data={chartData}>
            <XAxis
              dataKey="updated_at"
              type="number"
              interval="preserveStartEnd"
              domain={xAxisDomain}
              stroke="false"
              ticks={xAxisTicks}
              tickFormatter={tick => {
                moment(tick).format('DD/MM/YYYY HH:mm:ss');
              }}
              tick={props => (
                <CustomTick
                  {...props}
                  lastIndex={xAxisTicks.length - 1}
                  spanValue={{
                    fisrtIndex: 70,
                    lastIndex: -30,
                    othersIndex: -25
                  }}
                />
              )}
            />
            <Area
              fillOpacity={0.4}
              dataKey="warning"
              isAnimationActive={false}
              stroke={null}
              fill={MACHINE_WARNING_COLOR}
            />
          </AreaChart>
        </ResponsiveContainer>
      </ArtificalXAxis>
    </>
  );
};

MachineStatusGraph.propTypes = {
  machineId: T.string.isRequired
};

export default MachineStatusGraph;

import React, { forwardRef } from 'react';
import T from 'prop-types';
import { NotifyIcon, useResolutionCheck } from 'web-components';
import { useTranslation } from 'react-i18next';

import NotifyTypo from 'web-components/build/NotifyTypo';
import { StyledSelectedComparisonCard } from './elements';
import { DARKER_GREY } from '../../attrs/colors';

const ComparisonSensorCardPlaceholder = forwardRef(({ placeholderMenu, onClick, ...props }, ref) => {
  const { t } = useTranslation();
  const { isDesktop } = useResolutionCheck();

  const additionalCardProps = onClick ? { onClick, clickable: 'true' } : {};

  return (
    <StyledSelectedComparisonCard
      ref={ref}
      elevation={3}
      data-selector="placeholder-sensor-card"
      placeholder="true"
      placeholdermenu={placeholderMenu ? 'true' : 'false'}
      mobile={isDesktop ? 'false' : 'true'}
      {...additionalCardProps}
      {...props}
    >
      {placeholderMenu ? (
        <>
          <NotifyIcon color="primary" iconName="plusFilled" viewBox="0 0 33 32" style={{ marginRight: '.5rem' }} />
          <NotifyTypo.Caption color="primary">{t('dashboard.sensors.comparison.add_sensor')}</NotifyTypo.Caption>
        </>
      ) : (
        <NotifyTypo.Caption style={{ color: DARKER_GREY }}>
          {t('dashboard.sensors.compare_sensors_compare_placeholder')}
        </NotifyTypo.Caption>
      )}
    </StyledSelectedComparisonCard>
  );
});

ComparisonSensorCardPlaceholder.propTypes = {
  sensor: T.shape({
    mapValue: T.string,
    name: T.string,
    type: T.string,
    status: T.string
  }),
  placeholderMenu: T.bool,
  onClick: T.func
};

ComparisonSensorCardPlaceholder.defaultProps = {
  placeholderMenu: false,
  onClick: null,
  sensor: null
};

export default ComparisonSensorCardPlaceholder;

import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  margin-left: 10px !important;
  @media (max-width: 425px) {
    margin-left: 0 !important;
  }
`;

export default StyledButton;

import React from 'react';
import T from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MachineIcon, NotifyIcon, NotifyTypo, useResolutionCheck } from 'web-components';

import { useTranslation } from 'react-i18next';
import { SensorWrapper, StyledCard, StyledSensorName } from './elements';
import { getRoundedValue, getSensorProps, getSensorValue, getUnit } from '../../helpers/utils';
import { SENSOR_STATUS_NODATA } from '../../attrs/notifications';
import { getSensorNotificationRule } from '../../redux/machines/selectors';
import { STATIC_SENSOR_TYPE_BOOLEAN } from '../../helpers/consts';
import { defaultNullSensorValue } from '../../redux/machines/constants';

const { SensorCardValue } = NotifyTypo;

const SensorCard = ({ sensor, machineId }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();
  const notificationRules = useSelector(state => getSensorNotificationRule(state, sensor.type));

  const hasNotificationRule = notificationRules.length > 0;
  const hasCurrentValue = sensor.last && sensor.status !== SENSOR_STATUS_NODATA;
  const isKnownSensor = sensor.mapValue !== 'UNKNOWN';
  const hasLongValue =
    hasCurrentValue && (getRoundedValue((sensor || {}.last || {}).value) || {}).toString().length > 9;

  const machineIconProps = {
    /* eslint-disable camelcase */
    iconName: getSensorProps(sensor.is_custom || sensor.is_static ? sensor.custom_icon_type : sensor.mapValue).icon,
    status: sensor.status,
    style: {
      fontSize: '2rem'
    },
    viewBox: '0 0 32 32'
  };

  const notifyIconProps = {
    iconName: 'alarmIcon',
    viewBox: '0 0 16 16',
    style: {
      margin: '0 .3rem'
    }
  };

  const sensorNameProps = isTablet
    ? {
        style: {
          fontSize: '0.875rem'
        }
      }
    : {
        style: {
          fontSize: '0.875rem',
          marginRight: hasNotificationRule ? '0' : '.5rem'
        }
      };

  const sensorCardValueProps = isTablet
    ? {
        style: {
          whiteSpace: 'nowrap',
          fontSize: '1rem'
        }
      }
    : {
        style: {
          whiteSpace: 'nowrap',
          display: 'flex',
          justifyContent: 'flex-end'
        }
      };

  const dataTestProps = {
    'data-selector': 'sensor-card',
    'data-test-type': sensor.type,
    'data-test-status': sensor.status,
    'data-test-value': hasCurrentValue ? sensor.last.value : undefined
  };

  const cardDetails = () => {
    let sensorValue = getSensorValue(
      sensor,
      sensor.is_static ? (sensor.static_value || { value: 0 }).value : (sensor.last || { value: 0 }).value
    );
    if (sensor.is_static && sensor.value_type === STATIC_SENSOR_TYPE_BOOLEAN) {
      if (sensor.static_value) {
        if (sensor.static_value.value === defaultNullSensorValue) {
          sensorValue = defaultNullSensorValue;
        } else {
          sensorValue = t(`generics.${sensor.static_value.value}`);
        }
      }
    }
    return (
      <StyledCard {...dataTestProps} is_static={sensor.is_static ? 1 : 0}>
        <MachineIcon {...machineIconProps} />
        <SensorWrapper withWrap={isTablet || hasLongValue}>
          {hasCurrentValue && (
            <SensorCardValue variant="h4" {...sensorCardValueProps}>
              {sensorValue} {getUnit(sensor.unit, sensor.name)}
            </SensorCardValue>
          )}
          <StyledSensorName {...sensorNameProps}>
            {sensor.is_custom || sensor.is_static ? sensor.name : t(sensor.name)}
            {hasNotificationRule && <NotifyIcon {...notifyIconProps} />}
          </StyledSensorName>
        </SensorWrapper>
      </StyledCard>
    );
  };
  return isKnownSensor && !sensor.is_static ? (
    <Link
      style={{
        display: 'block',
        textDecoration: 'none',
        minHeight: 70
      }}
      to={`/dashboard/${machineId}/sensor/${sensor.type}`}
    >
      {cardDetails()}
    </Link>
  ) : (
    cardDetails()
  );
};

SensorCard.propTypes = {
  sensor: T.shape({
    mapValue: T.string,
    name: T.string,
    status: T.string,
    unit: T.string,
    type: T.string,
    last: T.shape({ value: T.oneOfType([T.string, T.number]) }),
    static_value: T.shape({ value: T.oneOfType([T.string, T.number, T.bool]) }),
    value_type: T.string,
    is_custom: T.bool,
    is_static: T.bool,
    custom_icon_type: T.string
  }).isRequired,
  machineId: T.string.isRequired
};

export default SensorCard;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { NotifyTypo } from 'web-components';

import SelectableComparisonSensorCard from 'components/SensorCard/SelectableComparisonSensorCard';

const SelectableSensors = ({ sensors, selectedSensorTypes, onSelect }) => {
  const { t } = useTranslation();

  return (
    <>
      <NotifyTypo.Heading2Regular gutterBottom>
        {t('general.graphs.compare_sensors_sensors')}
      </NotifyTypo.Heading2Regular>
      <Grid container spacing={2}>
        {sensors.map(sensor => {
          const disabled = !selectedSensorTypes.includes(sensor.type) && selectedSensorTypes.length === 6;

          return (
            <Grid key={sensor.type} item xs={12} sm={6} lg={4}>
              <SelectableComparisonSensorCard
                selected={selectedSensorTypes.includes(sensor.type)}
                onClick={onSelect}
                disabled={disabled}
                sensor={sensor}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

SelectableSensors.propTypes = {
  sensors: T.arrayOf(T.shape({})).isRequired,
  selectedSensorTypes: T.arrayOf(T.string).isRequired,
  onSelect: T.func.isRequired
};

export default SelectableSensors;

import { getMachineStatusProps, getSensorProps, getSensorPropsFromType, getUnit, getRoundedValue } from './utils';

export const getMachineIconName = alert => {
  if (alert.metric) {
    const metric = {
      ...alert.metric,
      ...(alert.metric.is_custom ? { custom_name: alert.metric.name } : []),
      ...getSensorPropsFromType(alert.metric).mapValue
    };
    return getSensorProps(metric.is_custom ? metric.custom_icon_type : metric.mapValue).icon;
  }

  if (alert.severity) {
    return getMachineStatusProps(alert.severity).icon;
  }

  return null;
};

export const makeErrorAlertTitle = (t, alert) =>
  [
    alert.machine.name,
    t('alerts.text_of'),
    alert.machine.productionLine,
    t('alerts.text_has_changed_its_state_to', { status: t(`machines.status.${alert.severity.toLowerCase()}`) })
  ].join(' ');

export const makeSensorAlertTitle = (t, alert) => {
  let newMetricName = '';
  let newMetricUnit = '';

  const metric = getSensorPropsFromType(alert.metric);

  if (alert.is_custom) {
    newMetricName = alert.metric;
    newMetricUnit = alert.custom_unit;
  }

  let thresholdEvaluationText = '';
  const unit = alert.is_custom ? newMetricUnit : getUnit(metric.unit);

  if (alert.is_higher === true) {
    thresholdEvaluationText = t('alerts.text_higher_threshold', {
      threshold: alert.threshold_value,
      unit,
      interpolation: { escapeValue: false }
    });
  } else if (alert.is_higher === false) {
    thresholdEvaluationText = t('alerts.text_lower_threshold', {
      threshold: alert.threshold_value,
      unit,
      interpolation: { escapeValue: false }
    });
  }

  return [
    alert.is_custom ? newMetricName : t(metric.name),
    t('alerts.text_of'),
    alert.machine.name,
    t('alerts.text_of'),
    alert.machine.productionLine,
    t('alerts.text_has_reached', {
      value: getRoundedValue(alert.value),
      unit,
      interpolation: { escapeValue: false }
    }),
    thresholdEvaluationText
  ].join(' ');
};

export const getAlertUrl = alert => {
  if (alert.is_custom) {
    return `/dashboard/${alert.machine_id}/sensor/${alert.custom_type}`;
  }

  return alert.metric
    ? `/dashboard/${alert.machine_id}/sensor/${alert.metric}`
    : `/dashboard/${alert.machine_id}/history`;
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const RETRY_INTERVAL = 2000;
const MAX_RETRIES_NUMBER = 100;

let retryCounter = 0;

export default async function retryOnError(fn, ...args) {
  let result;

  try {
    result = await fn(...args);
  } catch (err) {
    if (retryCounter < MAX_RETRIES_NUMBER) {
      retryCounter += 1;
      await delay(RETRY_INTERVAL * retryCounter);
      result = retryOnError(fn, ...args);
    }
  }

  return result;
}

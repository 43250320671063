import styled from 'styled-components';
import { NotifyIcon, NotifyTypo, PageHeader as Header, PageHeaderTabs as Tabs } from 'web-components';
import { Tab as TabItem } from '@mui/material';

const ContainerTitle = styled.div`
  display: flex;
  @media (max-width: 425px) {
    align-items: center;
  }
`;

const PageHeader = styled(Header)`
  padding: 4rem 5rem 20px 5rem;

  &.MuiPaper-root {
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      flex-wrap: wrap;
      padding: 5px 2rem;
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 3rem 20px 3rem;
  }
`;

const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;
const PageHeaderAction = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  @media (max-width: 425px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Tab = styled(TabItem)`
  &.MuiTab-root {
    color: #23252f;
    text-transform: none;
    @media (max-width: 425px) {
      padding: 0.5rem 1rem;
    }
  }
`;

const PageHeaderTabs = styled(Tabs)`
  &.MuiPaper-root {
    padding-left: 5rem;
    @media (max-width: 768px) {
      padding-left: 3rem;
    }
    @media (max-width: 425px) {
      padding-left: 0;
    }
  }
`;

const ProductionLineWrapper = styled.div`
  margin-bottom: 2.25rem;
`;

const InfoIcon = styled(NotifyIcon)`
  color: #58baaa !important;
  font-size: 1.8rem !important;
  margin-left: 7px;
`;

const ContainerProductionLine = styled.div`
  padding: 4rem 3.25rem 2rem 3.25rem;
  @media (max-width: 768px) {
    padding: 4rem 2rem 2rem 2rem;
  }
  @media (max-width: 425px) {
    padding: 5rem 0.5rem 0.5rem 0.5rem;
  }
`;

export {
  ProductionLineWrapper,
  ContainerTitle,
  PageHeader,
  InfoIcon,
  ContainerProductionLine,
  PageHeaderTitle,
  Tab,
  PageHeaderAction,
  PageHeaderTabs
};

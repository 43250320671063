import styled from 'styled-components';

export const StyledList = styled('ul')`
  position: absolute;
  z-index: 100;
  bottom: 1rem;
  right: 1rem;
  background: deeppink;
  margin: 0;
  padding: 1rem;
  list-style: none;
  color: white;
  opacity: 0.2;
`;

export default {};

import React from 'react';

import BatchRecordsOverview from 'components/BatchRecordsOverview';
import T from 'prop-types';
import withLoadingState from '../../helpers/withLoadingState';
import { PaginationResponse } from '../../helpers/propTypes';

const BatchRecords = ({ data }) => {
  if (!data || !data.data) {
    return null;
  }
  return <BatchRecordsOverview rows={data} />;
};

BatchRecords.propTypes = {
  data: T.shape(PaginationResponse)
};

BatchRecords.defaultProps = {
  data: null
};

export default withLoadingState(BatchRecords);

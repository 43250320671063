import {
  SensorSettingsMinMaxSchema,
  SensorSettingsMinSchema,
  SensorSettingsMaxSchema
} from '../../../../attrs/formValidation';

const getValidationSchema = trigger => {
  if (trigger.includes('min') && trigger.includes('max')) {
    return SensorSettingsMinMaxSchema;
  }
  if (trigger.includes('min')) {
    return SensorSettingsMinSchema;
  }

  if (trigger.includes('max')) {
    return SensorSettingsMaxSchema;
  }

  return null;
};

const generateInitialValues = (notifyRules, users, sensor, machineId) => {
  const { min, max } = notifyRules;
  const initialValues = {};

  const init = {
    value: '',
    sms: [],
    email: [],
    machine_id: machineId,
    metric_type: sensor.type,
    units: sensor.unit || sensor.custom_unit
  };

  if (sensor.trigger.includes('min')) {
    initialValues.min = { ...init };

    if (min) {
      initialValues.min = {
        ...initialValues.min,
        id: min.id,
        value: min.warning.min,
        email: users.filter(item => min['e-mail_user_ids'].includes(item.value)),
        sms: users.filter(item => min.sms_user_ids.includes(item.value))
      };
    }
  }
  if (sensor.trigger.includes('max')) {
    initialValues.max = { ...init };

    if (max) {
      initialValues.max = {
        ...initialValues.max,
        id: max.id,
        value: max.warning.max,
        email: users.filter(item => max['e-mail_user_ids'].includes(item.value)),
        sms: users.filter(item => max.sms_user_ids.includes(item.value))
      };
    }
  }

  return initialValues;
};

export { getValidationSchema, generateInitialValues };

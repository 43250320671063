import React, { useRef, useState } from 'react';
import T from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomTooltip from '../../../../../components/elements/tooltips/Tooltip';
import { getCurrentTemplate } from '../../../../../redux/graphTemplates/selectors';

const ChartTemplateDetailsHeader = ({ templateId, valueOffset, handleExportdata, handleDelete }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const graphTemplate = useSelector(getCurrentTemplate);
  const [anchorEl, setAnchorEl] = useState(null);
  const tooltipRef = useRef(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    history.push(`/dashboard/${id}/graph-templates/${templateId}/edit`);
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Button
          variant="text"
          startIcon={<ArrowBackIosRoundedIcon sx={{ height: 16 }} />}
          sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550, padding: 0, height: 14 } }}
          onClick={() => history.goBack()}
          data-selector="container-chart-template-details-btn-back"
        >
          {t('default_actions.back')}
        </Button>
        <Divider orientation="vertical" flexItem />
        <Breadcrumbs aria-label="breadcrumb" sx={{ flex: 1 }} spacing={0}>
          <Link
            underline="hover"
            color="primary.main"
            sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
            href={`/dashboard/${id}/graph-templates`}
            data-selector="chart-templates-link"
          >
            {t('container_chart_template_details.breadcrumb.graphs_templates')}
          </Link>

          <Typography
            component="span"
            sx={{
              fontSize: {
                xs: 12,
                md: 16
              },
              fontWeight: 'bold',
              color: '#333333',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {graphTemplate?.name}
          </Typography>
        </Breadcrumbs>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          spacing={0}
          sx={{
            width: {
              xs: '80%',
              sm: '90%',
              md: '90%'
            }
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              width: '100%'
            }}
            spacing={0}
          >
            <Box
              sx={{
                marginRight: '8px !important'
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block'
                }}
              >
                {graphTemplate?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: '4px !important',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                flex: '1'
              }}
            >
              {graphTemplate?.tmpl_access_type && (
                <CustomTooltip
                  ref={tooltipRef}
                  enterTouchDelay={0}
                  placement="bottom"
                  title={t(`machines.detail.tabs.templates.tmpl_access_type.${graphTemplate?.tmpl_access_type}`)}
                  disableHoverListener={false}
                  PopperProps={{
                    popperOptions: {
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: valueOffset
                        }
                      }
                    }
                  }}
                >
                  <Chip
                    label={t(`machines.detail.tabs.templates.tmpl_access_type.${graphTemplate?.tmpl_access_type}`)}
                    size="small"
                    sx={{ backgroundColor: '#DEF1EE', mb: 0 }}
                  />{' '}
                </CustomTooltip>
              )}
            </Box>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          sx={{ height: '56px !important', width: '30px !important' }}
          onClick={handleMenu}
          data-selector="chart-template-details-btn-options"
        >
          <MoreHorizIcon sx={{ fontSize: '30px !important' }} />
        </Button>
      </Stack>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: 1 }}
        PaperProps={{
          style: {
            width: 200
          }
        }}
        data-selector="chart-template-details-menu-options"
      >
        <MenuItem onClick={handleEdit} data-selector="chart-template-details-btn-edit">
          <ListItemIcon>
            <ModeEditOutlineOutlinedIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('default_actions.edit')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleExportdata} data-selector="chart-template-details-btn-export-data">
          <ListItemIcon>
            <DownloadOutlinedIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('container_chart_template_details.menu_options.export_data')}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDelete} data-selector="chart-template-details-btn-delete">
          <ListItemIcon>
            <DeleteForeverOutlinedIcon fontSize="small" sx={{ color: '#F13309' }} />
          </ListItemIcon>
          <ListItemText>{t('default_actions.delete')}</ListItemText>
        </MenuItem>
      </Menu>
      <Typography component="span" color="text.secondary" sx={{ fontSize: 14 }}>
        {graphTemplate?.description}
      </Typography>
    </>
  );
};

export default ChartTemplateDetailsHeader;

ChartTemplateDetailsHeader.propTypes = {
  templateId: T.string.isRequired,
  valueOffset: T.string.isRequired,
  handleExportdata: T.func.isRequired,
  handleDelete: T.func.isRequired
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';
import { useMediaQuery } from '@mui/material';
import { getOnlyNotifyRole } from 'helpers/role';
import InfoBlock from 'components/BatchRecordsDetails/InfoBlocks/InfoBlock';
import Breadcrumbs from '../../../../components/elements/Breadcrumbs';
import LastUpdated from '../../../../components/elements/LastUpdated';
import withLoadingState from '../../../../helpers/withLoadingState';
import { loadMachineStatusMetrics } from '../../../../redux/machines/actions';
import ConnectedMachineHistoricalGraph from './ConnectedMachineHistoricalGraph';
import { getUserRole } from '../../../../redux/user/selectors';
import PERMISSIONS from '../../../../attrs/permissions';
import MachineNotificationSettings from '../../../../components/forms/Machines/MachineNotificationSettings';
import {
  GraphWrapper,
  MachineContentWrapper,
  PageHeader,
  PageHeaderTitle,
  PageTitle,
  SettingsWrapper,
  StyledCard,
  StyledCardContent,
  TimeSelectionWrapper,
  TitleSettingsWrapper
} from './elements';
import TimeSelection from '../../../../components/Graphs/TimeSelection';
import MachineLegendTooltip from '../MachineLegendTooltip';
import { getSelectedMachine, getSelectedMachineName } from '../../../../redux/machines/selectors';
import { getData } from '../../../../redux/rootSelectors';
import MachineStateSelection from '../SensorHistory/MachineStateSelection';
import StyledButton from '../../../../components/elements/StyledButton';
import MobileSensorPortrait from '../../../../components/elements/MobileSensorPortrait';
import CurrentValue from './CurrentValue';
import { processData } from './processData';

const MachineHistory = () => {
  const { t } = useTranslation();
  const { id: machineId } = useParams();
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);
  const machineName = useSelector(getSelectedMachineName);
  const [permissions, setPermissions] = useState();
  const isMobile = useMediaQuery('(max-width:425px) and (orientation:portrait)');
  const machine = useSelector(compose(getData, getSelectedMachine));
  const error = useSelector(compose(state => state.unresolevdError, getSelectedMachine));
  const { machineDetailsData } = processData(machine);

  const breadcrumbObj = [
    { route: '/dashboard', name: `${t('dashboard.title')}` },
    { route: `/dashboard/${machineId}`, name: `${machineName}` },
    { route: null, name: `${t('dashboard.machines.status_timeline_title')}` }
  ];

  useEffect(() => {
    if (role) {
      const oneRole = getOnlyNotifyRole(role);
      setPermissions(PERMISSIONS.dashboard[oneRole]);
    }
  }, [role]);

  return (
    <>
      <PageHeader alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <PageTitle>
          <PageHeaderTitle>{`${t('dashboard.machines.status_timeline_title')} - ${machineName}`}</PageHeaderTitle>
        </PageTitle>
      </PageHeader>
      <MachineContentWrapper maxWidth={false}>
        <StyledCard>
          <StyledCardContent>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <NotifyTypo.Heading2 style={{ fontSize: 14 }}>
                  {t('dashboard.sensors.current_status')}
                </NotifyTypo.Heading2>
              </div>
              <div>
                {permissions && permissions.canEditMachineNotifications && (
                  <MachineNotificationSettings machine={{ id: machineId, name: machineName }} isMobile={isMobile} />
                )}
              </div>
            </div>
            <CurrentValue unresolvedError={error.data} machine={machine} />
          </StyledCardContent>
        </StyledCard>

        <InfoBlock config={machineDetailsData} hideContent marginTop="2rem" />

        <GraphWrapper>
          <SettingsWrapper>
            <TitleSettingsWrapper>
              <NotifyTypo.Heading2 style={{ marginRight: '0.75rem', fontSize: 14 }}>
                {t('dashboard.sensors.title_graph')}
              </NotifyTypo.Heading2>
              <LastUpdated type="machine" marginBottom="0" />
            </TitleSettingsWrapper>
            <div style={{ display: 'flex' }}>
              {isMobile ? <MachineLegendTooltip /> : <MachineStateSelection showMachineStatus={false} />}
              <StyledButton
                buttonText={t('dashboard.machines.refresh')}
                iconName="reload"
                variant="text"
                color="primary"
                onClick={() => dispatch(loadMachineStatusMetrics(machineId))}
                isMobile={isMobile}
              />
            </div>
          </SettingsWrapper>
          <TimeSelectionWrapper>
            <TimeSelection machineId={machineId} />
          </TimeSelectionWrapper>
          {isMobile ? <MobileSensorPortrait /> : <ConnectedMachineHistoricalGraph />}
        </GraphWrapper>
      </MachineContentWrapper>
    </>
  );
};

export default withLoadingState(MachineHistory);

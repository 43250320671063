const STATIC_SENSOR_TYPE_STRING = 'STRING';
const STATIC_SENSOR_TYPE_NUMBER = 'NUMBER';
const STATIC_SENSOR_TYPE_BOOLEAN = 'BOOLEAN';
const DEFAULT_FILE_FORMAT_EXPORT = 'Excel';

// FIXME: uncomment when excel is better implemented on backend

const FileFormats = [
  { key: 'excel', name: 'Excel Workbook (*.xlsx)', value: 'Excel' },
  { key: 'csv', name: 'CSV (*.csv)', value: 'CSV' }
];

const FileFormatsBatches = [
  { key: 'excel', name: 'Excel Workbook (*.xlsx)', value: 'Excel' },
  { key: 'csv', name: 'CSV (*.csv)', value: 'CSV' },
  { key: 'pdf', name: 'PDF (*.pdf)', value: 'PDF' }
];

const FileFormatsGraphTemplates = [
  { key: 'pdf', name: 'PDF', value: 'pdf' },
  { key: 'csv', name: 'CSV (*.csv)', value: 'csv' },
  { key: 'excel', name: 'XLSX (*.xlsx)', value: 'excel' }
];

const LineStyleTypes = Object.freeze({
  LINE_STYLE_SOLID: 'solid',
  LINE_STYLE_DASH: 'dashed'
});

export {
  STATIC_SENSOR_TYPE_STRING,
  STATIC_SENSOR_TYPE_NUMBER,
  STATIC_SENSOR_TYPE_BOOLEAN,
  DEFAULT_FILE_FORMAT_EXPORT,
  FileFormats,
  FileFormatsBatches,
  FileFormatsGraphTemplates,
  LineStyleTypes
};

const BATCH_NOTE_CREATE = 'BATCH_NOTE/CREATE';
const BATCH_NOTE_DELETE = 'BATCH_NOTE/DELETE';
const BATCH_NOTE_UPDATE = 'BATCH_NOTE/UPDATE';
const BATCH_NOTE_GET_ALL = 'BATCH_NOTE/GET_ALL';
const BATCH_NOTE_ALL_BY_BATCH_ID = 'BATCH_NOTE/ALL_BY_BATCH_ID';
const BATCH_NOTE_LOAD = 'BATCH_NOTE/LOAD';
const BATCH_NOTE_SHOW_MODAL = 'BATCH_NOTE/SHOW_MODAL';
const BATCH_NOTE_RESET = 'BATCH_NOTE/RESET';

export {
  BATCH_NOTE_CREATE,
  BATCH_NOTE_DELETE,
  BATCH_NOTE_UPDATE,
  BATCH_NOTE_GET_ALL,
  BATCH_NOTE_ALL_BY_BATCH_ID,
  BATCH_NOTE_LOAD,
  BATCH_NOTE_SHOW_MODAL,
  BATCH_NOTE_RESET
};

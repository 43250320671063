import styled from 'styled-components';
import { Button, Card, Divider, Paper } from '@mui/material';
import Carousel from 'react-multi-carousel';

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 1.25rem;
    margin-bottom: 2.5rem;
    box-shadow: none;
    background-color: transparent;
    @media (max-width: 425px) {
      padding: 0.2rem;
      margin-bottom: 1rem;
    }
  }
`;

export const StyledCard = styled(Card)`
  margin-right: 15px !important;
  min-width: 250px !important;
`;

export const StyledButtonAddNote = styled(Button)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  & > .MuiButton-startIcon {
    margin-right: 0;
  }
`;

export const StyledButtonAddNoteMobile = styled(Button)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  & > .MuiButton-startIcon {
    margin-right: 0;
  }
`;

export const StyledHeadingWrapper = styled.div(
  () => `
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  @media(max-width:425px){
    flex-wrap: wrap;
  }

  & > .MuiButtonBase-root > span {
    padding: 7px;
  }
  
  & > .MuiTypography-root {
    font-weight: 500;
  }

  & > .MuiButton-root {
    padding 0 0.25rem;
    font-weight: 0.875rem;
  }
`
);

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 1.25rem 0;
  }
`;

export const StyledCarousel = styled(Carousel)`
  // Adding padding do carousel items
  .react-multi-carousel-item {
    padding-right: 40px;
    @media (max-width: 768px) {
      padding-right: 20px;
    }
    @media (max-width: 425px) {
      padding-bottom: 2rem;
    }
  }
`;

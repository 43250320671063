import axios from './axios';

const loadMachineStatusHistory = (id, from, to) =>
  axios({
    method: 'get',
    url: '/v1/metrics/machine-status',
    params: {
      id,
      from,
      to
    }
  });

const loadLastSensorMetrics = (id, metrics) =>
  axios({
    method: 'get',
    url: '/v1/metrics/last',
    params: {
      machine_id: id,
      metrics
    }
  });

// const loadSensorHistory = (id, from, to, metrics) => axios({
//   method: 'get',
//   url: '/v1/metrics/history',
//   params: {
//     machine_id: id,
//     from,
//     to,
//     metrics,
//   },
// });

/* eslint-disable camelcase */
const loadCustomSensorResumed = (ids, machine_ids) =>
  axios({
    method: 'get',
    url: '/v1/custom_sensors/resumed',
    params: {
      ids,
      machine_ids
    }
  });

export {
  loadMachineStatusHistory,
  loadLastSensorMetrics,
  // loadSensorHistory,
  loadCustomSensorResumed
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';

import { TooltipElement, TooltipElementWrapper, TooltipElementBackground } from './elements';
import TooltipIcon from '../../../components/elements/tooltips/TooltipIcon';
import { MACHINE_STATUS_MAP } from '../../../attrs/notifications';

const { Body2 } = NotifyTypo;

const LegendComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Body2 style={{ fontWeight: 500 }}>{t('dashboard.machines.legends')}</Body2>
      {MACHINE_STATUS_MAP.filter(item => !item.invisible).map(item => (
        <TooltipElementWrapper key={item.value}>
          <TooltipElement color={item.color}>
            <TooltipElementBackground />
          </TooltipElement>
          {t(`machines.status.${item.value.toLowerCase()}`)}
        </TooltipElementWrapper>
      ))}
    </>
  );
};

const MachineLegendTooltip = () => <TooltipIcon placement="bottom-end" color="primary" title={<LegendComponent />} />;

export default MachineLegendTooltip;

import * as constants from './constants';

const loadUsers = () => ({
  type: constants.LOAD_USERS
});

const updateUserById = (userId, values) => ({
  type: constants.UPDATE_USER_BY_ID,
  userId,
  values
});

const deleteUserById = userId => ({
  type: constants.DELETE_USER_BY_ID,
  userId
});

const updateUserRole = (userId, values) => ({
  type: constants.UPDATE_USER_ROLE,
  userId,
  values
});

const inviteUser = values => ({
  type: constants.INVITE_USER,
  values
});

export { loadUsers, updateUserById, updateUserRole, deleteUserById, inviteUser };

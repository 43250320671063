import styled, { css } from 'styled-components';

export const TooltipElementWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 400;
`;

export const TooltipElementBackground = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 16px;
  height: 16px;
  position: absolute;
  left: 2px;
  top: 2px;
`;

export const TooltipElement = styled.div`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

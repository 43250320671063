import styled from 'styled-components';
import { Container } from '@mui/material';

export const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    padding: calc(40px + 2.5rem) 3.75rem 0;
    overflow-wrap: break-word;

    ${theme.breakpoints.down('sm')} {
      padding: 2.5rem 1.25rem 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
  @media(max-width:1024px){
    .MuiTable-root{
      min-width: 1024px;
    }
  }
`
);

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NewDialog } from 'web-components';
import { DateRangeCalendar } from 'web-components-ts';
import { Box, Stack, TextField } from '@mui/material';
import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

/**
 * Custom dialog to select a date range and time range with validations
 * ATTENTION: This is implemented locally because systems use diferent date providers
 * @param open<caption>Define if the Dialog will be open
 * @param handleClose<caption>Handle close method for default actions
 * @param title<caption>Title of Dialog
 * @param subTitle<caption>Subtitle of Dialog
 * @param startTimeCaption<caption>Start time caption
 * @param endTimeCaption<caption>End time caption
 * @param cancelCaption<caption>Cancel button caption
 * @param okCaption<caption>Save button caption
 * @param setValues<caption>Set values got from the dialog
 * @param handleApply<caption>Extra actions to be executed when user clicks on apply date
 * @param showTimePicker<caption>Can pass false to hide time pickers
 * @param maxDays<caption>Max days to be selected
 * @returns CustomDateDialog<caption>@type {NewDialog}
 */
function CustomDateDialog({
  open,
  handleClose,
  title,
  subTitle,
  startTimeCaption,
  endTimeCaption,
  cancelCaption,
  okCaption,
  setValues,
  handleApply,
  showTimePicker,
  maxDays,
  language
}) {
  const [startEndDates, setStartEndDates] = useState({
    startDate: new Date().toISOString(),
    endDate: undefined
  });

  const [startTime, setStartTime] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
  const [endTime, setEndTime] = useState(moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 }));
  const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);

  const handleDateChange = value => {
    setStartEndDates(value);
  };

  const handleStartTime = date => {
    setStartTime(date);
    if (date && date.isValid()) {
      setIsInvalidStartTime(false);
    } else {
      setIsInvalidStartTime(true);
    }
  };

  const handleEndTime = date => {
    setEndTime(date);
    if (date && date.isValid()) {
      setIsInvalidEndTime(false);
    } else {
      setIsInvalidEndTime(true);
    }
  };

  function canSave() {
    // Check if both times are defined and if endTime is greater than startTime
    const isValidTimeRange = !!startTime && !!endTime && new Date(endTime) > new Date(startTime);

    // Return true if showTimePicker is false or if the time range is valid
    return showTimePicker ? isValidTimeRange : true;
  }

  const handleOk = () => {
    // Format the start and end times (fallback to '00:00' for start and '23:59' for end)
    const newStartTime = startTime && startTime.isValid() ? startTime.format('HH:mm') : '00:00';
    const newEndTime = endTime && endTime.isValid() ? endTime.format('HH:mm') : '23:59';

    // Parse the start and end dates as UTC
    const startDate = startEndDates?.startDate ? new Date(startEndDates.startDate) : new Date();
    const endDate = startEndDates?.endDate ? new Date(startEndDates.endDate) : new Date();

    // Set the time components explicitly, keeping local time intact
    const [startHours, startMinutes] = newStartTime.split(':').map(Number);
    startDate.setHours(startHours, startMinutes, 0, 0);

    const [endHours, endMinutes] = newEndTime.split(':').map(Number);
    endDate.setHours(endHours, endMinutes, 0, 0);

    // Generate the final ISO strings
    const newStartDateTimeText = startDate.toISOString();
    const newFinalDateTimeText = endDate.toISOString();

    // with this values you can do whatever you want
    setValues(
      newStartDateTimeText,
      newFinalDateTimeText,
      new Date(newStartDateTimeText),
      new Date(newFinalDateTimeText)
    );

    handleApply();
    handleClose();
  };

  const content = (
    <Box
      sx={{
        maxWidth: 'sm',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mx: 'auto',
        '& > * + *': {
          // Applies top margin to all direct children after the first
          mt: 2
        }
      }}
    >
      <DateTimeProvider>
        <>
          <DateRangeCalendar
            returnType="string"
            handleDates={handleDateChange}
            selectedDates={startEndDates}
            maxDays={maxDays}
            blockAfterToday
            language={language}
          />
          {showTimePicker && (
            <Stack direction="row" spacing={2}>
              <TimePicker
                id="start-time-picker"
                label={startTimeCaption}
                ampm={false}
                format="HH:mm"
                value={startTime}
                onChange={handleStartTime}
                slotProps={{
                  textField: {
                    variant: 'filled'
                  }
                }}
                sx={{ maxWidth: '180px' }}
                renderInput={params => <TextField {...params} error={isInvalidStartTime} />}
              />
              <TimePicker
                id="end-time-picker"
                label={endTimeCaption}
                ampm={false}
                format="HH:mm"
                value={endTime}
                onChange={handleEndTime}
                slotProps={{
                  textField: {
                    variant: 'filled'
                  }
                }}
                sx={{ maxWidth: '180px' }}
                renderInput={params => <TextField {...params} error={isInvalidEndTime} />}
              />
            </Stack>
          )}
        </>
      </DateTimeProvider>
    </Box>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleOk={handleOk}
      title={title}
      subTitle={subTitle}
      content={content}
      cancelCaption={cancelCaption}
      okCaption={okCaption}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minWidth="450px"
      maxWidth="98%"
    />
  );
}

CustomDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  startTimeCaption: PropTypes.string.isRequired,
  endTimeCaption: PropTypes.string.isRequired,
  cancelCaption: PropTypes.string.isRequired,
  okCaption: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  handleApply: PropTypes.func,
  showTimePicker: PropTypes.bool,
  maxDays: PropTypes.number,
  language: PropTypes.string
};

CustomDateDialog.defaultProps = {
  subTitle: '',
  handleApply: () => null,
  showTimePicker: true,
  maxDays: 0,
  language: undefined
};

export default CustomDateDialog;

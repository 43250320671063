import React from 'react';
import T from 'prop-types';

import withLoadingState from '../../../../helpers/withLoadingState';
import EmptyState from '../../../../components/EmptyState';
import MachineGraph from '../../../../components/Graphs/machine';

const MachineHistoricalGraph = ({ metrics, machineId }) => {
  const hasData = metrics.length > 0;

  return <>{hasData ? <MachineGraph machineId={machineId} /> : <EmptyState type="machinesHistoricalData" />}</>;
};

MachineHistoricalGraph.propTypes = {
  metrics: T.arrayOf(T.shape({})).isRequired,
  machineId: T.string.isRequired
};

export default withLoadingState(MachineHistoricalGraph);

import * as constants from './constants';

// API
const loadGraphsTemplates = ({ machineId, accessType, sensorsList, templateName }) => ({
  type: constants.LOAD_GRAPHS_TEMPLATES,
  machineId,
  accessType,
  sensorsList,
  templateName
});

const favoriteGraphTemplate = graphId => ({
  type: constants.FAVORITE_GRAPH_TEMPLATE,
  graphId
});

const createGraphsTemplate = values => ({
  type: constants.CREATE_GRAPH_TEMPLATE,
  values
});

const getGraphsTemplateById = graphId => ({
  type: constants.GET_GRAPH_TEMPLATE_BY_ID,
  graphId
});

const deleteGraphsTemplateById = (machineID, templateID) => ({
  type: constants.DELETE_GRAPH_TEMPLATE_BY_ID,
  machineID,
  templateID
});

const loadSensorHistory = paramList => ({
  type: constants.LOAD_SENSOR_HISTORY,
  paramList
});

const loadStatusHistory = param => ({
  type: constants.LOAD_STATUS_HISTORY,
  param
});

const loadSensorAverage = paramList => ({
  type: constants.LOAD_SENSOR_AVERAGE,
  paramList
});

const deleteSensorGraphsTemplate = values => ({
  type: constants.DELETE_SENSOR_GRAPH_TEMPLATE,
  values
});

const updateGraphsTemplate = values => ({
  type: constants.UPDATE_GRAPH_TEMPLATE,
  values
});

const exportGraphsTemplate = (templateID, values) => ({
  type: constants.EXPORT_GRAPH_TEMPLATE,
  templateID,
  values
});

export {
  loadGraphsTemplates,
  favoriteGraphTemplate,
  createGraphsTemplate,
  getGraphsTemplateById,
  deleteGraphsTemplateById,
  deleteSensorGraphsTemplate,
  updateGraphsTemplate,
  loadSensorHistory,
  loadStatusHistory,
  loadSensorAverage,
  exportGraphsTemplate
};

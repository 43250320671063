import styled from 'styled-components';

import { Dialog } from 'web-components';

// TODO: Move all the styles to web-components once the redesign is completed by UI/UX
const DIALOG_BACKGROUND_COLOR = '#FFFFFF';
const FOOTER_BACKGROUND_COLOR = '#F8F8F9';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background-color: ${FOOTER_BACKGROUND_COLOR} !important;
  }
`;

export const StyledDialogHeader = styled(Dialog.Header)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};

  & .MuiTypography-h1 {
    font-size: 1.75rem;
    align-self: center;

    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
    }
  }
`
);

export const StyledDialogSubHeader = styled(Dialog.SubHeader)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  
  & .MuiTypography-body1 {
    font-size: 0.875rem;

    ${theme.breakpoints.down('xs')} {
      margin-top: -0.5rem;
    }
  }
`
);

export const StyledDialogBody = styled(Dialog.Body)`
  background-color: ${DIALOG_BACKGROUND_COLOR};
`;

export const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    padding: 1.75rem 1.25rem 2.25rem;

    & .MuiButtonBase-root {
      height: 3rem;
      font-size: 0.875rem;
    }
  }
`;

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#F7F7FA',
  color: theme.palette.getContrastText('#00DBDB'),
  height: 36,
  minWidth: 64,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  textTransform: 'none'
}));

import React from 'react';
import styled from 'styled-components';
import T from 'prop-types';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { COLOR_WARNING, COLOR_WARNING_CONTRAST } from '../../attrs/colors';

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${COLOR_WARNING};
    color: ${COLOR_WARNING_CONTRAST};
    height: 16px;
    font-size: 0.5rem;

    & > .MuiChip-label {
      padding: 1px 4px 0;
      text-transform: uppercase;
    }
  }
`;

const WarningStatusChip = ({ className }) => {
  const { t } = useTranslation();

  return <StyledChip className={className} label={t('machines.status.warning')} />;
};

WarningStatusChip.propTypes = {
  className: T.string
};

WarningStatusChip.defaultProps = {
  className: ''
};

export default WarningStatusChip;

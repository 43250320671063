import { connect } from 'formik';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import T from 'prop-types';

const FormikEffect = ({ onChange = noop, formik }) => {
  const { errors, touched, dirty, resetForm, submitForm } = formik;

  const submitDisabled = Object.keys(errors).length > 0 || Object.keys(touched).length === 0;

  useEffect(() => {
    onChange({ dirty, resetForm, submitForm, submitDisabled });
  }, [dirty, resetForm, submitForm, submitDisabled, onChange]);

  return null;
};

FormikEffect.propTypes = {
  onChange: T.func,
  formik: T.shape({
    errors: T.shape({}).isRequired,
    touched: T.bool.isRequired,
    dirty: T.bool.isRequired,
    resetForm: T.func.isRequired,
    submitForm: T.func.isRequired
  }).isRequired
};

FormikEffect.defaultProps = {
  onChange: () => null
};

export default connect(FormikEffect);

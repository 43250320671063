import { createSelector } from 'reselect';
import { getSettings } from '../selectors';

const getMachineSettings = createSelector([getSettings], settings => settings.machines);

const getSensorsSettings = createSelector([getMachineSettings], machineSettings => machineSettings.sensors);

const getGraphSettings = createSelector([getSettings], settings => settings.graph);

const getTimeWindow = createSelector([getGraphSettings], graph => graph.timeWindow);

const getScopedTimeWindow = createSelector([getGraphSettings], graph => graph.scopedTimeWindow);

const getTimeWindowRange = createSelector([getGraphSettings], graph => graph.timeWindowRange);

const getMachineIdAndSensorType = (_, machineId, sensorType) => ({ machineId, sensorType });

const getSensorSettingByIds = createSelector(
  [getSensorsSettings, getMachineIdAndSensorType],
  (sensors, { machineId, sensorType }) =>
    sensors.find(sensor => sensor.sensorType === sensorType && sensor.machineId === machineId) || null
);

export { getSensorSettingByIds, getTimeWindow, getScopedTimeWindow, getTimeWindowRange };

import styled from 'styled-components';

import { NotifyIcon } from 'web-components';

import { COLOR_SECONDARY_CONTRAST, SECONDARY_TEXT_COLOR } from 'attrs/colors';

export const ConcludedWrapper = styled.span`
  display: inline-block;
  background-color: ${SECONDARY_TEXT_COLOR};
  color: ${COLOR_SECONDARY_CONTRAST};
  font-size: 0.75rem;
  padding: 2px 8px 1px;
  border-radius: 12px;
`;

export const StyledNotifyIcon = styled(NotifyIcon)(
  ({ theme }) => `
  &.MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;

    ${theme.breakpoints.down('sm')} {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`
);

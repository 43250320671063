import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import { loadComparisonData, loadMachineStatusMetrics } from '../../../../redux/machines/actions';
import { getCommonLoadingState, getMetricsTimeFrame } from '../../../../helpers/utils';
import { METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from '../../../../attrs/layout';
import SensorComparisonGraph from './SensorComparisonGraph';
import { getSelectedMachineSensorComparisonSensorData, getSelectedMetrics } from '../../../../redux/machines/selectors';
import { getLoadingState } from '../../../../redux/rootSelectors';

const ConnectedSensorComparisonGraph = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const machineMetricsLoadingState = useSelector(compose(getLoadingState, getSelectedMetrics));
  const sensorHistoryLoadingState = useSelector(compose(getLoadingState, getSelectedMachineSensorComparisonSensorData));

  const loadNeededData = useCallback(() => {
    // TODO: We can remove the machine status now
    dispatch(
      loadMachineStatusMetrics(
        id,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
    dispatch(
      loadComparisonData(
        null,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
  }, [dispatch, id]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  return (
    <SensorComparisonGraph
      loadingState={getCommonLoadingState([machineMetricsLoadingState, sensorHistoryLoadingState])}
      reloadAction={loadNeededData}
    />
  );
};

export default ConnectedSensorComparisonGraph;

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import SensorHistoricalGraph from './SensorHistoricalGraph';
import { loadMachineStatusMetrics, loadSelectedSensorHistory } from '../../../../redux/machines/actions';
import { getSelectedMetrics, getSelectedSensorHistory } from '../../../../redux/machines/selectors';
import { METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from '../../../../attrs/layout';
import { getCommonLoadingState, getMetricsTimeFrame } from '../../../../helpers/utils';
import { getLoadingState } from '../../../../redux/rootSelectors';

const ConnectedSensorHistoricalGraph = () => {
  const dispatch = useDispatch();
  const { id, sensorType } = useParams();
  const loadingState = useSelector(compose(getLoadingState, getSelectedSensorHistory));
  const machineMetricsLoadingState = useSelector(compose(getLoadingState, getSelectedMetrics));

  const loadNeededData = useCallback(() => {
    dispatch(
      loadSelectedSensorHistory(
        id,
        sensorType,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
    dispatch(
      loadMachineStatusMetrics(
        id,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
  }, [dispatch, sensorType, id]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  return (
    <SensorHistoricalGraph
      loadingState={getCommonLoadingState([loadingState, machineMetricsLoadingState])}
      reloadAction={loadNeededData}
    />
  );
};

export default ConnectedSensorHistoricalGraph;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachineOptions, getProductionLinesOptions } from 'redux/support/selectors';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { DataTable } from 'web-components';
import T from 'prop-types';
import Button from '@mui/material/Button';
import Footer from '../DataTable/Footer';

import { generateColumns } from './AlertHistoryOverview.config';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { ALERT_HISTORY_DEFAULT_LIST_SORT, LIST_ORDER } from '../../attrs/sorter_default_values';
import { FilterContainer, StyledContainer } from './AlertHistory.styled';
import { PaginationResponse } from '../../helpers/propTypes';
import AlertFilter from './AlertFilter';
import { periodObject } from './periodObject';
import { getAlertHistorySortOrder } from '../../redux/alert_history/selectors';
import { loadAlertHistory, loadAlertsHistoryFiltered } from '../../redux/alert_history/actions';
import { isNullUndefinedOrEmpty, areAlarmsAndAlertsFilterValuesEmpty } from '../../helpers/utils';

const AlertHistoryOverview = ({ rows }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const productionLinesObj = useSelector(getProductionLinesOptions);
  const machines = useSelector(getMachineOptions);
  const productionLines = productionLinesObj.map(item => ({ label: item, value: item }));

  const alertHistorySortOrder = useSelector(getAlertHistorySortOrder);

  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState([]);
  const [filterProductionLine, setFilterProductionLine] = useState('');
  const [filterMachine, setFilterMachine] = useState('');
  const [filterPeriodTime, setFilterPeriodTime] = useState('');
  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentSort, setCurrentSort] = useState({
    colId: alertHistorySortOrder.sort || ALERT_HISTORY_DEFAULT_LIST_SORT,
    order: alertHistorySortOrder.order.toLowerCase() || LIST_ORDER.toLowerCase()
  });
  const [clear, setClear] = useState(false);
  const { t } = useTranslation();

  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const [filterValues, setFilterValues] = useState({
    productionLine: '',
    machine: '',
    periodTime: ''
  });

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(
        DataTable.sortFn(
          rows.data,
          currentSort.colId,
          currentSort.order,
          filterMachine,
          filterPeriodTime,
          filterProductionLine
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [currentSort, rows, filterMachine, filterPeriodTime, filterProductionLine]);

  useEffect(() => {
    if (filterValues.productionLine || filterValues.machine || filterValues.periodTime) {
      dispatch(
        loadAlertsHistoryFiltered(
          currentPage,
          rowsPerPage,
          currentSort.colId,
          currentSort.order.toUpperCase(),
          filterValues.machine,
          filterValues.periodTime,
          filterValues.productionLine
        )
      );
    }
  }, [filterValues, currentPage, currentSort, rowsPerPage, dispatch]);

  const setInitStates = () => {
    setFilterProductionLine('');
    setFilterMachine('');
    setFilterPeriodTime('');
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    setCurrentSort({
      colId: alertHistorySortOrder.sort || ALERT_HISTORY_DEFAULT_LIST_SORT,
      order: alertHistorySortOrder.order.toLowerCase() || LIST_ORDER.toLowerCase()
    });
  };

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    setFilterValues(prev => ({
      ...prev,
      page
    }));
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    setCurrentSort({ colId, order });
    setFilterValues(prev => ({
      ...prev,
      sort: { colId, order }
    }));
  };

  const handleFilterMachine = machineIds => {
    setIsLoading(true);
    const machinesValue = machineIds.join(';');
    setFilterMachine(machinesValue);
    setFilterValues(prev => ({
      ...prev,
      machine: machinesValue,
      page: PAGINATION_DEFAULT_START_PAGE
    }));
  };

  const handleFilterProductionLine = productionLineValues => {
    setIsLoading(true);
    const valuesProductionLine = productionLineValues.join(';');
    setFilterProductionLine(valuesProductionLine);
    setFilterValues(prev => ({
      ...prev,
      productionLine: valuesProductionLine,
      page: PAGINATION_DEFAULT_START_PAGE
    }));
  };

  const handleFilterPeriodTime = periodTime => {
    setIsLoading(true);
    setFilterPeriodTime(periodTime);
    setFilterValues(prev => ({
      ...prev,
      periodTime,
      page: PAGINATION_DEFAULT_START_PAGE
    }));
  };

  const handleClearFilter = () => {
    setIsLoading(true);
    setClear(true);
    setFilterValues({
      productionLine: '',
      machine: '',
      periodTime: ''
    });
    dispatch(loadAlertHistory(PAGINATION_DEFAULT_START_PAGE));
  };

  const props = {
    isLoading,
    columns: generateColumns(t),
    rows: currentView,
    hover: undefined,
    footer: (
      <Footer
        currentPage={rows.page}
        totalPages={rows.total_pages || 1}
        totalItems={rowsPerPage || 0}
        onChange={handleChangePage}
      />
    ),
    defaultSort: currentSort,
    sortFn: handleSort,
    dataSelectorTable: 'alert-history-table',
    dataSelectorRows: 'alert-history-table-row'
  };

  const isButtonDisabled = areAlarmsAndAlertsFilterValuesEmpty(filterProductionLine, filterMachine, filterPeriodTime);

  return (
    <>
      <StyledContainer maxWidth={false} theme={theme}>
        <FilterContainer>
          <AlertFilter
            labelFilter={t('alarms.filter.production_line')}
            clear={clear}
            optionsObj={productionLines.filter(productionLine => !isNullUndefinedOrEmpty(productionLine.label))}
            setClear={setClear}
            setInitStates={setInitStates}
            handleFilter={handleFilterProductionLine}
          />
          <AlertFilter
            labelFilter={t('alarms.filter.machine')}
            clear={clear}
            optionsObj={machines.filter(machine => !isNullUndefinedOrEmpty(machine.label))}
            setClear={setClear}
            setInitStates={setInitStates}
            handleFilter={handleFilterMachine}
          />
          <AlertFilter
            labelFilter={t('alarms.filter.period')}
            clear={clear}
            optionsObj={periodObject}
            isCheckbox={false}
            setClear={setClear}
            setInitStates={setInitStates}
            handleFilter={handleFilterPeriodTime}
            allOption
          />
          <Button color="primary" onClick={handleClearFilter} disabled={isButtonDisabled}>
            {t('alarms.filter.clear')}
          </Button>
        </FilterContainer>
        <DataTable hover {...props} />
      </StyledContainer>
    </>
  );
};

AlertHistoryOverview.propTypes = {
  rows: T.shape(PaginationResponse).isRequired
};

export default AlertHistoryOverview;

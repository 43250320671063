import styled from 'styled-components';
import { ListSubheader, MenuItem, MenuList, Popper } from '@mui/material';
import { COLOR_MAIN_HIGHLIGHT, MENU_ITEM_BORDER, PRIMARY_LIGHT_BACKGROUND } from 'attrs/colors';

const MENU_MAX_HEIGHT = '300px';
const SUBHEADER_BACKGROUND = '#FFFFFF';

export const StyledMenuList = styled(MenuList)`
  &.MuiList-root {
    padding: 0;
    max-height: ${MENU_MAX_HEIGHT};
    overflow: auto;
  }
`;

export const StyledListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-root {
    background-color: ${SUBHEADER_BACKGROUND};
    color: ${COLOR_MAIN_HIGHLIGHT};
  }
`;

export const MachineIconWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    justify-content: space-between;
    height: 4.5rem;
    border-top: 1px solid ${MENU_ITEM_BORDER};
    font-size: 0.857rem;
  }

  &.MuiListItem-root.Mui-selected {
    background: ${PRIMARY_LIGHT_BACKGROUND};
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 1100;
`;

import { StepLabel } from '@mui/material';
import styled from 'styled-components';

export const CustomStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: ${({ active, completed }) => (active || completed ? '#2BACA4' : '#9e9e9e')};
    &.Mui-active {
      color: #2baca4;
    }
    &.Mui-completed {
      color: #2baca4;
    }
  }
`;

import { call, put, takeLatest } from 'redux-saga/effects';
import { BATCH_NOTE_ALL_BY_BATCH_ID, BATCH_NOTE_CREATE, BATCH_NOTE_DELETE, BATCH_NOTE_UPDATE } from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { createBatchNote, deleteBatchNote, getAllBatchNotesByBatchId, updateBatchNote } from '../../api/batchNotes';
import { showBatchNotesModal } from './actions';

function* createBatchNotes({ batchId, title, note }) {
  yield put(statusAction(BATCH_NOTE_CREATE, actionStatus.START));
  try {
    const payload = {
      batch_id: batchId,
      title,
      note
    };
    const { data } = yield call(createBatchNote, payload);
    yield put(statusAction(BATCH_NOTE_CREATE, actionStatus.SUCCESS, { payload: data }));
    yield put(showBatchNotesModal(false)); // Hidden batchNotes modal
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'batch_records.batch_notes.success.create',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(BATCH_NOTE_CREATE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch_notes.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* loadAllBatchNotesByBatchId({ batchId }) {
  yield put(statusAction(BATCH_NOTE_ALL_BY_BATCH_ID, actionStatus.START));
  try {
    const { data } = yield getAllBatchNotesByBatchId(batchId);
    yield put(statusAction(BATCH_NOTE_ALL_BY_BATCH_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(BATCH_NOTE_CREATE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch_records.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* deleteBatchNotes({ batchNoteId, batchId }) {
  yield put(statusAction(BATCH_NOTE_DELETE, actionStatus.START));
  try {
    const { status } = yield deleteBatchNote(batchNoteId);
    const { data } = yield getAllBatchNotesByBatchId(batchId);
    yield put(statusAction(BATCH_NOTE_DELETE, actionStatus.SUCCESS, { payload: data }));

    if (status === 203) {
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.batch_notes.not_authorized_delete`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    } else {
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'batch_records.batch_notes.success.delete',
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(BATCH_NOTE_DELETE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch_notes.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* updateBatchNotes({ batchNote }) {
  yield put(statusAction(BATCH_NOTE_UPDATE, actionStatus.START));
  try {
    const res = yield updateBatchNote(batchNote.id, batchNote);
    if (res.status === 203) {
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.batch_notes.not_authorized_update`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    } else {
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'batch_records.batch_notes.success.update',
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }

    yield put(statusAction(BATCH_NOTE_UPDATE, actionStatus.SUCCESS, { payload: res.data }));
    yield put(showBatchNotesModal(false)); // Hidden batchNotes modal
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(BATCH_NOTE_UPDATE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch_notes.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

// Return a function to load all batch sagas functions
export function* watchLoadOrders() {
  yield takeLatest(BATCH_NOTE_CREATE, createBatchNotes);
  yield takeLatest(BATCH_NOTE_ALL_BY_BATCH_ID, loadAllBatchNotesByBatchId);
  yield takeLatest(BATCH_NOTE_DELETE, deleteBatchNotes);
  yield takeLatest(BATCH_NOTE_UPDATE, updateBatchNotes);
}

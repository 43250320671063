import React from 'react';
import T from 'prop-types';

import { useResolutionCheck } from 'web-components';
import BatchStatusIcon from 'components/BatchStatusIcon';

import { Grid } from '@mui/material';
import { COLUMN_END_TIME, COLUMN_START_TIME, COLUMN_STATUS } from '../BatchRecordsOverview.config';
import { CellLabel, CellSubValue, CellValue } from './MobileView.styled';

const SplitValue = ({ value }) => {
  const lastSpaceRegex = /\s(?=\S*$)/;
  const [date, time] = value.split(lastSpaceRegex);

  return (
    <>
      <CellSubValue>{date}</CellSubValue>
      <CellSubValue>{time}</CellSubValue>
    </>
  );
};

SplitValue.propTypes = {
  value: T.string.isRequired
};

const MobileCell = ({ label, id, value }) => {
  const { isSmallDevice } = useResolutionCheck();
  const cellValue = {
    [true]: () => value,
    [[COLUMN_START_TIME, COLUMN_END_TIME].includes(id)]: () => {
      const date = new Date(value).toLocaleString();
      if (isSmallDevice) {
        return <SplitValue value={date} />;
      }
      return date;
    },
    [id === COLUMN_STATUS]: () => <BatchStatusIcon status={value} />
  }.true;

  return (
    <Grid item xs={4}>
      <CellLabel>{label}</CellLabel>
      <CellValue isSmallDevice={isSmallDevice}>{cellValue()}</CellValue>
    </Grid>
  );
};

MobileCell.propTypes = {
  label: T.string.isRequired,
  id: T.string.isRequired,
  value: T.oneOfType([T.string, T.array]).isRequired
};

export default MobileCell;

import React from 'react';
import T from 'prop-types';
import { Button } from '@mui/material';
import { NotifyIcon } from 'web-components';

function StyledButton({ buttonText, iconName, isMobile, ...props }) {
  return isMobile ? (
    <Button {...props}>
      <NotifyIcon iconName={iconName} />
    </Button>
  ) : (
    <Button startIcon={<NotifyIcon iconName={iconName} />} {...props}>
      {buttonText}
    </Button>
  );
}
StyledButton.propTypes = {
  buttonText: T.string,
  iconName: T.string,
  isMobile: T.bool
};

StyledButton.defaultProps = {
  buttonText: null,
  iconName: null,
  isMobile: false
};

export default StyledButton;

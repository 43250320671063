import React from 'react';
import T from 'prop-types';
import { Hidden } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NavigationItem } from '../elements';

const NavigationItems = ({ routes }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const topBarRoutes = routes.filter(route => route.topNav);

  return (
    <Hidden smDown>
      {topBarRoutes.map(route => (
        <Link key={route.name} style={{ textDecoration: 'none' }} to={route.path}>
          <NavigationItem selected={location.pathname.includes(route.path)}>{t(`routes.${route.name}`)}</NavigationItem>
        </Link>
      ))}
    </Hidden>
  );
};

NavigationItems.propTypes = {
  routes: T.arrayOf(T.shape({})).isRequired
};

export default NavigationItems;

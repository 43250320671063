import * as constants from './constants';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE } from '../../attrs/pagination';
import { LIST_ORDER, ALARM_HISTORY_DEFAULT_LIST_SORT } from '../../attrs/sorter_default_values';

const loadAlarms = (
  page,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  sort = ALARM_HISTORY_DEFAULT_LIST_SORT,
  order = LIST_ORDER,
  machineIds,
  periodTime,
  productionLines,
  sensors
) => ({
  type: constants.LOAD_ALARMS,
  page,
  limit,
  sort,
  order,
  machineIds,
  periodTime,
  productionLines,
  sensors
});

const getAlarmsFiltered = (page, limit, sort, order, machineIds, periodTime, productionLines, sensors) => ({
  type: constants.LOAD_ALARMS_WITH_FILTER,
  page,
  limit,
  sort,
  order,
  machineIds,
  periodTime,
  productionLines,
  sensors
});
const setAlarmsSortOrder = (sort, order) => ({
  type: constants.CHANGE_ALARMS_ORDER,
  sort,
  order
});

export { loadAlarms, getAlarmsFiltered, setAlarmsSortOrder };

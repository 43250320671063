import styled from 'styled-components';
import { Container } from '@mui/material';

import { Dialog } from 'web-components';

import { PRIMARY_LIGHT_BACKGROUND } from 'attrs/colors';

export const ActionWrapper = styled.div(
  props => `
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;

  ${props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }

  ${props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    padding: 1rem 0;
    & button {
      width: 100%;
      margin-bottom: .5rem;
    }
  }
`
);

export const StyledDialogBody = styled(Dialog.Body)`
  &.MuiDialogContent-root {
    padding: 1rem 0 2rem;
  }
`;

export const SelectedSensorsWrapper = styled.div`
  background: ${PRIMARY_LIGHT_BACKGROUND};
  padding: 1.25rem 0;
`;

export const StyledSelectableSensorsContainer = styled(Container)`
  margin-top: 1rem;
`;

import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { createRoot } from 'react-dom/client';
import App from './App';
import * as features from './helpers/features';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { GlobalStyle } from './elements';

features.configure();

const { history, store, persistor } = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

// eslint-disable-next-line no-console
console.log(
  `%c                       (&&&&&&&&&&(
                 /&&&&&&&&&&&&&&&&&&&&&&*
              %&&&&&&&&&&&&&&&&&&&&&&&&&&&&%
            &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
          &&&&&&&&#      /&&&&&&.     /&&&&&&&&&
         &&&&&&&&&#        &&&&&.     /&&&&&&&&&&
        &&&&&&&&&&#         &&&&.     /&&&&&&&&&&& 
       &&&&&&&&&&&#          &&&,     /&&&&&&&&&&&%
       &&&&&&&&&&&#     &     #&/     /&&&&&&&&&&&&
       &&&&&&&&&&&#     (&     (&     /&&&&&&&&&&&&
       &&&&&&&&&&&#     .&&.    /     /&&&&&&&&&&&%
        &&&&&&&&&&#      &&&(         /&&&&&&&&&&& 
         &&&&&&&&&#      &&&&&        /&&&&&&&&&&  
          &&&&&&&&#      &&&&&&       /&&&&&&&&&   
          &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&.    
        ,&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&       
       &&&.      %&&&&&&&&&&&&&&&&&&&&&&%
                      /&&&&&&&&&&&&/`,
  'color: lightgreen;'
);

const app = (
  <Provider context={ReactReduxContext} store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter context={ReactReduxContext} history={history}>
        <GlobalStyle />
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

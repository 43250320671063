import React from 'react';
import T from 'prop-types';
import moment from 'moment';

import { DataTable } from 'web-components';

export const COLUMN_ACTIVATION_TIME = 'time';
export const COLUMN_ACKNOWLEDGE_TIME = 'resolved';
export const COLUMN_PRODUCTION_LINE = 'production_line';
export const COLUMN_MACHINE = 'machine_name';
export const COLUMN_ALERT_MESSAGE = 'message';

const formatDate = dateValue => moment(dateValue, 'YYYY-MM-DD[T]HH:mm:ssZ').format('DD MMM YYYY, hh:mm A');

export const generateColumns = t => [
  {
    id: COLUMN_ACTIVATION_TIME,
    label: t(`alarms.overview.activation_time`),
    sortable: true,
    cellRenderer: row => <div>{formatDate(row.time)}</div>
  },
  {
    id: COLUMN_ACKNOWLEDGE_TIME,
    label: t(`alarms.overview.acknowledge_time`),
    sortable: true,
    cellRenderer: row => <div>{row.resolved ? formatDate(row.resolved) : '-'}</div>
  },
  {
    id: COLUMN_MACHINE,
    label: t(`alarms.overview.machine`),
    sortable: false
  },
  {
    id: COLUMN_PRODUCTION_LINE,
    label: t(`alarms.overview.production_line`),
    sortable: false
  },
  {
    id: COLUMN_ALERT_MESSAGE,
    label: t(`alarms.overview.alarm_message`),
    sortable: false,
    cellRenderer: row => (
      <div style={{ width: '450px' }}>
        {row.is_custom ? row.message : `${row.code}: ${t(`dashboard.machines.error_codes.${row.code}`)}`}
      </div>
    )
  }
];

export const generateSortMenuItems = t => [
  {
    label: t('alarms.overview.order'),
    value: null,
    disabled: true
  },
  {
    label: `${t('alarms.overview.start_time')} - ${t('alarms.overview.ascending')}`,
    value: { colId: COLUMN_ACTIVATION_TIME, order: DataTable.ORDER_ASC }
  },
  {
    label: `${t('alarms.overview.start_time')} - ${t('alarms.overview.ascending')}`,
    value: { colId: COLUMN_ACKNOWLEDGE_TIME, order: DataTable.ORDER_ASC }
  }
];

export const AlertHistoryColumnProps = T.arrayOf(
  T.shape({
    id: T.string.isRequired,
    label: T.string.isRequired,
    sortable: T.bool
  })
);

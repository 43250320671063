import {
  SET_SCOPED_TIME_WINDOW,
  SET_SHOW_MACHINE_STATE,
  SET_TIME_WINDOW,
  SET_TIME_WINDOW_RANGE,
  SET_IS_BATCH_RECORD_DETAILS
} from './constants';

const showMachineState = (machineId, sensorType, setting) => ({
  type: SET_SHOW_MACHINE_STATE,
  machineId,
  sensorType,
  setting
});

const setTimeWindow = (from, to) => ({
  type: SET_TIME_WINDOW,
  from,
  to
});

const setScopedTimeWindow = (from, to, machineId = null, sensorType = null) => ({
  type: SET_SCOPED_TIME_WINDOW,
  from,
  to,
  machineId,
  sensorType
});

const setTimeWindowRange = (range = false) => ({
  type: SET_TIME_WINDOW_RANGE,
  range
});

const setIsBatchRecordsDetails = (isBatchRecord, batchId, timeFrom, timeTo) => ({
  type: SET_IS_BATCH_RECORD_DETAILS,
  isBatchRecord,
  batchId,
  timeFrom,
  timeTo
});

export { showMachineState, setTimeWindow, setScopedTimeWindow, setTimeWindowRange, setIsBatchRecordsDetails };

import React from 'react';
import { CardContent, Tooltip } from '@mui/material';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyledCard,
  StyledSpan,
  StyledTypography,
  StyledNoteTypographyHorizontalScroll,
  StyledEditButton,
  StyledDeleteButton,
  StyledTypographyLastUpdated,
  StyledIconButton,
  StyledEditButtonDisabled,
  StyledDeleteButtonDisabled,
  TypographyCaption,
  StyledAvatar,
  ContainerAvatar
} from './batchNote.styled';
import { BatchNoteResponse } from '../../../../helpers/propTypes';
import { getInitials } from '../../../../helpers/utils';

const BatchNote = ({ batchNote, handleDelete, handleEdit }) => {
  const { t } = useTranslation();

  return (
    <StyledCard data-selector="batch-note">
      <CardContent>
        <StyledTypography variant="h5" component="div">
          <div>
            {batchNote?.title.length > 36 ? (
              <Tooltip title={batchNote.title ? batchNote.title : ''}>
                <TypographyCaption sx={{ fontSize: 13 }}>
                  {batchNote?.title?.substring(0, 33)} {batchNote?.title.length >= 33 ? '...' : ''}
                </TypographyCaption>
              </Tooltip>
            ) : (
              <TypographyCaption sx={{ fontSize: 13 }}>{batchNote?.title}</TypographyCaption>
            )}
          </div>
          <div>
            <StyledIconButton
              onClick={handleDelete(batchNote)}
              size="small"
              aria-label="edit"
              sx={{ color: 'white' }}
              disabled={!batchNote.enabled_edit}
              data-selector="batch-note-delete-button"
            >
              {batchNote.enabled_edit ? (
                <StyledDeleteButton iconName="delete" color="inherit" />
              ) : (
                <StyledDeleteButtonDisabled iconName="delete" color="inherit" />
              )}
            </StyledIconButton>

            <StyledIconButton
              onClick={handleEdit(batchNote)}
              size="small"
              aria-label="edit"
              sx={{ color: 'white' }}
              disabled={!batchNote.enabled_edit}
              data-selector="batch-note-edit-button"
            >
              {batchNote.enabled_edit ? (
                <StyledEditButton iconName="edit" color="inherit" />
              ) : (
                <StyledEditButtonDisabled iconName="edit" color="inherit" />
              )}
            </StyledIconButton>
          </div>
        </StyledTypography>
        <StyledTypographyLastUpdated color="primary" component="div" gutterBottom>
          <StyledSpan>
            {t('batch_records.batch_notes.last_updated')}{' '}
            {batchNote.updated_at
              ? new Date(batchNote?.updated_at).toLocaleString()
              : new Date(batchNote?.created_at).toLocaleString()}
          </StyledSpan>
        </StyledTypographyLastUpdated>
        <ContainerAvatar>
          <StyledSpan data-selector="batch-note-user-name-span">{t('batch_records.batch_notes.author')}</StyledSpan>
          {batchNote.user_name && (
            <Tooltip title={batchNote.user_name}>
              <StyledAvatar>{getInitials(batchNote.user_name)}</StyledAvatar>
            </Tooltip>
          )}
        </ContainerAvatar>
        <StyledNoteTypographyHorizontalScroll variant="body2">{batchNote?.note}</StyledNoteTypographyHorizontalScroll>
      </CardContent>
    </StyledCard>
  );
};

BatchNote.propTypes = {
  batchNote: T.shape(BatchNoteResponse).isRequired,
  handleDelete: T.func.isRequired,
  handleEdit: T.func.isRequired
};

export default BatchNote;

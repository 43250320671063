let featureStore = {};

export const configure = () => {
  const storageEntries = Object.entries(window.localStorage);
  const featureTogglesFromStorage = storageEntries.reduce((acc, [key, value]) => {
    if (key.startsWith('is_')) {
      return {
        ...acc,
        [key]: value
      };
    }
    return acc;
  }, {});
  const featureTogglesFromUrl = Object.fromEntries(new URLSearchParams(window.location.search));

  featureStore = {
    is_debug: window.configuration.nodeEnv === 'development' ? 'on' : 'off',
    ...featureTogglesFromStorage,
    ...featureTogglesFromUrl
  };

  Object.entries(featureStore).forEach(([key, value]) => {
    window.localStorage.setItem(key, value);
  });
};

export const get = featureName => {
  if (featureStore[featureName] === 'on') {
    return true;
  }
  if (featureStore[featureName] === 'off') {
    return false;
  }
  return null;
};

export const getActiveFeatures = () => Object.keys(featureStore).filter(key => featureStore[key] === 'on');

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BatchRecords from './BatchRecords';
import { loadBatches } from '../../redux/batch/actions';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';

const ConnectedMachines = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(state => state.batch);

  const getData = () => {
    dispatch(loadBatches(PAGINATION_DEFAULT_START_PAGE, PAGINATION_DEFAULT_ROWS_PER_PAGE));
  };

  useEffect(getData, [dispatch]);

  return <BatchRecords loadingState={loadingState} reloadAction={getData} data={data} data-selector="batch-records" />;
};

export default ConnectedMachines;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';

const MachineStatus = ({ status }) => {
  const { t } = useTranslation();

  return (
    <NotifyTypo.MachineText
      style={{
        color: status.color,
        marginRight: '.5rem',
        marginTop: '.2rem',
        fontSize: '.75rem'
      }}
    >
      {t(`machines.status.${status.value.toLowerCase()}`)}
    </NotifyTypo.MachineText>
  );
};

MachineStatus.propTypes = {
  status: T.shape({ color: T.string, value: T.string }).isRequired
};

export default MachineStatus;

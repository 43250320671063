import styled from 'styled-components';

const BoxWrapper = styled.div`
  max-height: 72px;
  width: 100%;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 6px;
  /* padding: 24px; */
`;

const BoxCardInner = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  width: 100%;
  &.MuiGrid-item {
    padding-left: 0px !important;
    text-align: center;
  }
  text-align: center;
  margin: 0 auto;
`;

const BoxCardValue = styled.h4`
  align-self: stretch;
  text-align: center;
  color: #333333;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
  margin: 0;
`;

const BoxCardName = styled.p`
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  margin: 0 auto;
`;

const BoxBatchesCardName = styled.p`
  text-align: center;
  color: #333333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  margin: 0 auto;
`;

const BoxBatches = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
`;

const BoxBatchesInner = styled.div`
  width: 44px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

const BoxBatchesSecondInner = styled.div`
  width: 45px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
`;

const BoxBatchesThirdInner = styled.div`
  width: 45px;
  height: 32px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: contents;
`;

const BoxBatchesInnerText = styled.div`
  text-align: center;
  color: #333333;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
`;

const BoxBatchesInnerIcon = styled.div`
  position: relative;
  margin-left: 5px;
  align-self: flex-end;
  height: 16px;
  bottom: 3px;
`;

const BoxInner = styled.div`
  align-items: center;
  display: inline-flex;
`;

export {
  BoxWrapper,
  BoxCardInner,
  BoxCardValue,
  BoxCardName,
  BoxBatches,
  BoxBatchesInner,
  BoxBatchesSecondInner,
  BoxBatchesThirdInner,
  BoxBatchesInnerText,
  BoxBatchesInnerIcon,
  BoxInner,
  BoxBatchesCardName
};

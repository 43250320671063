import T from 'prop-types';

const PaginationResponse = {
  limit: T.number,
  page: T.number,
  total_length: T.number,
  total_pages: T.number,
  data: T.arrayOf(T.shape({}))
};

const BatchNoteResponse = {
  batch_id: T.string,
  created_at: T.string,
  deleted_at: T.string,
  id: T.string,
  note: T.string,
  title: T.string,
  updated_at: T.string,
  user_id: T.string,
  user_name: T.string,
  user_nickname: T.string,
  enabled_edit: T.bool
};

const BatchResponse = {
  details: T.shape({
    batch_id: T.string,
    cycles: T.number,
    date: T.shape({
      end: T.string,
      start: T.string
    }),
    id: T.string,
    length: T.number,
    max_allowed_temperature: T.number,
    progress: T.number,
    recipe: T.string,
    size: T.number,
    status: T.string
  }),
  gateway_id: T.string,
  machine: T.shape({
    commission_number: T.string,
    gateway_SN: T.string,
    id: T.string,
    mechanical_config: T.shape({
      bead_material: T.string,
      beads_filling_level: T.number,
      beads_size_1: T.number,
      beads_size_2: T.number,
      grinding_technology: T.string,
      inner_liner_material: T.string,
      samba_system: T.bool,
      sieve_size: T.number
    }),
    name: T.string,
    production_line: T.string,
    status: T.shape({
      updated_at: T.string,
      value: T.string,
      warning: T.bool
    }),
    type: T.string
  }),
  machine_id: T.string,
  property: T.shape({
    est_energy_consumption: T.number,
    grinding_energy: T.number,
    specific_energy: T.number,
    total_grinding_time: T.number
  }),
  target: T.shape({
    type: T.string,
    value: T.number
  }),
  time: T.string,
  batch_notes: T.arrayOf(T.shape(BatchNoteResponse))
};

export { PaginationResponse, BatchResponse, BatchNoteResponse };

import styled, { css } from 'styled-components';
import { Avatar } from '@mui/material';

import {
  COLOR_MAIN_HIGHLIGHT,
  COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY,
  COLOR_MAIN_HIGHLIGHT_TEXT
} from '../../attrs/colors';
import { NAVIGATION_BAR_HEIGHT } from '../../attrs/layout';

const PageTitleShortener = styled.div`
  text-overflow: ellipsis;
  width: 95%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ButtonWrapper = styled.div`
  @media (min-width: 1366px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 17%;
  }
  @media (min-width: 1024px) and (max-width: 1366px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25%;
  }
  @media only screen and (min-width: 2000px) {
    width: 10%;
  }
  & > :first-child {
    margin-right: 0.8rem;
  }
  min-width: fit-content;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  & > button {
    height: 100%;
  }
`;
const ContainerAvatar = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitleWrapper = styled.div`
  height: ${NAVIGATION_BAR_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
`;

const NavigationItem = styled.div`
  display: inline-flex;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  cursor: pointer;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 3px solid ${COLOR_MAIN_HIGHLIGHT};
  color: ${COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY} !important;
  text-decoration: none !important;
  ${props =>
    props.selected &&
    css`
      border-bottom: 3px solid ${COLOR_MAIN_HIGHLIGHT_TEXT};
      color: ${COLOR_MAIN_HIGHLIGHT_TEXT} !important;
    `}
`;

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    height: 26px;
    width: 26px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    margin-left: 8px;
    background: #58baaa;
  }
`;

export { ButtonWrapper, PageTitleWrapper, NavigationItem, PageTitleShortener, StyledAvatar, ContainerAvatar };

import React, { useState, useEffect } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { orderBy as lodashOrderBy } from 'lodash';
import { Table, Paper, TableRow, TableCell, TableBody, IconButton, useTheme } from '@mui/material';
import { NotifyIcon, SortableTableHeaderCells } from 'web-components';

import { updateUserRole } from '../../redux/users/actions';
import { isSuccess, getUserRole, getDialCodeFromCountryCode } from '../../helpers/utils';
import Delete from './Delete';
import { getUserData } from '../../redux/user/selectors';
import { getUsers } from '../../redux/rootSelectors';
import { StyledContainer, NotifyTypoBody1, HeadTable, TableContainer } from './elements';
import EditUserDialog from './EditUserDialog';

const UserTable = ({ columns, rows }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState('asc');
  const [isOpen, setIsOpen] = useState(false);
  const [editUser, setEditUser] = useState({});
  const { id: loggedInUserId } = useSelector(getUserData);
  const { updateLoadingState } = useSelector(getUsers);
  const isActive = rows.filter((item, index) => index === 0 && item)[0].is_active || false;

  const closeModal = () => {
    setIsOpen(false);
  };

  const OpenModal = user => {
    setIsOpen(true);
    setEditUser(user);
  };

  const handleRoleSubmit = values => {
    dispatch(updateUserRole(values.id, values));
  };

  useEffect(() => {
    if (isSuccess(updateLoadingState.status)) {
      closeModal();
    }
  }, [updateLoadingState]);

  return (
    <>
      <StyledContainer maxWidth={false} theme={theme}>
        <TableContainer component={Paper} isActive={isActive}>
          <Table aria-label="machine table">
            <HeadTable>
              <TableRow style={{ backgroundColor: '#EFEFEF' }}>
                <SortableTableHeaderCells
                  columns={columns}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  order={order}
                  setOrder={setOrder}
                />
                <TableCell key="options" />
              </TableRow>
            </HeadTable>
            <TableBody>
              {lodashOrderBy(rows, item => item[orderBy], order).map(row => {
                const selectedDialCode = getDialCodeFromCountryCode(((row || {}).phone || {}).country);
                const isLoggedInUser = row.id === loggedInUserId;

                return (
                  <TableRow key={row.id}>
                    {row.is_active ? (
                      <>
                        <TableCell>
                          <NotifyTypoBody1>{row.first_name}</NotifyTypoBody1>
                        </TableCell>
                        <TableCell>
                          <NotifyTypoBody1>{row.last_name}</NotifyTypoBody1>
                        </TableCell>
                        <TableCell>
                          <NotifyTypoBody1>{t(getUserRole(row.role).label)}</NotifyTypoBody1>
                        </TableCell>
                        <TableCell>
                          <NotifyTypoBody1>{row['e-mail']}</NotifyTypoBody1>
                        </TableCell>
                        <TableCell>
                          <NotifyTypoBody1>
                            {selectedDialCode} {((row || {}).phone || {}).number}
                          </NotifyTypoBody1>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <NotifyTypoBody1>{t(getUserRole(row.role).label)}</NotifyTypoBody1>
                        </TableCell>
                        <TableCell>
                          <NotifyTypoBody1>{row['e-mail']}</NotifyTypoBody1>
                        </TableCell>
                      </>
                    )}
                    {!isLoggedInUser ? (
                      <TableCell align="right">
                        <IconButton aria-label="edit_role" color="primary" onClick={() => OpenModal(row)}>
                          <NotifyIcon iconName="edit" style={{ fontSize: 25 }} />
                        </IconButton>
                        <Delete user={row} />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContainer>
      <EditUserDialog
        isOpen={isOpen}
        handleCLose={closeModal}
        handleFormSubmit={handleRoleSubmit}
        user={editUser}
        fullEdit
      />
    </>
  );
};

UserTable.propTypes = {
  columns: T.arrayOf(T.shape({})).isRequired,
  rows: T.arrayOf(T.shape({})).isRequired
};

export default UserTable;

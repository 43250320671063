import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import { PageHeader as Header, NotifyTypo, Styles } from 'web-components';

const MachineContentWrapper = styled(Styles.ContentWrapper)`
  &.MuiContainer-root {
    padding: 6rem 2rem 2rem 2rem;
    @media (max-width: 425px) {
      padding: 5rem 1rem 1rem 1rem;
    }
  }
`;

const GraphWrapper = styled(Card)`
  &.MuiCard-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    padding: 0 2rem 10rem 2rem;
    margin-top: 2rem;
    @media (max-width: 425px) {
      padding: 0 1rem 5rem 1rem;
    }
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const GraphHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MachineGraphHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    margin-left: 1rem;
  }
`;
const StyledCard = styled(Card)`
  &.MuiCard-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(17, 17, 26, 0.1);
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;
const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 1.75rem;
    @media (max-width: 425px) {
      align-content: flex-end;
      align-items: baseline;
      margin: 0;
    }
  }
`;

const PageHeader = styled(Header)`
  &.MuiPaper-root {
    flex-direction: column;
    align-self: flex-start;
    padding: 1rem 0 0 3rem;
    height: auto;
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      padding: 1rem 0 0 1.25rem;
    }
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;
const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    font-size: 20px;
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;
const TitleSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media (max-width: 425px) {
    flex-direction: column;
    margin-right: 5px;
  }
`;

const SettingsWrapper = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.mobile &&
    `
    padding: .2rem .5rem;
  `};
  @media (max-width: 425px) {
    padding: 1.2rem 0;
    flex-wrap: wrap;
  }
`;
const TimeSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
  }
`;

const ContainerCurrentStatus = styled.div`
  display: flex;
  flex-direction: row;
`;
const StatusMachineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10rem;
  @media (max-width: 425px) {
    margin-right: 5rem;
  }
`;
const HeadingCurrentStatus = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 400;
`;
const StatusNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  GraphWrapper,
  GraphHeading,
  MachineGraphHeading,
  StyledCard,
  StyledCardContent,
  PageHeader,
  PageHeaderTitle,
  PageTitle,
  MachineContentWrapper,
  TitleSettingsWrapper,
  SettingsWrapper,
  TimeSelectionWrapper,
  ContainerCurrentStatus,
  StatusMachineWrapper,
  HeadingCurrentStatus,
  StatusNotificationWrapper
};

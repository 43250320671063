import React from 'react';
import T from 'prop-types';
import moment from 'moment';

import { DataTable } from 'web-components';

export const COLUMN_ACTIVATION_TIME = 'created_at';
export const COLUMN_ACKNOWLEDGE_TIME = 'deleted_at';
export const COLUMN_PRODUCTION_LINE = 'production_line';
export const COLUMN_MACHINE = 'machine_name';
export const COLUMN_ALARM_MESSAGE = 'error_message';

const formatDate = dateValue => moment(dateValue, 'YYYY-MM-DD[T]HH:mm:ssZ').format('DD MMM YYYY, hh:mm A');

export const generateColumns = t => [
  {
    id: COLUMN_ACTIVATION_TIME,
    label: t(`alerts.overview.activation_time`),
    sortable: true,
    cellRenderer: row => <div>{formatDate(row.created_at)}</div>
  },
  {
    id: COLUMN_ACKNOWLEDGE_TIME,
    label: t(`alerts.overview.acknowledge_time`),
    sortable: true,
    cellRenderer: row => <div>{row.deleted_at ? formatDate(row.deleted_at) : '-'}</div>
  },
  {
    id: COLUMN_MACHINE,
    label: t(`alerts.overview.machine`),
    sortable: false
  },
  {
    id: COLUMN_PRODUCTION_LINE,
    label: t(`alerts.overview.production_line`),
    sortable: false
  },
  {
    id: COLUMN_ALARM_MESSAGE,
    label: t(`alerts.overview.alert_message`),
    sortable: false,
    cellRenderer: row => <div style={{ width: '550px' }}>{row.error_message.trim() ? row.error_message : '-'}</div>
  }
];

export const generateSortMenuItems = t => [
  {
    label: t('alarms.overview.order'),
    value: null,
    disabled: true
  },
  {
    label: `${t('alarms.overview.start_time')} - ${t('alarms.overview.ascending')}`,
    value: { colId: COLUMN_ACTIVATION_TIME, order: DataTable.ORDER_ASC }
  },
  {
    label: `${t('alarms.overview.start_time')} - ${t('alarms.overview.ascending')}`,
    value: { colId: COLUMN_ACKNOWLEDGE_TIME, order: DataTable.ORDER_ASC }
  }
];

export const AlarmHistoryColumnProps = T.arrayOf(
  T.shape({
    id: T.string.isRequired,
    label: T.string.isRequired,
    sortable: T.bool
  })
);

export const AlarmHistoryRowProps = T.shape({
  activation_time: T.string.isRequired,
  machine: T.string.isRequired,
  production_line: T.string.isRequired,
  acknowledge_time: T.string.isRequired,
  alarm_message: T.string.isRequired
});

export const AlarmHistoryRowsProps = T.arrayOf(AlarmHistoryRowProps);

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer, enhanceWithResetActions } from '../utils';

import { LOAD_USERS, UPDATE_USER_BY_ID, DELETE_USER_BY_ID } from './constants';

export default combineReducers({
  loadingState: loadingStateReducer(LOAD_USERS),
  updateLoadingState: enhanceWithResetActions([LOAD_USERS])(loadingStateReducer([UPDATE_USER_BY_ID])),
  data: typeToReducer(
    {
      [LOAD_USERS]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_USER_BY_ID]: {
        SUCCESS: (data, { payload }) => data.map(user => (user.id === payload.id ? { ...payload } : user))
      },
      [DELETE_USER_BY_ID]: {
        SUCCESS: (data, { userId }) => data.filter(user => user.id !== userId)
      }
    },
    []
  )
});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import MachinesDetail from './MachinesDetail';
import { loadMachine } from '../../../redux/machines/actions';
import { getSelectedMachine } from '../../../redux/machines/selectors';

const ConnectedMachinesDetail = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getSelectedMachine);
  const { id } = useParams();

  const getData = () => {
    dispatch(loadMachine(id));
  };

  useEffect(getData, [dispatch]);

  return <MachinesDetail loadingState={loadingState} reloadAction={getData} data={data} />;
};

export default ConnectedMachinesDetail;

import React from 'react';
import T from 'prop-types';

import UserTable from './UserTable';

const UserTableSelector = ({ rows, columns }) => <UserTable columns={columns} rows={rows} />;

UserTableSelector.propTypes = {
  columns: T.arrayOf(T.shape({})).isRequired,
  rows: T.arrayOf(T.shape({})).isRequired
};

export default UserTableSelector;

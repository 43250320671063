/* eslint-disable no-param-reassign */
import { eventChannel } from 'redux-saga';
import { getWebsocketToken } from '../../api/stream';
import retryOnError from './retry';
import * as features from '../../helpers/features';

function consoleLog(...args) {
  if (features.get('is_debug')) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

const openStreams = {};

const urlWithToken = (url, token) => {
  if (url.includes('?')) {
    return `${url}&token=${token}`;
  }

  return `${url}?token=${token}`;
};

export function cleanupOpenWebsocketConnections() {
  Object.keys(openStreams).forEach(endpoint => {
    const stream = openStreams[endpoint];

    if (stream.socket) {
      stream.socket.close();
    }
    if (stream.channel) {
      stream.channel.close();
    }

    delete openStreams[endpoint];
  });
}

async function reloadWebsocketToken() {
  if (localStorage.getItem('loadingWebsocketTokenLock')) {
    consoleLog('Token reload already in progress. Skipping.');
    return;
  }

  consoleLog('Reloading WebSocket token...');
  localStorage.setItem('loadingWebsocketTokenLock', true);

  try {
    const { data } = await getWebsocketToken();
    localStorage.setItem('websocketToken', data.token);
    consoleLog('WebSocket token reloaded successfully:', data.token);
  } catch (err) {
    consoleLog('Error reloading WebSocket token:', err.message);
  } finally {
    localStorage.setItem('loadingWebsocketTokenLock', false);
  }
}

export async function createWebSocketConnection(url) {
  const token = localStorage.getItem('websocketToken');

  return new Promise((resolve, reject) => {
    const socket = new WebSocket(urlWithToken(url, token));

    const onOpen = () => {
      resolve(socket);
    };

    const onClose = event => {
      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
      if (event.code > 1005) {
        consoleLog(`WebSocket closed with code ${event.code}. Reason: ${event.reason}`);
        reject(event);
      }
    };

    socket.addEventListener('open', onOpen);
    socket.addEventListener('close', onClose);
    socket.addEventListener('error', event => {
      consoleLog('WebSocket error:', event.message);
      socket.close();
    });
  });
}

async function createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage, retryAttempt = 0) {
  try {
    openStreams[endpoint].socket = await retryOnError(createWebSocketConnection, endpoint);

    if (openStreams[endpoint].socket) {
      consoleLog(`WebSocket connection for ${endpoint} opened.`);
      openStreams[endpoint].socket.addEventListener('message', onMessage);
      openStreams[endpoint].socket.addEventListener('close', async event => {
        consoleLog(`WebSocket connection for ${endpoint} closed with event code: ${event.code}`);
        if (event.code > 1005) {
          consoleLog('Attempting to reload token and reconnect...');
          await reloadWebsocketToken();
          // Exponential backoff, max 30s
          const delay = Math.min(1000 * 2 ** retryAttempt, 30000);
          setTimeout(() => {
            createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage, retryAttempt + 1);
          }, delay);
        }
      });
    }
  } catch (error) {
    consoleLog(`Failed to create WebSocket connection for ${endpoint}: ${error.message}`);
    const delay = Math.min(1000 * 2 ** retryAttempt, 30000);
    setTimeout(() => {
      createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage, retryAttempt + 1);
    }, delay);
  }
}

export async function createSocketChannel(endpoint) {
  if (openStreams[endpoint]) {
    return openStreams[endpoint].channel;
  }

  openStreams[endpoint] = {};
  openStreams[endpoint].channel = eventChannel(emit => {
    const onMessage = event => emit(event.data);

    createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage);

    return () => {
      if (openStreams[endpoint].socket) {
        openStreams[endpoint].socket.removeEventListener('message', onMessage);
      }
    };
  });

  return openStreams[endpoint].channel;
}

import React from 'react';
import T from 'prop-types';
import { AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { AXIS_LINE_COLOR } from 'attrs/colors';
import { calcYAxisWidth, getYAxisDomainBySensor } from 'components/Graphs/graphUtils';

const AuxiliaryAreaChart = ({ children, sensors, chartData, height, showAxisValues }) => {
  const xAxisProps = showAxisValues
    ? {
        stroke: AXIS_LINE_COLOR
      }
    : { hide: true };
  const yAxisStroke = showAxisValues ? AXIS_LINE_COLOR : 'transparent';
  const firstYAxisWidth = sensors[0]
    ? calcYAxisWidth(chartData, sensors[0].type ? sensors[0].type : 40, sensors[0] ? sensors[0].visualization : {})
    : 40;

  return (
    <ResponsiveContainer height={height}>
      <AreaChart>
        {children}
        <XAxis dataKey="time" type="number" {...xAxisProps} />
        <YAxis
          key="machine_state"
          yAxisId="machine_state"
          domain={[0, 1]}
          stroke={yAxisStroke}
          tick={false}
          tickLine={false}
          width={firstYAxisWidth}
        />
        {/* fake yaxis for every sensor to keep same width */}
        {sensors.map(
          (sensor, key) =>
            key !== 0 && (
              <YAxis
                key={`${sensor.type}-fake`}
                yAxisId={key}
                stroke={showAxisValues && key === 1 ? AXIS_LINE_COLOR : 'transparent'}
                tickLine={false}
                tick={false}
                width={calcYAxisWidth(chartData, sensors[key].type, sensors[key].visualization)}
                orientation="right"
                domain={getYAxisDomainBySensor(sensor)}
                type="number"
              />
            )
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

AuxiliaryAreaChart.propTypes = {
  children: T.node.isRequired,
  sensors: T.arrayOf(
    T.shape({
      type: T.string,
      visualization: T.shape({})
    })
  ).isRequired,
  xAxisDomain: T.arrayOf(T.number).isRequired,
  xAxisTicks: T.arrayOf(T.number).isRequired,
  chartData: T.arrayOf(T.shape({})).isRequired,
  height: T.number,
  showAxisValues: T.bool
};

AuxiliaryAreaChart.defaultProps = {
  height: 12,
  showAxisValues: false
};

export default AuxiliaryAreaChart;

import { Box, Step, Stepper } from '@mui/material';
import { CustomStepLabel } from '../AddNewTemplate.Styled';
import { StepIconComponent } from '../../StepIconComponent';

interface StepsProps {
  activeStep: number;
  steps: string[];
}

interface StepIconComponentProps {
  active: boolean;
  completed: boolean;
  error: boolean;
  icon: number;
}

const Steps = ({ activeStep, steps }: StepsProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: '42px auto'
    }}
  >
    <Stepper activeStep={activeStep} sx={{ width: '200px' }}>
      {steps.map((label, index) => (
        <Step key={label}>
          <CustomStepLabel
            active={index === activeStep}
            completed={index < activeStep}
            StepIconComponent={(props: StepIconComponentProps) => StepIconComponent({ ...props, icon: index + 1 })}
          />
        </Step>
      ))}
    </Stepper>
  </Box>
);

export { Steps };

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import SensorHistory from './SensorHistory';
import {
  loadLastSensorsMetric,
  loadSensor,
  resetMachineState,
  subscribeToSensorsUpdateStream
} from '../../../../redux/machines/actions';
import { getSelectedSensor, getSelectedSensorLastMetric } from '../../../../redux/machines/selectors';
import { getCommonLoadingState, getMetricsTimeFrame, isSuccess } from '../../../../helpers/utils';
import { getLoadingState } from '../../../../redux/rootSelectors';
import { METRICS_DEFAULT_TIME_FRAME_IN_HOURS } from '../../../../attrs/layout';

const ConnectedSensorHistory = () => {
  const dispatch = useDispatch();
  const { id, sensorType } = useParams();
  const loadingState = useSelector(compose(getLoadingState, getSelectedSensor));
  const lastMetricLoadingState = useSelector(compose(getLoadingState, getSelectedSensorLastMetric));
  const { loadingState: wsLoadingState } = useSelector(state => state.stream);

  const loadNeededData = useCallback(() => {
    dispatch(dispatch(loadLastSensorsMetric(id, sensorType)));
    dispatch(
      loadSensor(
        id,
        sensorType,
        getMetricsTimeFrame(METRICS_DEFAULT_TIME_FRAME_IN_HOURS),
        METRICS_DEFAULT_TIME_FRAME_IN_HOURS
      )
    );
  }, [dispatch, id, sensorType]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  useEffect(() => {
    if (isSuccess(wsLoadingState.status)) {
      dispatch(subscribeToSensorsUpdateStream(id));
    }
  }, [dispatch, id, wsLoadingState]);

  useEffect(() => () => dispatch(resetMachineState()), [dispatch]);

  return (
    <SensorHistory
      loadingState={getCommonLoadingState([loadingState, lastMetricLoadingState])}
      reloadAction={loadNeededData}
    />
  );
};

export default ConnectedSensorHistory;

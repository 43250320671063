const LOAD_ALERTS = 'ALERTS/GET_ALERTS';
const MARK_ALERT_AS_READ = 'MARK_ALERT_AS_READ';
const MARK_ALL_ALERTS_AS_READ = 'MARK_ALL_ALERTS_AS_READ';
const SUBSCRIBE_TO_ALERTS_STREAM = 'ALERTS/SUBSCRIBE_TO_ALERTS_STREAM';

const AlertTypeMetric = 'METRIC';
const AlertTypeMachineStatus = 'MACHINE_STATUS';

export {
  LOAD_ALERTS,
  MARK_ALERT_AS_READ,
  MARK_ALL_ALERTS_AS_READ,
  SUBSCRIBE_TO_ALERTS_STREAM,
  AlertTypeMetric,
  AlertTypeMachineStatus
};

import styled from 'styled-components';
import { NotifyTypo } from 'web-components';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${({ isMobile }) => (isMobile ? '1rem' : '5.5rem')};
  padding: ${({ isSmallDevice }) => (isSmallDevice ? '0.5rem 0' : '0')};
`;

export const ItemsCountLabel = styled(NotifyTypo.InfoText)`
  padding-right: 3rem;
`;

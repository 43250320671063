import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import { LOAD_ALERTS, MARK_ALERT_AS_READ, MARK_ALL_ALERTS_AS_READ } from './constants';

const markAlertsAsRead = (alerts, payloadAlert) => alerts.filter(alert => alert.id !== payloadAlert.id);

const removeAllAlerts = (alerts, payloadsAlert) => alerts.filter(alert => !payloadsAlert.includes(alert));

export default combineReducers({
  loadingState: loadingStateReducer(LOAD_ALERTS),
  data: typeToReducer(
    {
      [LOAD_ALERTS]: {
        SUCCESS: (data, { payload }) => payload
      },
      [MARK_ALERT_AS_READ]: (alerts, { payload }) => markAlertsAsRead(alerts, payload),
      [MARK_ALL_ALERTS_AS_READ]: (alerts, { payload }) => removeAllAlerts(alerts, payload)
    },
    []
  )
});

import axios from './axios';

const BATCH_REPORT_EXPORT_URL = '/data-exports/batch';

const putBatchReportExports = (batchId, req) =>
  axios({
    method: 'put',
    url: `/v1${BATCH_REPORT_EXPORT_URL}/${batchId}`,
    data: { ...req }
  });

export { putBatchReportExports };

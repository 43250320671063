import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { externalLogin } from 'redux/auth/actions';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function ExternalAuth() {
  const dispatch = useDispatch();
  const { tokenHeader, tokenPayload, tokenSignature, refreshTokenHeader, refreshTokenPayload, refreshTokenSignature } =
    useParams();

  const accessToken =
    tokenHeader && tokenPayload && tokenSignature ? `${tokenHeader}.${tokenPayload}.${tokenSignature}` : '';

  const refreshToken =
    refreshTokenHeader && refreshTokenPayload && refreshTokenSignature
      ? `${refreshTokenHeader}.${refreshTokenPayload}.${refreshTokenSignature}`
      : '';

  const authorization = {
    access: accessToken,
    refresh: refreshToken
  };

  dispatch(externalLogin(authorization));

  return (
    <Backdrop sx={{ color: '#2BACA4', zIndex: theme => theme.zIndex.drawer + 1 }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

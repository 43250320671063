const getUi = state => state.ui;
const getUser = state => state.user;
const getAuth = state => state.auth;
const getRouter = state => state.router;
const getAlerts = state => state.alerts;
const getMachines = state => state.machines;
const getUsers = state => state.users;
const getStream = state => state.stream;
const getSupport = state => state.support;
const getBatch = state => state.batch;
const getBatchNotes = state => state.batchNotes;
const getDataExport = state => state.dataExports;
const getReportExport = state => state.reportExport;
const getAlarmsHistory = state => state.alarmsHistory;
const getCustomSensor = state => state.customSensor;
const getAlertHistory = state => state.alertsHistory;
const getMetricsHistoryChartData = state => state.metricsHistoryChartData;
const getNewTemplate = state => state.newTemplate;

const getMetricsMachinesReducerState = state => state.metricsMachines;
const getGraphsTemplatesReducerState = state => state.graphTemplates;
const getFilters = state => state.filters;

/* universal */
const getLoadingState = state => state.loadingState;
const getData = state => state.data;
const getStatus = state => state.status;

export {
  getUi,
  getUser,
  getLoadingState,
  getData,
  getStatus,
  getRouter,
  getAuth,
  getAlerts,
  getMachines,
  getUsers,
  getStream,
  getSupport,
  getBatch,
  getBatchNotes,
  getDataExport,
  getReportExport,
  getAlarmsHistory,
  getCustomSensor,
  getAlertHistory,
  getMetricsHistoryChartData,
  getMetricsMachinesReducerState,
  getNewTemplate,
  getGraphsTemplatesReducerState,
  getFilters
};

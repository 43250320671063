import styled, { css } from 'styled-components';

export const QrReaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 0;

  & > section {
    width: 100%;
    max-width: ${({ mobile }) => (mobile ? '300px' : '350px')};
  }
`;

export const ContentMarginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ mobile }) =>
    mobile &&
    css`
      margin: 0 20px;
      & > .MuiButton-root {
        max-width: 300px;
        height: 48px;
      }
    `}
`;

/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';

import MachineNotificationSettings from './MachineNotificationSettings';
import { loadUsers } from '../../../../redux/users/actions';
import { getUsers } from '../../../../redux/rootSelectors';
import { getActiveUsersAsOptions, getCommonLoadingState } from '../../../../helpers/utils';
import { getMachineNotificationRules, getSelectedMachineNotificationRule } from '../../../../redux/machines/selectors';
import { loadMachineNotificationRules } from '../../../../redux/machines/actions';

const ConnectedMachineNotificationSettings = ({ machine, isMobile }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getUsers);
  const {
    loadingState: notificationRuleLoadingState,
    updateLoadingState: notificationUpdateState,
    deleteLoadingState: notificationDeleteState
  } = useSelector(getMachineNotificationRules);

  const notificationRules = useSelector(getSelectedMachineNotificationRule);

  const loadNeededData = useCallback(() => {
    dispatch(loadUsers());
    dispatch(loadMachineNotificationRules(machine.id));
  }, [dispatch, machine.id]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  // For machines, only one notification rule can exist
  const notifyRule = notificationRules.length > 0 ? notificationRules[0] : null;

  return (
    <MachineNotificationSettings
      loadingState={getCommonLoadingState([loadingState, notificationRuleLoadingState])}
      reloadAction={loadNeededData}
      users={getActiveUsersAsOptions(data)}
      notificationRule={notifyRule}
      notificationUpdateState={notificationUpdateState}
      notificationDeleteState={notificationDeleteState}
      machine={machine}
      isMobile={isMobile}
    />
  );
};

ConnectedMachineNotificationSettings.propTypes = {
  machine: T.shape({ name: T.string, id: T.string }).isRequired,
  isMobile: T.bool
};
ConnectedMachineNotificationSettings.defaultProps = {
  isMobile: false
};

export default ConnectedMachineNotificationSettings;

const ROLE_MANAGER = 'urn:roles:manager';
const ROLE_OPERATOR = 'urn:roles:operator';

const ROLES = [
  {
    value: ROLE_OPERATOR,
    label: 'profile.role.operator'
  },
  {
    value: ROLE_MANAGER,
    label: 'profile.role.manager'
  }
];

export { ROLE_MANAGER, ROLE_OPERATOR, ROLES };

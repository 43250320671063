import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { DataTable, useResolutionCheck } from 'web-components';

import { ROUTE_MACHINES } from 'attrs/routePaths';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import MobileView from './MobileView';
import Footer from '../DataTable/Footer';
import { COLUMN_COMMISSION_NUMBER, generateColumns } from './MachinesOverview.config';
import { StyledContainer } from './MachinesOverview.styled';
import { showMachineEditModal } from '../../redux/machines/actions';

const MachinesOverview = ({ rows }) => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentSort, setCurrentSort] = useState({
    colId: COLUMN_COMMISSION_NUMBER,
    order: DataTable.ORDER_ASC
  });
  const { isMobile } = useResolutionCheck();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const rowsPerPage = 10;

  useEffect(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;

    setCurrentView(
      DataTable.sortFn(rows.slice(startIndex, startIndex + rowsPerPage), currentSort.colId, currentSort.order)
    );
  }, [rows, currentSort, currentPage, rowsPerPage]);

  const handleChangePage = page => setCurrentPage(page);

  const handleSort = (colId, order) => {
    setCurrentSort({ colId, order });
    setCurrentView(DataTable.sortFn(currentView, colId, order));
  };

  const handleSearch = value => {
    setSearchValue(value);
  };

  const handleSelectRow = row => {
    dispatch(showMachineEditModal(false));
    history.push(`/${ROUTE_MACHINES}/${row.id}`);
  };

  const handleEditRow = row => {
    dispatch(showMachineEditModal(true));
    history.push(`/${ROUTE_MACHINES}/${row.id}`);
  };

  const props = {
    columns: generateColumns(t, handleEditRow),
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={Math.ceil(rows.length / rowsPerPage) || 1}
        totalItems={rowsPerPage || 0}
        onChange={handleChangePage}
      />
    ),
    defaultSort: currentSort,
    sortFn: handleSort,
    onClick: handleSelectRow,
    onEdit: handleEditRow,
    dataSelectorTable: 'machine-table',
    dataSelectorRows: 'machine-table-row'
  };

  const search = {
    searchValue,
    handleSearch
  };

  return (
    <>
      <StyledContainer maxWidth={false} theme={theme}>
        {isMobile ? <MobileView {...props} search={search} /> : <DataTable hover {...props} />}
      </StyledContainer>
    </>
  );
};

MachinesOverview.propTypes = {
  rows: T.arrayOf(T.shape({})).isRequired
};

export default MachinesOverview;

import * as constants from './constants';

const showDataExportModal = show => ({
  type: constants.DATA_EXPORT_SHOW_MODAL,
  value: show
});

const putRequestMachineDataExport = (machineId, req) => ({
  type: constants.DATA_EXPORT_SEND_REQUEST,
  req: { machineId, ...req },
  action: constants.DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES
});

export { showDataExportModal, putRequestMachineDataExport };

import styled from 'styled-components';
import { Tab as TabItem } from '@mui/material';
import { PageHeader as Header, NotifyTypo, PageHeaderTabs as Tabs } from 'web-components';

export const ContainerTitle = styled.div`
  display: flex;
  @media (max-width: 425px) {
    align-items: center;
  }
`;

export const PageHeader = styled(Header)`
  padding: 4rem 5rem 20px 5rem;

  &.MuiPaper-root {
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      flex-wrap: wrap;
      padding: 5px 10px;
    }
  }

  &.MuiPaper-elevation1 {
    &.withoutShadow {
      box-shadow: none;
    }
  }
  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 768px) {
    padding: 4rem 3rem 20px 3rem;
  }
`;

export const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;
export const PageHeaderAction = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  @media (max-width: 425px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Tab = styled(TabItem)`
  &.MuiTab-root {
    color: #23252f;
    text-transform: none;
    @media (max-width: 425px) {
      padding: 0.5rem 1rem;
    }
  }
`;

export const PageHeaderTabs = styled(Tabs)`
  &.MuiPaper-root {
    padding-left: 5rem;
    @media (max-width: 768px) {
      padding-left: 3rem;
    }
    @media (max-width: 425px) {
      padding-left: 0;
    }
  }
`;

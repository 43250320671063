import React from 'react';
import { Container } from '@mui/material';
import { PageWrapper, FormContainer } from './elements';
import Logo from '../../../attrs/logo';
import LoginForm from '../../../components/forms/Auth/LoginForm';

const SignIn = () => (
  <PageWrapper>
    <Container maxWidth="sm">
      <FormContainer>
        <Logo height="65px" style={{ marginBottom: '1.5rem' }} />
        <LoginForm />
      </FormContainer>
    </Container>
  </PageWrapper>
);

export default SignIn;

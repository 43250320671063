import styled, { css } from 'styled-components';
import { Card } from '@mui/material';
import { NotifyTypo } from 'web-components';
import {
  COLOR_MAIN_HIGHLIGHT_TEXT,
  COLOR_PRIMARY,
  COMPARISON_CARD_BORDER,
  COMPARISON_CARD_SELECTED_LIGHT,
  COMPARISON_PLACEHOLDER_CHARD_BORDER,
  LIGHT_GREY_OPAQUE
} from '../../attrs/colors';

const { Body1 } = NotifyTypo;

const StyledSelectableComparisonCard = styled(Card)`
  &.MuiPaper-root {
    padding: 10px 0.8rem;
    display: flex;
    align-items: center;
    min-height: 76px;
    border: 1px solid ${COMPARISON_CARD_BORDER};
    cursor: pointer;
    margin-bottom: 1rem;
    box-shadow: 0 4px 4px rgba(54, 125, 120, 0.25);
    border-radius: 4px;

    ${props =>
      props.selected &&
      css`
        border: 1px solid ${COMPARISON_CARD_SELECTED_LIGHT};
      `}
    ${props =>
      props.disabled &&
      css`
        opacity: 0.5;
      `}
    & > .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }
`;

const StyledSelectedComparisonCard = styled(Card)`
  &.MuiPaper-root {
    padding: 10px 0.8rem;
    display: flex;
    align-items: center;
    min-height: 76px;
    border: 2px solid ${props => props.color};
    justify-content: center;
    ${props =>
      props.placeholder === 'true' &&
      css`
        border: 1px solid ${COMPARISON_PLACEHOLDER_CHARD_BORDER};
      `}
    ${props =>
      props.placeholdermenu === 'true' &&
      css`
        border: 1px dashed ${COLOR_PRIMARY};
      `} ${props =>
      props.clickable === 'true' &&
      css`
        cursor: pointer;

        &:hover {
          background: ${LIGHT_GREY_OPAQUE};
        }
      `};
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 0.5rem;
      justify-content: center;
      text-align: left;
      height: 100px;
      ${props =>
        props.placeholdermenu === 'true' &&
        css`
          padding: 10px 0.8rem;
        `}
    }
    @media (max-width: 500px) {
      padding: 10px 0.8rem;
      align-items: center;
      text-align: center;
      min-height: 76px;
      width: 100%;
    }
  }
`;

const StyledCard = styled(Card)`
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
  &.MuiPaper-root {
    padding: 1rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 70px;
    box-shadow: 0 4px 4px rgba(54, 125, 120, 0.25);
    border-radius: 10px;
    ${props =>
      !props.is_static &&
      css`
        background: linear-gradient(90deg, ${COLOR_PRIMARY} 5px, ${COLOR_MAIN_HIGHLIGHT_TEXT} 5px);
      `}
  }

  &:hover {
    box-shadow: rgba(86, 93, 100, 0.2) 0 0.5rem 2.5rem 0;
  }
}
`;

const StyledSensorName = styled(Body1)`
  display: flex;
  align-items: center;
`;

const SensorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-left: 8px;
  ${props =>
    props.withWrap &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

const ContentSensorCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const WrapperContentSensorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export {
  StyledCard,
  StyledSensorName,
  SensorWrapper,
  StyledSelectableComparisonCard,
  StyledSelectedComparisonCard,
  ContentSensorCard,
  WrapperContentSensorCard
};

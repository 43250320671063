import React from 'react';
import { Toolbar, useMediaQuery } from '@mui/material';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NotifyTypo } from 'web-components';
import PageTitle from './PageTitle';
import SubNavigation from './SubNavigation';
import { ButtonWrapper, StyledAvatar, ContainerAvatar } from './elements';
import { StyledAppBar, AppBarContentWrapper } from '../../elements';
import ConnectedNavigationItems from './NavigationItems';
import LogoLink from './LogoLink';
import Alerts from './Alerts';
import { getInitials } from '../../helpers/utils';

const Navbar = ({ routes, hasBack, userName }) => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { t } = useTranslation();

  return (
    <StyledAppBar position="fixed">
      <Toolbar variant="dense">
        <LogoLink hasBack={hasBack} />
        <AppBarContentWrapper>
          <PageTitle />
          <ConnectedNavigationItems routes={routes} />
        </AppBarContentWrapper>
        <ButtonWrapper>
          {isDesktop && userName && (
            <ContainerAvatar>
              <NotifyTypo.Heading2Regular style={{ fontSize: '1rem' }}>{t('hello')}</NotifyTypo.Heading2Regular>
              <StyledAvatar>{getInitials(userName)}</StyledAvatar>
            </ContainerAvatar>
          )}
          <Alerts />
          <SubNavigation routes={routes} />
        </ButtonWrapper>
      </Toolbar>
    </StyledAppBar>
  );
};

Navbar.defaultProps = {
  hasBack: true,
  userName: ''
};

Navbar.propTypes = {
  routes: T.arrayOf(T.shape({})).isRequired,
  hasBack: T.bool,
  userName: T.string
};

export default Navbar;

import styled, { css } from 'styled-components';
import {
  COLOR_MAIN_HIGHLIGHT_TEXT,
  COLOR_MAIN_HIGHLIGHT,
  DARKER_GREY,
  LIGHTER_GREY,
  SENSOR_NODATA_COLOR
} from '../../attrs/colors';
import WarningStatusChip from '../elements/WarningStatusChip';
import { BRUSH_HEIGHT, BRUSH_PADDING, GRAPH_HEIGHT } from '../../attrs/layout';

const ChartWrapper = styled.div`
  width: 100%;
  height: ${GRAPH_HEIGHT}px;
  margin-top: 1.75rem;
  position: relative;
  ${props =>
    props.mobile &&
    css`
      height: 250px;
    `}
  & .recharts-tooltip-wrapper {
    z-index: 1;
  }
`;

const StyledWarningStatusChip = styled(WarningStatusChip)`
  margin-left: 6px;
`;

const TooltipWrapper = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.7rem 0.7rem;
  background: ${COLOR_MAIN_HIGHLIGHT};
  color: ${COLOR_MAIN_HIGHLIGHT_TEXT};
  opacity: 0.9;
  > .statusWrapper {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  > .sensorTime {
    margin-top: 1rem;
  }
  & div > .sensorName {
    font-weight: 400;
    font-size: 0.8rem;
  }
  & div > .sensorValue {
    font-weight: 500;
    font-size: 1rem;
  }
`;

const TooltipElementBackground = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 12px;
  height: 12px;
  position: absolute;
  left: 2px;
  top: 2px;
`;

const TooltipElement = styled.div`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  ${props =>
    props.sensorLegend &&
    css`
      border-radius: 6px;
      width: 12px;
      height: 12px;
    `}
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

const ArtificalXAxis = styled.div`
  margin-top: -33px;
  margin-left: -5px;
`;

const StyledBrushArea = styled.div`
  height: calc(${BRUSH_HEIGHT}px + ${BRUSH_PADDING}px);

  .cgmirI.MuiCard-root {
    padding: 0 2rem 15rem 2rem;
  }

  .recharts-surface {
    height: 140px;
  }

  .iKuJub {
    height: 120px;
  }

  .recharts-brush {
    transform: translate(0, -20px);
    > rect {
      stroke: ${LIGHTER_GREY};
    }
  }
  .recharts-brush-traveller {
    > rect {
      fill: ${SENSOR_NODATA_COLOR};
    }
  }
  .recharts-brush-texts {
    > text {
      fill: ${DARKER_GREY};
      font-size: 0.5rem;
      :first-child {
        text-anchor: end;
        transform: translate(5px, -${BRUSH_HEIGHT / 2 + 10}px);
      }
      transform: translate(5px, -${BRUSH_HEIGHT / 2 + 10}px);
      text-anchor: start;
    }
  }
  .recharts-brush-slide {
    fill-opacity: 0.1;
  }

  .recharts-xAxis {
    transform: translate(0, 70px);
  }

  .recharts-cartesian-axis-ticks {
    transform: translate(-40px, 0px);
  }
`;

export {
  TooltipWrapper,
  ChartWrapper,
  TooltipElement,
  TooltipElementBackground,
  StyledBrushArea,
  StyledWarningStatusChip,
  ArtificalXAxis
};

import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import {
  BATCH_REPORT_EXPORT_RESET,
  BATCH_REPORT_EXPORT_SEND_REQUEST,
  BATCH_REPORT_EXPORT_SHOW_MODAL,
  initialReportExportValues
} from './constants';
import { createReducer, enhanceWithResetActions, loadingStateReducer } from '../utils';

export default combineReducers({
  dataBatchReportExportState: enhanceWithResetActions([BATCH_REPORT_EXPORT_SHOW_MODAL, BATCH_REPORT_EXPORT_RESET])(
    loadingStateReducer([BATCH_REPORT_EXPORT_SHOW_MODAL, BATCH_REPORT_EXPORT_SEND_REQUEST])
  ),
  reportExport: typeToReducer(
    {
      [BATCH_REPORT_EXPORT_SEND_REQUEST]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    { ...initialReportExportValues }
  ),
  isOpen: createReducer(
    {
      [BATCH_REPORT_EXPORT_SHOW_MODAL]: (state, { value }) => value
    },
    false
  )
});

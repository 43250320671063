import React, { useState } from 'react';
import T from 'prop-types';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

import { Dialog, NotifyIcon } from 'web-components';

import { COLOR_PRIMARY } from 'attrs/colors';
import {
  ActionWrapper,
  SelectedSensorsWrapper,
  StyledDialogBody,
  StyledSelectableSensorsContainer
} from './SensorComparisonDialog.styled';
import SelectedSensors from './SelectedSensors';
import SelectableSensors from './SelectableSensors';

const SensorComparisonDialog = ({ className, sensors, selectedSensors, onChange, modify, minSelection }) => {
  const { t } = useTranslation();
  const [selectedSensorTypes, setSelectedSensorTypes] = useState(selectedSensors);
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();

  const sensorsWithTranslatedNames = sensors.map(item => ({ ...item, name: t(item.name) }));
  const sensorsSortedByName = orderBy(sensorsWithTranslatedNames, item => item.name, 'asc');

  const handleClick = (event, type) => {
    if (event) {
      event.stopPropagation();
    }

    const newSelectedSensorTypes = selectedSensorTypes.includes(type)
      ? selectedSensorTypes.filter(sensor => sensor !== type)
      : [...selectedSensorTypes, type];

    setSelectedSensorTypes(newSelectedSensorTypes);
  };

  const handleDeselectAll = () => {
    setSelectedSensorTypes([]);
  };

  const handleCompare = () => {
    setIsOpened(false);
    onChange(selectedSensorTypes);
  };

  return (
    <div className={className}>
      <Button
        variant="outlined"
        color={modify ? 'default' : 'primary'}
        onClick={() => setIsOpened(true)}
        startIcon={<NotifyIcon iconName={modify ? 'settings' : 'compare'} />}
      >
        {modify ? t('general.graphs.modify_sensor_selection') : t('general.graphs.compare_action')}
      </Button>
      <Dialog open={isOpened} fullScreen>
        <Dialog.Header highlightColor={COLOR_PRIMARY} onClose={() => setIsOpened(false)}>
          {t('general.graphs.compare_sensors_title')}
        </Dialog.Header>
        <Dialog.SubHeader withDivider={false}>{t('general.graphs.compare_sensors_subtitle')}</Dialog.SubHeader>

        <StyledDialogBody>
          <SelectedSensorsWrapper>
            <SelectedSensors
              sensors={sensorsSortedByName}
              selectedSensorTypes={selectedSensorTypes}
              onCancelClick={handleClick}
            />
          </SelectedSensorsWrapper>
          <StyledSelectableSensorsContainer>
            <SelectableSensors
              sensors={sensorsSortedByName}
              selectedSensorTypes={selectedSensorTypes}
              onSelect={handleClick}
            />
          </StyledSelectableSensorsContainer>
        </StyledDialogBody>

        <Dialog.Actions>
          <ActionWrapper theme={theme}>
            <Button
              disabled={selectedSensorTypes.length < 1}
              onClick={handleDeselectAll}
              variant={selectedSensorTypes.length < 1 ? 'contained' : 'text'}
              color="default"
            >
              {t('general.graphs.compare_deselect_all_sensors_button')}
            </Button>
            <Button
              disabled={selectedSensorTypes.length < minSelection}
              variant="contained"
              color="primary"
              onClick={handleCompare}
            >
              {t('general.graphs.compare_sensors_compare_button')}
            </Button>
          </ActionWrapper>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
};

SensorComparisonDialog.propTypes = {
  className: T.string,
  modify: T.bool,
  sensors: T.arrayOf(T.shape({})).isRequired,
  selectedSensors: T.arrayOf(T.string).isRequired,
  minSelection: T.oneOf([1, 2]),
  onChange: T.func.isRequired
};

SensorComparisonDialog.defaultProps = {
  className: '',
  modify: false,
  minSelection: 2
};

export default SensorComparisonDialog;

import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';

import { useResolutionCheck } from 'web-components';

import { getData } from 'redux/rootSelectors';
import { getSelectedMachineSensorComparisonSensorData } from 'redux/machines/selectors';
import withLoadingState from 'helpers/withLoadingState';
import MultipleSensorsBrush from 'components/Graphs/multipleSensors/MultipleSensorsBrush';
import { useSensorComparisonConfig } from '../sensorComparisonBlock.hooks';

import { BrushWrapper, GraphWrapper, StyledEmptyState } from './SensorComparisonGraph.styled';
import BatchMultipleSensorsGraph from './MultipleSensorsGraph';
import { getSelectedBatchRecord } from '../../../../redux/batch/selectors';

const BatchSensorComparisonGraph = () => {
  const { isMobile } = useResolutionCheck();
  const { machineId } = useSensorComparisonConfig();
  const data = useSelector(compose(getData, getSelectedMachineSensorComparisonSensorData));
  const batch = useSelector(getSelectedBatchRecord);
  const hasData = (data.sensors || []).length > 0;

  if (!hasData) {
    return <StyledEmptyState type="sensorHistoricalData" />;
  }

  return (
    <>
      <GraphWrapper mobile={isMobile}>
        <BatchMultipleSensorsGraph />
      </GraphWrapper>
      <BrushWrapper mobile={isMobile}>
        <MultipleSensorsBrush isBatchRecords batchId={batch.details.id} machineId={machineId} />
      </BrushWrapper>
    </>
  );
};

export default withLoadingState(BatchSensorComparisonGraph);

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { createReducer, loadingStateReducer } from '../utils';

import { CHANGE_ALARMS_ORDER, LOAD_ALARMS, LOAD_ALARMS_WITH_FILTER } from './constants';
import { ALARM_HISTORY_DEFAULT_LIST_SORT, LIST_ORDER } from '../../attrs/sorter_default_values';

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_ALARMS]),
  data: typeToReducer(
    {
      [LOAD_ALARMS]: {
        SUCCESS: (state, { payload }) => payload
      },
      [LOAD_ALARMS_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  alarmSortOrder: createReducer(
    {
      [CHANGE_ALARMS_ORDER]: (alarmSortOrder, { sort, order }) => ({
        sort,
        order
      })
    },
    {
      sort: ALARM_HISTORY_DEFAULT_LIST_SORT,
      order: LIST_ORDER
    }
  )
});

import { SHOW_SUPPORT, SHOW_SALESFORCE_MODAL } from './constants';

const showSupport = value => ({
  type: SHOW_SUPPORT,
  value
});

const showSalesforceModal = value => ({
  type: SHOW_SALESFORCE_MODAL,
  value
});

export { showSupport, showSalesforceModal };

import React from 'react';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import T from 'prop-types';
import {
  StyledButtonAddNote,
  StyledButtonAddNoteMobile,
  StyledCarousel,
  StyledHeadingWrapper,
  StyledPaper
} from './batchNotes.styled';
import BatchNote from './batchNote/BatchNote';
import CreateBatchNoteDialog from './createBatchNoteDialog';
import { setConfirmationRequest } from '../../../redux/ui/confirmations/actions';
import {
  createBatchNoteAction,
  deleteBatchNotes,
  showBatchNotesModal,
  updateBatchNoteAction
} from '../../../redux/batch_notes/actions';
import { getShowBatchNotesModal } from '../../../redux/batch_notes/selectors';
import withLoadingState from '../../../helpers/withLoadingState';
import { BatchNoteResponse } from '../../../helpers/propTypes';

const BatchNotes = ({ data, isMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams(); // Get batchId from url params

  /**
   * Handle create batch notes
   * @returns {(function(): void)|*}
   * @param values
   */
  const handleSubmitBatchNotes = values => {
    if (values.id !== '') {
      dispatch(updateBatchNoteAction(values));
    } else {
      dispatch(createBatchNoteAction(id, values.note, values.title));
    }
  };

  const responsive = {
    desktop_bigger: { breakpoint: { max: 5000, min: 2500 }, items: 5, slidesToSlide: 4 },
    desktop: { breakpoint: { max: 2500, min: 1330 }, items: 4, slidesToSlide: 3 },
    desktop_small: { breakpoint: { max: 1330, min: 1024 }, items: 3, slidesToSlide: 2 },
    tablet: { breakpoint: { max: 1024, min: 640 }, items: 2, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 640, min: 0 }, items: 1, slidesToSlide: 1 }
  };

  const isOpen = useSelector(getShowBatchNotesModal);

  // Handler for open/close modal for create new batchNote
  const [batchNote, setBatchNote] = React.useState({ id: '', title: '', note: '' });

  /**
   * Open/Close batch notes modal
   * @param open
   * @constructor
   */
  const OpenCreateNoteModal = open => {
    dispatch(showBatchNotesModal(open));
  };

  /**
   * Open an empty new batch note modal
   */
  const showCreateNoteModal = () => {
    // Reset batch note
    setBatchNote({ id: '', title: '', note: '' });
    OpenCreateNoteModal(true);
  };

  /**
   * Handler for closeing modal
   * @param values Formik return values
   * @returns {(function(): void)|*}
   * @constructor
   */
  const CloseCreateNoteModal = values => () => {
    let warning = false;
    if (batchNote) {
      if (values.note !== batchNote.note || values.title !== batchNote.title) {
        warning = true;
      }
    } else if (values.note !== '' || values.title !== '') {
      warning = true;
    }
    if (warning) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => {
            OpenCreateNoteModal(false);
          }
        })
      );
    } else {
      OpenCreateNoteModal(false);
    }
  };

  /**
   * Handler for delete batch notes
   * @param batchNoteHandler BatchResponse Type
   * @returns {(function(): void)|*}
   */
  const handleDelete = batchNoteHandler => () => {
    dispatch(
      setConfirmationRequest({
        message: 'batch_records.batch_notes.messages.delete',
        action: () => {
          dispatch(deleteBatchNotes(batchNoteHandler.id, id));
        }
      })
    );
  };

  /**
   * Handle edit note
   * @param batchNoteHandler
   * @returns {(function(): void)|*}
   */
  const handleEdit = batchNoteHandler => () => {
    setBatchNote(batchNoteHandler);
    OpenCreateNoteModal(true);
  };

  return (
    <StyledPaper>
      <StyledHeadingWrapper>
        <NotifyTypo.Caption>{t(`batch_records.batch_notes.additional_notes`)}</NotifyTypo.Caption>
        {!isMobile && (
          <StyledButtonAddNote
            variant="outlined"
            fullWidth={isMobile}
            color="primary"
            onClick={showCreateNoteModal}
            data-selector="batch-note-create-batch-note-button"
            size="medium"
            startIcon={<NotifyIcon iconName="plus" />}
          >
            {t('batch_records.batch_notes.add_notes')}
          </StyledButtonAddNote>
        )}
      </StyledHeadingWrapper>

      <StyledCarousel
        containerClass="react-multi-carousel-track"
        responsive={responsive}
        partialVisibility
        arrows
        keyBoardControl
      >
        {Array.isArray(data)
          ? data.map(bn => <BatchNote key={bn.id} batchNote={bn} handleDelete={handleDelete} handleEdit={handleEdit} />)
          : []}
      </StyledCarousel>
      <>
        {data.length === 0 && (
          <NotifyTypo.InfoText align="center" style={{ paddingTop: '2rem', paddingBottom: '4rem' }}>
            {t('batch_records.empty_batch_notes')}
          </NotifyTypo.InfoText>
        )}
        {isMobile && (
          <StyledButtonAddNoteMobile
            variant="outlined"
            fullWidth={isMobile}
            color="primary"
            onClick={showCreateNoteModal}
            data-selector="batch-note-create-batch-note-button"
            size="medium"
            startIcon={<NotifyIcon iconName="plus" />}
          >
            {t('batch_records.batch_notes.add_notes')}
          </StyledButtonAddNoteMobile>
        )}
      </>
      <CreateBatchNoteDialog
        isOpen={isOpen}
        handleCLose={CloseCreateNoteModal}
        handleFormSubmit={handleSubmitBatchNotes}
        batchNote={batchNote}
      />
    </StyledPaper>
  );
};

BatchNotes.propTypes = {
  data: T.arrayOf(T.shape(BatchNoteResponse)).isRequired,
  isMobile: T.bool
};

BatchNotes.defaultProps = {
  isMobile: false
};

export default withLoadingState(BatchNotes);

import React from 'react';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { NotifyIcon } from 'web-components';

import { setConfirmationRequest } from '../../redux/ui/confirmations/actions';
import { deleteUserById } from '../../redux/users/actions';

const Delete = ({ user }) => {
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    dispatch(
      setConfirmationRequest({
        title: 'dialog.confirmation.title_delete_user',
        subtitle: 'dialog.confirmation.subtitle_delete_user',
        message: 'dialog.confirmation.delete_user',
        delete: 'dialog.confirmation.confirm_delete',
        extraValue: user['e-mail'],
        action: () => dispatch(deleteUserById(user.id))
      })
    );
  };

  return (
    <>
      <IconButton color="primary" aria-label="delete_role" onClick={handleDeleteClick}>
        <NotifyIcon iconName="delete" style={{ fontSize: 25 }} />
      </IconButton>
    </>
  );
};

Delete.propTypes = {
  user: T.shape({ id: T.string, 'e-mail': T.string }).isRequired
};

export default Delete;

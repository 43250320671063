import React from 'react';
import T from 'prop-types';

import { NotifyIcon, useResolutionCheck } from 'web-components';

import { Grid, IconButton, useTheme } from '@mui/material';
import MobileCell from './MobileCell';
import { ContentRow, GridStyled, Wrapper } from './MobileView.styled';
import { COLUMN_EDIT, MachinesColumnProps, MachinesRowProps } from '../MachinesOverview.config';
import { getDefaultFieldValue } from '../../../helpers/utils';

const MobileRow = ({ columns, row, onClick, onEdit }) => {
  const { isSmallDevice } = useResolutionCheck();
  const theme = useTheme();

  return (
    <Wrapper theme={theme}>
      <GridStyled container>
        <Grid item xs={12} sm={11}>
          <ContentRow theme={theme} onClick={() => onClick(row)}>
            {columns
              .filter(column => column.id !== COLUMN_EDIT)
              .map(column => (
                <MobileCell
                  key={`mobile-row-${row.batchId}-cell-${column.id}`}
                  row={row}
                  id={column.id}
                  label={column.label}
                  value={getDefaultFieldValue(row[column.id])}
                  onEdit={onEdit}
                />
              ))}
          </ContentRow>
        </Grid>

        {!isSmallDevice && (
          <Grid item sm={1} style={{ margin: 'auto' }}>
            <IconButton
              style={{ float: 'right' }}
              aria-label="edit_machine_name"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                onEdit(row);
              }}
            >
              <NotifyIcon fontSize="large" iconName="edit" color="primary" />
            </IconButton>
          </Grid>
        )}
      </GridStyled>
    </Wrapper>
  );
};

MobileRow.propTypes = {
  columns: MachinesColumnProps.isRequired,
  row: MachinesRowProps.isRequired,
  onClick: T.func,
  onEdit: T.func
};

MobileRow.defaultProps = {
  onClick: () => null,
  onEdit: () => null
};

export default MobileRow;

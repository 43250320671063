import { Grid, InputAdornment } from '@mui/material';
import { FormElements, NotifyIcon } from 'web-components';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { CustomTreeView } from '../../../../../../components/CustomTreeView';

type SelectedProductLineInterface = {
  title: string;
  value: string;
};

type SelectedMachineInterface = {
  id: string;
  title: string;
  value: string;
};

type MachineInterface = {
  id: string;
  name: string;
};

type SelectedSensorInterface = {
  title: string;
  value: string;
};

type SensorInterface = {
  custom_sensor_icon?: string;
  custom_sensor_name?: string;
  is_custom: boolean;
  type: string;
};

type FilteredTreeDataInterface = {
  machines: {
    id: string;
    name: string;
    sensors: SensorInterface[];
  }[];
  name: string;
}[];

type SelectedItemsInterface = {
  machine_id: string;
  type: string;
  auto_scale: boolean;
  line_color: string;
  line_style: string;
  line_thickness: number;
  max_scale: number;
  min_scale: number;
  is_custom: boolean;
}[];

interface Step1Props {
  selectedProductLines: SelectedProductLineInterface[];
  handleProductLineChange: () => void;
  productionLinesObj: string[];
  clear: boolean;
  setClear: () => void;
  selectedMachines: SelectedMachineInterface[];
  handleMachineChange: () => void;
  machines: MachineInterface[];
  selectedSensors: SelectedSensorInterface[];
  handleSensorChange: () => void;
  sensors: SensorInterface[];
  filteredTreeData: FilteredTreeDataInterface;
  selectedItems: SelectedItemsInterface;
  handleSelectionChange: () => void;
}

const Step1 = ({
  selectedProductLines,
  handleProductLineChange,
  productionLinesObj,
  clear,
  setClear,
  selectedMachines,
  handleMachineChange,
  machines,
  selectedSensors,
  handleSensorChange,
  sensors,
  filteredTreeData,
  selectedItems,
  handleSelectionChange
}: Step1Props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormElements.MultiSelect
          activeColor="black"
          optionType="checkbox"
          label={t('dialog.add_new_template.filter_by_product_line')}
          name="product_line"
          id="product_line"
          bgcolor="#F7F7FA"
          fullWidth
          value={selectedProductLines}
          setFieldValue={handleProductLineChange}
          options={productionLinesObj.length > 0 && productionLinesObj.map(item => ({ title: item, value: item }))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
              </InputAdornment>
            )
          }}
          data-selector="dialog-add-new-template-filter-product-line-field"
          clear={clear}
          setClear={setClear}
          sx={{
            mt: { md: 1 },
            mb: { md: 1 }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormElements.MultiSelect
          activeColor="black"
          optionType="checkbox"
          label={t('dialog.add_new_template.filter_by_machine')}
          name="filter_machine"
          id="filter_machine"
          bgcolor="#F7F7FA"
          fullWidth
          multiple
          value={selectedMachines}
          setFieldValue={handleMachineChange}
          options={machines.length > 0 && machines.map(item => ({ id: item.id, title: item.name, value: item.name }))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
              </InputAdornment>
            )
          }}
          data-selector="dialog-add-new-template-filter-product-line-field"
          clear={clear}
          setClear={setClear}
          sx={{
            mt: { md: 1 },
            mb: { md: 1 }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormElements.MultiSelect
          activeColor="black"
          optionType="checkbox"
          label={t('dialog.add_new_template.filter_by_sensor')}
          name="filter_sensor"
          id="filter_sensor"
          bgcolor="#F7F7FA"
          fullWidth
          multiple
          value={selectedSensors}
          setFieldValue={handleSensorChange}
          options={
            sensors.length > 0 &&
            sensors.map(sensor => {
              const millSensor = getMillSensor(sensor?.type);
              const sensorName = t(millSensor?.name || sensor?.custom_sensor_name);
              return {
                title: sensorName,
                value: sensor.type
              };
            })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
              </InputAdornment>
            )
          }}
          data-selector="dialog-add-new-template-filter-product-line-field"
          clear={clear}
          setClear={setClear}
          sx={{
            mt: { md: 1 },
            mb: { md: 1 }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTreeView
          rawData={filteredTreeData}
          selectedItems={selectedItems}
          onSelectionChange={handleSelectionChange}
        />
      </Grid>
    </Grid>
  );
};

export { Step1 };

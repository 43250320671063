import styled from 'styled-components';
import { Container } from '@mui/material';

export const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    padding: calc(110px + 2.5rem) 3.75rem 0;

    ${theme.breakpoints.down('sm')} {
      padding: calc(100px + 2.5rem) 1.75rem 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
`
);

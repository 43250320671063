import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material';
import { FormElements, NotifyIcon } from 'web-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ErrorsInterface {
  templateIdentification: string;
  description: string;
}

interface PeriodObjectInterface {
  label: string;
  value: string;
}

interface Step2Props {
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (fieldName: string, value: string) => void;
  templateIdentification: string;
  errors: ErrorsInterface;
  filterTime: string;
  setFilterTime: React.Dispatch<React.SetStateAction<string>>;
  periodObject: PeriodObjectInterface[];
  description: string;
  accessType: string;
  setAccessType: React.Dispatch<React.SetStateAction<string>>;
}

const Step2 = ({
  handleInputChange,
  templateIdentification,
  errors,
  filterTime,
  setFilterTime,
  periodObject,
  description,
  accessType,
  setAccessType
}: Step2Props) => {
  const { t } = useTranslation();

  const handleChangeTemplateIdentification = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange('templateIdentification', e.target.value);
  };

  const handleChangePeriodTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterTime(e.target.value);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange('description', e.target.value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormElements.TextField
            label={t('dialog.add_new_template.template_identification')}
            size="medium"
            name="template_identification"
            id="template_identification"
            required
            fullWidth
            bgcolor="#F7F7FA"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={handleChangeTemplateIdentification}
            value={templateIdentification}
            error={!!errors.templateIdentification}
            helperText={errors.templateIdentification}
            data-selector="batch-note-modal-title-field"
            type="text"
            sx={{}}
          />
        </Grid>
        <Grid item xs={6}>
          <Box style={{ marginTop: '-16px' }}>
            <FormElements.Select
              name="period_time"
              id="period_time"
              required
              fullWidth
              value={filterTime}
              handleChange={handleChangePeriodTime}
              label={t('dialog.add_new_template.filter_time')}
              className=""
              withEmptyField={false}
              margin="normal"
              helperText=""
              native={false}
              error={false}
              disabled={false}
              iconName="chevronDown"
              size="medium"
            >
              {periodObject.map(fTime => (
                <MenuItem key={fTime.value} value={fTime.value}>
                  {`${t(fTime.label)}`}
                </MenuItem>
              ))}
            </FormElements.Select>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormElements.TextField
            className="subject"
            label={t('dialog.add_new_template.description')}
            id="description"
            required
            margin="normal"
            name="note"
            multiline
            rows={6}
            fullWidth
            onChange={handleChangeDescription}
            value={description}
            error={!!errors.description}
            helperText={errors.description}
            data-selector="dialog-add-new-template-desciption-field"
            sx={{
              mt: { md: 1 },
              mb: { md: '24px' }
            }}
            size="medium"
            type="text"
            bgcolor="#F7F7FA"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel>{t('dialog.add_new_template.your_template')}</FormLabel>
            <RadioGroup
              id="accessType"
              defaultValue="ACCESS_TYPE_USER_ONLY"
              value={accessType}
              onChange={e => setAccessType(e.target.value)}
              data-selector="dialog-add-new-template-access_type-field"
            >
              <FormControlLabel
                value="ACCESS_TYPE_USER_ONLY"
                control={<Radio />}
                label={t('dialog.add_new_template.access_type.only_me')}
                sx={{ marginBottom: '20px' }}
              />
              <FormControlLabel
                value="ACCESS_TYPE_ALL_ORG"
                control={<Radio />}
                label={t('dialog.add_new_template.access_type.all_users')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export { Step2 };

import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import {
  DATA_EXPORT_RESET,
  DATA_EXPORT_SEND_REQUEST,
  DATA_EXPORT_SHOW_MODAL,
  initialDataExportsValues
} from './constants';
import { createReducer, enhanceWithResetActions, loadingStateReducer } from '../utils';

export default combineReducers({
  dataExportLoadingState: enhanceWithResetActions([DATA_EXPORT_SHOW_MODAL, DATA_EXPORT_RESET])(
    loadingStateReducer([DATA_EXPORT_SHOW_MODAL, DATA_EXPORT_SEND_REQUEST])
  ),
  dataExports: typeToReducer(
    {
      [DATA_EXPORT_SEND_REQUEST]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    { ...initialDataExportsValues }
  ),
  isOpen: createReducer(
    // Show/hide data export modal
    {
      [DATA_EXPORT_SHOW_MODAL]: (state, { value }) => value
    },
    false
  )
});

import { put, select, takeLatest } from 'redux-saga/effects';
import compose from 'lodash/fp/compose';

import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';

import { getActiveOrganization, getUserInfo } from '../user/selectors';
import { getData, getUser } from '../rootSelectors';

import {
  loadMachineSensorsById,
  loadMachineSensorTreeView,
  loadProductionLines,
  loadMachines,
  loadSensors
} from '../../api/filters';
import {
  LOAD_MACHINE_SENSORS_BY_ID,
  LOAD_MACHINE_SENSORS_TREEVIEW,
  LOAD_PRODUCTION_LINES,
  LOAD_MACHINES,
  LOAD_SENSORS
} from './constants';

function* handleLoadMachineSensorsById({ machineId, templateName }) {
  yield put(statusAction(LOAD_MACHINE_SENSORS_BY_ID, actionStatus.START));
  try {
    const { data } = yield loadMachineSensorsById(machineId, templateName);
    yield put(statusAction(LOAD_MACHINE_SENSORS_BY_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_MACHINE_SENSORS_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadMachineSensorTreeView({ productionLines, sensors, machineIds }) {
  yield put(statusAction(constants.LOAD_MACHINE_SENSORS_TREEVIEW, actionStatus.START));
  try {
    const { data } = yield loadMachineSensorTreeView(productionLines, sensors, machineIds);
    yield put(statusAction(LOAD_MACHINE_SENSORS_TREEVIEW, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_MACHINE_SENSORS_TREEVIEW, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadProductionLines({ productionLines, machineIds }) {
  yield put(statusAction(LOAD_PRODUCTION_LINES, actionStatus.START));
  try {
    const { data } = yield loadProductionLines(productionLines, machineIds);
    yield put(statusAction(LOAD_PRODUCTION_LINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_PRODUCTION_LINES, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadMachines({ productionLines, sensors }) {
  yield put(statusAction(LOAD_MACHINES, actionStatus.START));
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));
  try {
    const { data } = yield loadMachines(organization.id, productionLines, sensors);
    yield put(statusAction(LOAD_MACHINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_MACHINES, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadSensors({ productionLines, machineIds }) {
  yield put(statusAction(LOAD_SENSORS, actionStatus.START));
  try {
    const { data } = yield loadSensors(productionLines, machineIds);
    yield put(statusAction(LOAD_SENSORS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_SENSORS, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_MACHINE_SENSORS_BY_ID, handleLoadMachineSensorsById);
  yield takeLatest(LOAD_MACHINE_SENSORS_TREEVIEW, handleLoadMachineSensorTreeView);
  yield takeLatest(LOAD_PRODUCTION_LINES, handleLoadProductionLines);
  yield takeLatest(LOAD_MACHINES, handleLoadMachines);
  yield takeLatest(LOAD_SENSORS, handleLoadSensors);
}

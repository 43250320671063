import { put, takeLatest, call } from 'redux-saga/effects';
import * as api from '../../api/stream';
import * as constants from './constants';
import * as authConstants from '../auth/constants';
import { actionStatus, statusAction, getError } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { cleanupOpenWebsocketConnections } from './websocket';

function* handleWebsocketsCleanup() {
  try {
    cleanupOpenWebsocketConnections();
    yield call([localStorage, 'removeItem'], 'websocketToken');
    yield call([localStorage, 'removeItem'], 'loadingWebsocketTokenLock');
  } catch (error) {
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `Error during websocket cleanup: ${error.message}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadWebsocketToken() {
  try {
    const { data } = yield call(api.getWebsocketToken);
    yield call([localStorage, 'setItem'], 'websocketToken', data.token);
    yield put(statusAction(constants.LOAD_WEBSOCKET_TOKEN, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_WEBSOCKET_TOKEN, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.stream.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleWebsockets({ value: isAuthenticated }) {
  yield call(handleWebsocketsCleanup);
  if (isAuthenticated) {
    yield call(handleLoadWebsocketToken);
  }
}

export function* watchLoadOrders() {
  yield takeLatest(authConstants.SET_AUTHENTICATION, handleWebsockets);
  yield takeLatest(constants.CLEANUP_WEBSOCKETS, handleWebsocketsCleanup);
}

import axios from './axios';

const getNotificationRules = params =>
  axios({
    method: 'get',
    url: '/v1/notify-rules',
    params
  });

const createNotificationRule = values =>
  axios({
    method: 'post',
    url: '/v1/notify-rules',
    data: values
  });

const updateNotificationRule = (id, values) =>
  axios({
    method: 'put',
    url: `/v1/notify-rules/${id}`,
    data: values
  });

const deleteNotificationRule = id =>
  axios({
    method: 'delete',
    url: `/v1/notify-rules/${id}`
  });

export { getNotificationRules, createNotificationRule, updateNotificationRule, deleteNotificationRule };

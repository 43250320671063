import React from 'react';
import BatchRecordsDetails from 'components/BatchRecordsDetails';
import T from 'prop-types';
import withLoadingState from '../../../helpers/withLoadingState';
import { BatchResponse } from '../../../helpers/propTypes';

const BatchRecordsDetail = ({ data }) => {
  if (!data || !data.details) {
    return null;
  }

  return <BatchRecordsDetails data={data} />;
};

BatchRecordsDetail.propTypes = {
  data: T.shape(BatchResponse)
};

BatchRecordsDetail.defaultProps = {
  data: null
};

export default withLoadingState(BatchRecordsDetail);

import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CarouselCard from './CarouselCard';
import AddSensorCard from './AddSensorCard';

function CarouselCardContainer({ template, handleAddSensor, handleEditSensor, handleDeleteSensor }) {
  const { t } = useTranslation();

  const [editingSensors, setEditingSensors] = useState([]);
  const [openAddSensor, setOpenAddSensor] = useState(false);

  useEffect(() => {
    setEditingSensors(template?.sensors || []);
  }, [template?.sensors]);

  const handleOpenAddSensor = () => {
    setOpenAddSensor(true);
  };

  const handleCloseAddSensor = (_event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpenAddSensor(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }} data-selector="carousel-card-container">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={{ xs: 2 }}
        columns={{ xs: 3, sm: 6, md: 9, lg: 9, xl: 12 }}
      >
        {editingSensors?.map((sensor, idx) => (
          <Grid
            item
            xs={3}
            key={`grid-${sensor.type}-${sensor?.temp_id || sensor.id}`}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <CarouselCard
              key={`card-${sensor.type}-${sensor?.temp_id || sensor.id}`}
              sensorIdx={idx}
              sensor={sensor}
              handleEditCardSensor={handleEditSensor}
              handleDeleteSensor={handleDeleteSensor}
            />
          </Grid>
        ))}
        {!editingSensors || editingSensors?.length < 8 ? (
          <Grid item xs={3} key="btn_add_card" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="outlined"
              sx={{
                minWidth: 300,
                maxWidth: 300,
                minHeight: 96,
                maxHeight: 96,
                border: '2px dashed',
                borderColor: '#80B8B3',
                borderRadius: 2,
                mr: 2,
                p: 2,
                '&:hover': {
                  border: '2px dashed',
                  borderColor: '#80B8B3'
                }
              }}
              onClick={handleOpenAddSensor}
            >
              <Stack spacing={0.5}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                  <Add />
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                    {t('edit_chart_template_details.add_sensor')}
                  </Typography>
                </Stack>
                <Typography sx={{ fontSize: 14, color: 'text.secondary', textTransform: 'none' }}>
                  {t('edit_chart_template_details.can_add', { amount: 8 - (editingSensors?.length || 0) })}
                </Typography>
              </Stack>
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <AddSensorCard
        open={openAddSensor}
        editingSensors={editingSensors}
        sensorsLength={editingSensors?.length || 0}
        handleCloseDialog={handleCloseAddSensor}
        handleApply={handleAddSensor}
      />
    </Box>
  );
}

CarouselCardContainer.propTypes = {
  template: PropTypes.shape(),
  handleAddSensor: PropTypes.func.isRequired,
  handleEditSensor: PropTypes.func.isRequired,
  handleDeleteSensor: PropTypes.func.isRequired
};

CarouselCardContainer.defaultProps = {
  template: {}
};

export default CarouselCardContainer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, CircularProgress, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useResolutionCheck, FormElements } from 'web-components';

import ReCAPTCHA from 'react-google-recaptcha';
import { RequestPasswordResetSchema } from '../../../attrs/formValidation';
import { isError, isLoading } from '../../../helpers/utils';
import FormAlert from '../FormAlert';
import { StyledFooterBar, StyledFooterBarContainer } from '../../../elements';
import { requestPasswordReset } from '../../../redux/auth/actions';
import { getRequestPasswordReset } from '../../../redux/auth/selectors';

const RequestPasswordResetForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile } = useResolutionCheck();
  const { status } = useSelector(getRequestPasswordReset);

  const handleRequestPasswordRequest = values => dispatch(requestPasswordReset(values.login, values.recaptchaValue));

  return (
    <Formik
      initialValues={{ login: '', recaptchaValue: '' }}
      validationSchema={RequestPasswordResetSchema}
      onSubmit={values => handleRequestPasswordRequest(values)}
    >
      {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched }) => (
        <form>
          <FormElements.TextField
            label={t('auth.email')}
            fullWidth
            id="login"
            margin="normal"
            name="login"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            error={errors.login && touched.login}
            helperText={errors.login && touched.login && t(`form.validate.${errors.login}`)}
          />
          <ReCAPTCHA
            sitekey="6Ldy1gMmAAAAANTSxV9caNAhISVMmlyn7vbBEz0K"
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '24px'
            }}
            onChange={value => handleChange({ target: { name: 'recaptchaValue', value } })}
          />

          {isError(status) && <FormAlert message={t('errors.auth.reset_password_request')} />}

          <StyledFooterBar position={isMobile ? 'sticky' : 'fixed'}>
            <Toolbar>
              <StyledFooterBarContainer maxWidth="md" theme={theme}>
                <div />
                <Button
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty || !values.recaptchaValue}
                  endIcon={
                    isLoading(status) && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
                  }
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                  type="submit"
                >
                  {t('form.send')}
                </Button>
              </StyledFooterBarContainer>
            </Toolbar>
          </StyledFooterBar>
        </form>
      )}
    </Formik>
  );
};

export default RequestPasswordResetForm;

import React, { useState } from 'react';
import T from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Grid, IconButton, Radio, Typography } from '@mui/material';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import { CardContent, CardHeader, LeftColumn, StyledCard, StyledTypography } from './GraphTemplateCard.Styled';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';

export const GraphTemplateCard = ({ graph, isRadio, checked, onSelectCard, onFavoriteChange, notClick }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [favorite, setFavorite] = useState(false);

  const navigate = () => {
    if (!isRadio) {
      history.push(`/dashboard/${id}/graph-templates/${graph.id}`);
    }
  };

  const toggleFavorite = e => {
    e.stopPropagation();
    const newFavoriteState = !favorite;
    setFavorite(newFavoriteState);
    onFavoriteChange(newFavoriteState, graph.id);
  };

  const handleCardClick = () => {
    if (!notClick) {
      navigate();
    }
  };

  return (
    <StyledCard sx={{ cursor: isRadio ? 'default' : 'pointer' }}>
      <CardContent onClick={handleCardClick}>
        <LeftColumn>
          <CardHeader>
            <Grid container direction="row" alignItems="center" spacing={2}>
              {isRadio && (
                <Grid item xs="auto">
                  <Box display="flex" justifyContent="right" alignItems="center">
                    <Radio
                      checked={checked}
                      onChange={onSelectCard}
                      value={graph.id}
                      name="dialog-clone-template-select-graph-template"
                      sx={{
                        marginLeft: { xs: 4, sm: 0.5 },
                        '& .MuiSvgIcon-root': {
                          fontSize: 24
                        },
                        color: COLOR_PRIMARY,
                        '&.Mui-checked': {
                          color: COLOR_PRIMARY
                        }
                      }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs="auto" order={{ xs: 0, md: 0 }}>
                <StyledTypography variant="h6">{graph.name}</StyledTypography>
              </Grid>

              <Grid item xs="auto" order={{ xs: 3, md: 1 }}>
                <Box display="flex" alignItems="center">
                  <Chip
                    label={t(`machines.detail.tabs.templates.tmpl_access_type.${graph.tmpl_access_type}`)}
                    size="small"
                    sx={{ backgroundColor: '#DEF1EE' }}
                  />
                </Box>
              </Grid>

              <Grid item xs="auto" order={{ xs: 2, md: 2 }}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body2" color="textSecondary">
                    {t(`machines.detail.tabs.templates.period_type.${graph.period_type}`)}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs="auto" order={{ xs: 1, md: 3 }}>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={toggleFavorite} size="small">
                    {graph.favorite ? <Star /> : <StarBorder />}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </CardHeader>
          <Typography variant="body2" color="textSecondary" sx={{ pt: 2 }}>
            {graph.description}
          </Typography>
        </LeftColumn>
      </CardContent>
    </StyledCard>
  );
};

GraphTemplateCard.propTypes = {
  graph: T.arrayOf(
    T.shape({
      id: T.oneOfType([T.string, T.number]).isRequired,
      machine_id: T.oneOfType([T.string, T.number]).isRequired,
      name: T.oneOfType([T.string]).isRequired,
      description: T.oneOfType([T.string]).isRequired,
      favorite: T.oneOfType([T.bool]).isRequired,
      period_type: T.oneOfType([T.string]).isRequired,
      sensors: T.shape({
        id: T.oneOfType([T.string, T.number]).isRequired,
        is_custom: true,
        machine_id: T.oneOfType([T.string]).isRequired,
        type: T.oneOfType([T.string]).isRequired
      }).isRequired,
      tmpl_access_type: T.oneOfType([T.string]).isRequired
    })
  ).isRequired,
  isRadio: T.bool,
  onFavoriteChange: T.func,
  checked: T.bool,
  onSelectCard: T.func,
  notClick: T.bool
};

GraphTemplateCard.defaultProps = {
  isRadio: false,
  checked: false,
  onFavoriteChange: undefined,
  onSelectCard: undefined,
  notClick: false
};

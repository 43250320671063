import styled from 'styled-components';
import { Paper } from '@mui/material';

import { NotifyTypo, FormElements } from 'web-components';

import SensorComparisonDialog from 'components/Graphs/multipleSensors/SensorComparisonDialog';
import SensorComparisonFooter from 'components/Graphs/multipleSensors/SensorComparisonFooter';
import EmptyState from 'components/EmptyState';

export const StyledPaper = styled(Paper)`
  padding: 0.625rem 2rem 1.25rem;
  margin-bottom: 2.5rem;
  &.MuiPaper-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(17, 17, 26, 0.1);
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.625rem;
  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

export const StyledCaption = styled(NotifyTypo.Caption)`
  &.MuiTypography-caption {
    font-weight: 500;
    margin-right: 1rem;
    @media (max-width: 425px) {
      margin-left: 10px;
    }
  }
`;

export const StyledCheckbox = styled(FormElements.Checkbox)`
  &.MuiFormControlLabel-root {
    margin: 0 2rem 0 auto;
    @media (max-width: 425px) {
      margin: 0;
    }
    & .MuiTypography-root {
      font-size: 0.875rem;
    }
  }
`;

export const StyledSensorComparisonDialog = styled(SensorComparisonDialog)`
  display: flex;
  justify-content: center;
  margin-bottom: 2.25rem;
`;

export const StyledSensorComparisonFooter = styled(SensorComparisonFooter)`
  margin-bottom: 2.25rem;
`;

export const StyledEmptyState = styled(EmptyState)`
  margin-bottom: 8rem;
`;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { NotifyTypo } from 'web-components';
import T from 'prop-types';

import { COLOR_MAIN_HIGHLIGHT, SECONDARY_TEXT_COLOR } from '../../../../attrs/colors';
import { getData } from '../../../../redux/rootSelectors';
import {
  getSelectedMachineSensorComparisonSensorTypes,
  getSelectedMachineSensors
} from '../../../../redux/machines/selectors';
import SelectedComparisonSensorCard from '../../../../components/SensorCard/SelectedComparisonSensorCard';
import { removeSensorFromComparison } from '../../../../redux/machines/actions';
import ComparisonSensorCardPlaceholder from '../../../../components/SensorCard/ComparisonSensorCardPlaceholder';
import { ContainerComparisonFooter } from './elements';
import { COLORS } from '../../../../components/Graphs/multipleSensors/utils';

const placeHolderKeys = [
  'placeholder-0',
  'placeholder-1',
  'placeholder-2',
  'placeholder-3',
  'placeholder-4',
  'placeholder-5'
];

const SensorComparisonFooter = ({ mobile, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sensors = useSelector(compose(getData, getSelectedMachineSensors));
  const selectedSensorTypes = useSelector(getSelectedMachineSensorComparisonSensorTypes);
  const sensorsWithTranslatedNames = (sensors || []).map(item => ({ ...item, name: t(item.name) }));

  const selectedSensorData = (selectedSensorTypes || [])
    .map(sensorType => sensorsWithTranslatedNames.find(sensor => sensor.type === sensorType))
    ?.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  const handleCancelClick = (e, type) => {
    e.stopPropagation();
    dispatch(removeSensorFromComparison(type));
  };

  const handleClick = event => {
    event.stopPropagation();
    setIsOpen();
  };

  return (
    <ContainerComparisonFooter color={COLOR_MAIN_HIGHLIGHT} data-selector="sensor-comparison-footer">
      <div style={{ marginBottom: '1.5rem' }}>
        <NotifyTypo.Body2
          style={{ fontSize: '0.857rem', fontWeight: 500, color: SECONDARY_TEXT_COLOR, marginTop: '1.5rem' }}
        >
          {t('dashboard.sensors.comparison.selected_sensors')}
        </NotifyTypo.Body2>
      </div>
      <Grid container spacing={2} direction={mobile ? 'column' : 'row'}>
        {selectedSensorData?.map((sensor, key) => (
          <Grid key={sensor.type} item xs={mobile ? 12 : 4}>
            <SelectedComparisonSensorCard
              aria-label="sensor-List"
              data-selector="sensor-card-list-toggle"
              onCancelClick={handleCancelClick}
              onClick={e => handleClick(e)}
              sensor={sensor}
              color={COLORS[key]}
              clickable="true"
            />
          </Grid>
        ))}
        {Array(6 - selectedSensorData?.length || 0)
          .fill()
          .map((_, i) => (
            <Grid key={placeHolderKeys[i]} item xs={mobile ? 12 : 4}>
              <ComparisonSensorCardPlaceholder
                aria-label="sensor-List"
                data-selector="sensor-placeholder-card-list-toggle"
                onClick={e => handleClick(e)}
                placeholderMenu
              />
            </Grid>
          ))}
      </Grid>
    </ContainerComparisonFooter>
  );
};

SensorComparisonFooter.propTypes = {
  mobile: T.bool,
  setIsOpen: T.func
};

SensorComparisonFooter.defaultProps = {
  mobile: false,
  setIsOpen: () => null
};

export default SensorComparisonFooter;

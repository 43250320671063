import styled from 'styled-components';

export const DetailsWrapper = styled.div(
  ({ theme }) => `
  padding: 0 2.25rem;

  ${theme.breakpoints.down('sm')} {
    padding: 0 1rem;
  }

  ${theme.breakpoints.down('xs')} {
    padding: 0;
  }
`
);

import React from 'react';
import T from 'prop-types';
import { Grid } from '@mui/material';
import BoxCard from '../components/BoxCard';
import BoxCardBatches from '../components/BoxCardBatches';

const ProductionMetricDetails = ({ metrics }) => (
  <Grid container spacing={2}>
    <Grid item xs={6} sm={3} md={2}>
      <BoxCard title="alarms" mvalue={`${metrics.alarms ?? 0}`} />
    </Grid>
    <Grid item xs={6} sm={3} md={2}>
      <BoxCard title="alerts" mvalue={`${metrics.alerts ?? 0}`} />
    </Grid>
    <Grid item xs={6} sm={3} md={2}>
      <BoxCard title="hour_meter" mvalue={`${metrics.hour_meter ?? 0}`} />
    </Grid>

    {metrics.batches ? (
      <Grid item xs={6} sm={3} md={2}>
        <BoxCardBatches
          completed={`${metrics?.batches?.completed ?? 0}`}
          cancelled={`${metrics?.batches?.cancelled ?? 0}`}
        />
      </Grid>
    ) : (
      ''
    )}
    {metrics.batches &&
      Object.entries(metrics.batches)
        .filter(([key]) => key !== 'completed' && key !== 'total' && key !== 'cancelled')
        .map(([key, value]) => (
          <Grid item xs={6} sm={6} md={2} key={key}>
            <BoxCard title={key} mvalue={`${value}`} />
          </Grid>
        ))}
  </Grid>
);

ProductionMetricDetails.propTypes = {
  metrics: T.shape({
    alarms: T.number,
    alerts: T.number,
    from: T.string,
    hour_meter: T.string,
    to: T.string,
    batches: T.shape({
      completed: T.number,
      cancelled: T.number
    })
  }).isRequired
};

export default ProductionMetricDetails;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

import { TrackedWrapper, UntrackedWrapper } from './MachineTrackStatusIcon.styled';

export const MACHINE_STATUS_TRACKED = 'TRACKED';
export const MACHINE_STATUS_UNTRACKED = 'UNTRACKED';

const MachineTrackStatusIcon = ({ status }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (status === MACHINE_STATUS_TRACKED) {
    return <TrackedWrapper theme={theme}>{t('machines.tracked')}</TrackedWrapper>;
  }
  return <UntrackedWrapper theme={theme}>{t('machines.not_tracked')}</UntrackedWrapper>;
};

MachineTrackStatusIcon.propTypes = {
  status: T.oneOf([MACHINE_STATUS_TRACKED, MACHINE_STATUS_UNTRACKED]).isRequired
};

export default MachineTrackStatusIcon;

import axios from './axios';

/**
 * Call getAlertHistory request
 * @param page
 * @param limit
 * @param sort
 * @param order
 * @param machineIds
 * @param periodTime
 * @param productionLines
 */
const getAlertHistory = (page, limit, sort, order, machineIds, periodTime, productionLines) =>
  axios({
    method: 'get',
    url: '/v1/alert-history/machine-errors',
    params: {
      page,
      limit,
      sort,
      order,
      machine_ids: machineIds === '' ? undefined : machineIds,
      period_time: periodTime === '' ? undefined : periodTime,
      production_lines: productionLines === '' ? undefined : productionLines
    }
  });

export { getAlertHistory };

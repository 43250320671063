import React, { forwardRef } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Card, CardActions, IconButton, CardHeader, Button, Avatar } from '@mui/material';
import { NotifyIcon, NotifyTypo, MachineIcon } from 'web-components';
import moment from 'moment';

import StyledChip from './StyledChip';
import { getMachineIconName, makeErrorAlertTitle, makeSensorAlertTitle } from '../../helpers/alerts';
import { AlertTypeMachineStatus } from '../../redux/alerts/constants';

const { Heading2, InfoText } = NotifyTypo;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    background: #2b2e42;
    border-radius: 10px;
    padding: 1rem;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  &.MuiCardHeader-root {
    align-items: flex-start;
    min-width: 300px;
    padding: 1.25rem 1.25rem 1rem;
    background: #2b2e42;

    @media (max-width: 375px) {
      width: 320px;
    }

    @media (min-width: 425px) {
      width: 380px;
    }

    @media (min-width: 768px) {
      width: 650px;
    }

    @media (min-width: 1024px) {
      width: 800px;
    }
  }
`;

const StyledCardActions = styled(CardActions)`
  &.MuiCardActions-root {
    justify-content: flex-end;
    padding: 0 1.25rem 0;
    background: #2b2e42;
    @media (max-width: 425px) {
      flex-wrap: wrap;
      flex-direction: column;
      align-content: center;
      align-items: center;
      padding: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 10px;
    padding: 1rem 1rem;

    @media (max-width: 425px) {
      padding: 1rem 6rem;
    }
  }
`;

const useStyles = makeStyles(() => ({
  action: {
    marginTop: -35,
    marginRight: -30
  }
}));

const MachineAlert = forwardRef(({ id, closeSnackbar, openAlertsMenu, alert, onViewAlertClick }, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:425px)');

  let alertMessage = '';
  if (alert.alert_type === AlertTypeMachineStatus) {
    if (alert.error) {
      if (alert.is_custom) {
        // Verify if this error is a custom_error or not
        alertMessage = `${alert.error.message}`;
      } else {
        alertMessage = `${alert.error.code}: ${t(`dashboard.machines.error_codes.${alert.error.code}`)}`;
      }
    }
  }

  const subHeaderChipNode =
    alert.error && !alert.metric ? (
      <StyledChip className={getMachineIconName(alert)} label={alertMessage} size="small" />
    ) : null;

  const subHeaderNode = (
    <InfoText style={{ position: 'absolute', left: 0, top: 10, marginLeft: 40 }}>
      {moment(alert.time).fromNow()}
    </InfoText>
  );

  const avatarNode = (
    <Avatar style={{ backgroundColor: 'inherit' }} variant="square" aria-label="status">
      <MachineIcon iconName={getMachineIconName(alert)} fontSize="large" status={alert.severity} viewBox="0 0 32 32" />
    </Avatar>
  );

  const actionNode = (
    <IconButton
      key="close"
      aria-label="close"
      data-selector="machine-alert-close-button"
      color="primary"
      onClick={() => closeSnackbar(id)}
    >
      <NotifyIcon fontSize="small" iconName="close" />
    </IconButton>
  );

  const titleNode = (
    <>
      {subHeaderNode}
      <Heading2 gutterBottom style={{ color: '#FFFFFF', marginBottom: 30, fontSize: '1rem', marginTop: 10 }}>
        {alert.metric ? makeSensorAlertTitle(t, alert) : makeErrorAlertTitle(t, alert)}
      </Heading2>
      {subHeaderChipNode}
    </>
  );

  const variantButton = isMobile ? 'outlined' : 'text';

  return (
    <>
      <StyledCard elevation={2} ref={ref} key={id} data-selector="machine-alert-card">
        <StyledCardHeader avatar={avatarNode} action={actionNode} title={titleNode} classes={classes} />
        <StyledCardActions>
          <StyledButton
            color="primary"
            fullWidth={isMobile}
            variant={variantButton}
            data-selector="machine-alert-card-view-all"
            onClick={openAlertsMenu}
          >
            {t('notifications.view_all')}
          </StyledButton>
          <StyledButton
            color="primary"
            fullWidth={isMobile}
            variant="contained"
            data-selector="machine-alert-view-alert-button"
            onClick={() => onViewAlertClick(alert)}
          >
            {alert.metric ? t('notifications.view_sensor') : t('notifications.view_machine')}
          </StyledButton>
        </StyledCardActions>
      </StyledCard>
    </>
  );
});

MachineAlert.propTypes = {
  id: T.number.isRequired,
  closeSnackbar: T.func.isRequired,
  alert: T.shape({
    severity: T.string.isRequired,
    error: T.shape({
      code: T.string.isRequired,
      message: T.string.isRequired
    }),
    metric: T.string,
    time: T.string.isRequired,
    status: T.string,
    machine: T.shape({
      name: T.sring,
      productionLine: T.string
    }),
    alert_type: T.string,
    is_custom: T.bool
  }).isRequired,
  onViewAlertClick: T.func.isRequired,
  openAlertsMenu: T.func.isRequired
};

export default MachineAlert;

import React from 'react';

import T from 'prop-types';
import InfoBlock from './InfoBlock';
import { BatchResponse } from '../../../helpers/propTypes';
import { getData } from './processData';

const InfoBlocks = ({ data, hasBatch, onClickEdit }) => {
  const { machineDetailsData, noBatchDetailsData } = getData(data);
  const config = hasBatch ? machineDetailsData : noBatchDetailsData;

  return (
    <>
      <InfoBlock config={config} status={data.isTracked} onClickEdit={onClickEdit} />
    </>
  );
};

InfoBlocks.propTypes = {
  data: T.shape(BatchResponse).isRequired,
  hasBatch: T.bool.isRequired,
  onClickEdit: T.func
};

InfoBlocks.defaultProps = {
  onClickEdit: () => null
};

export default InfoBlocks;

import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MachineDetail from './MachineDetail';
import {
  loadMachine,
  loadUnresolvedMachineError,
  resetMachineState,
  loadMachineNotificationRules,
  subscribeToMachinesUpdateStream,
  subscribeToSensorsUpdateStream
} from '../../../redux/machines/actions';
import { getSelectedMachine } from '../../../redux/machines/selectors';
import { isSuccess } from '../../../helpers/utils';
import { getLoadingState } from '../../../redux/rootSelectors';

/**
 * Machine details page, list machine status details and all sensors last data and update all sensors' data with a
 * WebSocket connection
 * @returns {JSX.Element}
 * @constructor
 */
const ConnectedMachineDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Load the selected machine
  const loadingState = useSelector(compose(getLoadingState, getSelectedMachine));
  const { loadingState: wsLoadingState } = useSelector(state => state.stream);

  // Load the machine, the notifications and unresolved machine errors
  useEffect(() => {
    dispatch(loadMachine(id));
    dispatch(loadMachineNotificationRules(id));
    dispatch(loadUnresolvedMachineError(id));
  }, [dispatch, id]);

  // Subscribe the websocket events to update sensors status data
  useEffect(() => {
    if (isSuccess(wsLoadingState.status)) {
      dispatch(subscribeToMachinesUpdateStream());
      dispatch(subscribeToSensorsUpdateStream(id));
    }
  }, [dispatch, id, wsLoadingState]);

  useEffect(() => () => dispatch(resetMachineState()), [dispatch]);

  return <MachineDetail loadingState={loadingState} reloadAction={() => dispatch(loadMachine(id))} />;
};

export default ConnectedMachineDetail;

import { getCurrentGMT, getDecimalSeparator, getUserLocale } from '../../helpers/utils';
import { DEFAULT_FILE_FORMAT_EXPORT } from '../../helpers/consts';

const DATA_EXPORT_SHOW_MODAL = 'DATA_EXPORT/SHOW_MODAL';
const DATA_EXPORT_SEND_REQUEST = 'DATA_EXPORT/SEND_EXPORT_SEND_REQUEST';
const DATA_EXPORT_RESET = 'DATA_EXPORT/RESET';

// Data Export Actions
// TODO: When batch records data export are done, add here the actions

const DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES = 'DATA_EXPORT/SEND_EXPORT_REQUEST_MACHINE';

const previusDateDay = (day = 30) => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() - day));
};

const previousDateHour = (hour = 1) => {
  const date = new Date();
  return new Date(date.setHours(date.getHours() - hour));
};

function roundToNearest15(isPrevious) {
  const date = isPrevious ? previousDateHour() : new Date();
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.round(date.getTime() / ms) * ms);
}

const initialDataExportsValues = {
  action: DEFAULT_FILE_FORMAT_EXPORT,
  date_from: previusDateDay(),
  date_to: new Date(),
  time_from: roundToNearest15(true),
  time_to: roundToNearest15(),
  window: '24h0m0s',
  timeZone: getCurrentGMT(),
  decimal_separator: getDecimalSeparator(),
  locale: getUserLocale()
};

export {
  DATA_EXPORT_SHOW_MODAL,
  DATA_EXPORT_SEND_REQUEST,
  DATA_EXPORT_SEND_EXPORT_REQUEST_MACHINES,
  DATA_EXPORT_RESET,
  initialDataExportsValues
};

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actionStatus, statusAction } from 'redux/utils';
import { decimateData } from 'helpers/chartUtils';
import { getUserInfo } from 'redux/user/selectors';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { generateId } from '../../helpers/utils';

import * as api from '../../api/chartTemplate';
import {
  CREATE_GRAPH_TEMPLATE,
  DELETE_GRAPH_TEMPLATE_BY_ID,
  DELETE_SENSOR_GRAPH_TEMPLATE,
  EXPORT_GRAPH_TEMPLATE,
  FAVORITE_GRAPH_TEMPLATE,
  GET_GRAPH_TEMPLATE_BY_ID,
  LOAD_GRAPHS_TEMPLATES,
  LOAD_SENSOR_AVERAGE,
  LOAD_SENSOR_HISTORY,
  LOAD_STATUS_HISTORY,
  UPDATE_GRAPH_TEMPLATE
} from './constants';

function* handleLoadGraphsTemplates({ machineId, accessType, sensorsList, templateName }) {
  yield put(statusAction(LOAD_GRAPHS_TEMPLATES, actionStatus.START));
  try {
    const { data } = yield call(api.loadGraphTemplates, machineId, accessType, sensorsList, templateName);
    yield put(statusAction(LOAD_GRAPHS_TEMPLATES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_GRAPHS_TEMPLATES, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleFavoriteGraphsTemplates({ graphId }) {
  yield put(statusAction(FAVORITE_GRAPH_TEMPLATE, actionStatus.START));
  try {
    const { data } = yield call(api.favoriteGraphsTemplate, graphId);
    yield put(statusAction(FAVORITE_GRAPH_TEMPLATE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(FAVORITE_GRAPH_TEMPLATE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleCreateGraphsTemplates({ values }) {
  yield put(statusAction(CREATE_GRAPH_TEMPLATE, actionStatus.START));
  try {
    const { data } = yield call(api.createGraphTemplate, values);
    yield put(statusAction(CREATE_GRAPH_TEMPLATE, actionStatus.SUCCESS, { payload: data }));
    let url = '/dashboard';
    if (values?.machine_id) {
      url = `/dashboard/${values?.machine_id}/graph-templates`;
      if (data?.id) {
        url = `${url}/${data.id}`;
      }
    }
    yield put(push(url));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'dialog.add_new_template.notification_successfull',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    yield put(
      statusAction(CREATE_GRAPH_TEMPLATE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleGetGraphsTemplateById({ graphId }) {
  yield put(statusAction(GET_GRAPH_TEMPLATE_BY_ID, actionStatus.START));
  try {
    const { data } = yield call(api.getGraphTemplateById, graphId);
    yield put(statusAction(GET_GRAPH_TEMPLATE_BY_ID, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(GET_GRAPH_TEMPLATE_BY_ID, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleDeleteGraphsTemplateById({ machineID, templateID }) {
  yield put(statusAction(DELETE_GRAPH_TEMPLATE_BY_ID, actionStatus.START));
  try {
    const { data } = yield call(api.delGraphTemplateById, templateID);
    yield put(statusAction(DELETE_GRAPH_TEMPLATE_BY_ID, actionStatus.SUCCESS, { payload: data }));
    let url = '/dashboard';
    if (machineID) {
      url = `/dashboard/${machineID}/graph-templates`;
    }
    yield put(push(url));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'dialog.delete_template.notification_successfull',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    yield put(
      statusAction(DELETE_GRAPH_TEMPLATE_BY_ID, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadSensorHistory({ paramList }) {
  yield put(statusAction(LOAD_SENSOR_HISTORY, actionStatus.START));
  try {
    const dataMap = new Map();
    if (paramList?.length) {
      const promises = paramList.map(param => api.loadSensorHistory(param.machineId, param));
      yield Promise.all(promises).then(results =>
        results.forEach((r, i) => {
          const aNiceSize = 9000;
          let sensorData = r?.data?.data;
          // decimate data if it's too big to not crash the browser
          if (sensorData && sensorData?.length > aNiceSize) {
            const threshold = Math.floor(sensorData.length / aNiceSize);
            sensorData = decimateData(sensorData, threshold);
          }
          dataMap.set(paramList[i].sensorId, sensorData);
        })
      );
    }
    yield put(statusAction(LOAD_SENSOR_HISTORY, actionStatus.SUCCESS, { payload: dataMap }));
  } catch (err) {
    yield put(
      statusAction(LOAD_SENSOR_HISTORY, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadStatusHistory({ param }) {
  yield put(statusAction(LOAD_STATUS_HISTORY, actionStatus.START));
  try {
    if (param?.machineId) {
      const { data } = yield call(api.loadStatusHistory, param.machineId, param);
      yield put(statusAction(LOAD_STATUS_HISTORY, actionStatus.SUCCESS, { payload: data }));
    }
  } catch (err) {
    yield put(
      statusAction(LOAD_STATUS_HISTORY, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadSensorAverage({ paramList }) {
  yield put(statusAction(LOAD_SENSOR_AVERAGE, actionStatus.START));
  try {
    const dataMap = new Map();
    if (paramList?.length) {
      const promises = paramList.map(param => api.loadSensorAverage(param.machineId, param));
      yield Promise.all(promises).then(results => results.map((r, i) => dataMap.set(paramList[i].sensorId, r?.data)));
    }
    yield put(statusAction(LOAD_SENSOR_AVERAGE, actionStatus.SUCCESS, { payload: dataMap }));
  } catch (err) {
    yield put(
      statusAction(LOAD_SENSOR_AVERAGE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleDeleteSensorGraphsTemplates({ values }) {
  yield put(statusAction(DELETE_SENSOR_GRAPH_TEMPLATE, actionStatus.START));
  try {
    const { data } = yield call(api.deleteSensorGraphTemplate, values);
    yield put(statusAction(DELETE_SENSOR_GRAPH_TEMPLATE, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'dialog.delete_sensor.notification_successfull',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    yield put(
      statusAction(DELETE_SENSOR_GRAPH_TEMPLATE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleUpdateGraphsTemplates({ values }) {
  yield put(statusAction(UPDATE_GRAPH_TEMPLATE, actionStatus.START));
  try {
    const { data } = yield call(api.updateGraphTemplate, values);
    yield put(statusAction(UPDATE_GRAPH_TEMPLATE, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'dialog.edit_template.notification_successfull',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    yield put(
      statusAction(UPDATE_GRAPH_TEMPLATE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleExportGraphsTemplates({ templateID, values }) {
  yield put(statusAction(EXPORT_GRAPH_TEMPLATE, actionStatus.START));
  try {
    const { user } = yield select(getUserInfo);
    const { data } = yield call(api.exportGraphTemplate, templateID, values);
    yield put(statusAction(EXPORT_GRAPH_TEMPLATE, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: `dialog.data_exports.machine_success_message`,
        variables: [{ name: 'email', data: user.data['e-mail'] }],
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    yield put(
      statusAction(EXPORT_GRAPH_TEMPLATE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_GRAPHS_TEMPLATES, handleLoadGraphsTemplates);
  yield takeLatest(FAVORITE_GRAPH_TEMPLATE, handleFavoriteGraphsTemplates);
  yield takeLatest(CREATE_GRAPH_TEMPLATE, handleCreateGraphsTemplates);
  yield takeLatest(GET_GRAPH_TEMPLATE_BY_ID, handleGetGraphsTemplateById);
  yield takeLatest(DELETE_GRAPH_TEMPLATE_BY_ID, handleDeleteGraphsTemplateById);
  yield takeLatest(LOAD_SENSOR_HISTORY, handleLoadSensorHistory);
  yield takeLatest(LOAD_STATUS_HISTORY, handleLoadStatusHistory);
  yield takeLatest(LOAD_SENSOR_AVERAGE, handleLoadSensorAverage);
  yield takeLatest(DELETE_SENSOR_GRAPH_TEMPLATE, handleDeleteSensorGraphsTemplates);
  yield takeLatest(UPDATE_GRAPH_TEMPLATE, handleUpdateGraphsTemplates);
  yield takeLatest(EXPORT_GRAPH_TEMPLATE, handleExportGraphsTemplates);
}

import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import { PageHeader as Header, NotifyTypo, Styles } from 'web-components';

import { PRIMARY_TEXT_COLOR } from '../../../../attrs/colors';

const SensorContentWrapper = styled(Styles.ContentWrapper)`
  &.MuiContainer-root {
    padding: 6rem 2rem 2rem 2rem;
    @media (max-width: 425px) {
      padding: 5rem 1rem 1rem 1rem;
    }
  }
`;

const GraphWrapper = styled(Card)`
  &.MuiCard-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    padding: 0 2rem 15rem 2rem;
    margin-top: 2rem;
    @media (max-width: 425px) {
      padding: 0 2rem 5rem 2rem;
    }
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const ContainerGraphHeading = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10rem;
  @media (max-width: 425px) {
    margin-right: 2rem;
  }
`;

const GraphHeading = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 400;
`;

const SettingsWrapper = styled.div`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.mobile &&
    `
    padding: .2rem .5rem;
  `};
  @media (max-width: 425px) {
    padding: 1.2rem 0;
    flex-wrap: wrap;
  }
`;

const TitleSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const MachineStateSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.mobile &&
    `
    flex: 1;
    justify-content: flex-end;
  `};
`;

const CheckboxWrapper = styled.div`
  & > .MuiFormHelperText-root {
    margin: -0.75rem 0 0 2rem;
  }

  & > .MuiFormControlLabel-root {
    ${props => props.showHelperText && `margin-top: 0.625rem;`}
  }

  & .MuiFormControlLabel-label.Mui-disabled {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

const SensorGraphHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.25rem;
`;

const SensorCurrentValue = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: baseline;
  & > div:first-child {
    margin-right: 1rem;
    white-space: nowrap;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
  &.mobile {
    padding: 0 0.5rem;
  }
`;
const StyledCard = styled(Card)`
  &.MuiCard-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 8px 10px rgba(17, 17, 26, 0.1);
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;
const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 1.75rem;
    @media (max-width: 425px) {
      align-content: flex-end;
      align-items: baseline;
      margin: 0 0 0 1rem;
    }
  }
`;

const PageHeader = styled(Header)`
  &.MuiPaper-root {
    flex-direction: column;
    align-self: flex-start;
    padding: 1rem 0 0 3rem;
    height: auto;
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      padding: 1rem 0 0 1.25rem;
    }
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;
const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    font-size: 20px;
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;
const TimeSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  @media (max-width: 768px) {
    align-items: flex-end;
    margin-left: 0;
  }
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
  }
`;

export {
  SensorContentWrapper,
  GraphWrapper,
  SettingsWrapper,
  ContainerGraphHeading,
  GraphHeading,
  SensorGraphHeading,
  SensorCurrentValue,
  MachineStateSelectionWrapper,
  CheckboxWrapper,
  StyledCard,
  StyledCardContent,
  PageHeader,
  PageTitle,
  PageHeaderTitle,
  TimeSelectionWrapper,
  TitleSettingsWrapper
};

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { createReducer, loadingStateReducer } from '../utils';

import {
  LOGIN,
  SET_AUTHENTICATION,
  REQUEST_PASSWORD_RESET,
  CHECK_TOKEN_CODE,
  RESET_PASSWORD_NON_AUTH,
  REGISTER
} from './constants';

const checkAuthCodeReducer = combineReducers({
  loadingState: loadingStateReducer(CHECK_TOKEN_CODE),
  data: typeToReducer(
    {
      [CHECK_TOKEN_CODE]: {
        START: () => null,
        ERROR: () => null
      },
      [CHECK_TOKEN_CODE]: {
        SUCCESS: (state, { payload }) => ({ ...state, ...payload })
      }
    },
    {}
  )
});

export default combineReducers({
  isAuthenticated: createReducer(
    {
      [SET_AUTHENTICATION]: (state, { value }) => value
    },
    false
  ),
  login: loadingStateReducer(LOGIN),
  requestPasswordReset: loadingStateReducer(REQUEST_PASSWORD_RESET),
  resetPassword: loadingStateReducer(RESET_PASSWORD_NON_AUTH),
  register: loadingStateReducer(REGISTER),
  checkAuthCode: checkAuthCodeReducer
});

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Container, Grid } from '@mui/material';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import T from 'prop-types';
import { COLOR_PRIMARY } from 'attrs/colors';
import { getUserRole, getDialCodeFromCountryCode } from 'helpers/utils';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogSubHeader
} from './styles';
import { ROLES } from '../../../attrs/roles';
import { setConfirmationRequest } from '../../../redux/ui/confirmations/actions';

const EditUserDialog = ({ user, isOpen, handleCLose, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const selectedDialCode = getDialCodeFromCountryCode(((user || {}).phone || {}).country);

  const handleCloseDialog = dirty => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => handleCLose()
        })
      );
    } else {
      handleCLose();
    }
  };

  return (
    <Formik
      initialValues={user}
      onSubmit={values => {
        handleFormSubmit(values);
      }}
      enableReinitialize
    >
      {({ values, handleSubmit, dirty, errors, handleChange, isSubmitting }) => (
        <StyledDialog open={isOpen} disableScrollLock>
          <StyledDialogHeader
            theme={theme}
            highlightColor={COLOR_PRIMARY}
            onClose={() => handleCloseDialog(dirty)}
            closeButtonProps={{ color: 'primary' }}
          >
            {t('user_management.edit_user.title')}
          </StyledDialogHeader>
          <StyledDialogSubHeader theme={theme} withDivider={false}>
            {t('user_management.edit_user.subtitle')}
          </StyledDialogSubHeader>
          <StyledDialogBody>
            <Grid container>
              <Grid item>
                <Container>
                  <FormElements.TextField
                    disabled
                    fullWidth
                    id="firstName"
                    label={t('user_management.list.first_name')}
                    margin="normal"
                    name="firstName"
                    value={values.first_name}
                  />
                  <FormElements.TextField
                    disabled
                    fullWidth
                    id="last_name"
                    label={t('user_management.list.last_name')}
                    margin="normal"
                    name="last_name"
                    value={values.last_name}
                  />
                  <FormElements.TextField
                    disabled
                    fullWidth
                    id="email"
                    label={t('user_management.list.email')}
                    margin="normal"
                    name="email"
                    value={values['e-mail']}
                  />
                  <FormElements.TextField
                    disabled
                    fullWidth
                    id="phone"
                    label={t('user_management.list.phone')}
                    margin="normal"
                    name="phone"
                    value={`${selectedDialCode} ${values.phone?.number}`}
                  />
                  <FormElements.Select
                    value={values.role}
                    fullWidth
                    margin="normal"
                    handleChange={handleChange}
                    label={t('user_management.list.role')}
                    name="role"
                    id="role"
                    size="small"
                  >
                    {ROLES.map(role => (
                      <option key={role.value} value={role.value}>
                        {t(getUserRole(role.value).label)}
                      </option>
                    ))}
                  </FormElements.Select>
                </Container>
              </Grid>
            </Grid>
          </StyledDialogBody>
          <StyledDialogActions>
            <Button variant="text" color="secondary" onClick={() => handleCloseDialog(dirty)}>
              {t('dashboard.custom.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
              disabled={isSubmitting || !dirty || Object.keys(errors).length !== 0}
            >
              {t('dashboard.custom.save')}
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    </Formik>
  );
};

EditUserDialog.propTypes = {
  user: T.shape({}),
  isOpen: T.bool.isRequired,
  handleCLose: T.func.isRequired,
  handleFormSubmit: T.func.isRequired
};

EditUserDialog.defaultProps = {
  user: {}
};

export default EditUserDialog;

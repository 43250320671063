import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import MachineHistory from './MachineHistory';
import { loadMachine, loadUnresolvedMachineError, resetMachineState } from '../../../../redux/machines/actions';
import { getSelectedMachine } from '../../../../redux/machines/selectors';
import { getLoadingState } from '../../../../redux/rootSelectors';

const ConnectedMachineHistory = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loadingState = useSelector(compose(getLoadingState, getSelectedMachine));

  useEffect(() => {
    dispatch(loadMachine(id));
    dispatch(loadUnresolvedMachineError(id));
  }, [dispatch, id]);

  useEffect(() => () => dispatch(resetMachineState()), [dispatch]);

  return <MachineHistory loadingState={loadingState} />;
};

export default ConnectedMachineHistory;

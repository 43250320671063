import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import { LOAD_METRICS_MACHINES } from './constants';

export default combineReducers({
  loadingState: loadingStateReducer(LOAD_METRICS_MACHINES),
  data: typeToReducer(
    {
      [LOAD_METRICS_MACHINES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

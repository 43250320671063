import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';
import { useTranslation } from 'react-i18next';

import BatchIcon from './BatchIcon';

import {
  BoxWrapper,
  BoxCardInner,
  BoxBatches,
  BoxInner,
  BoxBatchesSecondInner,
  BoxBatchesThirdInner,
  BoxBatchesInnerText,
  BoxBatchesInnerIcon,
  BoxBatchesInner,
  BoxBatchesCardName
} from './elements';

const BoxCardBatches = ({ completed, cancelled }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();

  return (
    <BoxWrapper withWrap={isTablet}>
      <BoxCardInner>
        <BoxBatches>
          <BoxInner>
            <BoxBatchesSecondInner>
              <BoxBatchesThirdInner>
                <BoxBatchesInnerText>{completed}</BoxBatchesInnerText>
                <BoxBatchesInnerIcon>
                  <BatchIcon icon="batchComplete" titleTooltip="machines.detail.tabs.metrics.completed_batches" />
                </BoxBatchesInnerIcon>
              </BoxBatchesThirdInner>
            </BoxBatchesSecondInner>
          </BoxInner>

          <BoxBatchesInner>
            <BoxBatchesSecondInner>
              <BoxBatchesThirdInner>
                <BoxBatchesInnerText>{cancelled}</BoxBatchesInnerText>
                <BoxBatchesInnerIcon>
                  <BatchIcon icon="batchError" titleTooltip="machines.detail.tabs.metrics.cancelled_batches" />
                </BoxBatchesInnerIcon>
              </BoxBatchesThirdInner>
            </BoxBatchesSecondInner>
          </BoxBatchesInner>
        </BoxBatches>
        <BoxBatchesCardName>{t(`machines.detail.tabs.metrics.batches`)}</BoxBatchesCardName>
      </BoxCardInner>
    </BoxWrapper>
  );
};

BoxCardBatches.propTypes = {
  completed: T.string.isRequired,
  cancelled: T.string.isRequired
};
export default BoxCardBatches;

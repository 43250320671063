import React from 'react';
import T from 'prop-types';
import { Loader } from 'web-components';

import LoadingStatusComponent from '../components/StatusIndicator/LoadingStatusComponent';

const withLoadingState = Component => {
  const C = ({ loadingState, loadingComponent, errorComponent, reloadAction, ignoreError, ...props }) => (
    <LoadingStatusComponent
      loadingState={loadingState}
      loadingComponent={loadingComponent}
      errorComponent={errorComponent}
      ignoreError={ignoreError}
      reloadAction={reloadAction}
      renderComponent={Component}
      renderProps={props}
    />
  );

  C.displayName = `withLoadingState(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;
  C.propTypes = {
    loadingState: T.shape({}).isRequired,
    loadingComponent: T.oneOfType([T.node, T.string, T.func]),
    errorComponent: T.oneOfType([T.node, T.string, T.func]),
    ignoreError: T.bool,
    reloadAction: T.func
  };
  C.defaultProps = {
    reloadAction: null,
    ignoreError: false,
    errorComponent: null,
    loadingComponent: () => <Loader standalone size={20} />
  };

  return C;
};

export default withLoadingState;

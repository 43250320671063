import axios from './axios';

const getAlarms = (page, limit, sort, order, machineIds, periodTime, productionLines, sensors) =>
  axios({
    method: 'get',
    url: '/v1/alarm-history/machines',
    params: {
      page,
      limit,
      sort,
      order,
      machine_ids: machineIds === '' ? undefined : machineIds,
      period_time: periodTime === '' ? undefined : periodTime,
      production_lines: productionLines === '' ? undefined : productionLines,
      sensors: sensors === '' ? undefined : sensors
    }
  });

export { getAlarms };

import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Toolbar } from '@mui/material';

import RegisterAccount from './RegisterAccount';
import { checkTokenCode } from '../../../redux/auth/actions';
import { NAVIGATION_BAR_HEIGHT, NAVIGATION_BAR_LOGO_HEIGHT, FOOTER_NAVIGATION_BAR_HEIGHT } from '../../../attrs/layout';
import { AppBarContentWrapper, StyledAppBar } from '../../../elements';
import Logo from '../../../attrs/logo';
import { LANGUAGES } from '../../../attrs/languages';
import { getCheckAuthCode } from '../../../redux/auth/selectors';

const ConnectedRegisterAccount = () => {
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getCheckAuthCode);
  const { token } = useParams();
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const [initLanguage, setInitLanguage] = useState(null);

  // Check for query string 'lang' in URL to change language accordingly
  const sp = new URLSearchParams(search);
  const lang = sp.get('lang');

  useEffect(() => {
    if (LANGUAGES.some(language => language.value === lang)) {
      i18n.changeLanguage(lang);
      setInitLanguage(lang);
    } else {
      setInitLanguage(i18n.language);
    }
  }, [i18n, lang]);

  useEffect(() => {
    dispatch(checkTokenCode(token || undefined));
  }, [dispatch, token]);

  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: NAVIGATION_BAR_HEIGHT,
        paddingTop: '2rem',
        paddingBottom: `${FOOTER_NAVIGATION_BAR_HEIGHT + 20}px`
      }}
    >
      <StyledAppBar position="fixed">
        <Toolbar variant="dense">
          <AppBarContentWrapper center>
            <Logo height={`${NAVIGATION_BAR_LOGO_HEIGHT}px`} style={{ minWidth: '70px' }} />
          </AppBarContentWrapper>
        </Toolbar>
      </StyledAppBar>
      <RegisterAccount
        loadingState={loadingState}
        reloadAction={() => dispatch(checkTokenCode(token))}
        token={token}
        initLanguage={initLanguage && initLanguage.replace(/-/g, '_')}
      />
    </Container>
  );
};

ConnectedRegisterAccount.propTypes = {
  match: T.shape({
    params: T.shape({})
  }).isRequired
};

export default ConnectedRegisterAccount;

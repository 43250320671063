/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import { Label, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  formatXAxis,
  formatYAxisRounded,
  isValidTimeRange,
  MetricCustomSensorTooltip,
  ScopedTimeRangeLoader,
  tickStyle,
  CustomTick
} from '../graphUtils';
import { ArtificalXAxis } from '../elements';
import { AXIS_LINE_COLOR, COLOR_PRIMARY, SENSOR_WARNING_COLOR } from '../../../attrs/colors';
import {
  getMachineStatusProps,
  getRoundedChartData,
  getSensorPropsFromMetric,
  isLoading
} from '../../../helpers/utils';
import { getSensorSettingByIds } from '../../../redux/ui/settings/selectors';
import { getSelectedScopeSensorHistory } from '../../../redux/machines/selectors';
import { setScopedTimeWindow } from '../../../redux/ui/settings/actions';

const SensorStatusChart = ({ machineId }) => {
  const dispatch = useDispatch();
  const { loadingState: scopedLoadingState, data: metricsHistoryChartData } =
    useSelector(getSelectedScopeSensorHistory);

  const sensorSettings = useSelector(state =>
    getSensorSettingByIds(state, machineId, (metricsHistoryChartData.sensor || { type: '' }).type)
  );
  const [zoomState, setZoomState] = useState({ refAreaLeft: null, refAreaRight: null });

  const shouldShowMachineState = (sensorSettings || {}).showMachineState || false;

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight } = zoomState;

    if (refAreaLeft === refAreaRight || refAreaRight === null) {
      setZoomState({
        refAreaLeft: null,
        refAreaRight: null
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    if (isValidTimeRange(moment(refAreaLeft).valueOf(), moment(refAreaRight).valueOf())) {
      dispatch(
        setScopedTimeWindow(
          moment(refAreaLeft).toISOString(),
          moment(refAreaRight).toISOString(),
          machineId,
          metricsHistoryChartData.sensor.type
        )
      );
    }
    setZoomState({ refAreaLeft: null, refAreaRight: null });
  };

  const handleMouseDown = e => {
    window.event.preventDefault();
    if (e && e.activeLabel) {
      setZoomState({ ...zoomState, refAreaLeft: e.activeLabel });
    }
  };

  const sensorData = getSensorPropsFromMetric(
    {
      ...metricsHistoryChartData.sensor,
      yAxisDomain: metricsHistoryChartData.yAxisDomain
    } || {}
  );

  metricsHistoryChartData.chartData = getRoundedChartData(sensorData, metricsHistoryChartData.chartData);

  function findHighestAndLowest(arr) {
    if (!arr || arr.length === 0) {
      return null;
    }

    let highest = parseFloat(arr[0].value);
    let lowest = parseFloat(arr[0].value);

    for (let i = 1; i < arr.length; i++) {
      const value = parseFloat(arr[i].value);
      if (!isNaN(value)) {
        if (value > highest) {
          highest = value;
        }
        if (value < lowest) {
          lowest = value;
        }
      }
    }
    return { highest, lowest };
  }

  const result = findHighestAndLowest(metricsHistoryChartData?.chartData);

  return (
    <>
      {isLoading(scopedLoadingState.status) && <ScopedTimeRangeLoader />}
      <ResponsiveContainer>
        <LineChart
          data={metricsHistoryChartData.chartData}
          onMouseDown={e => handleMouseDown(e)}
          onMouseMove={e => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
          onMouseUp={handleZoom}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={COLOR_PRIMARY} stopOpacity={1} />
              <stop offset="95%" stopColor="#fff" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            domain={['dataMin', 'dataMax']}
            ticks={metricsHistoryChartData.xAxisTicks}
            tick={false}
            tickFormatter={formatXAxis}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            type="number"
            domain={[result.lowest, result.highest]}
            tick={{ ...tickStyle, fill: AXIS_LINE_COLOR }}
            tickFormatter={formatYAxisRounded(metricsHistoryChartData.sensor)}
            stroke={AXIS_LINE_COLOR}
            width={metricsHistoryChartData.yAxisWidth}
          >
            <Label
              angle={-90}
              dx={-(metricsHistoryChartData.yAxisWidth / 2 - 12)}
              position="center"
              fontSize={14}
              fill={AXIS_LINE_COLOR}
            >
              {metricsHistoryChartData.unit}
            </Label>
          </YAxis>
          <Tooltip content={<MetricCustomSensorTooltip detail={metricsHistoryChartData.sensor} />} />
          <Line
            type="monotone"
            fill="url(#colorValue)"
            fillOpacity={1}
            stroke={COLOR_PRIMARY}
            isAnimationActive={false}
            dataKey="value"
            unit={metricsHistoryChartData.unit}
            dot={false}
          />
          {zoomState.refAreaLeft && zoomState.refAreaRight ? (
            <ReferenceArea x1={zoomState.refAreaLeft} x2={zoomState.refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
      <ArtificalXAxis>
        <ResponsiveContainer height={56}>
          <LineChart>
            {shouldShowMachineState &&
              metricsHistoryChartData.machineChartData.map(reference => {
                const { color } = getMachineStatusProps(reference.value);
                return (
                  <ReferenceArea
                    key={`${reference.start}-${reference.end}-${reference.value}-machine`}
                    x1={reference.start}
                    x2={reference.end}
                    y1={0.33}
                    y2={1}
                    yAxisId="machine_state"
                    fill={color}
                    fillOpacity={0.5}
                  />
                );
              })}
            {shouldShowMachineState &&
              metricsHistoryChartData.machineChartData.map(reference => {
                if (!reference.warning) {
                  return null;
                }

                return (
                  <ReferenceArea
                    key={`${reference.start}-${reference.end}-${reference.value}-warning`}
                    x1={reference.start}
                    x2={reference.end}
                    y1={0}
                    y2={0.33}
                    yAxisId="machine_state"
                    fill={SENSOR_WARNING_COLOR}
                    fillOpacity={0.5}
                  />
                );
              })}
            <XAxis
              dataKey="time"
              type="number"
              interval="preserveStartEnd"
              domain={metricsHistoryChartData.xAxisDomain}
              stroke={AXIS_LINE_COLOR}
              tickLine={false}
              ticks={metricsHistoryChartData.xAxisTicks}
              tickFormatter={tick => {
                moment(tick).format('DD/MM/YYYY HH:mm:ss');
              }}
              tick={props => 
              <CustomTick 
              {...props} 
              lastIndex={metricsHistoryChartData.xAxisTicks.length - 1} 
              spanValue={{
                fisrtIndex: -50,
                lastIndex: 0,
                othersIndex: 25
              }}
              />
            }
            />
            <YAxis
              key="machine_state"
              yAxisId="machine_state"
              domain={[0, 1]}
              stroke={AXIS_LINE_COLOR}
              tick={false}
              tickLine={false}
              width={metricsHistoryChartData.yAxisWidth}
            />
          </LineChart>
        </ResponsiveContainer>
      </ArtificalXAxis>
    </>
  );
};

SensorStatusChart.propTypes = {
  machineId: T.string.isRequired
};

export default SensorStatusChart;

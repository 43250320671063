import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import withLoadingState from '../../helpers/withLoadingState';
import MachinesOverview from '../../components/MachinesOverview';
import { ContainerTitle, PageHeader, PageHeaderTitle } from './elements';
import TooltipInfo from '../../components/TooltipInfo';

const Machines = ({ machines }) => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader className="withoutShadow" alwaysVisible>
        <PageHeader.Title>
          <ContainerTitle>
            <PageHeaderTitle>{t('machines.title')}</PageHeaderTitle>
            <TooltipInfo titleTooltip="machines.title_tooltip" />
          </ContainerTitle>
        </PageHeader.Title>
      </PageHeader>

      <MachinesOverview rows={machines} style={{ paddingTop: '2rem' }} />
    </>
  );
};

Machines.propTypes = {
  machines: T.arrayOf(T.shape({}))
};

Machines.defaultProps = {
  machines: null
};

export default withLoadingState(Machines);

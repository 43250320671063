import * as constants from './constants';

const loadMachinesensorsbyid = ({ machineId, templateName }) => ({
  type: constants.LOAD_MACHINE_SENSORS_BY_ID,
  machineId,
  templateName
});

const loadMachinesSensorsTreeView = ({ productionLines, sensors, machineIds }) => ({
  type: constants.LOAD_MACHINE_SENSORS_TREEVIEW,
  productionLines,
  sensors,
  machineIds
});

const loadProductionsLines = ({ productionLines, machineIds }) => ({
  type: constants.LOAD_PRODUCTION_LINES,
  productionLines,
  machineIds
});

const loadMachines = ({ productionLines, sensors } = {}) => ({
  type: constants.LOAD_MACHINES,
  productionLines,
  sensors
});

const loadSensors = ({ productionLines, machineIds }) => ({
  type: constants.LOAD_SENSORS,
  productionLines,
  machineIds
});

export { loadMachinesensorsbyid, loadMachinesSensorsTreeView, loadProductionsLines, loadMachines, loadSensors };

import { ADD_NOTIFICATION, RESOLVE_NOTIFICATION } from './constants';
import { createReducer } from '../../utils';

const initialState = [];

export default createReducer(
  {
    [ADD_NOTIFICATION]: (state, { notification }) => [notification, ...state],
    [RESOLVE_NOTIFICATION]: (state, { key }) => state.filter(({ key: notificationId }) => notificationId !== key)
  },
  initialState,
  []
);

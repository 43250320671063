import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Loader } from 'web-components';

import { useHistory } from 'react-router-dom';
import customTheme from './attrs/theme';
import { StyledSnackbarProvider } from './elements';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';
import Notifications from './components/Notifications';
import Confirmations from './components/Confirmations';
import { checkAuthentication } from './redux/auth/actions';
import { getIsAuthenticated } from './redux/auth/selectors';
import FeatureToggles from './components/FeatureToggles';
import { CacheBuster } from './CacheBuster';

function App() {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [prevPath, setPrevPath] = useState(undefined);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    dispatch(checkAuthentication());
    setIsLoading(false);
  }, [dispatch, setIsLoading]);

  const hasPathDashboardSensor = location.pathname.includes('/dashboard') || location.pathname.includes('/sensor');

  useEffect(() => {
    if (['/', '/login'].includes(location.pathname) && !hasPathDashboardSensor) {
      return;
    }

    setPrevPath(location);
  }, [location, hasPathDashboardSensor]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <Suspense fallback={<Loader standalone />}>
            <ThemeProvider theme={customTheme}>
              <FeatureToggles />
              <CssBaseline />
              {isLoading ? (
                <Loader standalone />
              ) : (
                <StyledSnackbarProvider dense maxSnack={1}>
                  {isAuthenticated ? <LoggedIn prevPath={prevPath} /> : <LoggedOut prevPath={prevPath} />}
                  <Notifications />
                  <Confirmations />
                </StyledSnackbarProvider>
              )}
            </ThemeProvider>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
}

export default App;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'web-components';

import withLoadingState from '../../helpers/withLoadingState';
import { ContentWrapper } from '../../elements';
import AccountForm from '../../components/forms/Account/AccountForm';

const Account = ({ user }) => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader alwaysVisible>
        <PageHeader.Title>
          <PageHeader.Title.Main>{t('profile.account_settings')}</PageHeader.Title.Main>
          <PageHeader.Title.Sub>{t('profile.account_sub_title')}</PageHeader.Title.Sub>
        </PageHeader.Title>
      </PageHeader>
      <ContentWrapper
        className="withPageHeader"
        maxWidth={false}
        style={{ background: '#fff', paddingLeft: 0, paddingRight: 0 }}
      >
        <AccountForm user={user} />
      </ContentWrapper>
    </>
  );
};

Account.propTypes = {
  user: T.shape({
    id: T.string,
    phone: T.shape({ country: T.string, number: T.string }),
    'e-mail': T.string
  })
};

Account.defaultProps = {
  user: null
};

export default withLoadingState(Account);

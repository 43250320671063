import React, { useEffect } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { NotifyTypo } from 'web-components';

import { loadOrganization } from '../../../redux/user/actions';
import { ProfileWrapper } from './elements';
import withLoadingState from '../../../helpers/withLoadingState';
import { getOrganization } from '../../../redux/user/selectors';

const OrganizationProfile = ({ organization }) => (
  <>
    <NotifyTypo.Heading2>{organization.name}</NotifyTypo.Heading2>
    <Avatar src={organization.logo} style={{ width: 40, height: 40, marginLeft: '1rem' }} />
  </>
);

OrganizationProfile.propTypes = {
  organization: T.shape({ name: T.string, logo: T.string }).isRequired
};

const OrganizationProfileWithLoadingState = withLoadingState(OrganizationProfile);

const ConnectedOrganizationProfile = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getOrganization);

  useEffect(() => {
    dispatch(loadOrganization());
  }, [dispatch]);

  return (
    <ProfileWrapper>
      <OrganizationProfileWithLoadingState
        loadingState={loadingState}
        reloadAction={() => dispatch(loadOrganization())}
        organization={data}
      />
    </ProfileWrapper>
  );
};

export default ConnectedOrganizationProfile;

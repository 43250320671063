import React from 'react';
import { useTranslation } from 'react-i18next';

import T from 'prop-types';

const MachineErrorChip = ({ error }) => {
  const { t } = useTranslation();

  return (error || {}).code || (error || {}).is_custom ? (
    <span style={{ color: 'red' }}>
      {error.is_custom ? error.message : `${error.code}: ${t(`dashboard.machines.error_codes.${error.code}`)}`}
    </span>
  ) : (
    <span> </span>
  );
};

MachineErrorChip.propTypes = {
  error: T.shape({
    code: T.string,
    is_custom: T.bool,
    message: T.string
  })
};

MachineErrorChip.defaultProps = {
  error: {
    code: '',
    is_custom: '',
    message: ''
  }
};

export default MachineErrorChip;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Button, Divider } from '@mui/material';

import { NotifyIcon, NotifyTypo } from 'web-components';

import { ReactComponent as CreateSupportTicketIcon } from 'attrs/images/create_support_ticket.svg';

import { DesktopContentWrapper, CreateTicketWrapper, ContactsWrapper, ContactItem } from './DesktopContent.styled';

const DesktopContent = ({ data, copyToClipboard, createTicket }) => {
  const { t } = useTranslation();

  return (
    <DesktopContentWrapper>
      <CreateTicketWrapper>
        <CreateSupportTicketIcon className="create-ticket" />
        <Button
          variant="contained"
          startIcon={<NotifyIcon iconName="plus" style={{ fontSize: '1.875rem' }} />}
          color="primary"
          size="large"
          onClick={createTicket}
          disableElevation
        >
          {t('support.create_ticket')}
        </Button>
      </CreateTicketWrapper>
      <Divider orientation="vertical" />
      <ContactsWrapper>
        {data['e-mail'] && (
          <div>
            <NotifyTypo.InfoText className="info-text">{t('support.email_us')}</NotifyTypo.InfoText>
            <ContactItem>
              <NotifyIcon className="icon" iconName="email" />
              <a href={`mailto:${data['e-mail']}`}>{data['e-mail']}</a>
              <IconButton
                className="copy-to-clipboard"
                aria-label="copy-to-clipboard"
                color="inherit"
                onClick={() => copyToClipboard(data['e-mail'], 'email')}
              >
                <NotifyIcon iconName="copy" />
              </IconButton>
            </ContactItem>
          </div>
        )}
        {data.phone && (
          <div>
            <NotifyTypo.InfoText className="info-text">{t('support.call_us')}</NotifyTypo.InfoText>
            <ContactItem>
              <NotifyIcon className="icon phone-icon" iconName="phone" />
              <a href={`tel: ${data.phone}`}>{data.phone}</a>
              <IconButton
                className="copy-to-clipboard"
                aria-label="copy-to-clipboard"
                color="inherit"
                onClick={() => copyToClipboard(data.phone, 'phone')}
              >
                <NotifyIcon iconName="copy" />
              </IconButton>
            </ContactItem>
          </div>
        )}
      </ContactsWrapper>
    </DesktopContentWrapper>
  );
};

DesktopContent.propTypes = {
  data: T.shape({ 'e-mail': T.string, phone: T.string }).isRequired,
  copyToClipboard: T.func.isRequired,
  createTicket: T.func.isRequired
};

export default DesktopContent;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import {
  LOAD_GRAPHS_TEMPLATES,
  FAVORITE_GRAPH_TEMPLATE,
  GET_GRAPH_TEMPLATE_BY_ID,
  DELETE_GRAPH_TEMPLATE_BY_ID,
  CREATE_GRAPH_TEMPLATE,
  LOAD_SENSOR_HISTORY,
  LOAD_STATUS_HISTORY,
  LOAD_SENSOR_AVERAGE,
  UPDATE_GRAPH_TEMPLATE
} from './constants';

const initialState = {
  newTemplate: null,
  error: null,
  templates: []
};

export default combineReducers({
  loadingState: loadingStateReducer(
    LOAD_GRAPHS_TEMPLATES,
    FAVORITE_GRAPH_TEMPLATE,
    GET_GRAPH_TEMPLATE_BY_ID,
    DELETE_GRAPH_TEMPLATE_BY_ID,
    CREATE_GRAPH_TEMPLATE
  ),
  data: typeToReducer(
    {
      [LOAD_GRAPHS_TEMPLATES]: {
        SUCCESS: (state, { payload }) => ({
          ...state,
          templates: payload
        })
      },
      [FAVORITE_GRAPH_TEMPLATE]: {
        SUCCESS: (state, { payload }) => ({
          ...state,
          favorite: payload
        })
      },
      [GET_GRAPH_TEMPLATE_BY_ID]: {
        SUCCESS: (state, { payload }) => ({
          ...state,
          currentTemplate: payload
        })
      },
      [DELETE_GRAPH_TEMPLATE_BY_ID]: {
        SUCCESS: (state, { payload }) => ({
          ...state,
          deleteSuccess: payload
        })
      },
      [CREATE_GRAPH_TEMPLATE]: {
        SUCCESS: (state, { payload }) => ({
          ...state,
          newTemplate: payload
        }),
        FAILURE: (state, { payload }) => ({
          ...state,
          error: payload
        })
      }
    },
    initialState
  ),
  loadSensorHistoryStatus: loadingStateReducer(LOAD_SENSOR_HISTORY),
  sensorHistoryResponse: typeToReducer(
    {
      [LOAD_SENSOR_HISTORY]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  loadStatusHistoryStatus: loadingStateReducer(LOAD_STATUS_HISTORY),
  statusHistoryResponse: typeToReducer(
    {
      [LOAD_STATUS_HISTORY]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  loadSensorAverageStatus: loadingStateReducer(LOAD_SENSOR_AVERAGE),
  sensorAverageResponse: typeToReducer(
    {
      [LOAD_SENSOR_AVERAGE]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  updateTemplateStatus: loadingStateReducer(UPDATE_GRAPH_TEMPLATE)
});

import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import compose from 'lodash/fp/compose';

import { getLoadingState, getSupport } from 'redux/rootSelectors';
import { COLOR_PRIMARY } from 'attrs/colors';
import { showSalesforceModal } from 'redux/ui/actions';
import { getShowSalesforceModal } from 'redux/ui/selectors';
import { isLoading } from 'helpers/utils';
import { setConfirmationRequest } from 'redux/ui/confirmations/actions';

import {
  StyledDialog,
  StyledDialogHeader,
  StyledDialogSubHeader,
  StyledDialogBody,
  StyledDialogActions
} from './SalesforceModal.styled';

import SupportForm from './SupportForm';

const SalesforceModal = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [formikHelpers, setFormikHelpers] = useState({});
  const isOpen = useSelector(getShowSalesforceModal);
  const { status: loadingStatus } = useSelector(compose(getLoadingState, getSupport));
  const isSubmitting = isLoading(loadingStatus);

  const handleFormChange = useCallback(({ dirty, resetForm, submitForm, submitDisabled }) => {
    setFormikHelpers({ dirty, resetForm, submitForm, submitDisabled });
  }, []);

  const handleCloseModal = () => {
    if (formikHelpers.dirty) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => dispatch(showSalesforceModal(false))
        })
      );
    } else {
      dispatch(showSalesforceModal(false));
    }
  };

  return (
    <StyledDialog fullScreen open={isOpen}>
      <StyledDialogHeader theme={theme} highlightColor={COLOR_PRIMARY} onClose={handleCloseModal} narrow>
        {t('support.salesforce.title')}
      </StyledDialogHeader>
      <StyledDialogSubHeader theme={theme} withDivider={false} narrow>
        {t('support.salesforce.title_subtext')}
      </StyledDialogSubHeader>
      <StyledDialogBody narrow>
        <SupportForm onFormChange={handleFormChange} />
      </StyledDialogBody>
      <StyledDialogActions>
        <Button color="primary" size="large" onClick={formikHelpers.resetForm} disableElevation>
          {t('form.reset')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={formikHelpers.submitForm}
          disabled={formikHelpers.submitDisabled || isSubmitting}
          disableElevation
        >
          {t('form.submit')}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default SalesforceModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Toolbar, IconButton, Link } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotifyIcon } from 'web-components';

import SetPassword from './SetPassword';
import { checkTokenCode } from '../../../../redux/auth/actions';
import { NAVIGATION_BAR_HEIGHT, NAVIGATION_BAR_LOGO_HEIGHT } from '../../../../attrs/layout';
import { AppBarContentWrapper, StyledAppBar } from '../../../../elements';
import Logo from '../../../../attrs/logo';
import { getCheckAuthCode } from '../../../../redux/auth/selectors';
import { LANGUAGES } from '../../../../attrs/languages';

const ConnectedSetPassword = () => {
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getCheckAuthCode);
  const { token } = useParams();
  const { search } = useLocation();
  const { i18n } = useTranslation();

  // Check for query string 'lang' in URL to change language accordingly
  const sp = new URLSearchParams(search);
  const lang = sp.get('lang');

  useEffect(() => {
    if (LANGUAGES.some(language => language.value === lang)) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  useEffect(() => {
    dispatch(checkTokenCode(token || undefined));
  }, [dispatch, token]);

  return (
    <Container
      maxWidth="sm"
      style={{
        marginTop: NAVIGATION_BAR_HEIGHT,
        paddingTop: '2rem'
      }}
    >
      <StyledAppBar position="fixed">
        <Toolbar variant="dense">
          <IconButton as={Link} edge="start" color="inherit" aria-label="back" href="/login">
            <NotifyIcon iconName="chevronLeft" />
          </IconButton>
          <AppBarContentWrapper center>
            <Logo height={`${NAVIGATION_BAR_LOGO_HEIGHT}px`} style={{ minWidth: '70px' }} />
          </AppBarContentWrapper>
          <div style={{ width: '48px' }} />
        </Toolbar>
      </StyledAppBar>
      <SetPassword loadingState={loadingState} reloadAction={() => dispatch(checkTokenCode(token))} token={token} />
    </Container>
  );
};

export default ConnectedSetPassword;

import T from 'prop-types';
import i18n from 'i18next';
import {
  getMachineType,
  getMillBeadMaterialType,
  getMillGrindingTechnology,
  getMillInnerLinerMaterial,
  isNullUndefinedOrEmpty
} from '../../../helpers/utils';

const getData = data => {
  if (!data) {
    return null;
  }

  const machineType = getMachineType(data.type);
  const grindingTechnology = getMillGrindingTechnology(data.mechanical_config.grinding_technology);
  const innerLinerMaterial = getMillInnerLinerMaterial(data.mechanical_config.inner_liner_material);
  const beadMaterialType = getMillBeadMaterialType(data.mechanical_config.bead_material);

  let sambaSystemKey = 'machines.detail.samba_system_false';
  if ([null, undefined].includes(data.mechanical_config.samba_system)) {
    sambaSystemKey = 'machines.detail.samba_system_undefined';
  } else if (data.mechanical_config.samba_system === true) {
    sambaSystemKey = 'machines.detail.samba_system_true';
  }

  /**
   Fields and data for machine WITH bach records enabled
   * @type {{localeKey: string, rows: [{id: string, row: [{}]}]}}
   */
  const machineDetailsData = {
    localeKey: 'machine_details',
    rows: [
      {
        id: 'machine-row-1',
        row: [
          {
            localeKey: 'commission_no',
            value: data.commission_number
          },
          {
            localeKey: 'name',
            value: data.name || i18n.t('machines.overview.machine_name_not_assigned')
          },
          {
            localeKey: 'brand',
            value: machineType.brand
          },
          {
            localeKey: 'production_line',
            value: data.production_line
          },
          {
            localeKey: 'mill_system',
            value: machineType.type
          },
          {
            localeKey: 'grinding_technology',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.grinding_technology) ? grindingTechnology.name : '--'
          },
          {
            localeKey: 'inner_liner_material',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.inner_liner_material) ? innerLinerMaterial.name : '--'
          },
          {
            localeKey: 'samba_system',
            value: i18n.t(sambaSystemKey)
          },
          {
            localeKey: 'sieve_size',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.sieve_size) ? data.mechanical_config.sieve_size : '--'
          }
        ]
      },
      {
        id: 'machine-beads',
        localeKey: 'beads',
        row: [
          {
            localeKey: 'bead_material',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.bead_material) ? beadMaterialType.name : '--'
          },
          {
            localeKey: 'bead_size_1',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.beads_size_1)
              ? data.mechanical_config.beads_size_1
              : '--'
          },
          {
            localeKey: 'bead_size_2',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.beads_size_2)
              ? data.mechanical_config.beads_size_2
              : '--'
          },
          {
            localeKey: 'bead_filling_level',
            value: !isNullUndefinedOrEmpty(data.mechanical_config.beads_filling_level)
              ? data.mechanical_config.beads_filling_level
              : '--'
          }
        ]
      }
    ]
  };

  /**
   * Fields and data for machine WITHOUT bach records enabled
   * @type {{localeKey: string, rows: [{id: string, row: [{}]}]}}
   */
  const noBatchDetailsData = {
    localeKey: 'machine_details',
    rows: [
      {
        id: 'machine-row-1',
        row: [
          {
            localeKey: 'commission_no',
            value: data.commission_number
          },
          {
            localeKey: 'name',
            value: data.name || i18n.t('machines.overview.machine_name_not_assigned')
          },
          {
            localeKey: 'brand',
            value: machineType.brand
          },
          {
            localeKey: 'production_line',
            value: data.production_line
          }
        ]
      }
    ]
  };

  return { machineDetailsData, noBatchDetailsData };
};

getData.propTypes = {
  data: T.shape({}).isRequired
};

export { getData };

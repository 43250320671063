const LOAD_MACHINES = 'MACHINES/LOAD';
const LOAD_MACHINES_RESUMED = 'MACHINES/LOAD-RESUMED';
const LOAD_MACHINE = 'MACHINES/LOAD_MACHINE';
const UPDATE_MACHINE = 'MACHINE/UPDATE';
const UPDATE_MACHINE_STATUS = 'MACHINE/UPDATE_STATUS';
const UPDATE_MACHINE_SENSOR_STATUS = 'MACHINE/UPDATE_MACHINE_SENSOR_STATUS';
const RESET_MACHINE = 'MACHINE/RESET';
const RESET_MACHINES = 'MACHINES/RESET';
const LOAD_MACHINE_HISTORY = 'MACHINE/LOAD_HISTORY';
const LOAD_SCOPED_MACHINE_HISTORY = 'MACHINE/LOAD_SCOPED_HISTORY';
const LOAD_MACHINE_ERROR = 'MACHINE/LOAD_ERROR';
const LOAD_UNRESOLVED_MACHINE_ERROR = 'MACHINE/LOAD_UNRESOLVED_ERROR';
const ADD_SENSORS_TO_MACHINE = 'MACHINE/ADD_SENSORS';
const LOAD_LAST_SENSOR_METRICS = 'MACHINE/LOAD_LAST_SENSOR_METRICS';
const LOAD_LAST_SENSOR_METRIC = 'MACHINE/LOAD_LAST_SENSOR_METRIC';
const LOAD_SENSOR = 'MACHINE/LOAD_SENSOR';
const LOAD_SENSOR_HISTORY = 'MACHINE/LOAD_SENSOR_HISTORY';
const LOAD_SCOPED_SENSOR_HISTORY = 'MACHINE/LOAD_SCOPED_SENSOR_HISTORY';
const LOAD_MACHINE_NOTIFICATION_RULES = 'MACHINE/LOAD_NOTIFICATION_RULES';
const HANDLE_NOTIFICATION_SUBMIT = 'MACHINES/HANDLE_NOTIFICATION_SUBMIT';
const CREATE_NOTIFICATION_RULE = 'MACHINES/CREATE_NOTIFICATION_RULE';
const UPDATE_NOTIFICATION_RULE = 'MACHINES/UPDATE_NOTIFICATION_RULE';
const DELETE_NOTIFICATION_RULE = 'MACHINES/DELETE_NOTIFICATION_RULE';
const RESET_NOTIFICATION_UPDATE = 'MACHINES/RESET_NOTIFICATION_UPDATE';
const SUBSCRIBE_TO_SENSORS_UPDATE_STREAM = 'MACHINES/SUBSCRIBE_TO_SENSORS_UPDATE_STREAM';
const SUBSCRIBE_TO_MACHINES_UPDATE_STREAM = 'MACHINES/SUBSCRIBE_TO_MACHINES_UPDATE_STREAM';
const MACHINE_EDIT_SHOW_MODAL = 'MACHINES/EDIT_MODAL';
const LOAD_MACHINE_METRICS = 'MACHINES/LOAD_MACHINE_METRICS';

// Comparison
const ADD_SENSORS_COMPARISON = 'MACHINES/SENSOR_COMPARISON/ADD_SENSORS';
const ADD_SENSOR_COMPARISON = 'MACHINES/SENSOR_COMPARISON/ADD_SENSOR';
const REPLACE_SENSOR_COMPARISON = 'MACHINES/SENSOR_COMPARISON/REPLACE_SENSOR';
const REMOVE_SENSOR_COMPARISON = 'MACHINES/SENSOR_COMPARISON/REMOVE_SENSOR';
const RESET_COMPARISON = 'MACHINES/SENSOR_COMPARISON/RESET';
const LOAD_SENSOR_COMPARISON_DATA = 'MACHINES/SENSOR_COMPARISON/LOAD_DATA';
const LOAD_SCOPED_SENSOR_COMPARISON_DATA = 'MACHINES/SENSOR_COMPARISON/LOAD_SCOPED_DATA';

const defaultNullSensorValue = '---';

export {
  LOAD_MACHINES,
  LOAD_MACHINES_RESUMED,
  UPDATE_MACHINE,
  UPDATE_MACHINE_STATUS,
  UPDATE_MACHINE_SENSOR_STATUS,
  LOAD_MACHINE,
  RESET_MACHINE,
  RESET_MACHINES,
  LOAD_MACHINE_HISTORY,
  LOAD_SCOPED_MACHINE_HISTORY,
  LOAD_MACHINE_ERROR,
  LOAD_UNRESOLVED_MACHINE_ERROR,
  ADD_SENSORS_TO_MACHINE,
  LOAD_LAST_SENSOR_METRICS,
  LOAD_LAST_SENSOR_METRIC,
  LOAD_SENSOR,
  LOAD_SENSOR_HISTORY,
  LOAD_MACHINE_NOTIFICATION_RULES,
  HANDLE_NOTIFICATION_SUBMIT,
  CREATE_NOTIFICATION_RULE,
  UPDATE_NOTIFICATION_RULE,
  DELETE_NOTIFICATION_RULE,
  RESET_NOTIFICATION_UPDATE,
  SUBSCRIBE_TO_SENSORS_UPDATE_STREAM,
  SUBSCRIBE_TO_MACHINES_UPDATE_STREAM,
  MACHINE_EDIT_SHOW_MODAL,
  ADD_SENSORS_COMPARISON,
  ADD_SENSOR_COMPARISON,
  REPLACE_SENSOR_COMPARISON,
  REMOVE_SENSOR_COMPARISON,
  RESET_COMPARISON,
  LOAD_SENSOR_COMPARISON_DATA,
  LOAD_SCOPED_SENSOR_HISTORY,
  LOAD_SCOPED_SENSOR_COMPARISON_DATA,
  defaultNullSensorValue,
  LOAD_MACHINE_METRICS
};

const LOAD_MACHINE_SENSORS_BY_ID = 'GRAPH_TEMPLATES/LOAD_MACHINE_SENSORS_BY_ID';
const LOAD_MACHINE_SENSORS_TREEVIEW = 'GRAPH_TEMPLATES/LOAD_MACHINE_SENSORS_TREEVIEW';
const LOAD_PRODUCTION_LINES = 'GRAPH_TEMPLATES/LOAD_PRODUCTION_LINES';
const LOAD_MACHINES = 'GRAPH_TEMPLATES/LOAD_MACHINES';
const LOAD_SENSORS = 'GRAPH_TEMPLATES/LOAD_SENSORS';

export {
  LOAD_MACHINE_SENSORS_BY_ID,
  LOAD_MACHINE_SENSORS_TREEVIEW,
  LOAD_PRODUCTION_LINES,
  LOAD_MACHINES,
  LOAD_SENSORS
};

import { Layout } from 'web-components';

const NAVIGATION_BAR_HEIGHT = Layout.navbarHeight || 65;
const NAVIGATION_BAR_LOGO_HEIGHT = Layout.navbarLogoHeight || 35;
const PAGE_HEADER_BAR_HEIGHT = Layout.pageHeaderBarHeight || 110;
const FOOTER_NAVIGATION_BAR_HEIGHT = Layout.footerNavbarHeight || 90;
const SUB_NAVIGATION_WIDTH = Layout.subNavWidth || 370;
const MENU_ITEM_HEIGHT = Layout.menuItemHeight || 60;
const MENU_PROFILE_ITEM_HEIGHT = Layout.menuProfileItemHeight || 100;
const EMPTY_STATE_IMAGE_SIZE_DESKTOP = Layout.emptyStateImageSizeDesktop || 264;
const EMPTY_STATE_IMAGE_SIZE_TABLET = Layout.emptyStateImageSizeTablet || 200;

const GRAPH_HEIGHT = 300;
const GRAPH_MOBILE_HEIGHT = 250;
const BRUSH_HEIGHT = 60;
const BRUSH_PADDING = 30;
const COMPARISON_FOOTER_HEIGHT = 108;
const METRICS_DEFAULT_TIME_FRAME_IN_HOURS = 8;

const NOTIFICATION_LIST_DEFAULT_AMOUNT = 5;
const NOTIFICATION_LIST_STEP_AMOUNT = 5;

const METRIC_UNITS = {
  C: '°C',
  cP: 'cP',
  'g/cm3': 'g/cm3',
  rpm: 'rpm',
  '1/min': '1/min',
  kWh: 'kWh',
  h: 'h',
  '%': '%',
  'm/s': 'm/s',
  A: 'A',
  'l/h': 'l/h',
  bar: 'bar',
  'pa.s': 'Pa·s',
  W: 'W',
  kW: 'kW'
};

export {
  NAVIGATION_BAR_HEIGHT,
  NAVIGATION_BAR_LOGO_HEIGHT,
  MENU_ITEM_HEIGHT,
  SUB_NAVIGATION_WIDTH,
  MENU_PROFILE_ITEM_HEIGHT,
  FOOTER_NAVIGATION_BAR_HEIGHT,
  PAGE_HEADER_BAR_HEIGHT,
  EMPTY_STATE_IMAGE_SIZE_DESKTOP,
  EMPTY_STATE_IMAGE_SIZE_TABLET,
  METRICS_DEFAULT_TIME_FRAME_IN_HOURS,
  METRIC_UNITS,
  NOTIFICATION_LIST_DEFAULT_AMOUNT,
  NOTIFICATION_LIST_STEP_AMOUNT,
  BRUSH_HEIGHT,
  BRUSH_PADDING,
  GRAPH_HEIGHT,
  GRAPH_MOBILE_HEIGHT,
  COMPARISON_FOOTER_HEIGHT
};

import axios from './axios';

const BATCH_NOTES_URL = '/batch_notes';

/**
 * Get all batch notes from a batch
 * @param batchId
 * @returns {AxiosPromise}
 */
const getAllBatchNotesByBatchId = batchId =>
  axios({
    method: 'get',
    url: `/v1${BATCH_NOTES_URL}/batch/${batchId}`
  });

/**
 * Get
 * @param batchNotedId
 * @returns {AxiosPromise}
 */
const getBatchNoteById = batchNotedId =>
  axios({
    method: 'get',
    url: `/v1${BATCH_NOTES_URL}/${batchNotedId}`
  });

/**
 * Create a new batch note
 * @param batchNote
 * @returns {AxiosPromise}
 */
const createBatchNote = data =>
  axios({
    method: 'post',
    url: `/v1${BATCH_NOTES_URL}`,
    data
  });

/**
 * Delete a batch note
 * @param batchNotedId
 * @returns {AxiosPromise}
 */
const deleteBatchNote = batchNotedId =>
  axios({
    method: 'delete',
    url: `/v1${BATCH_NOTES_URL}/${batchNotedId}`
  });

/**
 * Update a batch note
 * @param batchNotedId
 * @param batchNote
 * @returns {AxiosPromise}
 */
const updateBatchNote = (batchNotedId, batchNote) =>
  axios({
    method: 'put',
    url: `/v1${BATCH_NOTES_URL}/${batchNotedId}`,
    data: batchNote
  });

export { createBatchNote, deleteBatchNote, updateBatchNote, getAllBatchNotesByBatchId, getBatchNoteById };

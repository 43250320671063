import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, IconButton, Typography } from '@mui/material';
import { MachineIcon } from 'web-components';
import { getSensorProps } from 'helpers/utils';
import { SENSOR_STATUS_OK } from 'attrs/notifications';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { getLineStyle } from 'attrs/chartsTemplate';
import { generateColor } from 'helpers/colorUtils';
import EditSensorCard from './EditSensorCard';
import DeleteSensorCard from './DeleteSensorCard';

function CarouselCard({ sensorIdx, sensor, handleEditCardSensor, handleDeleteSensor }) {
  const { t } = useTranslation();
  const millSensor = getMillSensor(sensor?.type);
  const name = t(millSensor?.name || sensor?.name);
  const mapValue = millSensor?.mapValue || sensor?.custom_icon_type || 'n/a';
  const [openEditSensorDialog, setOpenEditSensorDialog] = useState(false);
  const [openDeleteSensorDialog, setOpenDeleteSensorDialog] = useState(false);

  const handleOpenEditSensorDialog = () => {
    setOpenEditSensorDialog(true);
  };

  const handleCloseEditSensorDialog = () => {
    setOpenEditSensorDialog(false);
  };

  const handleOpenDeleteSensorDialog = () => {
    setOpenDeleteSensorDialog(true);
  };

  const handleCloseDeleteSensorDialog = () => {
    setOpenDeleteSensorDialog(false);
  };

  const handleDeleteCard = () => {
    handleDeleteSensor(sensor?.temp_id || sensor?.id);
  };

  const machineIconProps = {
    iconName: getSensorProps(mapValue).icon,
    // accepted status type: SENSOR_STATUS_NODATA, SENSOR_STATUS_OK, SENSOR_STATUS_WARNING, SENSOR_STATUS_CRITICAL
    status: SENSOR_STATUS_OK,
    style: {
      fontSize: '2rem'
    },
    viewBox: '0 0 32 32'
  };

  return (
    <Paper
      sx={{
        minWidth: 300,
        maxWidth: 300,
        minHeight: 96,
        maxHeight: 96,
        border: `${sensor?.line_thickness || 1}px ${getLineStyle(sensor?.line_style)}`,
        borderColor: sensor?.line_color || generateColor(sensorIdx),
        borderRadius: 2,
        mr: 2,
        p: 1
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Stack direction="column" justifyContent="space-around" alignItems="flex-start" spacing={0.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <MachineIcon {...machineIconProps} />
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{t(`${name}`)}</Typography>
          </Stack>
          <Typography sx={{ fontSize: 10 }}>{sensor?.production_line}</Typography>
          <Typography sx={{ fontSize: 10 }}>{sensor?.machine_name}</Typography>
        </Stack>
        <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={0}>
          <IconButton aria-label="delete" onClick={handleOpenEditSensorDialog}>
            <ModeEditOutlinedIcon color="primary" fontSize="inherit" />
          </IconButton>
          <IconButton aria-label="edit" onClick={handleOpenDeleteSensorDialog}>
            <DeleteForeverOutlinedIcon sx={{ color: '#F13309' }} fontSize="inherit" />
          </IconButton>
        </Stack>
      </Stack>
      <EditSensorCard
        open={openEditSensorDialog}
        sensorIdx={sensorIdx}
        sensor={sensor}
        handleCloseDialog={handleCloseEditSensorDialog}
        handleApply={handleEditCardSensor}
      />
      <DeleteSensorCard
        open={openDeleteSensorDialog}
        handleCloseDialog={handleCloseDeleteSensorDialog}
        handleApply={handleDeleteCard}
      />
    </Paper>
  );
}

CarouselCard.propTypes = {
  sensorIdx: PropTypes.number.isRequired,
  sensor: PropTypes.shape().isRequired,
  handleEditCardSensor: PropTypes.func.isRequired,
  handleDeleteSensor: PropTypes.func.isRequired
};

export default CarouselCard;

import React from 'react';
import T from 'prop-types';
import ProductionLine from './ProductionLine';
import EmptyState from '../../components/EmptyState';
import { ContainerProductionLine } from './elements';

const StatusOverview = ({ machines }) => (
  <>
    {Object.keys(machines).length > 0 ? (
      <ContainerProductionLine>
        {Object.keys(machines).map(productionLine => (
          <ProductionLine key={productionLine} title={productionLine} machines={machines[productionLine]} />
        ))}
      </ContainerProductionLine>
    ) : (
      <EmptyState
        type="machines"
        action={{
          permissionBase: 'dashboard',
          permissionField: 'canEditMachines'
        }}
      />
    )}
  </>
);

StatusOverview.propTypes = {
  machines: T.shape({})
};

StatusOverview.defaultProps = {
  machines: null
};

export default StatusOverview;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Grid } from '@mui/material';

import { NotifyTypo } from 'web-components';

import SelectedComparisonSensorCard from 'components/SensorCard/SelectedComparisonSensorCard';
import ComparisonSensorCardPlaceholder from 'components/SensorCard/ComparisonSensorCardPlaceholder';

const placeHolderKeys = [
  'placeholder-0',
  'placeholder-1',
  'placeholder-2',
  'placeholder-3',
  'placeholder-4',
  'placeholder-5'
];

const SelectedSensors = ({ sensors, selectedSensorTypes, onCancelClick }) => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <NotifyTypo.Heading2 color="primary" gutterBottom>
        {t('general.graphs.compare_sensors_selected_sensors')}
      </NotifyTypo.Heading2>
      <Grid container spacing={2}>
        {selectedSensorTypes.map(type => {
          const sensor = sensors.find(item => item.type === type);
          return (
            <Grid key={sensor.type} item xs={4}>
              <SelectedComparisonSensorCard onCancelClick={onCancelClick} sensor={sensor} />
            </Grid>
          );
        })}
        {placeHolderKeys.slice(selectedSensorTypes.length).map(placeholderKey => (
          <Grid key={placeholderKey} item xs={4}>
            <ComparisonSensorCardPlaceholder />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

SelectedSensors.propTypes = {
  sensors: T.arrayOf(T.shape({})).isRequired,
  selectedSensorTypes: T.arrayOf(T.string).isRequired,
  onCancelClick: T.func.isRequired
};

export default SelectedSensors;

export const BATCH_TARGET_TYPE_TIME = 'TIME';
export const BATCH_TARGET_TYPE_GRINDING_ENERGY = 'GRINDING_ENERGY';
export const BATCH_TARGET_TYPE_SPECIFIC_ENERGY = 'SPECIFIC_ENERGY';
export const BATCH_TARGET_TYPE_NUMBER_CYCLES = 'NUMBER_CYCLES';
export const BATCH_DEFAULT_LIST_SORT = 'end_time';
export const BATCH_DEFAULT_LIST_ORDER = 'desc';

export const BATCH_TARGET_TYPE_DECIMAL = {
  [BATCH_TARGET_TYPE_GRINDING_ENERGY]: 1,
  [BATCH_TARGET_TYPE_SPECIFIC_ENERGY]: 3,
  [BATCH_TARGET_TYPE_NUMBER_CYCLES]: 1
};

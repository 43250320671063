import * as constants from './constants';

const loadMachines = () => ({
  type: constants.LOAD_MACHINES
});

const loadMachinesResumed = () => ({
  type: constants.LOAD_MACHINES_RESUMED
});

const loadMachine = id => ({
  type: constants.LOAD_MACHINE,
  id
});

const updateMachine = (id, values) => ({
  type: constants.UPDATE_MACHINE,
  id,
  values
});

const resetMachineState = () => ({
  type: constants.RESET_MACHINE
});

const resetMachinesState = () => ({
  type: constants.RESET_MACHINES
});

const loadMachineStatusMetrics = (id, timeWindow = false, timeRange = null) => ({
  type: constants.LOAD_MACHINE_HISTORY,
  id,
  timeWindow,
  timeRange
});

const loadScopedMachineHistory = (id, timeWindow) => ({
  type: constants.LOAD_SCOPED_MACHINE_HISTORY,
  id,
  timeWindow
});

const loadMachineError = (id, updatedAt) => ({
  type: constants.LOAD_MACHINE_ERROR,
  id,
  updatedAt
});

const loadUnresolvedMachineError = id => ({
  type: constants.LOAD_UNRESOLVED_MACHINE_ERROR,
  id
});

const addSensorsToMachine = sensors => ({
  type: constants.ADD_SENSORS_TO_MACHINE,
  sensors
});

const loadLastSensorsMetrics = (id, sensors) => ({
  type: constants.LOAD_LAST_SENSOR_METRICS,
  id,
  sensors
});

const loadLastSensorsMetric = (id, sensorType) => ({
  type: constants.LOAD_LAST_SENSOR_METRIC,
  id,
  sensorType
});

const loadSensor = (id, sensorType, timeWindow, timeRange) => ({
  type: constants.LOAD_SENSOR,
  id,
  sensorType,
  timeWindow,
  timeRange
});

const loadSelectedSensorHistory = (id, sensorType, timeWindow = false, timeRange = false) => ({
  type: constants.LOAD_SENSOR_HISTORY,
  id,
  sensorType,
  timeWindow,
  timeRange
});

const loadScopedSensorHistory = (id, sensorType, timeFrame) => ({
  type: constants.LOAD_SCOPED_SENSOR_HISTORY,
  id,
  sensorType,
  timeFrame
});

const loadMachineNotificationRules = (id, sensorType = null) => ({
  type: constants.LOAD_MACHINE_NOTIFICATION_RULES,
  id,
  sensorType
});

const handleNotificationSubmit = values => ({
  type: constants.HANDLE_NOTIFICATION_SUBMIT,
  values
});

const createNotificationRule = values => ({
  type: constants.CREATE_NOTIFICATION_RULE,
  values
});

const updateNotificationRule = (id, values) => ({
  type: constants.UPDATE_NOTIFICATION_RULE,
  id,
  values
});

const deleteNotificationRule = id => ({
  type: constants.DELETE_NOTIFICATION_RULE,
  id
});

const resetNotificationUpdateState = () => ({
  type: constants.RESET_NOTIFICATION_UPDATE
});

const updateMachineStatus = (payload, selectedMachineId) => ({
  type: constants.UPDATE_MACHINE_STATUS,
  payload,
  selectedMachineId
});

const updateMachineSensorStatus = (sensor, selectedSensorType) => ({
  type: constants.UPDATE_MACHINE_SENSOR_STATUS,
  sensor,
  selectedSensorType
});

const subscribeToSensorsUpdateStream = id => ({
  type: constants.SUBSCRIBE_TO_SENSORS_UPDATE_STREAM,
  id
});

const subscribeToMachinesUpdateStream = () => ({
  type: constants.SUBSCRIBE_TO_MACHINES_UPDATE_STREAM
});

const addSensorsForComparison = (sensorTypes, id) => ({
  type: constants.ADD_SENSORS_COMPARISON,
  sensorTypes,
  id,
  isCompare: true
});

const addSensorForComparison = sensorType => ({
  type: constants.ADD_SENSOR_COMPARISON,
  sensorType
});

const removeSensorFromComparison = sensorType => ({
  type: constants.REMOVE_SENSOR_COMPARISON,
  sensorType
});

const replaceSensorInComparison = (oldSensorType, newSensorType) => ({
  type: constants.REPLACE_SENSOR_COMPARISON,
  oldSensorType,
  newSensorType
});

const resetComparison = () => ({
  type: constants.RESET_COMPARISON
});

const loadComparisonData = (sensorTypes, timeWindow = false, timeRange = false) => ({
  type: constants.LOAD_SENSOR_COMPARISON_DATA,
  sensorTypes,
  timeWindow,
  timeRange,
  isCompare: true
});

const loadScopedComparisonData = (id, sensorTypes, timeFrame) => ({
  type: constants.LOAD_SCOPED_SENSOR_COMPARISON_DATA,
  id,
  sensorTypes,
  timeFrame,
  isCompare: true
});

const showMachineEditModal = show => ({
  type: constants.MACHINE_EDIT_SHOW_MODAL,
  value: show
});

const loadMachineMetrics = id => ({
  type: constants.LOAD_MACHINE_METRICS,
  id
});

export {
  loadMachines,
  loadMachinesResumed,
  updateMachine,
  updateMachineStatus,
  updateMachineSensorStatus,
  loadMachine,
  resetMachineState,
  resetMachinesState,
  loadMachineStatusMetrics,
  loadMachineError,
  loadUnresolvedMachineError,
  addSensorsToMachine,
  loadLastSensorsMetrics,
  loadLastSensorsMetric,
  loadSelectedSensorHistory,
  loadMachineNotificationRules,
  handleNotificationSubmit,
  createNotificationRule,
  updateNotificationRule,
  deleteNotificationRule,
  resetNotificationUpdateState,
  subscribeToSensorsUpdateStream,
  subscribeToMachinesUpdateStream,
  addSensorsForComparison,
  addSensorForComparison,
  replaceSensorInComparison,
  removeSensorFromComparison,
  resetComparison,
  loadComparisonData,
  loadScopedSensorHistory,
  loadScopedMachineHistory,
  loadScopedComparisonData,
  loadSensor,
  showMachineEditModal,
  loadMachineMetrics
};

import React from 'react';
import T from 'prop-types';

import { DataTable, NotifyIcon } from 'web-components';

import { IconButton } from '@mui/material';
import MachineTrackStatusIcon from '../MachineTrackStatusIcon';
import { MACHINE_STATUS_TRACKED, MACHINE_STATUS_UNTRACKED } from '../MachineTrackStatusIcon/MachineTrackStatusIcon';
import { getMachineType } from '../../helpers/utils';

export const COLUMN_COMMISSION_NUMBER = 'commission_number';
export const COLUMN_STATUS = 'status';
export const COLUMN_MACHINE_NAME = 'name';
export const COLUMN_PRODUCTION_LINE = 'production_line';
export const COLUMN_BRAND = 'brand';
export const COLUMN_MILL_SYSTEM = 'mill_system';
export const COLUMN_EDIT = 'edit';

export const generateColumns = (t, handleEditRow) => [
  {
    id: COLUMN_COMMISSION_NUMBER,
    label: t(`machines.overview.commission_number`),
    sortable: false
  },
  {
    id: COLUMN_STATUS,
    label: t(`machines.overview.status`),
    sortable: false,
    cellRenderer: row => {
      const status = row.name !== '' && row.production_line !== '' ? MACHINE_STATUS_TRACKED : MACHINE_STATUS_UNTRACKED;
      return (
        <div className="statusWrapper">
          <MachineTrackStatusIcon status={status} />
        </div>
      );
    }
  },
  {
    id: COLUMN_MACHINE_NAME,
    label: t(`machines.overview.name`),
    sortable: false,
    cellRenderer: row => <div>{row.name || '--'}</div>
  },
  {
    id: COLUMN_PRODUCTION_LINE,
    label: t(`machines.overview.production_line`),
    sortable: false,
    cellRenderer: row => <div>{row.production_line || '--'}</div>
  },
  {
    id: COLUMN_BRAND,
    label: t(`machines.overview.brand`),
    sortable: false,
    cellRenderer: row => {
      const machineType = getMachineType(row.type);
      return <div>{machineType.brand || t('machines.overview.machine_name_not_assigned')}</div>;
    }
  },
  {
    id: COLUMN_MILL_SYSTEM,
    label: t(`machines.overview.mill_system`),
    sortable: false,
    cellRenderer: row => {
      const machineType = getMachineType(row.type);
      return <div>{machineType.type || t('machines.overview.machine_name_not_assigned')}</div>;
    }
  },
  {
    id: COLUMN_EDIT,
    label: '',
    sortable: false,
    cellRenderer: row => (
      <IconButton
        aria-label="edit_machine_name"
        color="primary"
        onClick={e => {
          e.stopPropagation();
          handleEditRow(row);
        }}
      >
        <NotifyIcon fontSize="default" iconName="edit" color="primary" />
      </IconButton>
    )
  }
];

export const generateSortMenuItems = t => [
  {
    label: t('machines.overview.order'),
    value: null,
    disabled: true
  },
  {
    label: `${t('machines.overview.start_time')} - ${t('machines.overview.ascending')}`,
    value: { colId: COLUMN_STATUS, order: DataTable.ORDER_ASC }
  },
  {
    label: `${t('machines.overview.start_time')} - ${t('machines.overview.descending')}`,
    value: { colId: COLUMN_MACHINE_NAME, order: DataTable.ORDER_DESC }
  },
  {
    label: `${t('machines.overview.end_time')} - ${t('machines.overview.ascending')}`,
    value: { colId: COLUMN_COMMISSION_NUMBER, order: DataTable.ORDER_ASC }
  },
  {
    label: `${t('machines.overview.end_time')} - ${t('machines.overview.descending')}`,
    value: { colId: COLUMN_MILL_SYSTEM, order: DataTable.ORDER_DESC }
  }
];

export const MachinesColumnProps = T.arrayOf(
  T.shape({
    id: T.string.isRequired,
    label: T.string.isRequired,
    sortable: T.bool,
    cellRenderer: T.func
  })
);

export const MachinesRowProps = T.shape({
  commission_number: T.string.isRequired,
  name: T.string.isRequired,
  production_line: T.string.isRequired,
  type: T.string.isRequired
});

export const MachinesRowsProps = T.arrayOf(MachinesRowProps);

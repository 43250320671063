import styled from 'styled-components';

import { SECONDARY_TEXT_COLOR } from 'attrs/colors';
import { NotifyTypo } from 'web-components';

export const Wrapper = styled.div`
  margin: 0;
`;

export const BlockTitle = styled(NotifyTypo.Caption)`
  &.MuiTypography-root {
    display: block;
    padding-bottom: 1.25rem;
    color: ${SECONDARY_TEXT_COLOR};
    font-weight: 500;
  }
`;

export const Content = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.25rem 0.25rem;

  ${theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.breakpoints.down('xs')} {
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`
);

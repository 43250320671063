import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Machines from './Machines';
import { loadMachinesResumed, resetMachinesState } from '../../redux/machines/actions';

const ConnectedMachines = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(state => state.machines);

  useEffect(() => {
    dispatch(loadMachinesResumed());
  }, [dispatch]);

  useEffect(() => () => dispatch(resetMachinesState()), [dispatch]);

  return <Machines loadingState={loadingState} reloadAction={() => dispatch(loadMachinesResumed())} machines={data} />;
};

export default ConnectedMachines;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer, enhanceWithResetActions } from '../utils';

import { LOGOUT } from '../auth/constants';
import { LOAD_WEBSOCKET_TOKEN } from './constants';

export default combineReducers({
  loadingState: enhanceWithResetActions([LOGOUT])(loadingStateReducer(LOAD_WEBSOCKET_TOKEN)),
  websocketToken: typeToReducer(
    {
      [LOAD_WEBSOCKET_TOKEN]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    null
  )
});

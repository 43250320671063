import styled from 'styled-components';
import { Divider, Paper } from '@mui/material';
import { NotifyTypo } from 'web-components';

export const StyledPaper = styled(Paper)`
  padding: 1.25rem;
  margin-bottom: 2.5rem;
`;

export const HeadingWrapper = styled.div(
  ({ expanded }) => `
  display: flex;
  justify-content: space-between;
  padding-bottom: ${expanded ? '0.75' : '0'}rem;

  & > .MuiTypography-root {
    font-weight: 500;
  }

  & > .MuiButton-root {
    padding 0 0.25rem;
    font-weight: 0.875rem;
  }
  
  & .statusWrapper {
    display: flex;
    align-items: center;
    min-height: 1.875rem;
    padding-left: 1rem;
  }
`
);

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 1.25rem 0;
  }
`;

export const NotifyTypoCaption = styled(NotifyTypo.Caption)`
  font-weight: 500 !important;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  height: 2.625rem;
`;

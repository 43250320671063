import styled from 'styled-components';
import { Button } from '@mui/material';

const EmptyStateWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const ButtonEmpty = styled(Button)`
  &.MuiButton-root {
    margin-top: 1rem;
    padding: 1rem 8rem;

    @media (max-width: 375px) {
      padding: 1rem 6rem;
    }
  }
`;
export { EmptyStateWrapper, ButtonEmpty };

import moment from 'moment';
import { orderBy } from 'lodash';
import { getEquallyDistributedTicks } from '../graphUtils';
import { MACHINE_FUTURE } from '../../../attrs/notifications';

const createChartData = (data, timeWindow) => {
  const ordered = orderBy(data, item => item.updated_at, 'asc');
  const to = moment(timeWindow.to).valueOf();
  const chartData = [];

  ordered.forEach((td, key) => {
    const returning = [
      {
        [ordered[key - 1] ? ordered[key - 1].value : ordered[0].value]: 1,
        activeStatus: ordered[key - 1] ? ordered[key - 1].value : ordered[0].value,
        updated_at: moment(td.updated_at).valueOf(),
        value: ordered[key - 1] ? ordered[key - 1].value : ordered[0].value,
        warning: Number(ordered[key - 1] ? ordered[key - 1].warning : ordered[0].warning)
      },
      {
        ...td,
        [td.value]: 1,
        updated_at: moment(td.updated_at).valueOf(),
        activeStatus: td.value,
        warning: Number(td.warning)
      }
    ];

    if (ordered[key + 1]) {
      returning.push({
        ...td,
        [td.value]: 1,
        updated_at: moment(ordered[key + 1].updated_at).valueOf(),
        activeStatus: td.value,
        warning: Number(td.warning)
      });
    }

    chartData.push(...returning);
  });

  // Add entry for last entry on timestamp "now" (! "now" highest needs to be now)
  chartData.push({
    [chartData[chartData.length - 1] && chartData[chartData.length - 1].value]: 1,
    value: chartData[chartData.length - 1] && chartData[chartData.length - 1].value,
    updated_at: to > moment().valueOf() ? moment().valueOf() : to,
    activeStatus: chartData[chartData.length - 1] && chartData[chartData.length - 1].value
  });

  return chartData;
};

const ARTIFICAL_PERIOD = 10;

const generateMachineData = (data, timeWindow) => {
  const xAxisTicks = getEquallyDistributedTicks(timeWindow).sort();
  const xAxisDomain = [xAxisTicks[0], xAxisTicks[xAxisTicks.length - 1]];
  const from = moment(timeWindow.from).valueOf();
  const to = moment(timeWindow.to).valueOf();

  const initChartData = createChartData(data, timeWindow);
  const realChartData = [];

  // Adds an item every (ARTIFICAL_PERIOD) s
  // Since we can not connectNulls in the Brush chart for Area,
  // we check which status should be assigned to the specific time

  // IMPORTANT / TODO
  // This approach can result in worst in a ARTIFICAL_PERIOD second status appearance delay in the UI
  // Ideally, initChartData + artifical ones get merged properly, but the brush does currently not allow for
  // proper time selection brush an needs to have equal values all over [that we do not have for machine status changes]

  // TODO do it the other way round. For every item in original data we will create points ARTIFICAL_PERIOD
  for (let time = from; time <= to; time += ARTIFICAL_PERIOD * 1000) {
    const filteredData = initChartData.filter(item => item.updated_at >= time);

    const relevantInitData =
      filteredData.length > 0
        ? filteredData[0]
        : {
            [null]: 1,
            activeStatus: null,
            value: time > moment().valueOf() ? MACHINE_FUTURE : null
          };

    realChartData.push(
      {
        ...relevantInitData,
        updated_at: time
      },
      {
        ...relevantInitData,
        updated_at: time + ARTIFICAL_PERIOD * 1000
      }
    );
  }

  const chartData = orderBy(realChartData, item => item.updated_at, 'asc');

  return {
    chartData,
    xAxisTicks,
    xAxisDomain
  };
};

export { createChartData, generateMachineData };

import { PAGINATION_DEFAULT_ROWS_PER_PAGE } from '../../attrs/pagination';
import { ALERT_HISTORY_DEFAULT_LIST_SORT, LIST_ORDER } from '../../attrs/sorter_default_values';
import * as constants from './constants';

/**
 * Load alert history
 * @param page
 * @param limit
 * @param sort
 * @param order
 * @param machineIds
 * @param periodTime
 * @param productionLines
 * @returns {{periodTime, limit: number, machineIds, page, sort: string, type: string, order: string, productionLines}}
 */
const loadAlertHistory = (
  page,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  sort = ALERT_HISTORY_DEFAULT_LIST_SORT,
  order = LIST_ORDER,
  machineIds,
  periodTime,
  productionLines
) => ({
  type: constants.LOAD_ALERTS_HISTORY,
  page,
  limit,
  sort,
  order,
  machineIds,
  periodTime,
  productionLines
});

/**
 * Load alert history with filters
 * @param page
 * @param limit
 * @param sort
 * @param order
 * @param machineIds
 * @param periodTime
 * @param productionLines
 * @returns {{periodTime, limit, machineIds, page, sort, type: string, order, productionLines}}
 */
const loadAlertsHistoryFiltered = (page, limit, sort, order, machineIds, periodTime, productionLines) => ({
  type: constants.LOAD_ALERTS_HISTORY_WITH_FILTER,
  page,
  limit,
  sort,
  order,
  machineIds,
  periodTime,
  productionLines
});

/**
 * Update shortening order for alert history component
 * @param {string} sort Use sorter_default_values.js values
 * @param {string} order Use sorter_default_values.js values
 * @returns {{sort: string, type: string, order: string}}
 */
const setAlertHistorySortOrder = (sort, order) => ({
  type: constants.CHANGE_ALERTS_HISTORY_ORDER,
  sort,
  order
});

export { loadAlertHistory, loadAlertsHistoryFiltered, setAlertHistorySortOrder };

import styled from 'styled-components';
import { Dialog } from 'web-components';

const DIALOG_BACKGROUND_COLOR = '#FFFFFF';
const FOOTER_BACKGROUND_COLOR = '#F8F8F9';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background-color: ${FOOTER_BACKGROUND_COLOR} !important;
    box-shadow: 0px 8px 32px rgba(17, 17, 26, 0.03);
    border-radius: 10px;
    &.MuiDialog-paperFullWidth {
      @media (min-width: 1400px) {
        width: calc(60% - 64px);
      }
    }
  }
`;

export const StyledDialogHeader = styled(Dialog.Header)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  padding: 2rem 3.2rem 0.5rem !important;

  @media(max-width:768px){
    padding:2rem 1.8rem 0.5rem !important
  }
  @media(max-width:425px){
    padding: 1rem 1rem 0.5rem !important;
  }

  & h2{
    margin-left: 1.2rem;
  }
  
  & .MuiTypography-h1 {
    font-size: 1.75rem;
    align-self: center;

    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
    }
  }

  & .MuiButtonBase-root {
    @media(min-width:768px){
      position: absolute;
      right: 1rem;
      top: 1rem;
    }  
    & .MuiSvgIcon-root {
      @media(min-width:768px){
        width: 30px;
        height: 30px;
      }
      
    }
  }
`
);

export const StyledDialogSubHeader = styled(Dialog.SubHeader)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  &.MuiDialogTitle-root {
    padding: 0 4.5rem;
    @media(max-width:425px){
      padding: 0 2.2rem;
    }
    @media(min-width:426px) and (max-width:768px){
      padding: 0 3.2rem;
    }
  }

  & .MuiTypography-body1 {
    font-size: 1rem;
    @media(max-width:768px){
      font-size: 0.875rem;
    }

    ${theme.breakpoints.down('xs')} {
      margin-top: -0.5rem;
    }
  }
`
);

export const StyledDialogBody = styled(Dialog.Body)`
  background-color: ${DIALOG_BACKGROUND_COLOR};
  min-height: 30vh;
  @media (max-width: 768px) {
    min-height: 25vh;
  }
  &.MuiDialogContent-root {
    padding: 0.5rem 3rem;
    @media (max-width: 425px) {
      padding: 0.5rem 1rem;
    }
    @media (min-width: 426px) and (max-width: 768px) {
      padding: 0.5rem 2rem;
    }
    & .MuiContainer-root {
      @media (min-width: 426px) and (max-width: 768px) {
        padding-left: 20px;
      }
      @media (max-width: 425px) {
        padding-right: 0;
      }
    }
  }
`;

export const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    margin: 1.25rem;

    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 1rem 2.25rem;
    }
    @media (max-width: 425px) {
      flex-direction: column-reverse;
    }
  }
`;

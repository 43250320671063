import T from 'prop-types';
import ROUTES from '../attrs/routes';
import { getOnlyNotifyRole } from './role';

const getRoutesByRole = (role, features) => {
  const routes = [];
  const notifyRole = getOnlyNotifyRole(role);

  ROUTES.forEach(route => {
    let shouldShow = false;

    if (!route.roles || (route.roles && route.roles.includes(notifyRole))) {
      shouldShow = true;
    }

    if (!!route.allow_feature && features && !features.some(e => e.constant === route.allow_feature && e.enabled)) {
      shouldShow = false;
    }

    if (shouldShow) {
      routes.push(route);
    }
  });

  return routes;
};

getRoutesByRole.prototype = {
  role: T.arrayOf(T.shape({})),
  features: T.arrayOf(
    T.shape({
      enabled: T.bool,
      feature: T.objectOf(
        T.shape({
          constant: T.string,
          enabled: T.bool,
          id: T.string,
          name: T.string
        })
      ),
      feature_id: T.string,
      id: T.string,
      organization_id: T.string
    })
  )
};

export default getRoutesByRole;

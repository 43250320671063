import styled from 'styled-components';
import { PageHeader as Header, NotifyTypo } from 'web-components';

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
`;

const PageHeader = styled(Header)`
  padding: 4rem 5rem 20px 5rem;

  &.MuiPaper-root {
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      flex-wrap: wrap;
      padding: 5px 30px;
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 768px) {
    padding: 4rem 3rem 20px 3rem;
  }
`;

const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;

export { ContainerTitle, PageHeader, PageHeaderTitle };

import styled from 'styled-components';
import { Card, Fab, IconButton, Typography, Avatar } from '@mui/material';
import { NotifyIcon } from 'web-components';
import NotifyTypo from 'web-components/build/NotifyTypo';

export const StyledCard = styled(Card)`
  &.MuiPaper-root {
    min-width: 250px;
    max-height: 450px;
    overflow-y: scroll;
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(17, 17, 26, 0.1);
    margin-bottom: 1rem;

    @media (max-width: 425px) {
      max-height: 350px;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      padding-left: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #58baaa54;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #58baaa;
    }
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

export const TypographyCaption = styled(Typography)`
  max-height: 45px;
  overflow: hidden;
`;

export const StyledNoteTypographyHorizontalScroll = styled(Typography)`
  &.MuiTypography-body2 {
    margin-top: 20px;
    font-size: 0.8rem;
    max-height: 200px;
  }
`;

export const StyledFab = styled(Fab)`
  background-color: #cdeae5 !important;
  color: #58baaa !important;
  margin-left: 15px !important;
  cursor: inherit !important;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  &.MuiTypography-root {
    margin-bottom: 5px;
  }
  & div:first-child {
    width: 70%;
    align-content: flex-start;
  }
  & div:last-child {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-content: center !important;
    align-items: center;
  }
  & div:last-child button {
    max-width: 30px;
    max-height: 30px;
  }
`;

export const StyledNotifyCaption = styled(NotifyTypo.Caption)`
  font-weight: 500 !important;
  min-width: 80%;
`;

export const StyledTypographyLastUpdated = styled(Typography)`
  font-size: 0.8rem !important;
`;

export const StyledSpan = styled.span`
  font-size: 0.8rem !important;
  color: #7a7d85 !important;
`;

export const StyledIconButton = styled(IconButton)`
  //float: right !important;
`;

export const StyledEditButton = styled(NotifyIcon)`
  color: #58baaa !important;
`;

export const StyledEditButtonDisabled = styled(NotifyIcon)`
  color: rgba(88, 186, 170, 0.25) !important;
`;

export const StyledDeleteButton = styled(NotifyIcon)`
  color: #ba5858 !important;
`;

export const StyledDeleteButtonDisabled = styled(NotifyIcon)`
  color: rgba(186, 88, 88, 0.25) !important;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    height: 26px;
    width: 26px;
    font-size: 11px;
    font-weight: 500;
    color: #58baaa;
    margin-left: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #58baaa;
    border: 1px solid rgba(221, 221, 221, 0.5);
  }
`;
export const ContainerAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import { put, takeLatest } from 'redux-saga/effects';

import * as constants from './constants';
import { loadBatch, loadBatchList } from '../../api/batch';
import { actionStatus, getError, statusAction } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { setBatchFilter, setBatchMachineRange, setBatchSortOrder } from './actions';

function* handleLoadBatches({ page, limit, sort, order, search }) {
  yield put(statusAction(constants.LOAD_BATCHES, actionStatus.START));
  try {
    const { data } = yield loadBatchList(page, limit, sort, order, search);
    yield put(setBatchSortOrder(sort, order));
    yield put(setBatchFilter(search));

    yield put(statusAction(constants.LOAD_BATCHES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_BATCHES, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadBatchById({ batchId }) {
  if (batchId) {
    yield put(statusAction(constants.LOAD_BATCH, actionStatus.START));
    try {
      const { data } = yield loadBatch(batchId);

      if (data) {
        yield put(setBatchMachineRange(data.machine.id, data.details.date.start, data.details.date.end));
      }
      yield put(statusAction(constants.LOAD_BATCH, actionStatus.SUCCESS, { payload: data }));
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.LOAD_BATCH, actionStatus.ERROR, {
          message: error
        })
      );

      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.batch.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

function* handleFetchNextBatchPage({ page, limit, sort, order, search }) {
  yield handleLoadBatches({ page, limit, sort, order, search });
}

function* handleFetchFiltered({ page, limit, sort, order, batchId, machine, recipe }) {
  try {
    const { data } = yield loadBatchList(page, limit, sort, order, batchId, machine, recipe);
    yield put(setBatchSortOrder(sort, order));

    yield put(statusAction(constants.LOAD_BATCHES_WITH_FILTER, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_BATCHES_WITH_FILTER, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.batch.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_BATCHES, handleLoadBatches);
  yield takeLatest(constants.LOAD_BATCH, handleLoadBatchById);
  yield takeLatest(constants.LOAD_BATCHES_PAGE, handleFetchNextBatchPage);
  yield takeLatest(constants.LOAD_BATCHES_WITH_FILTER, handleFetchFiltered);
}

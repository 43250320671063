import styled from 'styled-components';

export const TreeItemLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TreeItemWrapper = styled.li`
  list-style-type: none;
`;

export const TreeListParent = styled.ul`
  padding-left: 0px;
`;

export const TreeList = styled.ul`
  padding-left: 20px;
`;

export const IconbackgroundCircleStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 15px;
  width: 30px;
  height: 30px;

  svg {
    width: 70%;
    height: auto;
  }
`;

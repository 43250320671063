import React from 'react';
import { Hidden } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageTitleShortener, PageTitleWrapper } from './elements';
import { getSelectedSensorName } from '../../redux/machines/selectors';

const getPageTitle = (crumbs, sensorName) => {
  const titles = ['history', 'sensor', 'machines', 'batch-records', 'user-management', 'account'];
  let navTitle = 'routes.dashboard';

  titles.forEach(title => {
    if (crumbs.includes(title) && navTitle === 'routes.dashboard') {
      navTitle = `routes.${title}`;
    }
  });

  return {
    [true]: navTitle,
    [navTitle === 'routes.dashboard' && crumbs.length > 1]: 'routes.machineOverview',
    [navTitle === 'routes.sensor' && sensorName !== null]: sensorName,
    [navTitle === 'routes.batch-records' && crumbs.length > 1]: 'routes.batch-records-details'
  }.true;
};

const PageTitle = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const sensorName = useSelector(getSelectedSensorName);

  const crumbs = pathname.split('/').filter(item => item !== '');

  return (
    <Hidden mdUp>
      <PageTitleWrapper>
        <PageTitleShortener>{t(`${getPageTitle(crumbs, sensorName)}`)}</PageTitleShortener>
      </PageTitleWrapper>
    </Hidden>
  );
};

export default PageTitle;

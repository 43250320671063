import React from 'react';

import T from 'prop-types';
import InfoBlock from './InfoBlock';
import { BatchResponse } from '../../../helpers/propTypes';
import { getData } from './processData';

const InfoBlocks = ({ data, isMobile }) => {
  const { batchDetailsData, batchStatusData, machineDetailsData } = getData(data);
  const marginTop = isMobile ? '10rem' : '8rem';
  return (
    <>
      <InfoBlock config={batchStatusData} marginTop={marginTop} />
      <InfoBlock config={batchDetailsData} />
      <InfoBlock config={machineDetailsData} hideContent />
    </>
  );
};

InfoBlocks.propTypes = {
  data: T.shape(BatchResponse).isRequired,
  isMobile: T.bool
};
InfoBlocks.defaultProps = {
  isMobile: false
};

export default InfoBlocks;

import React from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, CircularProgress, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useResolutionCheck, FormElements } from 'web-components';

import { ResetPasswordNonAuthSchema } from '../../../attrs/formValidation';
import { isError, isLoading } from '../../../helpers/utils';
import FormAlert from '../FormAlert';
import { StyledFooterBar, StyledFooterBarContainer } from '../../../elements';
import { resetPasswordNonAuth } from '../../../redux/auth/actions';
import { getResetPassword } from '../../../redux/auth/selectors';

const SetPasswordForm = ({ token }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile } = useResolutionCheck();

  const handleResetPassword = values => dispatch(resetPasswordNonAuth(values, token));
  const updateLoadingState = useSelector(getResetPassword);

  return (
    <Formik
      initialValues={{ password: '', confirm_password: '' }}
      validationSchema={ResetPasswordNonAuthSchema}
      onSubmit={values => handleResetPassword(values)}
    >
      {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched }) => (
        <form>
          <FormElements.TextField
            label={t('profile.new_password')}
            fullWidth
            id="password"
            margin="normal"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            type="password"
            error={errors.password && touched.password}
            helperText={
              errors.password &&
              touched.password &&
              errors.password &&
              touched.password &&
              t(`form.validate.${errors.password}`)
            }
          />
          <FormElements.TextField
            label={t('profile.confirm_password')}
            fullWidth
            id="confirm_password"
            margin="normal"
            name="confirm_password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirm_password}
            required
            type="password"
            error={errors.confirm_password && touched.confirm_password}
            helperText={
              errors.confirm_password &&
              touched.confirm_password &&
              errors.confirm_password &&
              touched.confirm_password &&
              t(`form.validate.${errors.confirm_password}`)
            }
          />
          {isError(updateLoadingState.status) && <FormAlert message={t('errors.user.reset_password')} />}
          <StyledFooterBar position={isMobile ? 'sticky' : 'fixed'}>
            <Toolbar>
              <StyledFooterBarContainer maxWidth="md" theme={theme}>
                <div />
                <Button
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                  endIcon={
                    isLoading(updateLoadingState.status) && (
                      <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
                    )
                  }
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                  type="submit"
                >
                  {t('form.reset_password')}
                </Button>
              </StyledFooterBarContainer>
            </Toolbar>
          </StyledFooterBar>
        </form>
      )}
    </Formik>
  );
};

SetPasswordForm.propTypes = {
  token: T.string.isRequired
};

export default SetPasswordForm;

import styled from 'styled-components';

import {
  MACHINE_STATUS_TRACKED_COLOR,
  MACHINE_STATUS_TRACKED_SECONDARY_COLOR,
  MACHINE_STATUS_UNTRACKED_COLOR,
  MACHINE_STATUS_UNTRACKED_SECONDARY_COLOR
} from 'attrs/colors';

export const TrackedWrapper = styled.span`
  display: inline-block;
  background-color: ${MACHINE_STATUS_TRACKED_SECONDARY_COLOR};
  color: ${MACHINE_STATUS_TRACKED_COLOR};
  font-size: 0.75rem;
  padding: 2px 8px 1px;
  border-radius: 12px;
  border: 1px solid ${MACHINE_STATUS_TRACKED_COLOR};
  overflow-wrap: break-word;
`;

export const UntrackedWrapper = styled.span`
  display: inline-block;
  background-color: ${MACHINE_STATUS_UNTRACKED_SECONDARY_COLOR};
  color: ${MACHINE_STATUS_UNTRACKED_COLOR};
  font-size: 0.75rem;
  padding: 2px 8px 1px;
  border-radius: 12px;
  border: 1px solid ${MACHINE_STATUS_UNTRACKED_COLOR};
  overflow-wrap: break-word;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'web-components';

import { showSupport } from 'redux/ui/actions';
import { getShowSupport } from 'redux/ui/selectors';
import { COLOR_PRIMARY } from 'attrs/colors';

import { DialogHeaderStyled, DialogSubHeaderStyled } from './SupportModal.styled';
import ConnectedSupportContent from './SupportContent';

const SupportModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(getShowSupport);
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogHeaderStyled highlightColor={COLOR_PRIMARY} onClose={() => dispatch(showSupport(false))}>
        {t('support.title')}
      </DialogHeaderStyled>
      <DialogSubHeaderStyled withDivider={false}>{t('support.subtitle')}</DialogSubHeaderStyled>
      <Dialog.Body>
        <ConnectedSupportContent />
      </Dialog.Body>
    </Dialog>
  );
};

export default SupportModal;

import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { MachineIcon, NotifyIcon } from 'web-components';
import { useTranslation } from 'react-i18next';
import { SENSOR_STATUS_OK } from '../../attrs/notifications';
import { getSensorProps } from '../../helpers/utils';
import { getMillSensor } from '../../attrs/sensorType';
import {
  IconbackgroundCircleStyle,
  TreeItemLabel,
  TreeItemWrapper,
  TreeList,
  TreeListParent
} from './CustomTreeViewStyles';

const findNodeById = (nodes, id, parentId = null) => {
  const foundNode = nodes.find(node => node.id === id);
  if (foundNode) return { ...foundNode, parentId };

  const childNode = nodes.reduce((found, node) => {
    if (!found && node.children) {
      return findNodeById(node.children, id, node.id);
    }
    return found;
  }, null);

  if (childNode) return childNode;

  return null;
};

const TreeItem = ({ node, handleSelection, selectedItems, selectionCount, sensorsLength }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  let iconKey = node.type;
  let iconKeyName = '';

  if (node.name.startsWith('mill_')) {
    const { name, mapValue } = getMillSensor(node.icon);
    iconKey = mapValue;
    iconKeyName = t(name);
  } else if (node.is_custom) {
    iconKey = node.is_custom ? node.icon : node.type;
    iconKeyName = node.name;
  }

  const machineIconProps = {
    iconName: getSensorProps(iconKey).icon,
    status: SENSOR_STATUS_OK,
    style: {
      fontSize: '1.5rem'
    },
    viewBox: '0 0 32 32'
  };

  const isSelected = nodeId =>
    Array.from(selectedItems.values()).some(parent => parent.childrenNames.some(child => child.id === nodeId));
  const handleToggle = () => setIsOpen(!isOpen);

  const handleCheck = event => {
    event.stopPropagation();
    handleSelection(node, event.target.checked);
  };

  const ShowName = () => (
    <div>
      {node.children &&
        (isOpen ? (
          <NotifyIcon iconName="chevronDown" fontSize="20px" />
        ) : (
          <NotifyIcon iconName="chevronRight" fontSize="default" />
        ))}
      {node.icon && (
        <IconbackgroundCircleStyle style={{ backgroundColor: node.iconBackgroundStyle }}>
          <MachineIcon {...machineIconProps} />
        </IconbackgroundCircleStyle>
      )}
      {node.children ? (
        <span
          style={{
            textTransform: 'capitalize',
            fontSize: '14px',
            color: '#333333',
            fontWeight: '500',
            verticalAlign: 'super'
          }}
        >
          {node.name}
        </span>
      ) : (
        <span style={{ fontSize: '14px', color: '#333333', fontWeight: '500' }}>{iconKeyName}</span>
      )}
    </div>
  );

  return (
    <TreeItemWrapper>
      <TreeItemLabel onClick={handleToggle}>
        {!node.children && (
          <Checkbox
            id={node.id}
            defaultChecked={isSelected(node.id) || node.is_checked}
            onChange={handleCheck}
            disabled={!isSelected(node.id) && selectionCount >= 8 - sensorsLength}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{ShowName()}</div>
      </TreeItemLabel>
      {isOpen && node.children && (
        <TreeList>
          {node.children.map(childNode => (
            <TreeItem
              key={childNode.id}
              node={childNode}
              handleSelection={handleSelection}
              selectedItems={selectedItems}
              selectionCount={selectionCount}
              sensorsLength={sensorsLength}
            />
          ))}
        </TreeList>
      )}
    </TreeItemWrapper>
  );
};

TreeItem.propTypes = {
  node: T.shape({
    id: T.oneOfType([T.string, T.number]).isRequired,
    icon: T.string,
    iconColor: T.string,
    iconBackgroundStyle: T.string,
    name: T.string.isRequired,
    children: T.arrayOf(
      T.shape({
        id: T.oneOfType([T.string, T.number]).isRequired,
        name: T.string.isRequired,
        icon: T.string,
        type: T.string,
        is_custom: T.bool,
        custom_sensor_icon: T.string,
        custom_icon_type: T.string,
        is_checked: T.bool
      })
    ),
    is_custom: T.bool,
    custom_sensor_icon: T.string,
    custom_icon_type: T.string,
    is_checked: T.bool,
    type: T.string
  }).isRequired,
  handleSelection: T.func.isRequired,
  selectedItems: T.instanceOf(Map).isRequired,
  selectionCount: T.number.isRequired,
  sensorsLength: T.number
};

TreeItem.defaultProps = {
  sensorsLength: 0
};

export const CustomTreeView = ({
  rawData,
  editingSensors,
  selectedItems: selectedItemsParam,
  onSelectionChange,
  sensorsLength
}) => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Map());

  const transformDataToTree = (dataParam, editing) =>
    dataParam.map(item => ({
      id: item.name,
      name: item.name,
      children: item.machines.map(machine => ({
        id: machine.id,
        name: machine.name,
        children: machine.sensors
          .map((sensor, index) => ({
            id: `${sensor.type}_${machine.name}_${index}`,
            name: sensor.custom_sensor_name || `${sensor.type}`,
            icon: sensor.custom_sensor_icon || `${sensor.type}`,
            is_custom: sensor.is_custom,
            type: sensor.type,
            iconBackgroundStyle: 'rgba(239, 251, 241, 1)',
            is_checked: selectedItemsParam.some(
              selected => selected.machine_id === machine.id && selected.type === sensor.type
            )
          }))
          .filter(s => !editing?.some(edt => edt.type === s.type))
      }))
    }));

  useEffect(() => {
    setData(transformDataToTree(rawData, editingSensors));
  }, [rawData, editingSensors]);

  const handleSelection = (node, isChecked) => {
    setSelectedItems(prevSelectedItems => {
      const newSelectedItems = new Map(prevSelectedItems);

      const updateParentSelection = parentNode => {
        if (!parentNode.parentId) return;

        const parentNodeFound = findNodeById(data, parentNode.parentId);
        if (!newSelectedItems.has(parentNodeFound.id)) {
          newSelectedItems.set(parentNodeFound.id, {
            productLine: parentNodeFound.parentId,
            machineID: parentNodeFound.id,
            machine: parentNodeFound.name,
            childrenNames: []
          });
        }

        const parentEntry = newSelectedItems.get(parentNodeFound.id);

        const childIndex = parentEntry.childrenNames.findIndex(child => child.id === parentNode.id);

        if (isChecked) {
          if (childIndex === -1) {
            parentEntry.childrenNames.push({
              id: parentNode.id,
              name: parentNode.name,
              type: parentNode.type,
              is_custom: parentNode?.is_custom,
              icon: parentNode?.icon
            });
          }
        } else if (childIndex !== -1) {
          parentEntry.childrenNames.splice(childIndex, 1);
        }

        if (parentEntry.childrenNames.length === 0) {
          newSelectedItems.delete(parentNodeFound.id);
        }
      };

      const nodeWithParent = findNodeById(data, node.id);
      if (nodeWithParent) {
        updateParentSelection(nodeWithParent);
      }

      onSelectionChange(Array.from(newSelectedItems.values()));
      return newSelectedItems;
    });
  };

  const selectionCount = Array.from(selectedItems.values()).reduce(
    (acc, parent) => acc + parent.childrenNames.length,
    0
  );

  return (
    <TreeListParent>
      {data.map(node => (
        <TreeItem
          key={node.id}
          node={node}
          selectedItems={selectedItems}
          handleSelection={handleSelection}
          selectionCount={selectionCount}
          sensorsLength={sensorsLength}
        />
      ))}
    </TreeListParent>
  );
};

CustomTreeView.propTypes = {
  rawData: T.arrayOf(
    T.shape({
      name: T.string.isRequired,
      machines: T.arrayOf(
        T.shape({
          id: T.string.isRequired,
          name: T.string.isRequired,
          sensors: T.arrayOf(
            T.shape({
              type: T.string.isRequired,
              custom_sensor_name: T.string,
              custom_sensor_icon: T.string,
              is_custom: T.bool,
              icon: T.string,
              iconBackgroundStyle: T.string,
              is_checked: T.bool
            })
          )
        })
      )
    })
  ).isRequired,
  editingSensors: T.arrayOf(T.shape()),
  selectedItems: T.arrayOf(T.shape()),
  onSelectionChange: T.func.isRequired,
  sensorsLength: T.number
};

CustomTreeView.defaultProps = {
  editingSensors: [],
  selectedItems: [],
  sensorsLength: 0
};

import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import compose from 'lodash/fp/compose';
import { orderBy } from 'lodash';

import * as features from '../../helpers/features';
import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { WS_API_BASE_URL, WS_API_BASE_URL_MOCK } from '../../attrs/paths';
import {
  NOTIFICATION_TYPE_INTERACTION,
  NOTIFICATION_TYPE_MASCHINE,
  NOTIFICATION_TYPE_SENSOR
} from '../../attrs/notifications';
import { getAlerts, getData, getUser } from '../rootSelectors';
import { getActiveOrganization, getUserId, getUserInfo } from '../user/selectors';
import * as api from '../../api/alerts';
import { loadMachines } from '../../api/machines';
import { createSocketChannel } from '../stream/websocket';

function consoleLog(...args) {
  if (features.get('is_debug')) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

function* enhanceAlerts(data) {
  const ordered = orderBy(data, item => item.time, 'desc');

  if (data.length === 0) {
    return [];
  }

  // if alerts existing: load machines and enhance alerts with machine data
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));
  const { data: machinesData } = yield loadMachines(organization.id);

  return ordered.map(alert => {
    const { name, production_line: productionLine } = machinesData.find(m => m.id === alert.machine_id) || {
      name: 'n/a',
      production_line: 'n/a'
    };

    return {
      ...alert,

      unread: true,
      machine: {
        name,
        productionLine
      }
    };
  });
}

function getAlertsStreamEndpoint() {
  const baseUrl = features.get('is_ws_mocked_server') ? WS_API_BASE_URL_MOCK : WS_API_BASE_URL;

  return `${baseUrl}/v1/ws/alerts`;
}

function* notifyUserWithNewAlerts(alerts) {
  const { data: existentAlerts } = yield select(getAlerts);
  const enhancedAlerts = yield enhanceAlerts(alerts);

  yield put(
    statusAction(constants.LOAD_ALERTS, actionStatus.SUCCESS, { payload: [...existentAlerts, ...enhancedAlerts] })
  );

  const lastAlert = enhancedAlerts[enhancedAlerts.length - 1];

  if (lastAlert) {
    const notificationType = lastAlert.metric ? NOTIFICATION_TYPE_SENSOR : NOTIFICATION_TYPE_MASCHINE;

    yield put(
      addNotification({
        key: generateId(),
        alert: lastAlert,
        type: lastAlert.severity,
        notificationType
      })
    );
  }
}

function* subscribeToAlertsStream() {
  try {
    const endpoint = getAlertsStreamEndpoint();
    const socketChannel = yield call(createSocketChannel, endpoint);

    while (true) {
      const payload = yield take(socketChannel);
      consoleLog(payload);
      const alerts = JSON.parse(payload);

      const alertsList = Array.isArray(alerts) ? alerts : [alerts];

      yield notifyUserWithNewAlerts(alertsList);
    }
  } catch (err) {
    // Error while connecting to the WebSocket or while loading alerts
    consoleLog(err);
  }
}

function* handleDeleteAllAlerts(alerts) {
  if (alerts.payload) {
    try {
      // eslint-disable-next-line camelcase
      const ids = alerts.payload.map(({ id, notification_id }) => id || notification_id);

      yield api.deleteNotifications(ids.filter(id => id));
      yield put(statusAction(constants.MARK_ALL_ALERTS_AS_READ, actionStatus.SUCCESS, ids));
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'form.notifications.success.delete',
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    } catch (err) {
      const error = getError(err);

      yield put(
        statusAction(constants.MARK_ALL_ALERTS_AS_READ, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.notification.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

function* handleDeleteAlert({ payload }) {
  const { id } = payload;
  yield put(statusAction(constants.MARK_ALERT_AS_READ, actionStatus.START));
  if (id) {
    try {
      yield api.deleteNotifications(id);
      yield put(statusAction(constants.MARK_ALERT_AS_READ, actionStatus.SUCCESS, { payload }));
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'form.notifications.success.delete',
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.MARK_ALERT_AS_READ, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.notification.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

function* handleLoadAlerts() {
  const userId = yield select(compose(getUserId, getData, getUserInfo, getUser));

  if (userId) {
    yield put(statusAction(constants.LOAD_ALERTS, actionStatus.START));

    try {
      // change the endpoint notification /alert to new /notifications
      // const { data } = yield api.getAlerts(userId);
      const { data } = yield api.getNotifications(userId);
      const enhancedAlerts = yield enhanceAlerts(data);
      const enhancedAlertsMarkedAsRead = enhancedAlerts.map(alert => ({
        ...alert,
        unread: false
      }));

      yield put(statusAction(constants.LOAD_ALERTS, actionStatus.SUCCESS, { payload: enhancedAlertsMarkedAsRead }));
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.LOAD_ALERTS, actionStatus.ERROR, {
          message: error
        })
      );

      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.alerts.${error}`,
          notificationType: NOTIFICATION_TYPE_INTERACTION
        })
      );
    }
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_ALERTS, handleLoadAlerts);
  yield takeLatest(constants.SUBSCRIBE_TO_ALERTS_STREAM, subscribeToAlertsStream);
  yield takeLatest(constants.MARK_ALERT_AS_READ, handleDeleteAlert);
  yield takeLatest(constants.MARK_ALL_ALERTS_AS_READ, handleDeleteAllAlerts);
}

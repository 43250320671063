import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ChartTemplateFooterContent from './ChartTemplateFooterContent';

function ChartTemplateFooterContainer({
  footerRef,
  resumedSensorMap,
  sensorVisibilityList,
  sensorAverageMap,
  toggleLineVisibility
}) {
  return (
    <Box sx={{ display: 'flex' }} data-selector="chart-template-footer-container">
      <Grid
        ref={footerRef}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={{ xs: 2 }}
        columns={{ xs: 3, sm: 4, md: 9, lg: 9, xl: 12 }}
      >
        {Array.from(resumedSensorMap).map(([key, value], i) => (
          <Grid item xs={12} sm={4} lg={3} key={value.sensorId}>
            <ChartTemplateFooterContent
              key={`card-${value.sensorId}`}
              resumedSensor={{ ...value, ...sensorAverageMap.get(key) }}
              isLineVisible={sensorVisibilityList[i]}
              toggleLineVisibility={() => toggleLineVisibility(i)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ChartTemplateFooterContainer.propTypes = {
  footerRef: PropTypes.shape(),
  resumedSensorMap: PropTypes.shape().isRequired,
  sensorVisibilityList: PropTypes.arrayOf(PropTypes.bool).isRequired,
  sensorAverageMap: PropTypes.shape().isRequired,
  toggleLineVisibility: PropTypes.func.isRequired
};

ChartTemplateFooterContainer.defaultProps = {
  footerRef: null
};

export default ChartTemplateFooterContainer;

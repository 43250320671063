import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import ui from './ui/reducer';
import user from './user/reducer';
import auth from './auth/reducer';
import machines from './machines/reducer';
import alerts from './alerts/reducer';
import users from './users/reducer';
import stream from './stream/reducer';
import support from './support/reducer';
import batch from './batch/reducer';
import batchNotes from './batch_notes/reducer';
import dataExports from './data_exports/reducer';
import reportExport from './batch_export/reducer';
import alarmsHistory from './alarms_history/reducer';
import customSensor from './custom_sensor/reducer';
import alertsHistory from './alert_history/reducer';
import metricsHistoryChartData from './metrics/reducer';
import metricsMachines from './metrics_machines/reducer';
import graphTemplates from './graphTemplates/reducer';
import filters from './filters/reducer';

const uiPersistConfig = {
  key: 'ui',
  storage,
  blacklist: ['alertsMenu', 'notifications', 'confirmations', 'support', 'salesforceModal']
};

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ui: persistReducer(uiPersistConfig, ui),
    user,
    auth,
    machines,
    alerts,
    users,
    stream,
    support,
    batch,
    batchNotes,
    dataExports,
    reportExport,
    alarmsHistory,
    customSensor,
    alertsHistory,
    metricsHistoryChartData,
    metricsMachines,
    graphTemplates,
    filters
  });

export default createRootReducer;

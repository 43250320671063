import { ADD_NOTIFICATION, RESOLVE_NOTIFICATION } from './constants';

const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  notification
});

const resolveNotification = key => ({
  type: RESOLVE_NOTIFICATION,
  key
});

export { addNotification, resolveNotification };

import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';

import { logout } from '../../redux/auth/actions';

const { Heading2, Body1 } = NotifyTypo;

const AlreadyLoggedIn = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { t } = useTranslation();

  return (
    <Container align="center" maxWidth="sm" style={{ padding: '2rem' }}>
      <Heading2 gutterBottom>{t('errors.generic')}</Heading2>
      <>
        <Body1>{t('auth.already_logged_in')}</Body1>
        <Button
          color="primary"
          onClick={() => dispatch(logout(`/register/${token}`))}
          style={{ marginTop: '1rem' }}
          variant="contained"
        >
          {t('auth.logout')}
        </Button>
      </>
    </Container>
  );
};

export default AlreadyLoggedIn;

import React from 'react';
import T from 'prop-types';
import AlertHistoryOverview from '../../components/AlertHistoryOverview';
import { PaginationResponse } from '../../helpers/propTypes';

const AlertHistory = ({ alerts }) => <AlertHistoryOverview rows={alerts} />;

AlertHistory.propTypes = {
  alerts: T.shape(PaginationResponse)
};

AlertHistory.defaultProps = {
  alerts: null
};

export default AlertHistory;

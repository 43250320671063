import { combineReducers } from 'redux';
import { getMetricsTimeFrame } from '../../../helpers/utils';

import { createReducer } from '../../utils';
import {
  SET_SHOW_MACHINE_STATE,
  SET_TIME_WINDOW,
  SET_SCOPED_TIME_WINDOW,
  SET_TIME_WINDOW_RANGE,
  SET_IS_BATCH_RECORD_DETAILS
} from './constants';

const machinesReducer = combineReducers({
  sensors: createReducer(
    {
      [SET_SHOW_MACHINE_STATE]: (sensors, { machineId, sensorType, setting }) => {
        if (sensors.find(s => s.machineId === machineId && s.sensorType === sensorType)) {
          return sensors.map(sensor => {
            if (sensor.machineId === machineId && sensor.sensorType === sensorType) {
              return { ...sensor, showMachineState: setting };
            }
            return sensor;
          });
        }
        return [...sensors, { machineId, sensorType, showMachineState: setting }];
      }
    },
    []
  )
});

const graphReducer = combineReducers({
  timeWindowRange: createReducer(
    {
      [SET_TIME_WINDOW_RANGE]: (timeWindowRange, { range }) => range
    },
    8
  ),
  timeWindow: createReducer(
    {
      [SET_TIME_WINDOW]: (timeWindow, { from, to }) => ({ ...timeWindow, from, to })
    },
    [{ ...getMetricsTimeFrame() }]
  ),
  scopedTimeWindow: createReducer(
    {
      [SET_TIME_WINDOW]: (timeWindow, { from, to }) => ({ ...timeWindow, from, to }),
      [SET_SCOPED_TIME_WINDOW]: (timeWindow, { from, to }) => ({ ...timeWindow, from, to })
    },
    []
  )
});

const batchReducer = combineReducers({
  isBatchRecord: createReducer(
    {
      [SET_IS_BATCH_RECORD_DETAILS]: (reducer, { batchId, isBatchRecord, timeFrom, timeTo }) => ({
        isBatchRecord,
        batchId,
        timeFrom,
        timeTo
      })
    },
    { isBatchRecord: false, batchId: '', timeFrom: null, timeTo: null }
  )
});

export default combineReducers({
  machines: machinesReducer,
  graph: graphReducer,
  batchSettings: batchReducer
});

import React from 'react';
import T from 'prop-types';

import { Button, Grid, InputAdornment } from '@mui/material';
import { DataTable, FormElements, NotifyIcon, useResolutionCheck } from 'web-components';

import { useTranslation } from 'react-i18next';
import { Content, Inline, PaperStyled } from './MobileView.styled';
import MobileRow from './MobileRow';
import { BatchRecordsColumnProps, BatchRecordsRowsProps } from '../BatchRecordsOverview.config';
import SortMenu from './SortMenu';
import Loading from './Loading';

const MobileView = ({ columns, rows, defaultSort, sortFn, footer, onClick, search, isLoading, clearAllFields }) => {
  const { isMobile } = useResolutionCheck();
  const { t } = useTranslation();

  const allFieldsEmpty = !search.batchId && !search.machine && !search.recipe;

  return (
    <>
      {isMobile && (
        <>
          <Inline isSmallDevice>
            <SortMenu isSmallDevice defaultSort={defaultSort} onSort={sortFn} />
          </Inline>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormElements.TextField
                label={t('batch_records.overview.search')}
                bgcolor="#FFFFFF"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="search" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                onChange={e => search.handleSearchBatchID(e.target.value)}
                value={search.batchId}
                data-selector="batch-records-list-batchid-field"
                sx={{
                  width: { xs: '100%', md: '100%' }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormElements.TextField
                label={t('batch_records.overview.search_machine')}
                bgcolor="#FFFFFF"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="search" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                onChange={e => search.handleSearchMachine(e.target.value)}
                value={search.machine}
                data-selector="batch-records-list-machinename-field"
                sx={{
                  width: { xs: '100%', md: '100%' }
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
              <FormElements.TextField
                label={t('batch_records.overview.search_recipe')}
                bgcolor="#FFFFFF"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="search" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                onChange={e => search.handleSearchRecipe(e.target.value)}
                value={search.recipe}
                data-selector="batch-records-list-recipe-field"
                sx={{
                  width: { xs: '100%', md: '100%' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} container alignItems="center" justifyContent="center" sx={{ marginBottom: 3 }}>
              <Button variant="text" disabled={allFieldsEmpty} onClick={clearAllFields}>
                {t('default_actions.clear_filter')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <PaperStyled square>
        <Content>
          {rows.map((row, index) => (
            // eslint-disable-next-line
            <MobileRow key={`mobile-row-${row.id}-${index}`} columns={columns} row={row} onClick={onClick}>
              {row.id}
            </MobileRow>
          ))}
        </Content>
        {footer}
        <Loading visible={isLoading} />
      </PaperStyled>
    </>
  );
};

MobileView.propTypes = {
  rows: BatchRecordsRowsProps,
  footer: T.node,
  columns: BatchRecordsColumnProps.isRequired,
  sortFn: T.func.isRequired,
  defaultSort: T.shape({
    colId: T.string,
    order: T.oneOf([DataTable.ORDER_ASC, DataTable.ORDER_DESC])
  }),
  onClick: T.func,
  search: T.shape({
    searchValue: T.string.isRequired,
    handleSearch: T.func.isRequired,
    handleSearchBatchID: T.func.isRequired,
    handleSearchMachine: T.func.isRequired,
    handleSearchRecipe: T.func.isRequired,
    batchId: T.string,
    machine: T.string,
    recipe: T.string
  }).isRequired,
  isLoading: T.bool,
  clearAllFields: T.func.isRequired
};

MobileView.defaultProps = {
  rows: [],
  footer: null,
  defaultSort: {
    colId: null,
    order: DataTable.ORDER_ASC
  },
  onClick: () => null,
  isLoading: false
};

export default MobileView;

import * as React from 'react';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

import { Box, Button } from '@mui/material';
import ButtonWithIconAndSubTitle from '../../../../../components/ButtonWithIconAndSubTitle';

export function AddTemplate({ open, handleCloseDialog, setNewTemplateIsOpen, setCloneTemplateIsOpen }) {
  const { t } = useTranslation();

  const handleClickNewTemplate = () => {
    handleCloseDialog();
    setNewTemplateIsOpen(true);
  };

  const handleClickCloneTemplate = () => {
    handleCloseDialog();
    setCloneTemplateIsOpen(true);
  };

  const content = (
    <>
      <Box
        sx={{
          mt: { xs: 5, md: 5 }
        }}
      >
        <ButtonWithIconAndSubTitle
          aria-label="button-create-template"
          data-selector="button-create-template"
          onClick={() => handleClickNewTemplate()}
          iconName="plus"
          title={t('dialog.add_template.buttons.new_template.title')}
          subtitle={t('dialog.add_template.buttons.new_template.subtitle')}
        />
        <ButtonWithIconAndSubTitle
          aria-label="button-create-template"
          data-selector="button-create-template"
          onClick={() => handleClickCloneTemplate()}
          iconName="copy"
          title={t('dialog.add_template.buttons.copy_from_existing.title')}
          subtitle={t('dialog.add_template.buttons.copy_from_existing.subtitle')}
        />
      </Box>
    </>
  );

  const actionBar = (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={() => handleCloseDialog(false)}
        sx={{ width: '127px', height: '56px', padding: '16px' }}
      >
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleCloseDialog(false)}
        sx={{ width: '127px', height: '56px', padding: '16px' }}
      >
        {t('default_actions.next')}
      </Button>
    </>
  );

  const handleOk = () => {
    handleCloseDialog();
  };

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.add_template.title')}
      subTitle={t('dialog.add_template.subtitle')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minWidth="800px"
      maxWidth="98%"
      actionBar={actionBar}
    />
  );
}

AddTemplate.propTypes = {
  open: T.bool.isRequired,
  handleCloseDialog: T.func.isRequired,
  setNewTemplateIsOpen: T.func.isRequired,
  setCloneTemplateIsOpen: T.func.isRequired
};

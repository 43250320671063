import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import BatchRecordsDetail from './BatchRecordsDetail';
import { loadBatchById } from '../../../redux/batch/actions';

const ConnectedBatchDetail = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(state => state.batch);
  const { id } = useParams();

  const getData = () => {
    dispatch(loadBatchById(id));
  };

  useEffect(getData, [dispatch]);

  return <BatchRecordsDetail loadingState={loadingState} reloadAction={getData} data={data} />;
};

export default ConnectedBatchDetail;

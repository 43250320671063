/**
 * Generate a color based on HSL to HEX
 * @returns {string} color
 */
export function hslToHex(h, s, l) {
  const hue = h / 360;
  const saturation = s / 100;
  const lightness = l / 100;

  let r;
  let g;
  let b;

  if (saturation === 0) {
    b = lightness;
    g = b;
    r = g;
  } else {
    const hue2rgb = (p, q, t) => {
      let tempT = t;

      if (tempT < 0) tempT += 1;
      if (tempT > 1) tempT -= 1;
      if (tempT < 1 / 6) return p + (q - p) * 6 * tempT;
      if (tempT < 1 / 2) return q;
      if (tempT < 2 / 3) return p + (q - p) * (2 / 3 - tempT) * 6;
      return p;
    };

    const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation;
    const p = 2 * lightness - q;

    const tr = (hue + 1 / 3) % 1;
    const tg = hue % 1;
    const tb = (hue - 1 / 3) % 1;

    r = hue2rgb(p, q, tr);
    g = hue2rgb(p, q, tg);
    b = hue2rgb(p, q, tb);
  }

  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

/**
 * Generate a color based on the index
 * @param {number} index
 * @returns {string} color
 */
export function generateColor(index) {
  const hue = (index * 137.508) % 360;
  const color = hslToHex(hue, 70, 60);
  return color;
}

import { TOGGLE_ALERTS_MENU } from './constants';
import { createReducer } from '../../utils';

const initialState = {
  isMenuOpen: false
};

export default createReducer(
  {
    [TOGGLE_ALERTS_MENU]: (state, { isMenuOpen }) => ({ isMenuOpen })
  },
  initialState
);

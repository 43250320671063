import {
  MACHINE_RUNNING_COLOR,
  MACHINE_IDLE_COLOR,
  MACHINE_MAINTENANCE_COLOR,
  MACHINE_CRITICAL_COLOR,
  MACHINE_NO_DATA_COLOR,
  MACHINE_WARNING_COLOR,
  SENSOR_NODATA_COLOR,
  SENSOR_OK_COLOR,
  SENSOR_WARNING_COLOR,
  SENSOR_CRITICAL_COLOR,
  MACHINE_FUTURE_COLOR
} from './colors';

// Notification types
export const NOTIFICATION_TYPE_MASCHINE = 'MACHINE_NOTIFICATION';
export const NOTIFICATION_TYPE_SENSOR = 'SENSOR_NOTIFICATION';
export const NOTIFICATION_TYPE_INTERACTION = 'INTERACTION_NOTIFICATION';

// MACHINE NOTIFICATIONS
// Sensor warnings
export const SENSOR_TEMPERATURE = 'TEMPERATURE';
export const SENSOR_CURRENT = 'CURRENT';
export const SENSOR_DENSITY = 'DENSITY';
export const SENSOR_ENERGY = 'ENERGY';
export const SENSOR_FLOW = 'FLOW';
export const SENSOR_LEVEL = 'LEVEL';
export const SENSOR_POWER = 'POWER';
export const SENSOR_PRESSURE = 'PRESSURE';
export const SENSOR_SPEED = 'SPEED';
export const SENSOR_TIME = 'TIME';
export const SENSOR_VALVE = 'VALVE';
export const SENSOR_VISCOSITY = 'VISCOSITY';
export const SENSOR_OPERATING_HOURS = 'OPERATING_HOURS';
export const SENSOR_HOUR_METER = 'HOURMETER';

// New custom sensor icon types
export const SENSOR_ANGLE = 'ANGLE';
export const SENSOR_CONCENTRATION = 'CONCENTRATION';
export const SENSOR_DISTANCE_MM_CM_M = 'DISTANCE_MM_CM_M';
export const SENSOR_FORCE = 'FORCE';
export const SENSOR_POSITION = 'POSITION';
export const SENSOR_PROGRESS = 'PROGRESS';
export const SENSOR_QUANTITY_NUMBER = 'QUANTITY_NUMBER';
export const SENSOR_TORQUE = 'TORQUE';
export const SENSOR_VIBRATION = 'VIBRATION';
export const SENSOR_VOLUME = 'VOLUME';
export const SENSOR_WEIGHT = 'WEIGHT';
export const SENSOR_PERCENTAGE = 'PERCENTAGE';
export const SENSOR_FREQUENCY = 'FREQUENCY';

// New IRIS V3 icon types
export const SENSOR_PRODUCT_MASS_FLOW = 'PRODUCT_MASS_FLOW';
export const SENSOR_COOLING_FLOW = 'COOLING_FLOW';
export const SENSOR_PRODUCT_VOLUME_FLOW = 'PRODUCT_VOLUME_FLOW';
export const SENSOR_PROPORTIONAL_VALVE = 'PROPORTIONAL_VALVE';
export const SENSOR_SPECIFIC_ENERGY = 'SPECIFIC_ENERGY';
export const SENSOR_SPEED_PERCENTAGE = 'SPEED_PERCENTAGE';

export const SENSOR_STATUS_NODATA = 'NODATA';
export const SENSOR_STATUS_OK = 'OK';
export const SENSOR_STATUS_WARNING = 'WARNING';
export const SENSOR_STATUS_CRITICAL = 'CRITICAL';

// Machine alerts
export const MACHINE_IDLE = 'IDLE';
export const MACHINE_MAINTENANCE = 'MAINTENANCE';
export const MACHINE_RUNNING = 'RUNNING';
export const MACHINE_NO_DATA = 'NODATA';
export const MACHINE_CRITICAL = 'CRITICAL';
export const MACHINE_WARNING = 'WARNING';
export const MACHINE_FUTURE = 'FUTURE';

export const SENSOR_STATUS_MAP = [
  {
    value: SENSOR_STATUS_NODATA,
    color: SENSOR_NODATA_COLOR
  },
  {
    value: SENSOR_STATUS_OK,
    color: SENSOR_OK_COLOR
  },
  {
    value: SENSOR_STATUS_WARNING,
    color: SENSOR_WARNING_COLOR
  },
  {
    value: SENSOR_STATUS_CRITICAL,
    color: SENSOR_CRITICAL_COLOR
  }
];

export const MACHINE_STATUS_MAP = [
  {
    value: MACHINE_RUNNING,
    icon: 'running',
    color: MACHINE_RUNNING_COLOR
  },
  {
    value: MACHINE_CRITICAL,
    icon: 'error',
    color: MACHINE_CRITICAL_COLOR
  },
  {
    value: MACHINE_MAINTENANCE,
    icon: 'maintenance',
    color: MACHINE_MAINTENANCE_COLOR
  },
  {
    value: MACHINE_IDLE,
    icon: 'idle',
    color: MACHINE_IDLE_COLOR
  },
  {
    value: MACHINE_WARNING,
    icon: 'warning',
    color: MACHINE_WARNING_COLOR
  },
  {
    value: MACHINE_NO_DATA,
    icon: 'missingLink',
    color: MACHINE_NO_DATA_COLOR
  },
  {
    value: MACHINE_FUTURE,
    icon: null,
    invisible: true,
    color: MACHINE_FUTURE_COLOR
  }
];

/**
 * Represents a simplified map of machine statuses.
 * A copy of the MACHINE_STATUS_MAP without the last element and only with 'value' and 'color'.
 * @constant {Array<{ value: string, color: string }>}
 */
export const MACHINE_STATUS_SIMPLE_MAP = MACHINE_STATUS_MAP.slice(0, -1).map(({ value, color }) => ({ value, color }));

export const SENSOR_MAP = [
  // Old Icons
  { value: 'SENSOR_UNKNOWN', icon: 'error' },
  { value: SENSOR_TEMPERATURE, icon: 'sensorTemperature' },
  { value: SENSOR_CURRENT, icon: 'sensorCurrent' },
  { value: SENSOR_DENSITY, icon: 'sensorDensity' },
  { value: SENSOR_ENERGY, icon: 'sensorEnergy' },
  { value: SENSOR_FLOW, icon: 'sensorFlow' },
  { value: SENSOR_LEVEL, icon: 'sensorLevel' },
  { value: SENSOR_POWER, icon: 'sensorPower' },
  { value: SENSOR_PRESSURE, icon: 'sensorPressure' },
  { value: SENSOR_SPEED, icon: 'sensorSpeed' },
  { value: SENSOR_TIME, icon: 'sensorTime' },
  { value: SENSOR_VALVE, icon: 'sensorValve' },
  { value: SENSOR_VISCOSITY, icon: 'sensorViscosity' },
  { value: SENSOR_OPERATING_HOURS, icon: 'sensorOperatingHours' },
  { value: SENSOR_HOUR_METER, icon: 'sensorHourMeter' },

  // New Icons (For Custom sensors)
  { value: SENSOR_ANGLE, icon: 'sensorAngle' },
  { value: SENSOR_CONCENTRATION, icon: 'sensorConcentration' },
  { value: SENSOR_DISTANCE_MM_CM_M, icon: 'sensorDistance' },
  { value: SENSOR_FORCE, icon: 'sensorForce' },
  { value: SENSOR_POSITION, icon: 'sensorPosition' },
  { value: SENSOR_PROGRESS, icon: 'sensorProgress' },
  { value: SENSOR_QUANTITY_NUMBER, icon: 'sensorQuantity' },
  { value: SENSOR_TORQUE, icon: 'sensorTorque' },
  { value: SENSOR_VIBRATION, icon: 'sensorVibration' },
  { value: SENSOR_VOLUME, icon: 'sensorVolume' },
  { value: SENSOR_WEIGHT, icon: 'sensorWeight' },
  { value: SENSOR_PERCENTAGE, icon: 'sensorPercentage' },
  { value: SENSOR_FREQUENCY, icon: 'sensorFrequency' },

  // New Icons (For IRIS V3)
  { value: SENSOR_PRODUCT_MASS_FLOW, icon: 'sensorFlow' },
  { value: SENSOR_COOLING_FLOW, icon: 'sensorFlow' },
  { value: SENSOR_PRODUCT_VOLUME_FLOW, icon: 'sensorFlow' },
  { value: SENSOR_PROPORTIONAL_VALVE, icon: 'sensorPercentage' },
  { value: SENSOR_SPECIFIC_ENERGY, icon: 'sensorEnergy' },
  { value: SENSOR_SPEED_PERCENTAGE, icon: 'sensorPercentage' }
];

export const NOTIFICATION_TYPE_OPTIONS = {
  [NOTIFICATION_TYPE_MASCHINE]: {
    autoHideDuration: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  },
  [NOTIFICATION_TYPE_SENSOR]: {
    autoHideDuration: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  },
  [NOTIFICATION_TYPE_INTERACTION]: {
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  }
};

import React, { useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

import { InfoIcon } from './styles';
import CustomTooltip from '../elements/tooltips/Tooltip';

function TooltipInfo({ titleTooltip }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:425px)');
  const tooltipRef = useRef(null);

  const returnPlacement = () => {
    if (isMobile) {
      return 'bottom';
    }
    return 'right';
  };
  const valueOffset = isMobile ? '-30px, 0' : '0, 0';

  return (
    <CustomTooltip
      ref={tooltipRef}
      enterTouchDelay={0}
      placement={returnPlacement()}
      title={t(titleTooltip)}
      disableHoverListener={false}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: valueOffset
            }
          }
        }
      }}
    >
      <span>
        <InfoIcon iconName="info" />
      </span>
    </CustomTooltip>
  );
}
TooltipInfo.propTypes = {
  titleTooltip: T.string.isRequired
};

export default TooltipInfo;

import React from 'react';
import T from 'prop-types';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { NotifyTypo } from 'web-components';
import MachineCard from '../../components/MachineCard/MachineCard';
import { ProductionLineWrapper } from './elements';

const ProductionLine = ({ title, machines }) => (
  <ProductionLineWrapper data-selector="production-line">
    <NotifyTypo.Heading1Regular gutterBottom style={{ marginBottom: '1rem' }}>
      {title}
    </NotifyTypo.Heading1Regular>
    <Grid container spacing={3}>
      {machines.map(machine => (
        <Grid key={machine.id} item xs={12} sm={6} md={3}>
          <Link
            style={{
              display: 'block',
              textDecoration: 'none',
              minHeight: 70
            }}
            to={`/dashboard/${machine.id}`}
          >
            <MachineCard machine={machine} />
          </Link>
        </Grid>
      ))}
    </Grid>
  </ProductionLineWrapper>
);

ProductionLine.propTypes = {
  title: T.string.isRequired,
  machines: T.arrayOf(T.shape()).isRequired
};

export default ProductionLine;

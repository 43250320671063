import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';
import { isNullUndefinedOrEmpty, concatDates } from '../helpers/utils';

const decimalNumberCheck = (value, max) => {
  const isValid = value ? ((value.toString().split('.') || {})[1] || []).length <= max : true;

  return isValid;
};

const isTimeStringValid = value => {
  const isValid = /^(|0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(value);
  return isValid;
};

const RequestPasswordResetSchema = Yup.object().shape({
  login: Yup.string().email('invalid_email').required('required')
});

const MachinesMachineName = Yup.object().shape({
  name: Yup.string().trim().required('required')
});

const MachinesProductionLineName = Yup.object().shape({
  production_line: Yup.string().trim().required('required')
});

const MachineSchema = Yup.object().shape({
  type: Yup.string().required('required'),
  name: Yup.string()
    .trim()
    .test('validText', 'invalid_text', text => /^[0-9A-Za-z\s\-_()]+$/.test(text))
    .required('required'),
  production_line: Yup.string()
    .trim()
    .test('validText', 'invalid_text', text => /^[0-9A-Za-z\s\-_()]+$/.test(text))
    .required('required'),
  mechanical_config: Yup.object().shape({
    bead_material: Yup.string(),
    beads_filling_level: Yup.number(),
    beads_size_1: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,17})?$/.test(`${number}`)
    ),
    beads_size_2: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,17})?$/.test(`${number}`)
    ),
    grinding_technology: Yup.string(),
    inner_liner_material: Yup.string(),
    samba_system: Yup.bool(),
    sieve_size: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,17})?$/.test(`${number}`)
    )
  })
});

const AccountUpdateSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('required'),
  last_name: Yup.string().trim().required('required'),
  phone: Yup.object().shape({
    number: Yup.number().typeError('number_required').required('required')
  })
});

const AccountRegisterSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('required'),
  last_name: Yup.string().trim().required('required'),
  phone: Yup.object().shape({
    number: Yup.number().typeError('number_required').required('required')
  }),
  password: Yup.string()
    .defined()
    .min(6, 'too_short')
    .max(50, 'too_long')
    .matches('[0-9]', 'password_number_required')
    .test('password-strength', 'The password is too weak. Please choose a stronger password.', value => {
      if (!value) return false;
      const passwordStrength = zxcvbn(value);
      return passwordStrength.score >= 4;
    })
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required'),
  terms_accept: Yup.bool().oneOf([true], 'terms_need_to_be_accepted')
});

// Password requirements: Min 6 letters, 1 number
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .defined()
    .min(6, 'too_short')
    .max(50, 'too_long')
    .matches('[0-9]', 'password_number_required')
    .test('password-strength', 'The password is too weak. Please choose a stronger password.', value => {
      if (!value) return false;
      const passwordStrength = zxcvbn(value);
      return passwordStrength.score >= 4;
    })
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required'),
  prev_password: Yup.string().required('required')
});

const ResetPasswordNonAuthSchema = Yup.object().shape({
  password: Yup.string()
    .defined()
    .min(6, 'too_short')
    .max(50, 'too_long')
    .matches('[0-9]', 'password_number_required')
    .test('password-strength', 'The password is too weak. Please choose a stronger password.', value => {
      if (!value) return false;
      const passwordStrength = zxcvbn(value);
      return passwordStrength.score >= 4;
    })
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required')
});

const LoginSchema = Yup.object().shape({
  login: Yup.string().email('invalid_email').required('required'),
  password: Yup.string().required('required')
});

const sms = Yup.array().when('value', {
  is: val => typeof val === 'number',
  then: Yup.array().when('email', {
    is: val => !val || (val || []).length === 0,
    then: Yup.array().required('required'),
    otherwise: Yup.array().notRequired()
  })
});

const email = Yup.array().when('value', {
  is: val => typeof val === 'number',
  then: Yup.array().when('sms', {
    is: val => !val || (val || []).length === 0,
    then: Yup.array().required('required'),
    otherwise: Yup.array().notRequired()
  })
});

const sensorValue = Yup.number()
  .when('sms', {
    is: val => val && (val || []).length > 0,
    then: Yup.number()
      .typeError('number_required')
      .test('decimalCheck', 'number_decimal_digits_1', val => decimalNumberCheck(val, 1))
      .required('required'),
    otherwise: Yup.number().nullable(true).notRequired()
  })
  .when('email', {
    is: val => val && (val || []).length > 0,
    then: Yup.number()
      .typeError('number_required')
      .test('decimalCheck', 'number_decimal_digits_1', val => decimalNumberCheck(val, 1))
      .required('required'),
    otherwise: Yup.number().notRequired()
  });

const max = Yup.object().shape(
  {
    value: sensorValue,
    sms,
    email
  },
  [
    ['value', 'sms'],
    ['value', 'email']
  ]
);

const min = Yup.object().shape(
  {
    value: sensorValue,
    sms,
    email
  },
  [
    ['value', 'sms'],
    ['value', 'email']
  ]
);

const MachineNotificationSchema = Yup.object().shape(
  {
    sms: Yup.array().when('email', {
      is: val => !val || val.length === 0,
      then: Yup.array().required('required'),
      otherwise: Yup.array().notRequired()
    }),
    email: Yup.array().when('sms', {
      is: val => !val || val.length === 0,
      then: Yup.array().required('required'),
      otherwise: Yup.array().notRequired()
    })
  },
  ['email', 'sms']
);

const SensorSettingsMaxSchema = Yup.object().shape({ max });
const SensorSettingsMinSchema = Yup.object().shape({ min });
const SensorSettingsMinMaxSchema = Yup.object().shape({ min, max });

const UserInviteSchema = Yup.object().shape({
  email: Yup.string().email('invalid_email').required('required')
});

const SalesforceSchema = Yup.object().shape({
  productionRelated: Yup.bool(),
  productionLine: Yup.string().when('productionRelated', {
    is: val => Boolean(val),
    then: Yup.string().notRequired(),
    otherwise: Yup.string().test('empty-check', 'required', value => Boolean(value))
  }),
  machineStopped: Yup.string().when('productionRelated', {
    is: val => Boolean(val),
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  subject: Yup.string().required('provide_subject')
});

/// //////////////////////// Batch

const BatchNoteFormSchema = Yup.object().shape({
  note: Yup.string().trim().required('required'),
  title: Yup.string().min(1, 'too_short_1').max(255, 'too_long_255').trim().required('required')
});

/// //////////////////////// Data Export

const hasStartBiggesOrEqualstEnd = env => {
  /* eslint-disable camelcase */
  const { date_from, time_from, date_to, time_to } = env.parent;
  const startFullDate = new Date(concatDates(date_from, time_from));
  const endFullDate = new Date(concatDates(date_to, time_to));
  const hasMaxOneMinuteDiff = Math.abs((startFullDate.getTime() - endFullDate.getTime()) / 1000) < 60;
  return !(startFullDate > endFullDate || hasMaxOneMinuteDiff);
};

const DataExportSchema = Yup.object().shape({
  date_to: Yup.date().test(['date_from', 'time_from', 'time_to', 'date_to'], 'invalid_date_to', function test() {
    return hasStartBiggesOrEqualstEnd(this);
  }),
  date_from: Yup.date().test(['date_from', 'time_from', 'time_to', 'date_to'], 'invalid_date_from', function test() {
    return hasStartBiggesOrEqualstEnd(this);
  }),
  time_from: Yup.date().test(['date_from', 'time_from', 'time_to', 'date_to'], 'invalid_time_from', function test() {
    return hasStartBiggesOrEqualstEnd(this);
  }),
  time_to: Yup.date().test(['date_from', 'time_from', 'time_to', 'date_to'], 'invalid_time_to', function test() {
    return hasStartBiggesOrEqualstEnd(this);
  })
});

/// //////////////////////// Graph Templtates

const GraphTemplateSchema = Yup.object().shape({
  templateidentification: Yup.string().trim().required('required')
});

export {
  ResetPasswordSchema,
  AccountUpdateSchema,
  RequestPasswordResetSchema,
  LoginSchema,
  ResetPasswordNonAuthSchema,
  AccountRegisterSchema,
  MachinesMachineName,
  MachinesProductionLineName,
  MachineSchema,
  SensorSettingsMinSchema,
  SensorSettingsMaxSchema,
  SensorSettingsMinMaxSchema,
  UserInviteSchema,
  MachineNotificationSchema,
  SalesforceSchema,
  BatchNoteFormSchema,
  isTimeStringValid,
  DataExportSchema,
  GraphTemplateSchema
};

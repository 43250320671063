import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import withLoadingState from '../../../../helpers/withLoadingState';
import { getSelectedMachineSensorComparisonSensorData } from '../../../../redux/machines/selectors';
import EmptyState from '../../../../components/EmptyState';
import { getData } from '../../../../redux/rootSelectors';
import MultipleSensorsChart from '../../../../components/Graphs/multipleSensors';

const SensorComparisonGraph = () => {
  const { id: machineId } = useParams();
  const data = useSelector(compose(getData, getSelectedMachineSensorComparisonSensorData));
  const hasData = data.chartData.length > 0;

  return <>{hasData ? <MultipleSensorsChart machineId={machineId} /> : <EmptyState type="sensorHistoricalData" />}</>;
};

export default withLoadingState(SensorComparisonGraph);

import axios from './axios';

const cleanparams = obj =>
  Object.entries(obj)
    .filter(([, v]) => v != null && v !== '')
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

const loadMachineSensorsById = (machineId, TemplateName) =>
  axios({
    method: 'get',
    url: `/v2/filters/machine/${machineId}/sensors`,
    params: {
      template_name: TemplateName === '' ? undefined : TemplateName
    }
  });

const loadMachineSensorTreeView = (productionLines, sensors, machineIds) => {
  const params = new URLSearchParams();
  productionLines.forEach(item => params.append('production_lines', item));
  sensors.forEach(item => params.append('sensors', item));
  machineIds.forEach(item => params.append('machine_ids', item));

  return axios({
    method: 'get',
    url: `/v2/filters/machines/production-lines/tree-view`,
    params
  });
};

const loadProductionLines = (productionLines, machineIds) => {
  const params = cleanparams({
    production_lines: productionLines,
    machine_ids: machineIds
  });

  return axios({
    method: 'get',
    url: `/v2/filters/production-lines/resumed`,
    params
  });
};

const loadSensors = (productionLines, machineIds) => {
  const params = cleanparams({
    production_lines: productionLines,
    machine_ids: machineIds
  });

  return axios({
    method: 'get',
    url: `/v2/filters/sensors/resumed`,
    params
  });
};

const loadMachines = (organizationId, productionLines, sensors) =>
  axios({
    method: 'get',
    url: '/v2/filters/machines/resumed',
    params: {
      organization_id: organizationId,
      production_lines: productionLines,
      sensors
    }
  });

export { loadMachineSensorsById, loadMachineSensorTreeView, loadProductionLines, loadMachines, loadSensors };

import React from 'react';
import { Label, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import T from 'prop-types';

import moment from 'moment';

import {
  calcYAxisWidth,
  formatYAxisRounded,
  getYAxisDomainBySensor,
  MetricsCustomSensorCompareTooltip,
  ScopedTimeRangeLoader,
  tickStyle,
  CustomTickChart
} from 'components/Graphs/graphUtils';
import { AXIS_LINE_COLOR, SENSOR_WARNING_COLOR } from 'attrs/colors';
import { getMachineStatusProps, getSensorUnit, isLoading } from 'helpers/utils';

import { COLORS } from '../utils';
import useMultipleSensorsGraphData from './useMultipleSensorsGraphData';
import AuxiliaryAreaChart from './AuxiliaryAreaChart';

const MultipleSensorsGraph = ({ machineId }) => {
  const {
    scopedLoadingState,
    zoomState,
    setZoomState,
    xAxisTicks,
    xAxisDomain,
    machineChartData,
    chartData,
    shouldShowMachineState,
    handleZoom,
    handleMouseDown,
    ...multipleSensorsGraphData
  } = useMultipleSensorsGraphData(machineId, 'comparison');

  const sensors = multipleSensorsGraphData.sensors.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <>
      {isLoading(scopedLoadingState.status) && <ScopedTimeRangeLoader />}
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          onMouseDown={e => handleMouseDown(e)}
          onMouseMove={e => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
          onMouseUp={handleZoom}
        >
          <XAxis
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            allowDataOverflow
            domain={['dataMin, dataMax']}
            stroke="false"
            ticks={xAxisTicks}
            tickFormatter={tick => {
              moment(tick).format('DD/MM/YYYY HH:mm:ss');
            }}
            tick={props => <CustomTickChart {...props} />}
          />
          {sensors.map((sensor, key) => {
            const yAxisWidth = calcYAxisWidth(chartData, sensor.type, sensor.visualization);
            const labelOffsetFromCenter = sensor.yAxisWidth / 2 - 30;
            return (
              <YAxis
                key={`${sensor.type}-yaxis`}
                yAxisId={key}
                stroke={AXIS_LINE_COLOR}
                tick={{ ...tickStyle, fill: COLORS[key] }}
                tickFormatter={formatYAxisRounded(sensor)}
                tickLine={false}
                width={yAxisWidth}
                orientation={key > 0 ? 'right' : 'left'}
                domain={getYAxisDomainBySensor(sensor)}
                type="number"
              >
                <Label
                  angle={-90}
                  dx={key > 0 ? labelOffsetFromCenter : -labelOffsetFromCenter}
                  position="center"
                  fill={COLORS[key]}
                  fontSize={14}
                >
                  {`${getSensorUnit(sensor)}`}
                </Label>
              </YAxis>
            );
          })}
          <Tooltip content={<MetricsCustomSensorCompareTooltip detail={sensors} />} />
          {sensors.map((sensor, key) => (
            <Line
              type="monotone"
              dot={false}
              key={`${sensor.is_custom ? `custom_sensor_id_${sensor.custom_sensor_id}` : sensor.type}-line`}
              isAnimationActive={false}
              stroke={COLORS[key]}
              strokeWidth={2}
              name={sensor.is_custom ? sensor.custom_name : sensor.name}
              dataKey={sensor.is_custom ? `custom_sensor_id_${sensor.custom_sensor_id}` : sensor.type}
              yAxisId={key}
              // Used into <CustomSensorCompareTooltip /> component, we can't get the sensors details inside the
              // <CustomSensorCompareTooltip />, soo I added the ; separator
              unit={sensor.is_custom ? `${sensor.custom_unit};${sensor.name}` : sensor.unit}
            />
          ))}
          {zoomState.refAreaLeft && zoomState.refAreaRight ? (
            <ReferenceArea x1={zoomState.refAreaLeft} x2={zoomState.refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
      <div style={{ marginTop: -50 }}>
        <AuxiliaryAreaChart
          sensors={sensors}
          xAxisDomain={xAxisDomain}
          xAxisTicks={xAxisTicks}
          chartData={chartData}
          height={56}
          showAxisValues
        >
          {shouldShowMachineState &&
            machineChartData.map(reference => {
              const { color } = getMachineStatusProps(reference.value);
              return (
                <ReferenceArea
                  key={`${reference.start}-${reference.end}-${reference.value}-machine`}
                  x1={reference.start}
                  x2={reference.end}
                  y1={0.33}
                  y2={1}
                  yAxisId="machine_state"
                  fill={color}
                  fillOpacity={0.5}
                />
              );
            })}
          {shouldShowMachineState &&
            machineChartData.map(reference => {
              if (!reference.warning) {
                return null;
              }

              return (
                <ReferenceArea
                  key={`${reference.start}-${reference.end}-${reference.value}-warning`}
                  x1={reference.start}
                  x2={reference.end}
                  y1={0}
                  y2={0.33}
                  yAxisId="machine_state"
                  fill={SENSOR_WARNING_COLOR}
                  fillOpacity={0.5}
                />
              );
            })}
        </AuxiliaryAreaChart>
      </div>
    </>
  );
};

MultipleSensorsGraph.propTypes = {
  machineId: T.string.isRequired
};

export default MultipleSensorsGraph;

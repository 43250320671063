import React from 'react';
import T from 'prop-types';
import { CustomStepIcon } from './StepIconComponent.styled';

export function StepIconComponent({ active, completed, icon }) {
  return (
    <CustomStepIcon active={active} completed={completed}>
      {icon}
    </CustomStepIcon>
  );
}

StepIconComponent.propTypes = {
  active: T.bool,
  completed: T.bool.isRequired,
  icon: T.node
};

StepIconComponent.defaultProps = {
  active: false,
  icon: null
};

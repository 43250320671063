import styled from 'styled-components';
import { COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY, COLOR_PRIMARY_CONTRAST } from 'attrs/colors';
import { Popper } from '@mui/material';

const COLOR_BACKGROUND = '#222635';
const COLOR_CHECKMARK = '#59d66e';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 1rem;
  padding-right: ${({ isSmallDevice }) => (isSmallDevice ? '0' : '1rem')};

  & .MuiButton-label {
    text-transform: uppercase;
  }

  & .MuiPaper-root {
    background-color: ${COLOR_BACKGROUND};
    color: ${COLOR_PRIMARY_CONTRAST};
    border-radius: 10px;
    width: 100%;

    & .MuiMenuItem-root {
      font-size: 0.875rem;
      padding: 1rem 2rem 1.25rem 0.5rem;
      border-bottom: 1px solid ${COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY}70;

      & > .MuiSvgIcon-root {
        color: ${COLOR_CHECKMARK};
        margin-right: 1.25rem;

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  & .fullWidth {
    width: calc(100% - 2.5rem);

    & .menuLabel {
      width: 100%;
      text-align: center;
      margin-right: 2.25rem;
    }
  }
`;

export const PopperStyled = styled(Popper)`
  z-index: 9;
`;

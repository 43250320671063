import styled, { createGlobalStyle, css } from 'styled-components';
import { PageHeader as Header, ResponsiveIconButton, Styles } from 'web-components';

// Injecting white background
const WhiteBackground = createGlobalStyle`
  body {
    background: #fff !important;
  }
`;

const DesktopRefreshButton = styled(ResponsiveIconButton)`
  margin-left: 1.5rem !important;
  padding: 2px 5px !important;
`;

const TooltipElementWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 400;
`;

const TooltipElementBackground = styled.div`
  background: rgba(255, 255, 255, 0.9);
  width: 16px;
  height: 16px;
  position: absolute;
  left: 2px;
  top: 2px;
`;

const TooltipElement = styled.div`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 0.75rem;
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

const StyledContainer = styled.div`
  padding: 0 3rem 0 3rem;
  @media (max-width: 768px) {
    padding: 0 3rem 0 3rem;
  }
  @media (max-width: 425px) {
    padding: 0 1rem;
  }
`;

const ContainerWrapper = styled(Styles.ContentWrapper)`
  &.MuiContainer-root {
    padding: 6rem 0 2rem 0;
    @media (max-width: 425px) {
      padding: 5rem 0 1rem 0;
    }
  }
  & .MuiContainer-root {
    padding-top: 1rem !important;
  }
  & .MuiTabs-indicator {
    min-height: 0px !important;
    bottom: 10px;
  }
`;

const PageHeader = styled(Header)`
  &.MuiPaper-root {
    flex-direction: column;
    align-self: flex-start;
    padding: 1rem 0 0 3rem;
    height: auto;
    background-color: #f7f7fa;
    @media (max-width: 425px) {
      padding: 1rem 0 0 1rem;
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const PageHeaderTabsMessage = styled.div`
  float: right;

  color: var(--neutral-700, #7a7d85);
  text-align: right;
  /* Other/sub - 14px [regular] */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const ContainerLinkBackPage = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const ContainerPageTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 3rem);
  @media (max-width: 768px) {
    width: calc(100% - 3rem);
  }
  @media (max-width: 425px) {
    width: calc(100% - 1rem);
    padding-right: 1rem;
  }
`;

const ContainerSensorTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-direction: row;
  align-items: flex-end;
  @media (max-width: 425px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TabsHeader = styled.div`
  header {
    position: relative !important;
    margin-top: 0 !important;
  }
`;

const ContainerMetricsTitle = styled.div`
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-left: 0px;
  @media (max-width: 425px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

const MetricsTitle = styled.h2`
  margin: 0;
  font-weight: 400;
  font-size: 1.143rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  color: #333333;
`;

const MetricsSubtitle = styled.h6`
  margin: 0;
  font-size: 0.857rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  margin-bottom: 0.35em;
  color: #7a7d85;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 2fr;
`;

const GridInner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -24px;
  width: calc(100% + 24px);
  margin-left: -24px;
  &.MuiGrid-item {
    padding-left: 0px !important;
  }
`;

const SensorsTitle = styled.h2`
  margin-top: 30;
  font-weight: 400;
  font-size: 1.143rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  color: #333333;
  width: 100%;
`;

export {
  WhiteBackground,
  DesktopRefreshButton,
  TooltipElementWrapper,
  TooltipElement,
  TooltipElementBackground,
  StyledContainer,
  ContainerWrapper,
  PageHeader,
  ContainerLinkBackPage,
  ContainerPageTitle,
  ContainerSensorTitle,
  TabsHeader,
  ContainerMetricsTitle,
  MetricsTitle,
  MetricsSubtitle,
  Wrapper,
  GridInner,
  PageHeaderTabsMessage,
  SensorsTitle
};

import React from 'react';
import { Grid, Button, InputAdornment } from '@mui/material';
import { DataTable, FormElements, NotifyIcon } from 'web-components';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

const DesktopView = ({ props, search, clearAllFields }) => {
  const { t } = useTranslation();

  const allFieldsEmpty = !search.batchId && !search.machine && !search.recipe;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sx={{ marginBottom: 3 }}>
          <FormElements.TextField
            label={t('batch_records.overview.search')}
            bgcolor="#FFFFFF"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="search" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={e => search.handleSearchBatchID(e.target.value)}
            value={search.batchId}
            data-selector="batch-records-list-batchid-field"
            sx={{
              width: { xs: '100%', md: '100%' }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ marginBottom: 3 }}>
          <FormElements.TextField
            label={t('batch_records.overview.search_machine')}
            bgcolor="#FFFFFF"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="search" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={e => search.handleSearchMachine(e.target.value)}
            value={search.machine}
            data-selector="batch-records-list-machinename-field"
            sx={{
              width: { xs: '100%', md: '100%' }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ marginBottom: 3 }}>
          <FormElements.TextField
            label={t('batch_records.overview.search_recipe')}
            bgcolor="#FFFFFF"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="search" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            onChange={e => search.handleSearchRecipe(e.target.value)}
            value={search.recipe}
            data-selector="batch-records-list-recipe-field"
            sx={{
              width: { xs: '100%', md: '100%' }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} container alignItems="center" sx={{ marginBottom: 3 }}>
          <Button variant="text" disabled={allFieldsEmpty} onClick={clearAllFields}>
            {t('default_actions.clear_filter')}
          </Button>
        </Grid>
      </Grid>
      <DataTable hover {...props} />
    </>
  );
};

DesktopView.propTypes = {
  props: T.shape({}).isRequired,
  search: T.shape({
    batchId: T.string.isRequired,
    machine: T.string.isRequired,
    recipe: T.string.isRequired,
    handleSearchBatchID: T.func.isRequired,
    handleSearchMachine: T.func.isRequired,
    handleSearchRecipe: T.func.isRequired
  }).isRequired,
  clearAllFields: T.func.isRequired
};

export default DesktopView;

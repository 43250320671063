import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import { enhanceWithResetActions, loadingStateReducer } from '../utils';
import {
  LOAD_METRIC_HISTORY,
  LOAD_METRICS_HISTORY_CHART_DATA,
  LOAD_METRICS_HISTORY_RESET_CHART_DATA
} from './constants';

const metricsChartDataReducer = combineReducers({
  metricsHistoryChartData: typeToReducer(
    {
      [LOAD_METRICS_HISTORY_CHART_DATA]: {
        SUCCESS: (state, { payload }) => payload
      }
    },
    {
      chartData: [],
      machineChartData: [],
      xAxisDomain: [],
      xAxisTicks: [],
      unit: '',
      yAxisDomain: [],
      yAxisWidth: 0
    }
  )
});

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_METRIC_HISTORY]),
  ret: enhanceWithResetActions([LOAD_METRICS_HISTORY_RESET_CHART_DATA])(metricsChartDataReducer)
});

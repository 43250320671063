import { put, select, takeLatest } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from '../utils';
import * as constants from './constants';
import { getTimeWindow, getTimeWindowRange } from '../ui/settings/selectors';
import {
  generateId,
  getChartDataNullNormalize,
  getMetricsTimeFrame,
  getSensorPropsFromType
} from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { loadMetricHistoryChartData, loadMetricsHistoryChartData } from '../../api/metrics_history';

/**
 * Handle and call machines metrics chart data history for one (1) sensor
 * @param id
 * @param sensorType
 * @param timeWindow
 * @param timeRange
 * @param simpleMetric Indicate if the call will be for one or more sensors (this will change the chartData return type)
 * @returns {Generator<*, void, *>}
 */
// eslint-disable-next-line no-unused-vars
function* handleLoadMetricsHistoryChartData({ id, sensorType, timeWindow, simpleMetric }) {
  yield put(statusAction(constants.LOAD_METRICS_HISTORY_CHART_DATA, actionStatus.START));

  const storeTimeWindow = yield select(getTimeWindow);
  const storeTimeRange = yield select(getTimeWindowRange);
  const selectedTimeWindow = timeWindow || (storeTimeRange && getMetricsTimeFrame(storeTimeRange)) || storeTimeWindow;

  try {
    const { from, to } = selectedTimeWindow;
    const sensorProps = getSensorPropsFromType(sensorType);
    let payload;
    if (!simpleMetric) {
      const { data } = yield loadMetricsHistoryChartData(id, from, to, `${sensorType},${sensorProps.unit}`);
      payload = data;
    } else {
      const { data } = yield loadMetricHistoryChartData(id, from, to, `${sensorType},${sensorProps.unit}`);
      payload = data;
    }

    payload = getChartDataNullNormalize(payload);

    yield put(statusAction(constants.LOAD_METRICS_HISTORY_CHART_DATA, actionStatus.SUCCESS, { payload }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_METRICS_HISTORY_CHART_DATA, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_METRICS_HISTORY_CHART_DATA, handleLoadMetricsHistoryChartData);
}

import styled from 'styled-components';

export const CustomStepIcon = styled('div')`
  background-color: ${({ active, completed }) => {
    if (active) {
      return '#2BACA4';
    }
    if (completed) {
      return '#2BACA4';
    }
    return '#e0e0e0';
  }};
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 0.875rem;
  margin-top: 8px;
`;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NotifyTypo } from 'web-components';

import withLoadingState from '../../../../helpers/withLoadingState';
import { getCheckAuthCode } from '../../../../redux/auth/selectors';
import SetPasswordForm from '../../../../components/forms/Auth/SetPasswordForm';

const SetPassword = ({ token }) => {
  const { t } = useTranslation();
  const { data } = useSelector(getCheckAuthCode);

  return (
    <>
      <NotifyTypo.InfoText align="center" gutterBottom style={{ marginBottom: '2rem' }}>
        ({t('profile.account_reset_password_info_text')}
        <b>{data['e-mail']}</b>)
      </NotifyTypo.InfoText>
      <SetPasswordForm token={token} />
    </>
  );
};

SetPassword.propTypes = {
  token: T.string.isRequired
};

export default withLoadingState(SetPassword);

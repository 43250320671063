import { createTheme } from '@mui/material/styles';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_CONTRAST,
  COLOR_SECONDARY,
  COLOR_SECONDARY_CONTRAST,
  COLOR_WARNING,
  COLOR_WARNING_CONTRAST,
  COLOR_ERROR,
  COLOR_ERROR_CONTRAST,
  COLOR_SUCCESS,
  COLOR_SUCCESS_CONTRAST,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  COLOR_MAIN_HIGHLIGHT,
  COLOR_MAIN_HIGHLIGHT_TEXT,
  LIGHT_GREY
} from './colors';
import { NAVIGATION_BAR_HEIGHT } from './layout';

// Custom theme to overwrite default MuiTheme
const customTheme = createTheme({
  palette: {
    background: {
      default: LIGHT_GREY
    },
    primary: {
      main: COLOR_PRIMARY,
      contrastText: COLOR_PRIMARY_CONTRAST
    },
    secondary: {
      main: COLOR_SECONDARY,
      contrastText: COLOR_SECONDARY_CONTRAST
    },
    warning: {
      main: COLOR_WARNING,
      contrastText: COLOR_WARNING_CONTRAST
    },
    error: {
      main: COLOR_ERROR,
      contrastText: COLOR_ERROR_CONTRAST
    },
    success: {
      main: COLOR_SUCCESS,
      contrastText: COLOR_SUCCESS_CONTRAST
    },
    text: {
      primary: PRIMARY_TEXT_COLOR,
      secondary: SECONDARY_TEXT_COLOR
    }
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '2rem'
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.714rem'
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.429rem'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.143rem'
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem'
    },
    body2: {
      fontWeight: 400,
      fontSize: '1rem'
    },
    subtitle1: {
      fontSize: '0.857rem',
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '0.857rem',
      fontWeight: 400
    },
    caption: {
      fontSize: '0.857rem'
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: COLOR_MAIN_HIGHLIGHT,
        color: COLOR_MAIN_HIGHLIGHT_TEXT
      },
      paperAnchorTop: {
        marginTop: NAVIGATION_BAR_HEIGHT
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color'
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40
      }
    },
    MuiPaper: {
      elevation2: {
        boxShadow: '0px 1px 8px rgba(17, 17, 26, 0.1), 0px 8px 24px rgba(17, 17, 26, 0.1)',
        borderRadius: '4px'
      },
      elevation3: {
        boxShadow: '0px 8px 32px rgba(17, 17, 26, 0.03)',
        borderRadius: '4px'
      }
    }
  }
});

export default customTheme;

import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';

import { ChartWrapper } from '../elements';
import MachineStatusBrush from './MachineStatusBrush';
import MachineStatusGraph from './MachineStatusGraph';

const MachineGraph = ({ machineId }) => {
  const { isMobile } = useResolutionCheck();

  return (
    <ChartWrapper data-selector="machine-history-chart" mobile={isMobile} style={{ paddingRight: '1rem' }}>
      <MachineStatusGraph machineId={machineId} />
      <MachineStatusBrush machineId={machineId} />
    </ChartWrapper>
  );
};

MachineGraph.propTypes = {
  machineId: T.string.isRequired
};

export default MachineGraph;

import React, { useState } from 'react';
import T from 'prop-types';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NotifyTypo, NotifyIcon } from 'web-components';

import { StyledPaper, HeadingWrapper, StyledDivider, StyledMarginDiv } from './InfoBlock.styled';
import InfoBlockRow from './InfoBlockRow';

const InfoBlock = ({ config, hideContent, marginTop }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const onExpandClick = () => setExpanded(!expanded);

  return (
    <StyledMarginDiv marginTop={marginTop}>
      <StyledPaper elevation={3}>
        <HeadingWrapper expanded={!hideContent || expanded}>
          {hideContent && (
            <IconButton color="primary" size="small" onClick={onExpandClick} style={{ marginRight: '1.5rem' }}>
              <NotifyIcon iconName={expanded ? 'removeBox' : 'addBox'} />
            </IconButton>
          )}
          <NotifyTypo.Caption>{t(`batch_records.details.${config.localeKey}`)}</NotifyTypo.Caption>
        </HeadingWrapper>
        {(expanded || !hideContent) &&
          config.rows.map((row, ind) => (
            <div key={row.id}>
              <InfoBlockRow localeKey={row.localeKey} dataProvider={row.row} />
              {ind < config.rows.length - 1 && <StyledDivider />}
            </div>
          ))}
      </StyledPaper>
    </StyledMarginDiv>
  );
};

InfoBlock.propTypes = {
  config: T.shape({
    localeKey: T.string,
    rows: T.arrayOf(T.shape({}))
  }).isRequired,
  hideContent: T.bool,
  marginTop: T.string
};

InfoBlock.defaultProps = {
  hideContent: false,
  marginTop: '0'
};

export default InfoBlock;

import { createSelector } from 'reselect';
import { uniq } from 'lodash';

import { getMachines } from '../rootSelectors';

export const getProductionLinesOptions = createSelector([getMachines], machines => {
  const productionLines = machines.data
    .map(({ production_line: prodLine }) => prodLine)
    .sort((a, b) => (a.toUpperCase() >= b.toUpperCase() ? 1 : -1));

  return uniq(productionLines);
});

export const getMachineOptions = createSelector([getMachines], machines =>
  machines.data
    .map(({ production_line: prodLine, name: label, id: value }) => ({ prodLine, label, value }))
    .sort((a, b) => (a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1))
);

export const getProductionLinesNameOptions = createSelector([getMachines], machines => {
  const productionLines = machines.data
    .map(item => ({ label: item.production_line, value: item.production_line }))
    .sort((a, b) => (a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1));
  return uniq(productionLines);
});

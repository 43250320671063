import React from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useResolutionCheck } from 'web-components';

import withLoadingState from 'helpers/withLoadingState';
import { generateId } from 'helpers/utils';
import { SUCCESS, ERROR } from 'attrs/status';
import { NOTIFICATION_TYPE_INTERACTION } from 'attrs/notifications';
import { addNotification } from 'redux/ui/notifications/actions';
import { showSalesforceModal, showSupport } from 'redux/ui/actions';
import { getActiveOrganizationId } from 'redux/user/selectors';

import SupportSpareParts from './SupportSpareParts';
import DesktopContent from './DesktopContent';
import DeviceContent from './DeviceContent';
import { ContentWrapper } from './SupportContent.styled';

// For the spare parts QR PoC: module is only allowed for following organizations:
const SPARE_PARTS_ORGANIZATION_IDS = ['7f34d931-3b8b-40eb-8fc6-7bc7f897ba48', '5341d858-c970-4670-b9bb-372970b96aea'];

const SupportContent = ({ data }) => {
  const dispatch = useDispatch();
  const { isMobile } = useResolutionCheck();
  const organizationId = useSelector(getActiveOrganizationId);

  const shouldShowSpareParts = SPARE_PARTS_ORGANIZATION_IDS.includes(organizationId);

  const copyToClipboard = (value, type) => {
    navigator.clipboard.writeText(value).then(
      () => {
        dispatch(
          addNotification({
            key: generateId(),
            type: SUCCESS,
            message: `support.notifications.success_clipboard_${type}`,
            notificationType: NOTIFICATION_TYPE_INTERACTION
          })
        );
      },
      () => {
        dispatch(
          addNotification({
            key: generateId(),
            type: ERROR,
            message: `support.notifications.error_clipboard_${type}`,
            notificationType: NOTIFICATION_TYPE_INTERACTION
          })
        );
      }
    );
  };

  const handleCreateTicket = () => {
    dispatch(showSalesforceModal(true));
    dispatch(showSupport(false));
  };

  const ContentComponent = isMobile ? DeviceContent : DesktopContent;

  return (
    <ContentWrapper>
      <ContentComponent data={data} copyToClipboard={copyToClipboard} createTicket={handleCreateTicket} />
      {shouldShowSpareParts && <SupportSpareParts />}
    </ContentWrapper>
  );
};

SupportContent.propTypes = {
  data: T.shape({ 'e-mail': T.string, phone: T.string })
};

SupportContent.defaultProps = {
  data: null
};

export default withLoadingState(SupportContent);

import React from 'react';
import T from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';

import { getLastMachineTimestamp, getLastSelectedMachineSensorTimestamp } from '../../redux/machines/selectors';
import { getUserData } from '../../redux/user/selectors';
// import DataExportDialog from "../DataExportDialog";

const LAST_TIMESTAMP_TYPES = {
  dashboard: getLastMachineTimestamp,
  machine: getLastSelectedMachineSensorTimestamp
};

const LastUpdated = ({ type, align, marginBottom }) => {
  const { t } = useTranslation();
  const lastTimestamp = useSelector(LAST_TIMESTAMP_TYPES[type]);
  const user = useSelector(getUserData);

  const dateToBeShown = moment(lastTimestamp || null).locale(user.language || 'en_US');

  return (
    <NotifyTypo.InfoText data-selector="last-updated" align={align || 'left'} style={{ marginBottom }}>
      {t('dashboard.machines.last_updated')} {dateToBeShown.isValid() ? dateToBeShown.format('LLL') : '---'}
    </NotifyTypo.InfoText>
  );
};

export default React.memo(LastUpdated);

LastUpdated.propTypes = {
  type: T.string.isRequired,
  align: T.string,
  marginBottom: T.string
};

LastUpdated.defaultProps = {
  align: '',
  marginBottom: '1.5rem'
};

import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import T from 'prop-types';

import { ContentWrapper } from 'elements';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReportExportDialog from 'components/ReportExportDialog';
import {
  DetailsWrapper,
  ContainerTitle,
  ContainerPageTitle,
  PageHeader,
  ContainerButton
} from './BatchRecordsDetails.styled';
import InfoBlocks from './InfoBlocks';
import SensorComparisonBlock from './SensorComparisonBlock';
import { BatchResponse } from '../../helpers/propTypes';
import BatchNotes from './batchNotes/batchNotes';
import { getBatchNotes } from '../../redux/rootSelectors';
import { getAllBatchNotesByBatchIdAction } from '../../redux/batch_notes/actions';
import Breadcrumbs from '../elements/Breadcrumbs';

const BatchRecordsDetails = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const name = data.details.batch_id ? data.details.batch_id : t('batch_records.overview.items');
  const isMobile = useMediaQuery('(max-width:425px)');

  // Get all batches notes for batch details
  const { batchNotes, batchLoadingState } = useSelector(getBatchNotes);
  useEffect(() => {
    dispatch(getAllBatchNotesByBatchIdAction(data.details.id));
  }, [dispatch, data]);

  const getAllBatchNotes = () => {
    dispatch(getAllBatchNotesByBatchIdAction(data.details.id));
  };

  const breadcrumbObj = [
    { route: '/batch-records', name: `${t('batch_records.title')}` },
    { route: null, name: `${t('batch_records.batch_details')}` }
  ];

  return (
    <>
      <PageHeader className="withoutShadow" alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <ContainerPageTitle>
          <PageHeader.Title>
            <ContainerTitle>
              <PageHeader.Title.Main>{name}</PageHeader.Title.Main>
            </ContainerTitle>
          </PageHeader.Title>
          <ContainerButton>
            <ReportExportDialog batchId={data?.details?.id} />
          </ContainerButton>
        </ContainerPageTitle>
      </PageHeader>
      <ContentWrapper maxWidth={false} style={{ marginTop: '2rem' }}>
        <DetailsWrapper theme={theme}>
          <InfoBlocks data={data} isMobile={isMobile} />
          <BatchNotes
            loadingState={batchLoadingState}
            data={Array.isArray(batchNotes) ? batchNotes : []}
            reloadAction={getAllBatchNotes}
            isMobile={isMobile}
          />
          <SensorComparisonBlock mobile={isMobile} />
        </DetailsWrapper>
      </ContentWrapper>
    </>
  );
};

BatchRecordsDetails.propTypes = {
  data: T.shape(BatchResponse).isRequired
};

export default BatchRecordsDetails;

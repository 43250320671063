import styled from 'styled-components';

import { NotifyTypo } from 'web-components';

import { PRIMARY_TEXT_COLOR } from 'attrs/colors';

export const ACTIVE_COLOR = '#000000';

export const Wrapper = styled.div`
  margin: 3rem 0 2rem;

  & > * {
    display: block;

    &:nth-child(2) {
      margin-top: 0.5rem;
    }
  }

  & .MuiButtonBase-root {
    padding-left: 0;
  }
`;

export const StyledInfoText = styled(NotifyTypo.InfoText)`
  &.MuiTypography-colorTextSecondary {
    font-size: 1rem;
    font-weight: 500;
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

import * as constants from './constants';

const createBatchNoteAction = (batchId, note, title) => ({
  type: constants.BATCH_NOTE_CREATE,
  batchId,
  note,
  title
});

const updateBatchNoteAction = batchNote => ({
  type: constants.BATCH_NOTE_UPDATE,
  batchNote
});

const getAllBatchNotesByBatchIdAction = batchId => ({
  type: constants.BATCH_NOTE_ALL_BY_BATCH_ID,
  batchId
});
const showBatchNotesModal = show => ({
  type: constants.BATCH_NOTE_SHOW_MODAL,
  value: show
});

const deleteBatchNotes = (id, batchId) => ({
  type: constants.BATCH_NOTE_DELETE,
  batchNoteId: id,
  batchId
});

export {
  createBatchNoteAction,
  getAllBatchNotesByBatchIdAction,
  showBatchNotesModal,
  deleteBatchNotes,
  updateBatchNoteAction
};

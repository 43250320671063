import * as constants from './constants';

const showBatchReportExportModal = show => ({
  type: constants.BATCH_REPORT_EXPORT_SHOW_MODAL,
  value: show
});

const putRequesBatchReportExport = (batchId, req) => ({
  type: constants.BATCH_REPORT_EXPORT_SEND_REQUEST,
  req: { batchId, ...req },
  action: constants.BATCH_REPORT_EXPORT_SEND_EXPORT_REQUEST_MACHINES
});

export { showBatchReportExportModal, putRequesBatchReportExport };

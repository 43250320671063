import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';

import {
  BatchMetricsCustomSensorCompareTooltip,
  calcYAxisWidth,
  formatXAxis,
  formatYAxisRounded,
  getYAxisDomainBySensor,
  ScopedTimeRangeLoader,
  tickStyle
} from 'components/Graphs/graphUtils';
import { AXIS_LINE_COLOR, COLOR_PRIMARY, SENSOR_WARNING_COLOR } from 'attrs/colors';
import { getMachineStatusProps, getSensorName, getSensorPropsFromType, getSensorUnit, isLoading } from 'helpers/utils';

import { COLORS } from 'components/Graphs/multipleSensors/utils';
import {
  AuxiliaryAreaChart,
  useMultipleSensorsGraphData
} from 'components/Graphs/multipleSensors/MultipleSensorsGraph';
import { useSensorComparisonConfig } from '../../sensorComparisonBlock.hooks';
import CustomChartLabels from './CustomChartLabels';

const BatchMultipleSensorsGraph = () => {
  const { t } = useTranslation();
  const { machineId, batchStart, batchEnd } = useSensorComparisonConfig();
  const {
    scopedLoadingState,
    sensors,
    zoomState,
    setZoomState,
    xAxisTicks,
    xAxisDomain,
    machineChartData,
    chartData,
    shouldShowMachineState,
    handleZoom,
    handleMouseDown
  } = useMultipleSensorsGraphData(machineId, 'batch-sensor-comparison');
  const batchStartMs = moment(batchStart).valueOf();
  const batchEndMs = moment(batchEnd).valueOf();
  const blockStart = Math.max(batchStartMs, xAxisDomain[1]);
  const blockEnd = Math.min(batchEndMs, xAxisDomain[0]);

  const isTablet = useMediaQuery('(max-width:768px)');
  const styleProps = {
    true: { top: 280, fontSize: 12 },
    false: { top: 325 }
  };
  const renderLegend = props => {
    // eslint-disable-next-line
    const { payload } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          // eslint-disable-next-line
          payload.map(entry => (
            <div key={`${entry.payload.dataKey}-render-legend`} style={{ color: `${entry.color}`, marginRight: 30 }}>
              {entry.value}
            </div>
          ))
        }
      </div>
    );
  };

  return (
    <>
      {isLoading(scopedLoadingState.status) && <ScopedTimeRangeLoader />}
      <CustomChartLabels
        sensors={sensors}
        xAxisDomain={xAxisDomain}
        xAxisTicks={xAxisTicks}
        chartData={chartData}
        config={[
          {
            time: batchStartMs,
            label: t('batch_records.details.cycle_period'),
            position: 'insideBottomLeft'
          }
        ]}
      />
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          onMouseDown={e => handleMouseDown(e)}
          onMouseMove={e => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
          onMouseUp={handleZoom}
        >
          <XAxis
            dataKey="time"
            type="number"
            interval="preserveStartEnd"
            domain={xAxisDomain}
            stroke={AXIS_LINE_COLOR}
            ticks={xAxisTicks}
            tick={false}
            tickFormatter={formatXAxis}
            tickLine={false}
            axisLine={false}
          />
          {sensors.map((sensor, key) => {
            const yAxisWidth = calcYAxisWidth(chartData, sensor.type, sensor.visualization);
            return (
              <YAxis
                key={`${sensor.type}-yaxis`}
                yAxisId={key}
                stroke={AXIS_LINE_COLOR}
                tick={{ ...tickStyle, fill: COLORS[key] }}
                tickFormatter={formatYAxisRounded(sensor)}
                tickLine={false}
                width={yAxisWidth}
                orientation={key > 0 ? 'right' : 'left'}
                domain={getYAxisDomainBySensor(sensor)}
                type="number"
              />
            );
          })}
          <Tooltip content={<BatchMetricsCustomSensorCompareTooltip />} />
          {sensors.map((sensor, key) => {
            const sensorDetails = getSensorPropsFromType(sensor.type);
            return (
              <Line
                type="monotone"
                dot={false}
                key={`${sensor.is_custom ? `custom_sensor_id_${sensor.custom_sensor_id}` : sensor.type}-line`}
                isAnimationActive={false}
                stroke={COLORS[key]}
                strokeWidth={2}
                name={`${getSensorName(sensor, t(sensorDetails.name))} (${getSensorUnit(sensor)})`}
                dataKey={sensor.type}
                yAxisId={key}
                unit={getSensorUnit(sensor)}
              />
            );
          })}

          {zoomState.refAreaLeft && zoomState.refAreaRight ? (
            <ReferenceArea x1={zoomState.refAreaLeft} x2={zoomState.refAreaRight} strokeOpacity={0.3} />
          ) : null}
          <ReferenceArea x1={blockStart} x2={blockEnd} fill={COLOR_PRIMARY} fillOpacity={0.16} />
          <Legend
            align="center"
            verticalAlign="top"
            iconSize={0}
            wrapperStyle={styleProps[isTablet]}
            content={renderLegend}
          />
        </LineChart>
      </ResponsiveContainer>
      <div style={{ marginTop: -40 }}>
        <AuxiliaryAreaChart
          sensors={sensors}
          xAxisDomain={xAxisDomain}
          xAxisTicks={xAxisTicks}
          chartData={chartData}
          height={56}
          showAxisValues
        >
          {shouldShowMachineState &&
            machineChartData.map(reference => {
              const { color } = getMachineStatusProps(reference.value);
              return (
                <ReferenceArea
                  key={`${reference.start}-${reference.end}-${reference.value}-machine`}
                  x1={reference.start}
                  x2={reference.end}
                  y1={0.33}
                  y2={1}
                  yAxisId="machine_state"
                  fill={color}
                  fillOpacity={0.5}
                />
              );
            })}
          {shouldShowMachineState &&
            machineChartData.map(reference => {
              if (!reference.warning) {
                return null;
              }

              return (
                <ReferenceArea
                  key={`${reference.start}-${reference.end}-${reference.value}-warning`}
                  x1={reference.start}
                  x2={reference.end}
                  y1={0}
                  y2={0.33}
                  yAxisId="machine_state"
                  fill={SENSOR_WARNING_COLOR}
                  fillOpacity={0.5}
                />
              );
            })}
          <ReferenceArea
            x1={blockStart}
            x2={blockEnd}
            y1={0}
            y2={1}
            yAxisId="machine_state"
            fill={COLOR_PRIMARY}
            fillOpacity={0.16}
          />
        </AuxiliaryAreaChart>
      </div>
      <div style={{ marginTop: '-15px' }}>
        <CustomChartLabels
          sensors={sensors}
          xAxisDomain={xAxisDomain}
          xAxisTicks={xAxisTicks}
          chartData={chartData}
          config={[
            {
              time: batchStartMs,
              label: 'batchStart',
              shouldRenderArrow: true
            },
            {
              time: batchEndMs,
              label: 'batchEnd',
              shouldRenderArrow: true
            }
          ]}
        />
      </div>
      <CustomChartLabels
        sensors={sensors}
        xAxisDomain={xAxisDomain}
        xAxisTicks={xAxisTicks}
        chartData={chartData}
        config={[
          {
            time: batchStartMs,
            label: t('batch_records.details.batch_start'),
            position: 'insideBottom'
          },
          {
            time: batchEndMs,
            label: t('batch_records.details.batch_end'),
            position: 'insideBottom'
          }
        ]}
      />
    </>
  );
};

export default BatchMultipleSensorsGraph;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Container, Divider, InputAdornment, ListItemIcon, MenuItem } from '@mui/material';
import { Dialog, FormElements, NotifyIcon, NotifyTypo } from 'web-components';

import withLoadingState from '../../../../helpers/withLoadingState';
import { setConfirmationRequest } from '../../../../redux/ui/confirmations/actions';
import { getSensorPropsFromType, getUnit, isSuccess } from '../../../../helpers/utils';
import { generateInitialValues, getValidationSchema } from './utils';
import { COLOR_PRIMARY, COLOR_SECONDARY } from '../../../../attrs/colors';
import { handleNotificationSubmit, resetNotificationUpdateState } from '../../../../redux/machines/actions';
import RemoveNotificationRuleDialog from '../MachineNotificationSettings/RemoveNotificationRuleDialog';
import StyledButton from '../../../elements/StyledButton';

const { Heading1, Heading1Regular, Heading2, Body2, Caption } = NotifyTypo;

const SensorNotificationSettings = ({
  sensor,
  users,
  machine,
  menu,
  onMenuClick,
  notificationRules,
  notificationUpdateState,
  notificationDeleteState,
  isMobile
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { name: machineName, configuration: machineConfiguration } = machine;

  let errorThreshold = null;
  const sensorSettings = ((machineConfiguration || {}).metrics || {}).find(
    item => (item.is_custom ? item.custom_type : item.type) === sensor.type
  );
  if (((sensorSettings || {}).thresholds || {}).critical) {
    errorThreshold = sensorSettings.thresholds.critical;
  }

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onMenuClick) {
      onMenuClick();
    }
    dispatch(resetNotificationUpdateState());
  }, [dispatch, setOpen, onMenuClick]);

  useEffect(() => {
    if (isSuccess(notificationUpdateState.status)) {
      handleClose();
    }
  }, [notificationUpdateState, handleClose]);

  const initialValues = generateInitialValues(notificationRules, users, sensor, machine.id);

  const isValidNumber = type => type && typeof type.value === 'number';

  const handleFormSubmit = values => {
    const { min, max } = values;
    const valuesToPass = {
      ...(isValidNumber(max) && { max }),
      ...(isValidNumber(min) && { min })
    };

    dispatch(handleNotificationSubmit(valuesToPass));
  };

  const handleDialogClose = (dirty, resetForm) => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => {
            resetForm({ initialValues, values: initialValues });
            handleClose();
          }
        })
      );
    } else {
      handleClose();
    }
  };
  const hasNotificationRule =
    typeof notificationRules.min !== 'undefined' || typeof notificationRules.max !== 'undefined';
  const createOrEditAlertsButtonText = hasNotificationRule
    ? t('dashboard.sensors.edit_alerts')
    : t('dashboard.sensors.create_alerts');

  const sensorDetails = getSensorPropsFromType(sensor.type);
  return (
    <>
      {menu ? (
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <NotifyIcon iconName="notification" color="primary" fontSize="small" />
          </ListItemIcon>
          {createOrEditAlertsButtonText}
        </MenuItem>
      ) : (
        <StyledButton
          buttonText={createOrEditAlertsButtonText}
          color="primary"
          data-selector="sensor-create-alerts-button"
          data-test-type={hasNotificationRule ? 'edit' : 'create'}
          iconName="edit"
          variant="text"
          onClick={() => setOpen(true)}
          isMobile={isMobile}
        />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleFormSubmit(values)}
        validationSchema={getValidationSchema(sensor.trigger)}
      >
        {({ values, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, errors, touched }) => (
          <Dialog open={open} fullScreen>
            <Dialog.Header
              closeButtonProps={{ 'data-selector': 'sensor-notification-settings-close' }}
              highlightColor={COLOR_PRIMARY}
              onClose={() => handleDialogClose(dirty, resetForm)}
            >
              {t('dashboard.sensors.notifications.settings_title')}
            </Dialog.Header>
            <Dialog.SubHeader>{t('dashboard.sensors.notifications.settings_subtitle')}</Dialog.SubHeader>
            <Dialog.Body>
              <Container maxWidth="sm" style={{ marginTop: '1rem' }}>
                <Heading1Regular gutterBottom>{machineName}</Heading1Regular>
                <Heading1 gutterBottom style={{ margin: '1rem 0 2rem 0' }}>
                  {sensor.is_custom ? sensor.custom_name || sensor.name : t(sensorDetails.name)}
                </Heading1>
                {sensor.trigger &&
                  sensor.trigger.map(item => (
                    <div key={item} style={{ margin: '3rem 0' }}>
                      <Heading2>{t(`dashboard.sensors.notifications.trigger_${item}`)}</Heading2>
                      <FormElements.TextField
                        label={t('dashboard.sensors.notifications.value')}
                        fullWidth
                        id={`${item}_value`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {sensor.is_custom ? sensor.custom_unit : getUnit(sensor.unit)}
                            </InputAdornment>
                          )
                        }}
                        margin="normal"
                        name={`${item}.value`}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={typeof values[item].value === 'number' ? values[item].value : ''}
                        error={errors[item] && errors[item].value && touched[item] && touched[item].value}
                        helperText={
                          errors[item] &&
                          errors[item].value &&
                          touched[item] &&
                          touched[item].value &&
                          t(`form.validate.${errors[item].value}`)
                        }
                      />
                      <FormElements.MultiSelect
                        activeColor={COLOR_PRIMARY}
                        fullWidth
                        label={t('form.notifications.sms')}
                        id={`${item}_sms`}
                        name={`${item}.sms`}
                        defaultValue={initialValues[item].sms}
                        value={values[item].sms}
                        setFieldValue={setFieldValue}
                        options={users}
                        style={{ margin: '1rem 0' }}
                      />
                      <FormElements.MultiSelect
                        activeColor={COLOR_PRIMARY}
                        fullWidth
                        label={t('form.notifications.email')}
                        id={`${item}_email`}
                        name={`${item}.email`}
                        defaultValue={initialValues[item].email}
                        value={values[item].email}
                        setFieldValue={setFieldValue}
                        options={users}
                        style={{ margin: '1rem 0' }}
                      />
                      {((errors[item] || {}).email || (errors[item] || {}).sms) && (
                        <div style={{ marginBottom: '1rem' }}>
                          <Caption style={{ color: COLOR_SECONDARY }}>
                            {t('dashboard.sensors.notifications.provide_one_recipient')}
                          </Caption>
                        </div>
                      )}
                      <RemoveNotificationRuleDialog
                        notificationRule={notificationRules[item]}
                        notificationDeleteState={notificationDeleteState}
                        onSuccessfulDeletion={() => {
                          resetForm({ initialValues, values: initialValues });
                        }}
                      />
                    </div>
                  ))}
                <Divider style={{ marginBottom: '3rem' }} />

                {errorThreshold &&
                  Object.keys(errorThreshold).map(trigger => (
                    <div key={`error_trigger_${trigger}`} style={{ margin: '2rem 0' }}>
                      <Heading2>{t(`dashboard.sensors.notifications.error_trigger_${trigger}`)}</Heading2>
                      <FormElements.TextField
                        label={t('dashboard.sensors.notifications.value')}
                        disabled
                        fullWidth
                        id={`error_trigger_${trigger}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {sensor.is_custom ? sensor.custom_unit : getUnit(sensor.unit)}
                            </InputAdornment>
                          )
                        }}
                        margin="normal"
                        value={errorThreshold[trigger] || ''}
                      />
                    </div>
                  ))}
                {!sensor.trigger.length && !errorThreshold && (
                  <Body2 style={{ fontWeight: 500 }}>
                    {t('dashboard.sensors.notifications.error_trigger_no_values')}
                  </Body2>
                )}
              </Container>
            </Dialog.Body>
            <Dialog.Actions>
              <Button
                data-selector="apply-rules-button"
                disabled={!dirty || Object.keys(errors).length !== 0}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="submit"
              >
                {t('form.apply')}
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

SensorNotificationSettings.propTypes = {
  sensor: T.shape({
    name: T.string,
    id: T.string,
    trigger: T.arrayOf(T.string),
    unit: T.string,
    type: T.string,
    is_custom: T.bool,
    custom_type: T.string,
    custom_name: T.string,
    custom_unit: T.string
  }).isRequired,
  machine: T.shape({ name: T.string, configuration: T.shape({}), id: T.string }).isRequired,
  menu: T.bool.isRequired,
  notificationUpdateState: T.shape({ status: T.string }).isRequired,
  notificationDeleteState: T.shape({ status: T.string }).isRequired,
  users: T.arrayOf(T.shape({})),
  notificationRules: T.shape({
    min: T.shape({}),
    max: T.shape({})
  }).isRequired,
  onMenuClick: T.func,
  isMobile: T.bool.isRequired
};

SensorNotificationSettings.defaultProps = {
  users: [],
  onMenuClick: null
};

export default withLoadingState(SensorNotificationSettings);

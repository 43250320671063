import styled from 'styled-components';
import { Dialog } from 'web-components';

const DIALOG_BACKGROUND_COLOR = '#FFFFFF';
const FOOTER_BACKGROUND_COLOR = '#F8F8F9';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background-color: ${FOOTER_BACKGROUND_COLOR} !important;
  }
`;

// Define the header title
export const StyledDialogHeader = styled(Dialog.Header)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  padding: 1rem 1.5rem !important;

  & .MuiTypography-h1 {
    font-size: 1.75rem;
    align-self: center;

    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
    }
  }

  & .MuiIconButton-root {
    position: absolute;
    right: .5rem;
  }
`
);

// Define the subheader title
export const StyledDialogSubHeader = styled(Dialog.SubHeader)(
  ({ theme }) => `
  background-color: ${DIALOG_BACKGROUND_COLOR};
  
  & .MuiTypography-body1 {
    font-size: 0.875rem;

    ${theme.breakpoints.down('xs')} {
      margin-top: -0.5rem;
    }
  }
`
);

// Body
export const StyledDialogBody = styled(Dialog.Body)`
  background-color: ${DIALOG_BACKGROUND_COLOR};
`;

// Buttons
export const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    margin: 1.25rem;

    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 0.375rem 2.25rem;
    }
  }
`;

import styled from 'styled-components';
import { InputAdornment } from '@mui/material';

import { COLOR_PRIMARY, SECONDARY_TEXT_COLOR } from 'attrs/colors';
import { NotifyTypo } from 'web-components';

export const SupportFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormDetailsWrapper = styled.div`
  & .productionLine {
    margin-top: 1.75rem;
  }

  & .mandatoryFieldsLabel {
    margin-top: 3rem;
  }

  & .MuiFormLabel-filled {
    color: ${COLOR_PRIMARY};
  }
`;

export const MandatoryFieldsLabel = styled(NotifyTypo.InfoText)`
  &.MuiTypography-root {
    margin: 1.5rem 0 0.5rem;
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  font-size: 0.875rem;
  color: ${SECONDARY_TEXT_COLOR};
`;

import * as constants from './constants';
import { BATCH_MACHINE_RANGE, CHANGE_BATCHES_FILTER, CHANGE_BATCHES_ORDER } from './constants';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE } from '../../attrs/pagination';
import { BATCH_DEFAULT_LIST_ORDER, BATCH_DEFAULT_LIST_SORT } from '../../attrs/batch';
import { removeEmptyValuesRecursivelyPaginated } from '../utils';

const loadBatches = (
  page,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  sort = BATCH_DEFAULT_LIST_SORT,
  order = BATCH_DEFAULT_LIST_ORDER
) =>
  removeEmptyValuesRecursivelyPaginated({
    type: constants.LOAD_BATCHES,
    page,
    limit,
    sort,
    order
  });

const loadBatchById = batchId => ({
  type: constants.LOAD_BATCH,
  batchId
});

const fetchNextBatchPage = (page, limit, sort, order, search) => ({
  type: constants.LOAD_BATCHES_PAGE,
  page,
  limit,
  sort,
  order,
  search
});

const setBatchMachineRange = (machineId, start, end) => ({
  type: BATCH_MACHINE_RANGE,
  machineId,
  start,
  end
});

const setBatchSortOrder = (sort, order) => ({
  type: CHANGE_BATCHES_ORDER,
  sort,
  order
});

const setBatchFilter = filter => ({
  type: CHANGE_BATCHES_FILTER,
  filter
});

const getBatchesFiltered = (page, limit, sort, order, batchId, machine, recipe) =>
  removeEmptyValuesRecursivelyPaginated({
    type: constants.LOAD_BATCHES_WITH_FILTER,
    page,
    limit,
    sort,
    order,
    batchId,
    machine,
    recipe
  });

export {
  loadBatches,
  loadBatchById,
  fetchNextBatchPage,
  setBatchMachineRange,
  setBatchSortOrder,
  setBatchFilter,
  getBatchesFiltered
};

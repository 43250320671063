import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Divider, Button } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';

import { NotificationHeading, ErrorWrapper } from './elements';
import { showSupport } from '../../../redux/ui/actions';

const { Heading1, Body1, Body2 } = NotifyTypo;

const AlertError = ({ setMenuOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSupportClick = () => {
    setMenuOpen(false);
    dispatch(showSupport(true));
  };

  return (
    <ErrorWrapper data-selector="alert-error-component">
      <NotificationHeading key="notificationHeader">
        <Heading1>{t('alerts.title')}</Heading1>
      </NotificationHeading>
      <Divider />
      <Body2 align="center" style={{ fontWeight: 500 }}>
        <NotifyIcon iconName="reload" style={{ margin: '1rem 0' }} />
      </Body2>
      <Body2 align="center" style={{ marginBottom: '1rem', fontWeight: 500 }}>
        {t('alerts.text_error_1')}
      </Body2>
      <Body1 align="center">{t('alerts.text_error_2')}</Body1>
      <Body1 align="center">
        <Button
          onClick={handleSupportClick}
          style={{
            textTransform: 'none',
            textDecoration: 'underline',
            fontSize: '1rem',
            fontWeight: 400
          }}
        >
          {t('alerts.text_error_3')}
        </Button>
      </Body1>
    </ErrorWrapper>
  );
};

AlertError.propTypes = {
  setMenuOpen: T.func.isRequired
};

export default AlertError;

import React from 'react';
import T from 'prop-types';

import withLoadingState from '../../helpers/withLoadingState';
import UserTableSelector from '../../components/UserTable';
import EmptyState from '../../components/EmptyState';

const UserManagementContent = ({ users, selectedTab }) => {
  const filteredUsers = users.filter(selectedTab.filter);

  return (
    <>
      {filteredUsers.length ? (
        <UserTableSelector columns={selectedTab.columns} rows={filteredUsers} />
      ) : (
        <EmptyState type={`user_management_${selectedTab.title}`} />
      )}
    </>
  );
};

UserManagementContent.propTypes = {
  users: T.arrayOf(T.shape({})),
  selectedTab: T.shape({
    columns: T.arrayOf(T.shape({})),
    title: T.string,
    subtitle: T.string,
    filter: T.func
  }).isRequired
};

UserManagementContent.defaultProps = {
  users: null
};

export default withLoadingState(UserManagementContent);

import { put, takeLatest } from 'redux-saga/effects';
import * as constants from './constants';
import { loadScopedComparisonData, loadScopedSensorHistory } from '../../machines/actions';

function* handleSetScopedTimeWindow({ from, to, machineId, sensorType }) {
  if (typeof sensorType === 'string') {
    yield put(loadScopedSensorHistory(machineId, sensorType, { from, to }));
  }
  if (Array.isArray(sensorType)) {
    yield put(loadScopedComparisonData(machineId, sensorType, { from, to }));
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.SET_SCOPED_TIME_WINDOW, handleSetScopedTimeWindow);
}

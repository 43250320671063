import T from 'prop-types';
import { BatchResponse } from 'helpers/propTypes';
import { getValueToNullableCell } from 'helpers/stringHandler';
import {
  getMachineType,
  getMillBeadMaterialType,
  getMillGrindingTechnology,
  getMillInnerLinerMaterial
} from 'helpers/utils';
import i18n from 'i18next';

const processData = data => {
  if (!data) {
    return null;
  }

  const machineType = getMachineType(data?.type);
  const grindingTechnology = getMillGrindingTechnology(data?.mechanical_config?.grinding_technology);
  const innerLinerMaterial = getMillInnerLinerMaterial(data?.mechanical_config?.inner_liner_material);
  const beadMaterialType = getMillBeadMaterialType(data?.mechanical_config?.bead_material);

  let sambaSystemKey = 'batch_records.details.samba_system_false';
  if ([null, undefined].includes(data?.mechanical_config.samba_system)) {
    sambaSystemKey = 'batch_records.details.samba_system_undefined';
  } else if (data?.mechanical_config.samba_system === true) {
    sambaSystemKey = 'batch_records.details.samba_system_true';
  }

  const machineDetailsData = {
    localeKey: 'machine_details',
    rows: [
      {
        id: 'machine-row-1',
        row: [
          {
            localeKey: 'commission_no',
            value: getValueToNullableCell(data?.commission_number)
          },
          {
            localeKey: 'name',
            value: getValueToNullableCell(data?.name)
          },
          {
            localeKey: 'brand',
            value: machineType.brand
          },
          {
            localeKey: 'mill_system',
            value: machineType.type
          },
          {
            localeKey: 'grinding_technology',
            value: getValueToNullableCell(grindingTechnology?.name)
          },
          {
            localeKey: 'inner_liner_material',
            value: getValueToNullableCell(innerLinerMaterial?.name)
          },
          {
            localeKey: 'samba_system',
            value: i18n.t(sambaSystemKey)
          },
          {
            localeKey: 'sieve_size',
            value: getValueToNullableCell(data?.mechanical_config?.sieve_size)
          }
        ]
      },
      {
        id: 'machine-beads',
        localeKey: 'beads',
        row: [
          {
            localeKey: 'bead_material',
            value: getValueToNullableCell(beadMaterialType?.name)
          },
          {
            localeKey: 'bead_size_1',
            value: getValueToNullableCell(data?.mechanical_config?.beads_size_1)
          },
          {
            localeKey: 'bead_size_2',
            value: getValueToNullableCell(data?.mechanical_config?.beads_size_2)
          },
          {
            localeKey: 'bead_filling_level',
            value: getValueToNullableCell(data?.mechanical_config?.beads_filling_level)
          }
        ]
      }
    ]
  };
  return { machineDetailsData };
};

processData.propTypes = {
  data: T.shape(BatchResponse).isRequired
};

export { processData };

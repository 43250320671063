import axios from './axios';

const loadMetricsHistoryChartData = (id, from, to, metrics) =>
  axios({
    method: 'get',
    url: '/v1/metrics/history/chart-data',
    params: {
      machine_id: id,
      from,
      to,
      metrics
    }
  });

const loadMetricHistoryChartData = (id, from, to, metrics) =>
  axios({
    method: 'get',
    url: '/v1/metrics/metric-chart-data',
    params: {
      machine_id: id,
      from,
      to,
      simple_metric: metrics
    }
  });

export { loadMetricsHistoryChartData, loadMetricHistoryChartData };

import { Card } from '@mui/material';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR } from '../../../../attrs/colors';

const GraphWrapper = styled(Card)`
  &.MuiCard-root {
    border: 1px solid #f7f7fa;
    box-sizing: border-box;
    padding: 0 2rem 2rem 2rem;
    margin-top: 6rem;
    @media (max-width: 425px) {
      padding: 0 2rem 5rem 2rem;
    }
  }
  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const MachineStateSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    props.mobile &&
    `
    flex: 1;
  `};
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

const CheckboxWrapper = styled.div`
  & > .MuiFormHelperText-root {
    margin: -0.75rem 0 0 2rem;
  }

  & > .MuiFormControlLabel-root {
    ${props => props.showHelperText && `margin-top: 0.625rem;`}
  }

  & .MuiFormControlLabel-label.Mui-disabled {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

const ContainerComparisonFooter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  color: ${props => props.color};
  align-items: flex-start;
  margin-top: 8rem;

  @media (max-width: 500px) {
    padding: 0;
    margin-top: 3rem;
    width: 100%;
  }
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
`;

export { GraphWrapper, MachineStateSelectionWrapper, CheckboxWrapper, ContainerComparisonFooter, ContainerTitle };

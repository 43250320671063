import React from 'react';

import { StyledList } from './elements';
import * as features from '../../helpers/features';

const FeatureToggles = () => {
  if (!features.get('is_debug')) return null;

  return (
    <StyledList>
      <li>{`version: ${window.configuration.releaseVersion}`}</li>
      {features.getActiveFeatures().map(f => (
        <li key={f}>{`${f}: ON`}</li>
      ))}
    </StyledList>
  );
};

export default FeatureToggles;

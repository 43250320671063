import { SET_CONFIRMATION_REQUEST, RESOLVE_CONFIRMATION_REQUEST } from './constants';

const setConfirmationRequest = confirmation => ({
  type: SET_CONFIRMATION_REQUEST,
  confirmation
});

const resolveConfirmationRequest = () => ({
  type: RESOLVE_CONFIRMATION_REQUEST
});

export { setConfirmationRequest, resolveConfirmationRequest };

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DataTable, useResolutionCheck } from 'web-components';

import { FooterWrapper, ItemsCountLabel } from './Footer.styled';

const Footer = ({ currentPage, totalPages, totalItems, onChange }) => {
  const { t } = useTranslation();
  const { isMobile, isSmallDevice } = useResolutionCheck();

  return (
    <FooterWrapper isMobile={isMobile} isSmallDevice={isSmallDevice}>
      {!isSmallDevice && (
        <ItemsCountLabel>
          {totalItems || 0} {t('general.overview.items')}
        </ItemsCountLabel>
      )}
      <DataTable.Pagination
        numberOfPages={totalPages}
        page={currentPage}
        onChange={onChange}
        localeText={{
          of: t('general.overview.of')
        }}
        size={isSmallDevice ? 'small' : 'medium'}
      />
    </FooterWrapper>
  );
};

Footer.propTypes = {
  currentPage: T.number.isRequired,
  totalPages: T.number.isRequired,
  totalItems: T.number.isRequired,
  onChange: T.func.isRequired
};

export default Footer;

import axios from './axios';

const loadBatchList = (page, limit, sort, order, batchId, machine, recipe) =>
  axios({
    method: 'get',
    url: '/v1/batch',
    params: {
      limit,
      page,
      sort,
      order,
      batch_id: batchId,
      machine_name: machine,
      recipe
    }
  });

export const loadGraphTemplates = (machineId, accessType, sensor, templateName) => {
  const params = {
    access_type: accessType || undefined,
    sensor_name: sensor || undefined,
    template_name: templateName || undefined
  };

  return axios({
    method: 'get',
    url: `/v2/machine/${machineId}/trends/chart-templates`,
    params
  });
};

const loadBatch = batchId =>
  axios({
    method: 'get',
    url: `/v1/batch/${batchId}`
  });

const loadBatchRecordSensorHistory = (batchId, metrics, timeFrom = '', timeTo = '') =>
  axios({
    method: 'get',
    url: `/v1/batch/${batchId}/history`,
    params: {
      metrics,
      from: timeFrom,
      to: timeTo
    }
  });

export { loadBatchList, loadBatch, loadBatchRecordSensorHistory };

import styled from 'styled-components';
import { Dialog } from 'web-components';

const ActionWrapper = styled.div(
  props => `
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
  ${props.theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }
  ${props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    padding: 1rem 0;
    & button {
      width: 100%;
      margin-bottom: .5rem;
    }
  }
`
);
const StyledDialogHeader = styled(Dialog.Header)`
  &.MuiDialogTitle-root {
    padding-left: 3.2rem;
  }
`;
const StyledDialogSubHeader = styled(Dialog.SubHeader)`
  &.MuiDialogTitle-root {
    padding-left: 3.2rem;
  }
`;
const StyledDialogActions = styled(Dialog.Actions)`
  &.MuiDialogActions-root {
    justify-content: space-between;
    margin: 1.25rem;

    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 1rem 2.25rem;
    }
    @media (max-width: 425px) {
      flex-direction: column-reverse;
    }
  }
`;

export { ActionWrapper, StyledDialogSubHeader, StyledDialogHeader, StyledDialogActions };

import { call, put, takeLatest } from 'redux-saga/effects';
import { actionStatus, statusAction } from 'redux/utils';

import * as api from '../../api/metrics_machines';
import { LOAD_METRICS_MACHINES } from './constants';

function* handleLoadMetricsMachines({ machineId }) {
  yield put(statusAction(LOAD_METRICS_MACHINES, actionStatus.START));
  try {
    const { data } = yield call(api.loadMetricsMachines, machineId);
    yield put(statusAction(LOAD_METRICS_MACHINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_METRICS_MACHINES, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_METRICS_MACHINES, handleLoadMetricsMachines);
}

import React from 'react';
import T from 'prop-types';
import { useSelector } from 'react-redux';
import { MachineIcon, NotifyIcon } from 'web-components';
import Tooltip from '@mui/material/Tooltip';
import { ContentCard, StyledCard, StyledContent } from './elements';
import MachineStatus from '../elements/MachineStatus';
import { getMachineStatusProps } from '../../helpers/utils';
import { getSelectedMachineNotificationRule } from '../../redux/machines/selectors';
import MachineErrorTooltip from '../MachineErrorChip/MachineErrorTooltip';

const MachineCard = ({ machine, withName, withAlertSign, unresolvedError, showError }) => {
  const status = getMachineStatusProps(((machine || {}).status || {}).value);
  const isWarning = ((machine || {}).status || {}).warning || false;
  const errorWS = ((machine || {}).status || {}).error || {};
  const notificationRules = useSelector(getSelectedMachineNotificationRule);

  const showTooltip = () => {
    if (!showError) {
      return null;
    }

    if (machine?.status?.value === 'CRITICAL' && errorWS.code) {
      return (
        <Tooltip title={<MachineErrorTooltip error={errorWS} />} placement="top">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NotifyIcon fontSize="small" color="error" iconName="error" viewBox="0 0 24 24" />
          </div>
        </Tooltip>
      );
    }
    if (machine?.status?.value === 'CRITICAL' && unresolvedError.code) {
      return (
        <Tooltip title={<MachineErrorTooltip error={unresolvedError} />} placement="top">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <NotifyIcon fontSize="small" color="error" iconName="error" viewBox="0 0 24 24" />
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  const hasNotificationRule = notificationRules.length > 0;

  return (
    <StyledCard data-selector="machine-card" data-test-status={status.value} borderColor={status.color}>
      <MachineIcon iconName={status.icon || 'error'} style={{ fontSize: '2rem' }} viewBox="0 0 32 32" />

      <StyledContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MachineStatus status={status} />
          {withAlertSign && hasNotificationRule && (
            <NotifyIcon fontSize="small" iconName="alarmIcon" viewBox="0 0 14 14" style={{ marginLeft: '.5rem' }} />
          )}
          {showTooltip()}
        </div>
        <ContentCard>
          {withName && machine.name}
          {isWarning && <NotifyIcon iconName="warning" viewBox="0 0 20 20" style={{ marginLeft: 8 }} />}
        </ContentCard>
      </StyledContent>
    </StyledCard>
  );
};

MachineCard.propTypes = {
  machine: T.shape({ name: T.string, id: T.string, status: T.shape({ value: T.string, warning: T.bool }) }),
  withName: T.bool,
  withAlertSign: T.bool,
  unresolvedError: T.instanceOf(Object),
  showError: T.bool
};

MachineCard.defaultProps = {
  machine: null,
  withName: true,
  withAlertSign: false,
  unresolvedError: {},
  showError: false
};

export default MachineCard;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer, enhanceWithResetActions } from '../utils';

import {
  SET_USER_INFORMATION,
  GET_ORGANIZATION,
  LOAD_USER,
  UPDATE_USER,
  RESET_PASSWORD,
  RESET_USER_UPDATE_LOADING_STATE
} from './constants';
import { LOGIN, LOGOUT } from '../auth/constants';

const organizationReducer = combineReducers({
  loadingState: loadingStateReducer(GET_ORGANIZATION),
  data: typeToReducer(
    {
      [GET_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const userReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_USER),
  updateLoadingState: enhanceWithResetActions([LOAD_USER, RESET_USER_UPDATE_LOADING_STATE])(
    loadingStateReducer([UPDATE_USER, RESET_PASSWORD])
  ),
  data: typeToReducer(
    {
      [LOGIN]: {
        START: () => null,
        ERROR: () => null
      },
      [LOGOUT]: {
        SUCCESS: () => null
      },
      [SET_USER_INFORMATION]: (state, { payload }) => ({ ...state, ...payload }),
      [LOAD_USER]: {
        SUCCESS: (state, { payload }) => ({ ...state, ...payload })
      },
      [UPDATE_USER]: {
        SUCCESS: (state, { payload }) => ({ ...state, ...payload })
      }
    },
    {}
  )
});

export default combineReducers({
  user: userReducer,
  organization: organizationReducer
});

import * as constants from './constants';

const loadWebsocketToken = () => ({
  type: constants.LOAD_WEBSOCKET_TOKEN
});

const cleanupWebsockets = () => ({
  type: constants.CLEANUP_WEBSOCKETS
});

export { loadWebsocketToken, cleanupWebsockets };

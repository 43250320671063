import React from 'react';
import T from 'prop-types';

import { BatchStatusChip, DataTable } from 'web-components';
import { getToFixedValue } from 'helpers/utils';

const COLUMN_BATCH_ID = 'batch_id';
const COLUMN_MACHINE = 'machine';
const COLUMN_RECIPE = 'recipe';
const COLUMN_PROGRESS = 'progress';
const COLUMN_GRINDING_ENERGY = 'grinding_energy';
export const COLUMN_STATUS = 'status';
export const COLUMN_START_TIME = 'start_time';
export const COLUMN_END_TIME = 'end_time';

export const generateColumns = t => [
  { id: COLUMN_BATCH_ID, label: t(`batch_records.overview.batch_id`), sortable: false },
  {
    id: COLUMN_MACHINE,
    label: t(`batch_records.overview.machine`),
    sortable: false,
    cellRenderer: row => <div>{row.machine || t('machines.overview.machine_name_not_assigned')}</div>
  },
  {
    id: COLUMN_STATUS,
    label: t(`batch_records.overview.status`),
    sortable: false,
    cellRenderer: row => (
      <div className="statusWrapper">
        <BatchStatusChip status={row.status} label={t(`batch_records.overview.${row.status.toLowerCase()}`)} />
      </div>
    )
  },
  { id: COLUMN_RECIPE, label: t(`batch_records.overview.recipe`), sortable: false },
  {
    id: COLUMN_PROGRESS,
    label: t(`batch_records.overview.progress`),
    sortable: false,
    cellRenderer: row => <div> {Number.isInteger(row.progress) ? row.progress : getToFixedValue(row.progress)}% </div>
  },

  {
    id: COLUMN_START_TIME,
    label: t(`batch_records.overview.start_time`),
    sortable: true,
    cellRenderer: row => <div>{new Date(row.start_time.toString()).toLocaleString()}</div>
  },
  {
    id: COLUMN_GRINDING_ENERGY,
    label: t(`batch_records.overview.grinding_energy`),
    sortable: false,
    cellRenderer: row => (
      <div>
        {Number.isInteger(row.grinding_energy) ? row.grinding_energy : getToFixedValue(row.grinding_energy)} kWh
      </div>
    )
  }
];

export const generateSortMenuItems = t => [
  {
    label: t('batch_records.overview.order'),
    value: null,
    disabled: true
  },
  {
    label: `${t('batch_records.overview.start_time')} - ${t('batch_records.overview.ascending')}`,
    value: { colId: COLUMN_START_TIME, order: DataTable.ORDER_ASC }
  },
  {
    label: `${t('batch_records.overview.start_time')} - ${t('batch_records.overview.descending')}`,
    value: { colId: COLUMN_START_TIME, order: DataTable.ORDER_DESC }
  }
];

export const BatchRecordsColumnProps = T.arrayOf(
  T.shape({
    id: T.string.isRequired,
    label: T.string.isRequired,
    sortable: T.bool,
    cellRenderer: T.func
  })
);

export const BatchRecordsRowProps = T.shape({
  batch_id: T.string.isRequired,
  machine: T.string.isRequired,
  status: T.string.isRequired,
  recipe: T.string.isRequired,
  progress: T.string.isRequired,
  start_time: T.string.isRequired,
  grinding_energy: T.string.isRequired
});

export const BatchRecordsRowsProps = T.arrayOf(BatchRecordsRowProps);

import styled from 'styled-components';
import { Container, Grid } from '@mui/material';
import { FOOTER_NAVIGATION_BAR_HEIGHT } from '../../../attrs/layout';

export const ContainerForm = styled(Container)`
  padding-bottom: ${FOOTER_NAVIGATION_BAR_HEIGHT + 20}px;
`;

export const ButtonContainer = styled(Grid)`
  &.MuiGrid-container {
    display: flex;
    justify-content: space-between;
    background: #f7f7fa;
    padding: 2rem;
    border-top: 1px solid rgba(43, 47, 66, 0.5);
    & .MuiButtonBase-root {
      font-size: 0.875rem;
    }

    & button {
      padding: 1rem 2.25rem;
    }
    @media (max-width: 425px) {
      flex-direction: column-reverse;
    }
  }
`;

import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { createReducer, enhanceWithResetActions, loadingStateReducer } from '../utils';

import {
  ADD_SENSOR_COMPARISON,
  ADD_SENSORS_COMPARISON,
  ADD_SENSORS_TO_MACHINE,
  CREATE_NOTIFICATION_RULE,
  defaultNullSensorValue,
  DELETE_NOTIFICATION_RULE,
  LOAD_LAST_SENSOR_METRIC,
  LOAD_LAST_SENSOR_METRICS,
  LOAD_MACHINE,
  LOAD_MACHINE_HISTORY,
  LOAD_MACHINE_NOTIFICATION_RULES,
  LOAD_MACHINES,
  LOAD_MACHINES_RESUMED,
  LOAD_SCOPED_MACHINE_HISTORY,
  LOAD_SCOPED_SENSOR_COMPARISON_DATA,
  LOAD_SCOPED_SENSOR_HISTORY,
  LOAD_SENSOR,
  LOAD_SENSOR_COMPARISON_DATA,
  LOAD_SENSOR_HISTORY,
  LOAD_UNRESOLVED_MACHINE_ERROR,
  MACHINE_EDIT_SHOW_MODAL,
  REMOVE_SENSOR_COMPARISON,
  REPLACE_SENSOR_COMPARISON,
  RESET_COMPARISON,
  RESET_MACHINE,
  RESET_MACHINES,
  RESET_NOTIFICATION_UPDATE,
  UPDATE_MACHINE,
  UPDATE_MACHINE_SENSOR_STATUS,
  UPDATE_MACHINE_STATUS,
  UPDATE_NOTIFICATION_RULE
} from './constants';
import { STATIC_SENSOR_TYPE_BOOLEAN, STATIC_SENSOR_TYPE_NUMBER, STATIC_SENSOR_TYPE_STRING } from '../../helpers/consts';
import { isStaticSensor } from '../../helpers/utils';

const metricsReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_HISTORY),
  data: typeToReducer(
    {
      [LOAD_MACHINE_HISTORY]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  scopedHistory: combineReducers(
    {
      loadingState: loadingStateReducer(LOAD_SCOPED_MACHINE_HISTORY),
      data: typeToReducer(
        {
          [LOAD_MACHINE_HISTORY]: {
            SUCCESS: (data, { payload }) => payload
          },
          [LOAD_SCOPED_MACHINE_HISTORY]: {
            SUCCESS: (data, { payload }) => payload
          }
        },
        []
      )
    },
    {}
  )
});

const selectedUnresolvedErrorReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_UNRESOLVED_MACHINE_ERROR),
  data: typeToReducer(
    {
      [LOAD_UNRESOLVED_MACHINE_ERROR]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const selectedErrorReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_UNRESOLVED_MACHINE_ERROR),
  data: typeToReducer(
    {
      [LOAD_UNRESOLVED_MACHINE_ERROR]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_MACHINE_STATUS]: (data, { payload, selectedMachineId }) => {
        // if update machine payload is matching with selected machine,
        // check whether there is an error in the payload
        // if not: update with initial state
        if (selectedMachineId === payload.machine_id) {
          if (payload.error) {
            return {
              ...data,
              ...payload.error,
              message: payload.error.message,
              code: payload.error.code,
              time: payload.updated_at
            };
          }
          return {
            machine_id: data.machine_id,
            time: payload.updated_at,
            warning: payload.warning
          };
        }

        return data;
      }
    },
    {}
  )
});

const lastSensorValueOrDefault = (sensor = { values: [] }) => {
  const lastValue = sensor.values[0] || {};

  return {
    value: typeof lastValue.value === 'number' ? lastValue.value : '---',
    time: lastValue.time || defaultNullSensorValue
  };
};

const staticLastSensorValueOrDefault = (sensor = { static_values: [] }) => {
  const lastValue = sensor.static_values[0] || {};
  let sensorType = sensor.value_type;
  // FIXME: This is ugly, but works, for some reason the selector at subscribeToSensorsUpdateStream ->
  //        getSelectedSensor don't return any data for static sensors or custom sensors,
  //        and we can't found where they validate the sensor value coming from websocket, soo
  //        we can't get the sensor.value_type from websocket. So we need to infer by the last value object name
  if (!sensor.value_type) {
    Object.entries(lastValue).forEach(([key]) => {
      if (key !== 'time') {
        if (key.includes('string')) {
          sensorType = STATIC_SENSOR_TYPE_STRING;
        } else if (key.includes('boolean')) {
          sensorType = STATIC_SENSOR_TYPE_BOOLEAN;
        } else if (key.includes('number')) {
          sensorType = STATIC_SENSOR_TYPE_NUMBER;
        }
      }
    });
  }

  let nextValue;
  switch (sensorType) {
    case STATIC_SENSOR_TYPE_STRING:
      return {
        value: lastValue.string_value ? lastValue.string_value : defaultNullSensorValue,
        time: lastValue.time || defaultNullSensorValue
      };
    case STATIC_SENSOR_TYPE_NUMBER:
      return {
        value: lastValue.number_value ? lastValue.number_value : defaultNullSensorValue,
        time: lastValue.time || defaultNullSensorValue
      };
    case STATIC_SENSOR_TYPE_BOOLEAN:
      nextValue = lastValue.boolean_value === true ? 'true' : 'false';
      return {
        value: nextValue,
        time: lastValue.time || defaultNullSensorValue
      };
    default:
      return {
        value: defaultNullSensorValue,
        time: defaultNullSensorValue
      };
  }
};

const machineSensorComparisonReducer = combineReducers({
  selected: typeToReducer(
    {
      [ADD_SENSORS_COMPARISON]: (data, { sensorTypes }) => sensorTypes,
      [ADD_SENSOR_COMPARISON]: (data, { sensorType }) => [...data, sensorType],
      [REPLACE_SENSOR_COMPARISON]: (data, { oldSensorType, newSensorType }) =>
        data.map(item => (item === oldSensorType ? newSensorType : item)),
      [REMOVE_SENSOR_COMPARISON]: (data, { sensorType }) => (data || []).filter(sensor => sensor !== sensorType)
    },
    []
  ),
  historical: combineReducers({
    loadingState: loadingStateReducer(LOAD_SENSOR_COMPARISON_DATA),
    data: typeToReducer(
      {
        [LOAD_SENSOR_COMPARISON_DATA]: {
          SUCCESS: (data, { payload }) => payload
        },
        [REMOVE_SENSOR_COMPARISON]: (data, { sensorType }) =>
          (data.sensors || []).filter(sensor => sensor.type !== sensorType)
      },
      {}
    )
  }),
  scopedHistory: combineReducers({
    loadingState: loadingStateReducer(LOAD_SCOPED_SENSOR_COMPARISON_DATA),
    data: typeToReducer(
      {
        [LOAD_SENSOR_COMPARISON_DATA]: {
          SUCCESS: (data, { payload }) => payload
        },
        [LOAD_SCOPED_SENSOR_COMPARISON_DATA]: {
          SUCCESS: (data, { payload }) => payload
        },
        [REMOVE_SENSOR_COMPARISON]: (data, { sensorType }) =>
          (data.sensors || []).filter(sensor => sensor.type !== sensorType)
      },
      {}
    )
  })
});

const selectedMachineSensorReducer = combineReducers({
  data: typeToReducer(
    {
      [ADD_SENSORS_TO_MACHINE]: (data, { sensors }) => sensors,
      [LOAD_LAST_SENSOR_METRICS]: {
        SUCCESS: (data, { payload }) =>
          data.map(s1 => {
            const newData = {};
            const found = payload.find(s2 => s2.type === s1.type);
            if (found) {
              newData.status = found.status;
              newData.last = lastSensorValueOrDefault(found);
              if (found.is_static || isStaticSensor(found.type)) {
                newData.static_value = staticLastSensorValueOrDefault(found);
                newData.static_values = found.static_values;
              }
            }
            return {
              ...s1,
              ...newData
            };
          })
      },
      [UPDATE_MACHINE_SENSOR_STATUS]: (data, { sensor }) =>
        data.map(s => {
          if (s.type === sensor.type) {
            const ret = {
              ...s,
              status: sensor.status,
              last: lastSensorValueOrDefault(sensor)
            };
            if (s.is_static || isStaticSensor(s.type)) {
              ret.static_value = staticLastSensorValueOrDefault(sensor);
            }
            return ret;
          }

          return s;
        })
    },
    []
  ),
  comparison: enhanceWithResetActions([RESET_MACHINE, RESET_COMPARISON])(machineSensorComparisonReducer)
});

const notificationRulesReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_NOTIFICATION_RULES),
  updateLoadingState: enhanceWithResetActions([RESET_NOTIFICATION_UPDATE])(
    loadingStateReducer([UPDATE_NOTIFICATION_RULE, CREATE_NOTIFICATION_RULE])
  ),
  deleteLoadingState: enhanceWithResetActions([RESET_NOTIFICATION_UPDATE])(
    loadingStateReducer([DELETE_NOTIFICATION_RULE])
  ),
  data: typeToReducer(
    {
      [DELETE_NOTIFICATION_RULE]: {
        SUCCESS: (data, { id }) => data.filter(rule => rule.id !== id)
      },
      [LOAD_MACHINE_NOTIFICATION_RULES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const selectedSensorReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_SENSOR),
  data: typeToReducer(
    {
      [LOAD_SENSOR]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  history: combineReducers(
    {
      loadingState: loadingStateReducer(LOAD_SENSOR_HISTORY),
      data: typeToReducer(
        {
          [LOAD_SENSOR]: {
            SUCCESS: (data, { payload }) => payload
          },
          [LOAD_SENSOR_HISTORY]: {
            SUCCESS: (data, { payload }) => payload
          }
        },
        []
      )
    },
    {}
  ),
  scopedHistory: combineReducers(
    {
      loadingState: loadingStateReducer(LOAD_SCOPED_SENSOR_HISTORY),
      data: typeToReducer(
        {
          [LOAD_SENSOR_HISTORY]: {
            SUCCESS: (data, { payload }) => payload
          },
          [LOAD_SCOPED_SENSOR_HISTORY]: {
            SUCCESS: (data, { payload }) => payload
          }
        },
        []
      )
    },
    {}
  ),
  lastMetric: combineReducers({
    loadingState: loadingStateReducer(LOAD_LAST_SENSOR_METRIC),
    data: typeToReducer(
      {
        [LOAD_LAST_SENSOR_METRIC]: {
          SUCCESS: (data, { payload = [] }) => lastSensorValueOrDefault(payload[0])
        },
        [UPDATE_MACHINE_SENSOR_STATUS]: (data, { sensor, selectedSensorType }) => {
          if (sensor.type === selectedSensorType) {
            return lastSensorValueOrDefault(sensor);
          }
          return data;
        }
      },
      {}
    )
  })
});

const selectedReducer = combineReducers({
  loadingState: enhanceWithResetActions([UPDATE_MACHINE])(loadingStateReducer([LOAD_MACHINE, UPDATE_MACHINE])),
  updateLoadingState: enhanceWithResetActions([LOAD_MACHINE])(loadingStateReducer([UPDATE_MACHINE])),
  data: typeToReducer(
    {
      [LOAD_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_MACHINE_STATUS]: (machine, { payload }) => {
        if (machine.id === payload.machine_id) {
          return {
            ...machine,
            status: {
              value: payload.status,
              updated_at: payload.updated_at,
              warning: payload.warning,
              error: payload.error
            }
          };
        }

        return machine;
      }
    },
    {}
  ),
  error: enhanceWithResetActions([RESET_MACHINE])(selectedErrorReducer),
  unresolevdError: enhanceWithResetActions([RESET_MACHINE])(selectedUnresolvedErrorReducer),
  metrics: enhanceWithResetActions([RESET_MACHINE])(metricsReducer),
  sensors: enhanceWithResetActions([RESET_MACHINE])(selectedMachineSensorReducer),
  sensor: enhanceWithResetActions([RESET_MACHINE])(selectedSensorReducer),
  notificationRules: enhanceWithResetActions([RESET_MACHINE])(notificationRulesReducer),
  machineEditModal: createReducer(
    // Show/hide the batch note modal
    {
      [MACHINE_EDIT_SHOW_MODAL]: (state, { value }) => value
    },
    false
  )
});

export default combineReducers({
  loadingState: enhanceWithResetActions([RESET_MACHINES])(loadingStateReducer([LOAD_MACHINES, LOAD_MACHINES_RESUMED])),
  updateLoadingState: enhanceWithResetActions([LOAD_MACHINES, LOAD_MACHINES_RESUMED])(
    loadingStateReducer([UPDATE_MACHINE])
  ),
  data: enhanceWithResetActions([RESET_MACHINES])(
    typeToReducer(
      {
        [LOAD_MACHINES]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_MACHINES_RESUMED]: {
          SUCCESS: (state, { payload }) => payload
        },
        [UPDATE_MACHINE]: {
          SUCCESS: (data, { payload }) => data.map(machine => (machine.id === payload.id ? { ...payload } : machine))
        },
        [UPDATE_MACHINE_STATUS]: (state, { payload }) =>
          state.map(machine => {
            if (machine.id === payload.machine_id) {
              return {
                ...machine,
                status: {
                  value: payload.status,
                  updated_at: payload.updated_at,
                  warning: payload.warning,
                  error: payload.error
                }
              };
            }

            return machine;
          })
      },
      []
    )
  ),
  selected: enhanceWithResetActions([RESET_MACHINE])(selectedReducer)
});

import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import compose from 'lodash/fp/compose';
import { push } from 'connected-react-router';

import * as features from '../../helpers/features';
import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import {
  generateId,
  getChartDataNullNormalize,
  getMachineErrorTimeFrame,
  getMetricsTimeFrame,
  getSensorPropsFromMetric,
  getSensorPropsFromType
} from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { WS_API_BASE_URL, WS_API_BASE_URL_MOCK } from '../../attrs/paths';
import { createSocketChannel } from '../stream/websocket';
import { getSelectedMachine, getSelectedMachineSensorComparisonSensorTypes, getSelectedSensor } from './selectors';
import { getActiveOrganization, getUserInfo } from '../user/selectors';
import { getData, getUser } from '../rootSelectors';
import {
  loadMachine,
  loadMachineErrors,
  loadMachines,
  loadMachinesResumed,
  loadUnresolvedMachineErrors,
  updateMachine
} from '../../api/machines';
import { loadLastSensorMetrics, loadMachineStatusHistory } from '../../api/metrics';
import {
  addSensorsToMachine,
  createNotificationRule,
  loadLastSensorsMetrics,
  loadMachineNotificationRules,
  loadMachineStatusMetrics,
  updateMachineSensorStatus,
  updateMachineStatus,
  updateNotificationRule
} from './actions';
import * as api from '../../api/notification';
import { setTimeWindow, setTimeWindowRange } from '../ui/settings/actions';
import { getTimeWindow, getTimeWindowRange } from '../ui/settings/selectors';
import { getSettings } from '../ui/selectors';
import { loadBatchRecordSensorHistory } from '../../api/batch';
import { loadMetricHistoryChartData, loadMetricsHistoryChartData } from '../../api/metrics_history';

function consoleLog(...args) {
  if (features.get('is_debug')) {
    // eslint-disable-next-line no-console
    console.groupCollapsed('WebSocket: %o', new Date().toISOString());
    // eslint-disable-next-line no-console
    console.log(...args);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}

function* handleUpdateMachine({ id, values }) {
  yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.START));

  try {
    const { data } = yield updateMachine(id, values);
    yield put(statusAction(constants.UPDATE_MACHINE, actionStatus.SUCCESS, { payload: data }));

    let message = 'form.success';

    // If machine is currently untracked and name + production line was submitted,
    // show specific success message
    if (!values.isTracked && values.production_line && values.name) {
      message = 'form.machines.success.is_tracked_now';
    }

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

/**
 * Handle load A LOT of machines
 */
function* handleLoadMachines() {
  yield put(statusAction(constants.LOAD_MACHINES, actionStatus.START));
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));
  try {
    const { data } = yield loadMachines(organization.id);
    yield put(statusAction(constants.LOAD_MACHINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINES, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

/**
 * Load machines with resumed data for list
 * @returns none
 */
function* handleLoadMachinesResumed() {
  yield put(statusAction(constants.LOAD_MACHINES_RESUMED, actionStatus.START));
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));
  try {
    const { data } = yield loadMachinesResumed(organization.id);
    yield put(statusAction(constants.LOAD_MACHINES_RESUMED, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINES_RESUMED, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* createSensorList(id, data) {
  const { metrics } = (data || {}).configuration || {};

  if (metrics && metrics.length > 0) {
    const sensors = metrics.map(metric => ({
      ...metric,
      is_custom: metric.is_custom,
      is_static: metric.is_static,
      type: metric.type,
      ...getSensorPropsFromMetric(metric)
    }));

    yield put(addSensorsToMachine(sensors));
    yield put(loadLastSensorsMetrics(id, sensors));
  }
}

/**
 * Handle load ONE machine
 * @param id
 */
function* handleLoadMachine({ id }) {
  yield put(statusAction(constants.LOAD_MACHINE, actionStatus.START));
  try {
    const { data } = yield loadMachine(id);
    yield createSensorList(id, data);
    yield put(statusAction(constants.LOAD_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

// Get the last machine error, not matter whether resolved or unresolved
// Currently it is not being used
function* handleLoadMachineError({ id, updatedAt }) {
  yield put(statusAction(constants.LOAD_MACHINE_ERROR, actionStatus.START));
  try {
    const { from, to } = getMachineErrorTimeFrame(updatedAt);
    const { data } = yield loadMachineErrors(id, from, to);
    const payload = data.length > 0 ? data[data.length - 1] : {};

    yield put(statusAction(constants.LOAD_MACHINE_ERROR, actionStatus.SUCCESS, { payload }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_ERROR, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadUnresolvedMachineError({ id }) {
  yield put(statusAction(constants.LOAD_UNRESOLVED_MACHINE_ERROR, actionStatus.START));
  try {
    const { data } = yield loadUnresolvedMachineErrors(id);

    // Get the first unresolved
    const payload = data.length > 0 ? data[0] : {};

    yield put(statusAction(constants.LOAD_UNRESOLVED_MACHINE_ERROR, actionStatus.SUCCESS, { payload }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_UNRESOLVED_MACHINE_ERROR, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadMachineHistory({ id, timeWindow, timeRange }) {
  yield put(statusAction(constants.LOAD_MACHINE_HISTORY, actionStatus.START));
  const storeTimeWindow = yield select(getTimeWindow);
  const storeTimeRange = yield select(getTimeWindowRange);
  const selectedTimeWindow = timeWindow || (storeTimeRange && getMetricsTimeFrame(storeTimeRange)) || storeTimeWindow;

  try {
    const { from, to } = selectedTimeWindow;
    const { data } = yield loadMachineStatusHistory(id, from, to);
    const payload = data.length > 0 ? data[0].history : [];

    if (timeRange === false || timeRange) {
      yield put(setTimeWindowRange(timeRange));
    }
    yield put(setTimeWindow(from, to));
    yield put(statusAction(constants.LOAD_MACHINE_HISTORY, actionStatus.SUCCESS, { payload }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_HISTORY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadScopedMachineHistory({ id, timeWindow }) {
  yield put(statusAction(constants.LOAD_SCOPED_MACHINE_HISTORY, actionStatus.START));
  try {
    const { from, to } = timeWindow;
    const { data } = yield loadMachineStatusHistory(id, from, to);
    const payload = data.length > 0 ? data[0].history : [];

    yield put(statusAction(constants.LOAD_SCOPED_MACHINE_HISTORY, actionStatus.SUCCESS, { payload }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SCOPED_MACHINE_HISTORY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadLastSensorMetric({ id, sensorType }) {
  yield put(statusAction(constants.LOAD_LAST_SENSOR_METRIC, actionStatus.START));
  try {
    const sensorProps = getSensorPropsFromType(sensorType);
    const { data } = yield loadLastSensorMetrics(id, `${sensorType},${sensorProps.unit}`);
    yield put(statusAction(constants.LOAD_LAST_SENSOR_METRIC, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_LAST_SENSOR_METRIC, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadSensorLastMetrics({ id, sensors }) {
  yield put(statusAction(constants.LOAD_LAST_SENSOR_METRICS, actionStatus.START));
  try {
    const metrics = sensors
      .filter(sensor => sensor.mapValue !== 'SENSOR_UNKNOWN' || sensor.is_custom || sensor.is_static)
      .map(sensor => [sensor.type, sensor.unit])
      .flat()
      .join(',');

    const { data } = yield loadLastSensorMetrics(id, metrics);
    yield put(statusAction(constants.LOAD_LAST_SENSOR_METRICS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_LAST_SENSOR_METRICS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadSensorHistory({ id, sensorType, timeWindow, timeRange }) {
  yield put(statusAction(constants.LOAD_SENSOR_HISTORY, actionStatus.START));
  const storeTimeWindow = yield select(getTimeWindow);
  const storeTimeRange = yield select(getTimeWindowRange);
  const selectedTimeWindow = timeWindow || (storeTimeRange && getMetricsTimeFrame(storeTimeRange)) || storeTimeWindow;

  try {
    const { from, to } = selectedTimeWindow;
    const sensorProps = getSensorPropsFromType(sensorType);
    // const { data } = yield loadSensorHistory(id, from, to, `${sensorType},${sensorProps.unit}`);
    const { data } = yield loadMetricHistoryChartData(id, from, to, `${sensorType},${sensorProps.unit}`);

    // const payload = {
    //   ...((data || []).length > 0 ? { ...data[0], custom_name: data[0].name } : []),
    //   ...sensorProps,
    //   values: data.length > 0 ? data[0].values : []
    // };

    if (timeRange) {
      yield put(setTimeWindowRange(timeRange));
    }
    yield put(setTimeWindow(from, to));
    yield put(statusAction(constants.LOAD_SENSOR_HISTORY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SENSOR_HISTORY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadSensor({ id, sensorType, timeWindow, timeRange }) {
  yield put(statusAction(constants.LOAD_SENSOR, actionStatus.START));
  const storeTimeWindow = yield select(getTimeWindow);
  const storeTimeRange = yield select(getTimeWindowRange);
  const selectedTimeWindow = timeWindow || (storeTimeRange && getMetricsTimeFrame(storeTimeRange)) || storeTimeWindow;

  try {
    const { from, to } = selectedTimeWindow;
    const sensorProps = getSensorPropsFromType(sensorType);
    // const { data } = yield loadSensorHistory(id, from, to, `${sensorType},${sensorProps.unit}`);
    const { data } = yield loadMetricHistoryChartData(id, from, to, `${sensorType},${sensorProps.unit}`);

    // const payload = {
    //   ...((data || []).length > 0 ? { ...data[0], custom_name: data[0].name } : []),
    //   ...sensorProps,
    //   values: data.length > 0 ? data[0].values : []
    // };

    if (timeRange) {
      yield put(setTimeWindowRange(timeRange));
    }
    yield put(setTimeWindow(from, to));
    yield put(statusAction(constants.LOAD_SENSOR, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SENSOR, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadScopedSensorHistory({ id, sensorType, timeFrame }) {
  yield put(statusAction(constants.LOAD_SCOPED_SENSOR_HISTORY, actionStatus.START));
  try {
    const { from, to } = timeFrame;
    const sensorProps = getSensorPropsFromType(sensorType);
    // const { data } = yield loadSensorHistory(id, from, to, `${sensorType},${sensorProps.unit}`);
    const { data } = yield loadMetricHistoryChartData(id, from, to, `${sensorType},${sensorProps.unit}`);

    // const payload = {
    //   ...((data || []).length > 0 ? { ...data[0], custom_name: data[0].name } : []),
    //   ...sensorProps,
    //   values: data.length > 0 ? data[0].values : []
    // };

    // const payload = {
    //   ...((data || []).length > 0 ? { ...data[0], custom_name: data[0].name } : []),
    //   ...sensorProps,
    //   values: data.length > 0 ? data[0].values : []
    // };

    yield put(statusAction(constants.LOAD_SCOPED_SENSOR_HISTORY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SCOPED_SENSOR_HISTORY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadMachineNotificationRules({ id, sensorType }) {
  yield put(statusAction(constants.LOAD_MACHINE_NOTIFICATION_RULES, actionStatus.START));
  try {
    const { data } = yield api.getNotificationRules({ machine_id: id, metric_type: sensorType });
    let dataToSave = data;

    // Only for dev purpose
    if (features.get('is_mocked_data')) {
      const values = ['min', 'max'];

      dataToSave = data.map(rule => ({
        ...rule,
        warning: {
          [values[Math.floor(Math.random() * Math.floor(2))]]: rule.warning.min || rule.warning.max
        }
      }));
    }

    yield put(statusAction(constants.LOAD_MACHINE_NOTIFICATION_RULES, actionStatus.SUCCESS, { payload: dataToSave }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_NOTIFICATION_RULES, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

const convertMachineRuleToBackendObject = rule => ({
  'e-mail_user_ids': rule.email.map(email => email.value),
  machine_id: rule.machine_id,
  sms_user_ids: rule.sms.map(sms => sms.value)
});

const convertSensorRuleToBackendObject = (rule, key) => ({
  'e-mail_user_ids': rule.email.map(email => email.value),
  machine_id: rule.machine_id,
  metric_type: rule.metric_type,
  sms_user_ids: rule.sms.map(sms => sms.value),
  units: rule.units,
  warning: {
    [key]: parseFloat(rule.value)
  }
});

function* handleSensorNotificationRuleSubmit(values) {
  yield* Object.keys(values).map(function* progress(rule) {
    const convertedObject = convertSensorRuleToBackendObject(values[rule], rule);

    if (values[rule].id) {
      yield put(updateNotificationRule(values[rule].id, convertedObject));
    } else {
      yield put(createNotificationRule(convertedObject));
    }
  });
}

function* handleMachineNotificationRuleSubmit(values) {
  if (values.id) {
    yield put(updateNotificationRule(values.id, convertMachineRuleToBackendObject(values)));
  } else {
    yield put(createNotificationRule(convertMachineRuleToBackendObject(values)));
  }
}

function* handleNotificationSubmit({ values }) {
  if (values.max || values.min) {
    yield handleSensorNotificationRuleSubmit(values);
  } else {
    yield handleMachineNotificationRuleSubmit(values);
  }
}

function* handleUpdateNotificationRule({ id, values }) {
  yield put(statusAction(constants.UPDATE_NOTIFICATION_RULE, actionStatus.START));

  try {
    const { data } = yield api.updateNotificationRule(id, values);

    yield put(statusAction(constants.UPDATE_NOTIFICATION_RULE, actionStatus.SUCCESS, { payload: data }));

    const sensorType = (values || {}).metric_type || null;
    const machineId = (values || {}).machine_id || null;

    yield put(loadMachineNotificationRules(machineId, sensorType));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.notifications.success.update',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_NOTIFICATION_RULE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.notification.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleDeleteNotificationRule({ id }) {
  yield put(statusAction(constants.DELETE_NOTIFICATION_RULE, actionStatus.START));

  try {
    yield api.deleteNotificationRule(id);

    yield put(statusAction(constants.DELETE_NOTIFICATION_RULE, actionStatus.SUCCESS, { id }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.notifications.success.delete',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.DELETE_NOTIFICATION_RULE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.notification.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleCreateNotificationRule({ values }) {
  yield put(statusAction(constants.CREATE_NOTIFICATION_RULE, actionStatus.START));

  try {
    const { data } = yield api.createNotificationRule(values);

    yield put(statusAction(constants.CREATE_NOTIFICATION_RULE, actionStatus.SUCCESS, { payload: data }));

    const sensorType = (values || {}).metric_type || null;
    const machineId = (values || {}).machine_id || null;

    yield put(loadMachineNotificationRules(machineId, sensorType));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.notifications.success.create',
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_NOTIFICATION_RULE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.notification.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* getMachinesStateUpdateStreamEndpoint() {
  const organization = yield select(compose(getActiveOrganization, getData, getUserInfo, getUser));
  const baseUrl = features.get('is_ws_mocked_server') ? WS_API_BASE_URL_MOCK : WS_API_BASE_URL;
  return `${baseUrl}/v1/ws/machines?organization_id=${organization.id}`;
}

function* subscribeToMachineStatusUpdateStream() {
  try {
    const endpoint = yield call(getMachinesStateUpdateStreamEndpoint);
    const socketChannel = yield call(createSocketChannel, endpoint);

    while (true) {
      const payload = yield take(socketChannel);
      const machineStatus = JSON.parse(payload);
      // get current selected machine
      const selectedMachine = yield select(getSelectedMachine);
      const selectedMachineId = selectedMachine?.data?.id || null;

      yield put(updateMachineStatus(machineStatus, selectedMachineId));
    }
  } catch (err) {
    // Error while connecting to the WebSocket or while loading machine status updates
    consoleLog(`Error loading machine status: ${err.message}`);
  }
}

function getSensorsUpdateStreamEndpoint(machineId) {
  const baseUrl = features.get('is_ws_mocked_server') ? WS_API_BASE_URL_MOCK : WS_API_BASE_URL;
  return `${baseUrl}/v1/ws/metrics?machine_id=${machineId}`;
}

function* subscribeToSensorsUpdateStream({ id }) {
  try {
    const endpoint = getSensorsUpdateStreamEndpoint(id);
    const socketChannel = yield call(createSocketChannel, endpoint);

    while (true) {
      const payload = yield take(socketChannel);
      const sensor = JSON.parse(payload);

      if (sensor?.machine_id === id) {
        const selectedSensor = yield select(compose(getData, getSelectedSensor));
        yield put(updateMachineSensorStatus(sensor, selectedSensor?.type));
      }
    }
  } catch (err) {
    // Error while connecting to the WebSocket or while loading machine sensors
    consoleLog(`Error loading machine sensors data: ${err.message}`);
  }
}

function* handleLoadSensorComparisonData({ id, sensorTypes, timeWindow, timeRange, isCompare }) {
  yield put(statusAction(constants.LOAD_SENSOR_COMPARISON_DATA, actionStatus.START));

  try {
    const selectedMachine = yield select(getSelectedMachine);
    const { batchSettings } = yield select(getSettings);
    const sensors = sensorTypes || (yield select(getSelectedMachineSensorComparisonSensorTypes));

    const storeTimeWindow = yield select(getTimeWindow);
    const storeTimeRange = yield select(getTimeWindowRange);
    const selectedTimeWindow = timeWindow || (storeTimeRange && getMetricsTimeFrame(storeTimeRange)) || storeTimeWindow;

    const { from, to } = selectedTimeWindow;
    const sensorsProps = sensors.map(type => getSensorPropsFromType(type));
    const sensorUnitString = sensorsProps.map(sensor => `${sensor.type},${sensor.unit}`).join();

    let metricsHistory;
    if (batchSettings.isBatchRecord.isBatchRecord) {
      const { timeFrom, timeTo } = batchSettings.isBatchRecord;
      const { data } = yield call(
        loadBatchRecordSensorHistory,
        batchSettings.isBatchRecord.batchId,
        sensorUnitString,
        timeFrom,
        timeTo
      );
      metricsHistory = data;
    } else {
      let payload;
      if (isCompare) {
        const { data } = yield call(
          loadMetricsHistoryChartData,
          id || selectedMachine.data.id,
          from,
          to,
          sensorUnitString
        );
        payload = data;
      } else {
        const { data } = yield call(
          loadMetricHistoryChartData,
          id || selectedMachine.data.id,
          from,
          to,
          sensorUnitString
        );
        payload = data;
      }
      metricsHistory = payload;
    }

    metricsHistory = getChartDataNullNormalize(metricsHistory);

    if (timeRange) {
      yield put(setTimeWindowRange(timeRange));
    }
    yield put(setTimeWindow(from, to));
    yield put(statusAction(constants.LOAD_SENSOR_COMPARISON_DATA, actionStatus.SUCCESS, { payload: metricsHistory }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SENSOR_COMPARISON_DATA, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleUpdateLogic(sensorTypes) {
  const selectedMachine = yield select(getSelectedMachine);
  const selectedMachineId = selectedMachine.data.id;

  // We want to adapt the url when updating selected sensors
  const url = `/dashboard/${selectedMachineId}/sensor/compare?types=${sensorTypes.join(',')}`;
  yield put(push(url));

  // Since we are changing the location with push, we do not need to load sensor data manually,
  // but machine data
  yield put(loadMachineStatusMetrics(selectedMachineId));
}

function* handleAddSensorComparisonData({ sensorType }) {
  const currentSensors = yield select(getSelectedMachineSensorComparisonSensorTypes);
  const uniqueSensorList = [...currentSensors, sensorType].filter((v, i, a) => a.indexOf(v) === i);

  yield handleUpdateLogic(uniqueSensorList);
}

function* handleReplaceSensorComparison({ oldSensorType, newSensorType }) {
  const currentSensors = yield select(getSelectedMachineSensorComparisonSensorTypes);
  const newSensorList = currentSensors.map(item => (item === oldSensorType ? newSensorType : item));

  yield handleUpdateLogic(newSensorList);
}

function* handleRemoveSensorFromComparison({ sensorType }) {
  const currentSensors = yield select(getSelectedMachineSensorComparisonSensorTypes);
  const newSensorList = currentSensors.filter(sensor => sensor !== sensorType);

  yield handleUpdateLogic(newSensorList);
}

function* handleLoadScopedSensorComparisonData({ id, sensorTypes, timeFrame, isCompare }) {
  yield put(statusAction(constants.LOAD_SCOPED_SENSOR_COMPARISON_DATA, actionStatus.START));
  let sensors = sensorTypes;
  const { batchSettings } = yield select(getSettings);

  if (!sensorTypes) {
    sensors = yield select(getSelectedMachineSensorComparisonSensorTypes);
  }

  try {
    const { from, to } = timeFrame;
    const sensorsProps = sensors.map(type => getSensorPropsFromType(type));
    const sensorUnitString = sensorsProps
      .map(sensor => `${sensor.type},${sensor.unit}`)
      .flat()
      .join();

    let metricsHistory;
    if (batchSettings.isBatchRecord.isBatchRecord) {
      const { timeFrom, timeTo } = {
        timeFrom: batchSettings.isBatchRecord.timeFrom,
        timeTo: batchSettings.isBatchRecord.timeTo
      };
      const { data } = yield loadBatchRecordSensorHistory(
        batchSettings.isBatchRecord.batchId,
        sensorUnitString,
        timeFrom,
        timeTo
      );
      metricsHistory = data;
    } else {
      // const { data } = yield loadSensorHistory(id, from, to, sensorUnitString);
      // const { data } = yield loadSensorHistory(selectedMachine.data.id, from, to, sensorUnitString);
      let payload;
      if (isCompare) {
        const { data } = yield loadMetricsHistoryChartData(id, from, to, sensorUnitString);
        payload = data;
      } else {
        const { data } = yield loadMetricHistoryChartData(id, from, to, sensorUnitString);
        payload = data;
      }
      metricsHistory = payload;
    }

    metricsHistory = getChartDataNullNormalize(metricsHistory);

    // const enrichedData = metricsHistory.map((historical) => ({
    //   ...(historical || null ? { ...historical, custom_name: historical.name } : []),
    //   ...sensorsProps.find((sensor) => sensor.type === historical.type),
    //   values: historical.values.length > 0 ? historical.values : []
    // }));

    // Ensure order
    // const originallyOrdered = sensors
    //   .map((sensorType) => enrichedData.find((sensor) => sensor.type === sensorType))
    //   .filter((x) => x);

    yield put(
      statusAction(constants.LOAD_SCOPED_SENSOR_COMPARISON_DATA, actionStatus.SUCCESS, {
        payload: metricsHistory
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SCOPED_SENSOR_COMPARISON_DATA, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machine.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_MACHINES, handleLoadMachines);
  yield takeLatest(constants.LOAD_MACHINES_RESUMED, handleLoadMachinesResumed);
  yield takeLatest(constants.UPDATE_MACHINE, handleUpdateMachine);
  yield takeLatest(constants.LOAD_MACHINE, handleLoadMachine);
  yield takeLatest(constants.LOAD_MACHINE_HISTORY, handleLoadMachineHistory);
  yield takeLatest(constants.LOAD_SCOPED_MACHINE_HISTORY, handleLoadScopedMachineHistory);
  yield takeLatest(constants.LOAD_MACHINE_ERROR, handleLoadMachineError);
  yield takeLatest(constants.LOAD_UNRESOLVED_MACHINE_ERROR, handleLoadUnresolvedMachineError);
  yield takeLatest(constants.LOAD_LAST_SENSOR_METRICS, handleLoadSensorLastMetrics);
  yield takeLatest(constants.LOAD_LAST_SENSOR_METRIC, handleLoadLastSensorMetric);
  yield takeLatest(constants.LOAD_SENSOR_HISTORY, handleLoadSensorHistory);
  yield takeLatest(constants.LOAD_SENSOR, handleLoadSensor);
  yield takeLatest(constants.LOAD_SCOPED_SENSOR_HISTORY, handleLoadScopedSensorHistory);
  yield takeLatest(constants.LOAD_MACHINE_NOTIFICATION_RULES, handleLoadMachineNotificationRules);
  yield takeLatest(constants.HANDLE_NOTIFICATION_SUBMIT, handleNotificationSubmit);
  yield takeLatest(constants.SUBSCRIBE_TO_MACHINES_UPDATE_STREAM, subscribeToMachineStatusUpdateStream);
  yield takeLatest(constants.SUBSCRIBE_TO_SENSORS_UPDATE_STREAM, subscribeToSensorsUpdateStream);
  yield takeEvery(constants.UPDATE_NOTIFICATION_RULE, handleUpdateNotificationRule);
  yield takeEvery(constants.CREATE_NOTIFICATION_RULE, handleCreateNotificationRule);
  yield takeEvery(constants.DELETE_NOTIFICATION_RULE, handleDeleteNotificationRule);
  yield takeLatest(constants.ADD_SENSORS_COMPARISON, handleLoadSensorComparisonData);
  yield takeLatest(constants.ADD_SENSOR_COMPARISON, handleAddSensorComparisonData);
  yield takeLatest(constants.REPLACE_SENSOR_COMPARISON, handleReplaceSensorComparison);
  yield takeLatest(constants.LOAD_SENSOR_COMPARISON_DATA, handleLoadSensorComparisonData);
  yield takeLatest(constants.REMOVE_SENSOR_COMPARISON, handleRemoveSensorFromComparison);
  yield takeLatest(constants.LOAD_SCOPED_SENSOR_COMPARISON_DATA, handleLoadScopedSensorComparisonData);
}

import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveIconButton, NotifyTypo, NotifyIcon } from 'web-components';
import { Button, Divider, Hidden, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { getOnlyNotifyRole } from 'helpers/role';
import Breadcrumbs from '../../../../components/elements/Breadcrumbs';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getUserRole } from '../../../../redux/user/selectors';
import PERMISSIONS from '../../../../attrs/permissions';
import SensorNotificationSettings from '../../../../components/forms/Machines/SensorNotificationSettings';

import CurrentValue from './CurrentValue';
import {
  getSelectedSensor,
  getSelectedSensorLastMetric,
  getSelectedMachine
} from '../../../../redux/machines/selectors';
import { getData } from '../../../../redux/rootSelectors';
import ConnectedSensorHistoricalGraph from './ConnectedSensorHistoricalGraph';
import { loadMachine, loadSelectedSensorHistory } from '../../../../redux/machines/actions';
import TimeSelection from '../../../../components/Graphs/TimeSelection';
import MachineStateSelection from './MachineStateSelection';
import MachineLegendTooltip from '../MachineLegendTooltip';
import {
  SensorContentWrapper,
  GraphWrapper,
  SettingsWrapper,
  StyledCard,
  StyledCardContent,
  PageHeader,
  PageTitle,
  PageHeaderTitle,
  TimeSelectionWrapper,
  TitleSettingsWrapper
} from './elements';
import { getCustomSensorId, getSensorName, getSensorPropsFromType, isCustomSensor } from '../../../../helpers/utils';
import { getCustomSensorSelector } from '../../../../redux/custom_sensor/selectors';
import { GetCustomSensorById } from '../../../../redux/custom_sensor/actions';
import MobileSensorPortrait from '../../../../components/elements/MobileSensorPortrait';

const SensorHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);
  const [permissions, setPermissions] = useState();
  const isMobile = useMediaQuery('(max-width:425px) and (orientation:portrait)');
  const isDesktop = useMediaQuery('(min-width:1440px)');

  const { id: machineId } = useParams();
  const metricHistory = useSelector(compose(getData, getSelectedSensor));

  const { customSensor } = useSelector(getCustomSensorSelector);
  const lastMetric = useSelector(compose(getData, getSelectedSensorLastMetric));
  const dateToBeShown = moment((lastMetric || {}).time || null);
  const machine = useSelector(compose(getData, getSelectedMachine));

  const { loadingState: wsLoadingState } = useSelector(state => state.stream);

  useEffect(() => {
    if (isCustomSensor(metricHistory.sensor.type)) {
      metricHistory.name = '';
      dispatch(GetCustomSensorById(getCustomSensorId(metricHistory.sensor.type)));
    }
    dispatch(loadMachine(machineId));
  }, [dispatch, metricHistory, wsLoadingState, machineId]);

  useEffect(() => {
    if (role) {
      const oneRole = getOnlyNotifyRole(role);
      setPermissions(PERMISSIONS.dashboard[oneRole]);
    }
  }, [role]);

  if (isCustomSensor(metricHistory.sensor.type)) {
    metricHistory.name = customSensor.name;
    metricHistory.unit = customSensor.unit;
    metricHistory.period = `${customSensor.period_sec}s`;
    metricHistory.mapValue = customSensor.custom_icon_type;
  }

  const reloadSensorHistory = () => {
    // dispatch(loadMachineStatusMetrics(machineId));
    dispatch(loadSelectedSensorHistory(machineId, metricHistory.sensor.type));
  };

  const sensorDetails = getSensorPropsFromType(metricHistory.sensor.type);
  const breadcrumbObj = [
    { route: '/dashboard', name: `${t('dashboard.title')}` },
    { route: `/dashboard/${machineId}`, name: `${machine.name}` },
    { route: null, name: `${getSensorName(metricHistory.sensor, t(sensorDetails.name))}` }
  ];

  return (
    <>
      <PageHeader alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <PageTitle>
          <PageHeaderTitle>
            {`${t('dashboard.sensors.sensor_overview')} - ${getSensorName(
              metricHistory.sensor,
              t(sensorDetails.name)
            )}`}
          </PageHeaderTitle>
        </PageTitle>
      </PageHeader>

      <SensorContentWrapper maxWidth={false}>
        <StyledCard>
          <StyledCardContent>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <NotifyTypo.Heading2 style={{ fontSize: 14 }}>
                  {t('dashboard.sensors.current_status')}
                </NotifyTypo.Heading2>
              </div>
              {isMobile ? (
                <div style={{ display: 'flex' }}>
                  <ResponsiveIconButton
                    buttonText={t('dashboard.sensors.refresh')}
                    color="primary"
                    iconName="reload"
                    variant="text"
                    onClick={reloadSensorHistory}
                  />
                  <Divider orientation="vertical" flexItem />
                  {metricHistory.sensor.trigger && permissions && permissions.canEditSensorNotifications && (
                    <SensorNotificationSettings
                      sensor={metricHistory.sensor}
                      machineId={machineId}
                      isMobile={isMobile}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {metricHistory.sensor.trigger && permissions && permissions.canEditSensorNotifications && (
                    <SensorNotificationSettings
                      sensor={metricHistory.sensor}
                      machineId={machineId}
                      isMobile={isMobile}
                    />
                  )}
                </div>
              )}
            </div>
            <CurrentValue />
          </StyledCardContent>
        </StyledCard>

        <GraphWrapper>
          <Hidden smDown>
            <SettingsWrapper>
              <TitleSettingsWrapper>
                <NotifyTypo.Heading2 style={{ marginRight: '0.75rem', fontSize: 14 }}>
                  {t('dashboard.sensors.title_graph')}
                </NotifyTypo.Heading2>
                <NotifyTypo.InfoText>
                  {t('dashboard.sensors.last_updated')} {dateToBeShown.isValid() ? dateToBeShown.format('LT') : '---'}
                </NotifyTypo.InfoText>
              </TitleSettingsWrapper>
              <div style={{ display: 'flex' }}>
                {isDesktop && <MachineStateSelection />}
                <ResponsiveIconButton
                  buttonText={t('dashboard.sensors.refresh')}
                  color="primary"
                  iconName="reload"
                  variant="text"
                  onClick={reloadSensorHistory}
                  style={{ marginLeft: '2rem', marginRight: '1rem' }}
                />
                <Divider orientation="vertical" flexItem />
                <Button
                  data-selector="compare-button"
                  onClick={() =>
                    dispatch(push(`/dashboard/${machineId}/sensor/compare?types=${metricHistory.sensor.type}`))
                  }
                  variant="text"
                  color="primary"
                  startIcon={<NotifyIcon iconName="compare" />}
                  style={{ marginLeft: '1rem' }}
                >
                  {t('dashboard.sensors.compare_action')}
                </Button>
              </div>
            </SettingsWrapper>
          </Hidden>
          <Hidden mdUp>
            <SettingsWrapper mobile>
              <TitleSettingsWrapper>
                <NotifyTypo.Heading2 style={{ marginRight: '0.75rem' }}>
                  {t('dashboard.sensors.title_graph')}
                </NotifyTypo.Heading2>
                <NotifyTypo.InfoText>
                  {t('dashboard.sensors.last_updated')} {dateToBeShown.isValid() ? dateToBeShown.format('LT') : '---'}
                </NotifyTypo.InfoText>
              </TitleSettingsWrapper>
              {isMobile && <MachineLegendTooltip />}
              {!isMobile && (
                <div style={{ display: 'flex' }}>
                  <ResponsiveIconButton
                    buttonText={t('dashboard.sensors.refresh')}
                    color="primary"
                    iconName="reload"
                    variant="text"
                    onClick={reloadSensorHistory}
                    style={{ marginLeft: '2rem', marginRight: '1rem' }}
                  />
                  <Divider orientation="vertical" flexItem />
                  <ResponsiveIconButton
                    buttonText={t('dashboard.sensors.compare_action')}
                    color="primary"
                    iconName="compare"
                    data-selector="compare-button"
                    onClick={() =>
                      dispatch(push(`/dashboard/${machineId}/sensor/compare?types=${metricHistory.sensor.type}`))
                    }
                    variant="text"
                    style={{ marginLeft: '1rem' }}
                  />
                </div>
              )}
            </SettingsWrapper>
          </Hidden>
          <TimeSelectionWrapper>
            <TimeSelection machineId={machineId} sensorType={metricHistory.sensor.type} />
            {!isDesktop && <MachineStateSelection mobile isMobile={isMobile} />}
          </TimeSelectionWrapper>
          {isMobile ? (
            <MobileSensorPortrait>
              <Button
                color="primary"
                startIcon={<NotifyIcon iconName="compare" />}
                data-selector="compare-button"
                onClick={() =>
                  dispatch(push(`/dashboard/${machineId}/sensor/compare?types=${metricHistory.sensor.type}`))
                }
                variant="outlined"
                size="large"
                fullWidth
                style={{ marginTop: '2rem', padding: '1rem 0' }}
              >
                {t('dashboard.sensors.compare_action')}
              </Button>
            </MobileSensorPortrait>
          ) : (
            <ConnectedSensorHistoricalGraph />
          )}
        </GraphWrapper>
      </SensorContentWrapper>
    </>
  );
};

export default withLoadingState(SensorHistory);

import React, { useEffect } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UserManagementContent from './UserManagementContent';
import { loadUsers } from '../../redux/users/actions';
import { getUsers } from '../../redux/rootSelectors';

const ConnectedUserManagementContent = ({ selectedTab }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getUsers);

  useEffect(() => {
    dispatch(loadUsers());
  }, [dispatch]);

  return (
    <UserManagementContent
      loadingState={loadingState}
      reloadAction={() => dispatch(loadUsers())}
      users={data}
      selectedTab={selectedTab}
    />
  );
};

ConnectedUserManagementContent.propTypes = {
  selectedTab: T.shape({}).isRequired
};

export default ConnectedUserManagementContent;

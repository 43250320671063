import React, { useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyIcon } from 'web-components';
import { CustomCheckbox, getStyles, MenuProps, useStyles } from './styles';

/**
 * @param labelFilter
 * @param optionsObj
 * @param {boolean} isCheckbox Define if the filter is a multiselect or single select
 * @param {boolean} clear Define if select will have a "clear" button
 * @param setClear
 * @param handleFilter
 * @param setInitStates
 * @param {boolean} allOption Add an "All" option into select if isCheckbox equal false
 * @returns {JSX.Element}
 * @constructor
 */
function AlarmFilter({ labelFilter, optionsObj, isCheckbox, clear, setClear, handleFilter, setInitStates, allOption }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState([]);

  const clearFilter = useCallback(() => {
    setSelectedValue([]);
    setInitStates();
    setClear(false);
  }, [setClear, setInitStates, setSelectedValue]);

  useEffect(() => {
    if (clear) {
      clearFilter();
    }
  }, [clear, clearFilter]);

  useEffect(() => {
    handleFilter(selectedValue);
    // eslint-disable-next-line
  }, [selectedValue]);

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleDelete = value => {
    const newState = selectedValue.filter(item => item !== value);
    setSelectedValue(newState);
  };

  const IconFilter = () => <NotifyIcon iconName="filter" fontSize="default" color="primary" />;

  const returnLabel = value => {
    const searchLabel = optionsObj.filter(item => value === item.value);
    return searchLabel[0].label;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="multiple-chip-label">{labelFilter}</InputLabel>
      {isCheckbox ? (
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedValue}
          onChange={handleChange}
          IconComponent={IconFilter}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={returnLabel(value)}
                  clickable
                  deleteIcon={
                    <NotifyIcon fontSize="default" iconName="cancel" onMouseDown={event => event.stopPropagation()} />
                  }
                  className={classes.chip}
                  classes={{ deleteIcon: classes.deleteIcon }}
                  size="small"
                  color="primary"
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {optionsObj.map(item => (
            <MenuItem key={item.value} value={item.value} style={getStyles(item, selectedValue, theme)}>
              <CustomCheckbox checked={selectedValue.indexOf(item.value) > -1} color="primary" />
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          labelId="simple-select"
          id="simple-select"
          value={selectedValue}
          onChange={handleChange}
          IconComponent={IconFilter}
          input={<Input id="select-simple" />}
          MenuProps={MenuProps}
        >
          {allOption ? (
            <MenuItem value="" style={{ backgroundColor: 'transparent' }}>
              {t('general.select.all_periods')}
            </MenuItem>
          ) : (
            ''
          )}
          {optionsObj.map(item => (
            <MenuItem key={item.value} value={item.value} style={getStyles(item, selectedValue, theme)}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}

AlarmFilter.propTypes = {
  optionsObj: T.arrayOf(T.shape([])).isRequired,
  labelFilter: T.string.isRequired,
  isCheckbox: T.bool,
  clear: T.bool,
  setClear: T.func,
  handleFilter: T.func,
  setInitStates: T.func,
  allOption: T.bool
};

AlarmFilter.defaultProps = {
  isCheckbox: true,
  clear: false,
  setClear: () => null,
  handleFilter: () => null,
  setInitStates: () => null,
  allOption: true
};

export default AlarmFilter;

import React, { useMemo, useState } from 'react';
import T from 'prop-types';
import moment from 'moment';

import { TimePicker } from '@mui/x-date-pickers';
import { Container, Divider, MenuItem, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormElements, useResolutionCheck } from 'web-components';
import { Dialog, DateRangeCalendar } from 'web-components-ts';

import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { MENU_ITEM_BORDER } from '../../attrs/colors';
import { loadComparisonData, loadMachineStatusMetrics, loadSelectedSensorHistory } from '../../redux/machines/actions';
import { getMetricsTimeFrame } from '../../helpers/utils';

const currentTimeISO = moment().toISOString();
const TimeSelection = ({ machineId, sensorType, sensorTypes }) => {
  const { t, i18n } = useTranslation();
  const { isDesktop } = useResolutionCheck();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const language = useMemo(() => i18n.language, [i18n.language]);

  const [localTimeWindow, setLocalTimeWindow] = useState({
    startDate: undefined,
    endDate: undefined
  });

  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);

  const getSelectedTimeWindow = () => {
    const startDate = localTimeWindow?.startDate ? moment(localTimeWindow.startDate) : moment(currentTimeISO);
    const showStartTime = startTime ? moment(startTime) : moment(localTimeWindow?.startDate || currentTimeISO);

    const endDate = localTimeWindow?.endDate ? moment(localTimeWindow.endDate) : moment(currentTimeISO);
    const showEndTime = endTime ? moment(endTime) : moment(localTimeWindow?.endDate || currentTimeISO);
    return `${startDate.format('D MMM')}, ${showStartTime.format('HH:mm')} - ${endDate.format(
      'D MMM'
    )}, ${showEndTime.format('HH:mm')}`;
  };

  const handleChangeMode = e => {
    if (e.target.value === 3) {
      setSelected(2);
    } else {
      setSelected(e.target.value);
    }
  };

  const handleLoadTimeFrame = timeFrame => {
    dispatch(loadMachineStatusMetrics(machineId, getMetricsTimeFrame(timeFrame), timeFrame));
    if (sensorType) {
      dispatch(loadSelectedSensorHistory(machineId, sensorType, getMetricsTimeFrame(timeFrame), timeFrame));
    }
    if (sensorTypes) {
      dispatch(loadComparisonData(sensorTypes, getMetricsTimeFrame(timeFrame), timeFrame));
    }
  };

  const TIME_SELECTION_OPTIONS = [
    {
      id: 0,
      label: 'last8hours',
      onClick: () => handleLoadTimeFrame(8)
    },
    {
      id: 1,
      label: 'last24hours',
      onClick: () => handleLoadTimeFrame(24)
    }
  ];

  const handleDateChange = ({ startDate, endDate }) => {
    if (localTimeWindow.startDate !== startDate || localTimeWindow.endDate !== endDate) {
      setLocalTimeWindow(() => ({
        startDate,
        endDate
      }));
    }
  };

  const combineDateAndTime = (dateISO, time) => {
    if (time) {
      const parsedDate = moment(dateISO);
      const combinedDateTime = parsedDate.set({
        hour: time.get('hour'),
        minute: time.get('minute')
      });
      return combinedDateTime.toISOString();
    }
    return dateISO;
  };

  const handleSave = () => {
    const startDate = localTimeWindow?.startDate || currentTimeISO;
    const endDate = localTimeWindow?.endDate || currentTimeISO;
    const dateTimeParam = { from: combineDateAndTime(startDate, startTime), to: combineDateAndTime(endDate, endTime) };
    dispatch(loadMachineStatusMetrics(machineId, dateTimeParam, false));
    if (sensorType) {
      dispatch(loadSelectedSensorHistory(machineId, sensorType, dateTimeParam, false));
    }
    if (sensorTypes) {
      dispatch(loadComparisonData(sensorTypes, dateTimeParam, false));
    }
    setOpenModal(false);
  };

  const handleStartTimeChange = newValue => {
    setStartTime(newValue);
  };

  const handleEndTimeChange = newValue => {
    setEndTime(newValue);
  };

  const content = (
    <DateTimeProvider>
      <Container
        disableGutters
        maxWidth="sm"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <DateRangeCalendar
          returnType="string"
          handleDates={handleDateChange}
          selectedDates={localTimeWindow}
          maxDays={6}
          language={language}
        />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Divider style={{ width: '100%', margin: '1rem 0' }} />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <TimePicker
              id="start-time-picker"
              label={t('dashboard.custom.time_selection.start_time')}
              format="HH:mm"
              value={startTime}
              onChange={handleStartTimeChange}
              slotProps={{
                textField: {
                  variant: 'filled'
                }
              }}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              id="end-time-picker"
              label={t('dashboard.custom.time_selection.end_time')}
              format="HH:mm"
              value={endTime}
              onChange={handleEndTimeChange}
              slotProps={{
                textField: {
                  variant: 'filled'
                }
              }}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </Container>
    </DateTimeProvider>
  );

  const handleClose = () => {
    setOpenModal(false);
  };

  function canSave() {
    // Ensure startTime and endTime are valid, and endTime is greater than startTime
    const isValidTimeRange = !!startTime && !!endTime && new Date(endTime) > new Date(startTime);

    // Return true if localTimeWindow?.startDate exists or if the time range is valid
    return !!localTimeWindow?.startDate || isValidTimeRange;
  }

  return (
    <>
      <FormElements.Select
        value={selected}
        native={false}
        handleChange={handleChangeMode}
        label={t(`dashboard.custom.time_selection.time_period`)}
        name="time-selection"
        id="time-selection"
        size="small"
        MenuProps={{
          elevation: 2
        }}
        style={{
          width: 280,
          maxWidth: 280
        }}
      >
        {TIME_SELECTION_OPTIONS.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            selected={item.id === selected}
            onClick={item.onClick}
            style={{ height: 48, border: `1px solid ${MENU_ITEM_BORDER}` }}
          >
            {t(`dashboard.custom.time_selection.${item.label}`)}
          </MenuItem>
        ))}
        <MenuItem
          key={2}
          value={2}
          selected={selected === 2}
          onClick={() => setOpenModal(true)}
          style={{ height: 48, border: `1px solid ${MENU_ITEM_BORDER}` }}
        >
          {selected === 2 && canSave() ? getSelectedTimeWindow() : t('dashboard.custom.time_selection.custom_date')}
        </MenuItem>
      </FormElements.Select>
      <Dialog
        open={openModal}
        canSave={canSave()}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleOk={handleSave}
        title={t('dashboard.custom.time_selection.title')}
        subTitle={t('dashboard.custom.time_selection.subtitle')}
        content={content}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('default_actions.filter')}
        displayActionBar="flex"
        justifyContentActionBar="space-between"
        minWidth={!isDesktop ? '100%' : undefined}
      />
    </>
  );
};

TimeSelection.propTypes = {
  machineId: T.string.isRequired,
  sensorType: T.string,
  sensorTypes: T.arrayOf(T.string)
};

TimeSelection.defaultProps = {
  sensorType: null,
  sensorTypes: null
};

export default TimeSelection;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../../redux/user/actions';
import Account from './Account';

const ConnectedAccount = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(state => state.user.user);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return <Account loadingState={loadingState} reloadAction={() => dispatch(loadUser())} user={data} />;
};

export default ConnectedAccount;

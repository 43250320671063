import React, { useLayoutEffect } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useMediaQuery } from '@mui/material';
import { DialCodes } from 'web-components';
import { resetUserUpdateLoadingState, updateUser } from '../../../redux/user/actions';
import { AccountUpdateSchema } from '../../../attrs/formValidation';
import { getDialCodeFromCountryCode, isLoading } from '../../../helpers/utils';
import AccountFormActionButtons from './AccountFormActionButtons';
import AccountFormFields from './AccountFormFields';

const AccountForm = ({ user }) => {
  const dispatch = useDispatch();
  const { updateLoadingState } = useSelector(state => state.user.user);

  const isSubmitting = isLoading(updateLoadingState.status);

  const isMobile = useMediaQuery('max-width:425px');

  const updateUserInformation = values => {
    dispatch(updateUser(user.id, values));
  };

  // Resets loading/error state
  useLayoutEffect(
    () => () => {
      dispatch(resetUserUpdateLoadingState());
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={{
        ...user,
        phone: {
          ...user.phone,
          country: ((user || {}).phone || {}).country || DialCodes[0].code
        }
      }}
      validationSchema={AccountUpdateSchema}
      onSubmit={values => updateUserInformation(values)}
      enableReinitialize
    >
      {({ values, dirty, handleReset, handleChange, handleSubmit, handleBlur, errors, touched }) => {
        const selectedDialCode = getDialCodeFromCountryCode(values.phone.country);

        return (
          <>
            <AccountFormFields
              user={user}
              dirty={dirty}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              selectedDialCode={selectedDialCode}
              updateLoadingState={updateLoadingState}
            />

            <AccountFormActionButtons
              dirty={dirty}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              errors={errors}
              isMobile={isMobile}
            />
          </>
        );
      }}
    </Formik>
  );
};

AccountForm.propTypes = {
  user: T.shape({
    id: T.string,
    phone: T.shape({ country: T.string, number: T.string }),
    'e-mail': T.string
  }).isRequired
};

export default AccountForm;

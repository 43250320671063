import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { NotifyTypo } from 'web-components';

const ContainerLinkBackPage = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Heading = styled(NotifyTypo.Heading2)`
  &.MuiTypography-h4 {
    margin-right: 5px;
    font-size: 14px;
    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`;

function Breadcrumbs({ data }) {
  return (
    <ContainerLinkBackPage>
      {data.map((item, index) =>
        index !== data.length - 1 ? (
          <Link style={{ textDecoration: 'none' }} to={item.route} key={item.name}>
            <Heading color="primary">
              {data.length > 2 && index === data.length - 2 && ' / '}
              {item.name}
            </Heading>
          </Link>
        ) : (
          <Heading key={item.name}>{`/ ${item.name}`}</Heading>
        )
      )}
    </ContainerLinkBackPage>
  );
}
Breadcrumbs.propTypes = {
  data: T.arrayOf(Object).isRequired
};

export default Breadcrumbs;

import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, NotifyTypo } from 'web-components';

import { getConfirmations } from '../../redux/ui/selectors';
import { resolveConfirmationRequest } from '../../redux/ui/confirmations/actions';
import { COLOR_PRIMARY } from '../../attrs/colors';
import { StyledDialogHeader, StyledDialog, StyledDialogActions, StyledDialogBody, ContentBody } from './style';

const { Body1, Body2, InfoText } = NotifyTypo;

const ConfirmationDialog = () => {
  const dispatch = useDispatch();
  const confirmation = useSelector(getConfirmations);

  const handleClose = () => {
    if (confirmation.disagreeAction) {
      confirmation.disagreeAction();
    }
    dispatch(resolveConfirmationRequest());
  };

  const handleAgree = () => {
    dispatch(resolveConfirmationRequest());
    confirmation.action();
  };

  const { t } = useTranslation();
  const open = typeof confirmation === 'object';

  return (
    confirmation && (
      <>
        <StyledDialog open={open}>
          <StyledDialogHeader
            highlightColor={COLOR_PRIMARY}
            onClose={handleClose}
            closeButtonProps={{ color: 'primary' }}
          >
            {t(confirmation.title)}
          </StyledDialogHeader>
          <Dialog.SubHeader withDivider={false}>
            <InfoText style={{ marginLeft: '0.5rem' }}>{t(confirmation.subtitle)}</InfoText>
          </Dialog.SubHeader>
          <StyledDialogBody>
            <ContentBody>
              <Body1>{t(confirmation.message)}</Body1>
              {confirmation.extraValue && (
                <Body2 style={{ fontWeight: 500, marginLeft: 5 }}>{`${confirmation.extraValue}?`}</Body2>
              )}
            </ContentBody>
            {confirmation.second_message && (
              <Body1 style={{ marginLeft: '0.5rem' }}>{t(confirmation.second_message)}</Body1>
            )}
          </StyledDialogBody>
          <StyledDialogActions>
            <Button
              data-selector="confirmation-modal-disagree-button"
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {t('dialog.confirmation.disagree')}
            </Button>
            <Button
              data-selector="confirmation-modal-agree-button"
              variant="contained"
              color="secondary"
              onClick={handleAgree}
            >
              {confirmation.delete ? t(confirmation.delete) : t('dialog.confirmation.agree')}
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      </>
    )
  );
};

export default ConfirmationDialog;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import T from 'prop-types';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResolutionCheck, NotifyTypo } from 'web-components';

import NoMachinesImg from '../../attrs/images/dashboard_no_machines.svg';
import NoSensorsImg from '../../attrs/images/dashboard_no_sensors.svg';
import NoUsersImg from '../../attrs/images/user_management_no_users.svg';
import Rotate from '../../attrs/images/dashboard_compare_sensors_rotate.svg';
import { getUserRole } from '../../redux/user/selectors';
import PERMISSIONS from '../../attrs/permissions';
import { EmptyStateWrapper, ButtonEmpty } from './elements';
import { EMPTY_STATE_IMAGE_SIZE_TABLET, EMPTY_STATE_IMAGE_SIZE_DESKTOP } from '../../attrs/layout';

const { Heading1, PageHeadingSubtitle } = NotifyTypo;

const EMPTY_STATES_IMG = {
  machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines',
    actionText: 'empty_state.no_machines_manager_action_text',
    actionButtonText: 'empty_state.no_machines_manager_button_text',
    actionLink: '/machines'
  },
  machines_tracked: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines_tracked',
    actionText: 'empty_state.no_machines_tracked_action_text'
  },
  machines_not_tracked: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines_not_tracked',
    actionText: 'empty_state.no_machines_not_tracked_action_text'
  },
  sensors: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_sensors'
  },
  machinesHistoricalData: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines_historical'
  },
  sensorHistoricalData: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_sensors_historical'
  },
  user_management_pending: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_pending',
    actionText: 'empty_state.no_users_pending_action_text'
  },
  user_management_active: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_active'
  },
  sensors_comparison_noSensorsSelected: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.batch_sensors_comparison_no_sensor_selected',
    actionText: 'empty_state.batch_sensors_comparison_no_sensor_selected_action_text'
  },
  sensors_comparison_too_small_width: {
    imgSrc: Rotate,
    mainText: 'empty_state.sensors_comparison_too_small_width',
    actionText: 'empty_state.sensors_comparison_too_small_width_action_text'
  },
  batch_sensors_comparison_too_small_width: {
    imgSrc: Rotate,
    mainText: 'empty_state.batch_sensors_comparison_too_small_width',
    actionText: 'empty_state.batch_sensors_comparison_too_small_width_action_text'
  },
  batch_sensors_comparison_no_sensor_selected: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.batch_sensors_comparison_no_sensor_selected',
    actionText: 'empty_state.batch_sensors_comparison_no_sensor_selected_action_text'
  },
  alarms_tracked: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.batch_sensors_comparison_no_sensor_selected',
    actionText: 'empty_state.batch_sensors_comparison_no_sensor_selected_action_text'
  },
  metrics: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_sensors'
  },
  graph_templates: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_templates'
  }
};

export const EmptyState = ({ className, type, action }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();
  const typeState = EMPTY_STATES_IMG[type];
  const role = useSelector(getUserRole);
  let permissions = null;

  if (action) {
    permissions = PERMISSIONS[action.permissionBase][role];
  }

  const imgSize = isTablet ? EMPTY_STATE_IMAGE_SIZE_TABLET : EMPTY_STATE_IMAGE_SIZE_DESKTOP;
  const isPermitted = permissions && permissions[action.permissionField];

  return (
    <EmptyStateWrapper className={className} data-selector="empty-state">
      <Avatar style={{ height: imgSize, width: imgSize }} src={typeState.imgSrc} />
      <Heading1 gutterBottom align="center" style={{ margin: '1rem' }}>
        {t(typeState.mainText)}
      </Heading1>
      {(isPermitted || (!action && typeState.actionText)) && (
        <PageHeadingSubtitle align="center" style={{ color: '#7A7D85' }}>
          {t(typeState.actionText)}
        </PageHeadingSubtitle>
      )}
      {isPermitted && (
        <ButtonEmpty component={Link} color="primary" size="large" variant="contained" to={t(typeState.actionLink)}>
          {t(typeState.actionButtonText)}
        </ButtonEmpty>
      )}
    </EmptyStateWrapper>
  );
};

EmptyState.propTypes = {
  className: T.string,
  type: T.string.isRequired,
  action: T.shape({ permissionBase: T.string, permissionField: T.string })
};

EmptyState.defaultProps = {
  className: '',
  action: null
};

export default EmptyState;

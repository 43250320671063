import styled from 'styled-components';
import { Container } from '@mui/material';
import { PageHeader as Header } from 'web-components';

export const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    padding: calc(110px + 2.5rem) 3.75rem 0;

    ${theme.breakpoints.down('sm')} {
      padding: calc(100px + 2.5rem) 1.75rem 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
`
);

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
  @media (max-width: 768px) {
    margin-left: 0.7rem;
  }
`;

export const PageHeader = styled(Header)`
  &.MuiPaper-root {
    background-color: #f7f7fa;
  }
`;

import { toPng } from 'html-to-image';

/**
 * Determines the direction of a y-axis label based on index.
 * @param {number} value - The index value.
 * @returns {string} - The direction ('left' or 'right').
 */
export function determineDirection(value) {
  if (value === 0 || value % 2 === 0) {
    return 'left';
  }
  return 'right';
}

/**
 * Size for each y-axis label.
 */
// here
const yAxioSize = 60;

/**
 * Calculates the space to render y-axis labels.
 * @param {number} input - The input value.
 * @returns {number} - The calculated value.
 */
export function calculateValue(input) {
  return Math.floor(input / 2) * yAxioSize;
}

/**
 * Finds a status date that is closest to the target date.
 * @param {Date} target - The target date.
 * @param {Array} dates - The array of dates.
 * @returns {Object} - The closest status date object.
 */
export function findClosestStatusDate(target, dates) {
  return dates.reduce((prev, curr) => (Math.abs(curr.t - target) < Math.abs(prev.t - target) ? curr : prev));
}

/**
 * Returns sensor amount for left/right based on the input side number.
 *
 * @param {number} input - Amount of sensors.
 * @returns {number[]} - Sensors in each side as an array.
 */
export const getSensorBySide = input => {
  const sensorBySide = {
    1: [1, 0],
    2: [1, 1],
    3: [2, 1],
    4: [2, 2],
    5: [3, 2],
    6: [3, 3],
    7: [4, 3],
    8: [4, 4]
  };
  return sensorBySide[input] || [0, 0];
};

/**
 * Calculates pixel value to be used as padding in the chart sides based on the amount of sensors.
 * @param {number} size - The number of sensors.
 * @returns {Array} - Array of pixel value for each chart side.
 */
export function sideSpace(size) {
  if (typeof size !== 'number' || size <= 0) {
    // Validate input is a non-negative number
    return ['30px', '30px'];
  }
  const sensorBySide = getSensorBySide(size);
  const sensorLeftSide = sensorBySide[0];
  const sensorRightSide = sensorBySide[1];
  const leftPxSize = `${sensorLeftSide * yAxioSize}px`;
  const rightPxSize = `${Math.max(sensorRightSide * yAxioSize, 30)}px`;
  return [leftPxSize, rightPxSize];
}

/**
 * Helps to get the status color.
 */
export const statusMap = {
  IDLE: '#5C35AE',
  MAINTENANCE: '#397CCA',
  RUNNING: '#59D66E',
  NODATA: '#7A7D85',
  CRITICAL: '#F13309',
  WARNING: '#F19909',
  FUTURE: 'rgba(255, 255, 255, 0)'
};

/**
 * Gets a range even for negative values.
 * @param {number} begin - The beginning value.
 * @param {number} end - The ending value.
 * @returns {number} - The calculated range.
 * @throws {Error} - If both begin and end are not numbers.
 */
export function getRange(begin, end) {
  // Validate input
  if (typeof begin !== 'number' || typeof end !== 'number') {
    throw new Error('Both begin and end must be numbers');
  }
  // Calculate the range based on the conditions
  return begin < 0 ? end - begin : end;
}

/**
 * Decimates an array of data by removing elements based on a threshold.
 *
 * @param {Array} data - Array of data to be decimated.
 * @param {number} threshold - Treshold value for decimation.
 * @returns {Array} - Decimated array.
 */
export const decimateData = (data, threshold) => {
  const decimated = [];
  for (let i = 0; i < data.length; i += threshold) {
    decimated.push(data[i]);
  }
  return decimated;
};

/**
 * Converts the rendered chart image to a base64 encoded string.
 * The bottom 24 pixels are cut off to remove zoom controls.
 *
 * @param {object} chartInstance - ECharts instance.
 * @param {number} maxWidth - maximum width of the resized image.
 * @param {number} maxHeight - maximum height of the resized image.
 * @returns {string} - base64 encoded image string.
 */
export const convertChartTemplate2Bas64 = (chartInstance, maxWidth, maxHeight) => {
  // render canvas from the ECharts instance
  const originalCanvas = chartInstance.getRenderedCanvas({
    type: 'png',
    pixelRatio: 1,
    backgroundColor: '#fff'
  });

  // Create an off-screen canvas for resizing
  const resizedCanvas = document.createElement('canvas');
  let { width } = originalCanvas;
  let { height } = originalCanvas;

  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else if (height > maxHeight) {
    width *= maxHeight / height;
    height = maxHeight;
  }

  resizedCanvas.width = width;
  resizedCanvas.height = height;
  const ctx = resizedCanvas.getContext('2d');
  ctx.drawImage(originalCanvas, 0, 0, width, height + 24);

  return resizedCanvas.toDataURL('image/png', 1);
};

/**
 * Generates a data URL image from the given element reference.
 *
 * @param {React.RefObject} elementRef - reference to the element to generate the image from.
 * @returns {Promise<string>} A promise that resolves to the compressed data URL of the generated image.
 */
export const generateImage = async elementRef => {
  let compressedDataUrl = '';
  if (elementRef.current) {
    const dataUrl = await toPng(elementRef.current, { backgroundColor: '#fff', quality: 0.7 });
    compressedDataUrl = dataUrl;
  }
  return compressedDataUrl;
};

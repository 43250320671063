import styled from 'styled-components';

import { PRIMARY_TEXT_COLOR } from 'attrs/colors';

export const DesktopContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 380px;
`;

export const CreateTicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;

  & > .create-ticket {
    margin-bottom: 1.25rem;
  }
`;

export const ContactsWrapper = styled.div`
  width: 100%;
  margin-top: 3.25rem;

  & > div {
    margin-left: 4.5rem;
  }

  & .info-text {
    margin-bottom: 0.75rem;
  }

  & .icon {
    margin-right: 1rem;
  }

  & .phone-icon {
    font-size: 1.875rem;
  }

  & .copy-to-clipboard {
    margin-left: 1.5rem;

    & svg {
      font-size: 1.875rem;
    }
  }

  & a {
    color: ${PRIMARY_TEXT_COLOR};
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1.25rem;
`;

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';

import EmptyState from '../../../../components/EmptyState';
import SensorGraph from '../../../../components/Graphs/sensor';
import { getSelectedSensorHistory } from '../../../../redux/machines/selectors';
import { getData } from '../../../../redux/rootSelectors';
import withLoadingState from '../../../../helpers/withLoadingState';

const SensorHistoricalGraph = () => {
  const { id: machineId } = useParams();
  const history = useSelector(compose(getData, getSelectedSensorHistory));
  const hasData = history.chartData.length > 0;

  return hasData ? <SensorGraph machineId={machineId} /> : <EmptyState type="sensorHistoricalData" />;
};

export default withLoadingState(SensorHistoricalGraph);

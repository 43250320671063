import styled from 'styled-components';
import { Card, Typography } from '@mui/material';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledTypography = styled(Typography)`
  margin-right: auto;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

import React, { useState } from 'react';
import { Button, IconButton, CircularProgress, Divider } from '@mui/material';
import QrReader from 'react-qr-reader';
import { useTranslation } from 'react-i18next';
import { NotifyTypo, useResolutionCheck, NotifyIcon } from 'web-components';

import { ContentMarginWrapper, QrReaderWrapper } from './SupportSpareParts.styled';

const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const SupportSpareParts = () => {
  const { t } = useTranslation();
  const [qrState, setQrState] = useState(false);
  const [qrUrlResult, setUrlResult] = useState(null);
  const [qrError, setQrError] = useState(null);
  const { isMobile } = useResolutionCheck();

  const handleError = () => {
    setQrError(true);
  };

  const handleOpen = () => {
    setQrState(!qrState);
    setUrlResult(null);
    if (!qrState) {
      setQrError(false);
    }
  };

  const handleScan = data => {
    if (data) {
      setQrError(false);
      if (isValidHttpUrl(data)) {
        setUrlResult(data);
      }
    }
  };

  const qrActionButton = qrUrlResult ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop: isMobile && '1.5rem'
      }}
    >
      <div style={{ flex: 1, marginRight: '.5rem' }}>
        <Button color="primary" fullWidth variant="contained" href={qrUrlResult} target="_blank">
          <NotifyTypo.Body1 variant="button" noWrap>
            {t('support.go_to')}
          </NotifyTypo.Body1>
        </Button>
      </div>
      <IconButton key="close" aria-label="close" color="inherit" onClick={() => setUrlResult(null)}>
        <NotifyIcon fontSize="small" iconName="close" />
      </IconButton>
    </div>
  ) : (
    <Button
      color="primary"
      endIcon={
        qrError ? (
          <NotifyIcon fontSize="small" iconName="warning" style={{ marginLeft: '1rem' }} />
        ) : (
          <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
        )
      }
      fullWidth
      variant="outlined"
      onClick={handleOpen}
      size="large"
      style={{ marginTop: isMobile && '1.5rem' }}
    >
      {t('support.scanning')}
    </Button>
  );

  return (
    <>
      {!isMobile && <Divider style={{ margin: '2rem 0' }} />}
      <ContentMarginWrapper mobile={isMobile}>
        {!qrState ? (
          <Button
            color="primary"
            fullWidth
            variant="outlined"
            onClick={handleOpen}
            style={{ marginTop: isMobile && '1.5rem' }}
            size="large"
          >
            {t('support.spare_parts')}
          </Button>
        ) : (
          qrActionButton
        )}
      </ContentMarginWrapper>
      {qrState && (
        <QrReaderWrapper mobile={isMobile}>
          {qrError ? t('errors.generic') : <QrReader delay={300} onError={handleError} onScan={handleScan} />}
        </QrReaderWrapper>
      )}
    </>
  );
};

export default SupportSpareParts;
